<!--
 * Love and Peace
 * Components: 登录 / 登录弹窗
 * TODO:
 * NOTE: 只支持 中国手机号
-->
<template>
    <Popup v-show="!isShow" ref="popup" class="custom-bg" :value="show" :hide-on-blur="false" style="width: 100%" @on-hide="$emit('close')">
        <div class="content-wrap txt-center" style="width: 100%">
            <!-- header -->
            <div class="title txt-center">
                <span class="fs-16">登陆解锁本场职位</span>
            </div>
            <!-- 主体部分 -->
            <div class="input-wrap mt-5 mlr-3">
                <div class="flex-row-start" style="border-bottom: 1px solid #f3f3f6">
                    <div class="flex-row-start flex-none" style="min-width: 100px" @click.stop="actionCountryCode">
                        <div class="flex-auto pl-2">
                            {{ countryName }}<span class="ml-1">{{ countryCode }}</span>
                        </div>
                        <i class="flex-none color-gray txt-right icon-arrow-down"></i>
                    </div>
                    <XInput ref="phone" v-model="phone" style="flex: 1" class="input-custom fs-16" placeholder="请输入手机号" type="tel" autocomplete="username"></XInput>
                </div>
                <XInput ref="code" v-model="code" class="input-custom mt-2 fs-16" placeholder="输入验证码" type="tel" autocomplete="one-time-code">
                    <span slot="right" class="fs-14 send-code" @click="actionSendCode">{{ sendCodeText }}</span>
                </XInput>
            </div>
            <div class="btn-wrap">
                <div class="login-btn fs-16" @click="submit">注册/登录</div>
                <div v-if="weixin.isWx" class="weixin-btn fs-16">
                    <a :href="weixin.href" style="color: #ffffff">微信一键登录</a>
                </div>
            </div>
            <div class="flex-column-center fs-xs p-1 color-gray">
                <div class="mt-1 txt-center">点击“注册/登录”按钮，即表示你同意<a href="https://static.nowcoder.com/protocol/register.html" target="_blank">《注册协议》</a>和<a href="https://static.nowcoder.com/protocol/privacy-policy.html" target="_blank">《隐私政策》</a></div>
            </div>
            <div style="height: 20px"></div>
        </div>
        <!-- 滑动验证 弹窗 -->
        <PopupCaptcha ref="captcha" v-model="captchaShow" style="z-index: 1001" :type="captchaType" @verify="actionVerify" />
    </Popup>
</template>

<script>
import UI from '@/js/lib/util/ui';
import Utils from '@/js/lib/util/util';
import {Popup, XInput} from '@ncfe/nc.vux';
import PopupCaptcha from '@/js/cpn/popup/PopupCaptcha';

const CODE_CHINA = '+86';
const Uri = require('@ncfe/nc.uri');
const Str = require('@ncfe/nc.str');
const $ = require('@ncfe/nc.jquery');
const Conf = require('@/js/lib/conf');
const Base = require('@ncfe/nc.base');
const Time = require('@ncfe/nc.time');
const LoginAction = require('@/js/lib/action/login');
const App = require('@/js/lib/util/app');

export default {
    components: {Popup, XInput, PopupCaptcha},
    props: ['show', 'success'],
    data: fData,
    mounted: fMounted,
    methods: {
        val: fVal,
        check: fCheck,
        submit: fSubmit,
        actionSendCode: fActionSendCode,
        actionVerify: fActionVerify,
        actionCountryCode: fActionCountryCode,

        showToast: fShowToast,
        checkPhone: fCheckPhone,

        encrypt: fEncrypt,
        getRequestBody: fGetRequestBody,

        actionClose
    },
    watch: {
        show: {
            handler: function () {
                let that = this;
                if (!that.show) return;
            },
            immediate: true
        }
    },
    computed: {
        fullPhone: fFullPhone,
        isModeResetPwd: fIsResetPwd,
        isModeSMSLogin: fIsModeSMSLogin
    }
};

function fData() {
    return {
        register: false,
        captchaShow: false,
        captchaType: Conf.captcha.sendVerifyCode,

        countryCode: CODE_CHINA,
        countryName: '中国',
        phone: '',
        code: '',

        isShow: false,
        weixin: {
            isWx: App.isWx,
            href: Utils.creatWxQuickLoginUrl()
        },
        sendCodeText: '获取验证码',
        mode: 'sms'
    };
}

function fMounted() {
    let that = this;
    $(document.body).append(that.$refs.captcha.$el);
}

function fSubmit() {
    let that = this;
    if (!that.check()) return;
    let bRight = true;

    if (that.isModeSMSLogin && !that.register) {
        let oData = that.getRequestBody();
        bRight &&
            LoginAction.login({
                body: {account: oData.phone, code: oData.code},
                call: oResult => {
                    that.showToast('登录成功');
                    that.success && that.success(oResult);
                    that.actionClose();
                },
                error: oResult => that.showToast(oResult.msg)
            });
    } else if (that.register) {
        bRight &&
            LoginAction.registerPhone({
                body: {...that.getRequestBody(), from: Uri.getParam('from')},
                call: oResult => {
                    that.showToast('注册成功');
                    // 谷歌注册转化
                    window.gtag_report_conversion && window.gtag_report_conversion();
                    that.success && that.success(oResult);
                    that.actionClose();
                },
                error: oResult => that.showToast(oResult.msg)
            });
    }
}

function fActionSendCode() {
    let that = this;
    let bChina = that.countryCode === CODE_CHINA;
    let sAccount = that.phone;
    if (bChina ? !Str.isPhone(sAccount) : !/^\d+$/.test(sAccount)) {
        that.showToast('请输入正确的手机号码');
        return false;
    }
    !that.isSending &&
        that.checkPhone(that.fullPhone, function (bRight) {
            bRight && (that.captchaShow = true);
        });
}

function fCheckPhone(sPhone, fCb) {
    let that = this;
    let oPhoneMap = (that.phoneMap = that.phoneMap || {});
    let oPhoneTipsMap = (that.phoneTipsMap = that.phoneTipsMap || {});
    if (!sPhone) {
        return fCb && fCb(false);
    }
    if (sPhone in oPhoneMap) {
        return _fDeal(oPhoneMap[sPhone], oPhoneTipsMap[sPhone]);
    }
    LoginAction.checkPhone({
        query: {phone: that.getRequestBody().phone},
        call: () => _fDeal(true),
        error: oResult => _fDeal(false, oResult)
    });

    function _fDeal(bRight, oResult) {
        oPhoneMap[sPhone] = bRight;
        oPhoneTipsMap[sPhone] = oResult;
        if (that.isModeSMSLogin) {
            if (bRight) {
                that.captchaShow = true;
                that.register = true;
            }
            !bRight && fCb && fCb(true);
        } else {
            if (!bRight) {
                oResult = oResult || {};
                let sMessage = '该手机号已注册<br />请直接登录' || oResult.msg;
                that.showToast(sMessage);
            }
            fCb && fCb(bRight);
        }
    }
}

function fActionVerify(oData) {
    let that = this;
    that.isSending = true;
    LoginAction.sendMobileCodeV2({
        body: Base.extend({phone: that.fullPhone}, oData),
        call: function () {
            that.showToast('验证码发送成功');
            Time.countDown({
                second: 120,
                call: nSecond => (that.sendCodeText = nSecond + '秒重新获取'),
                end: () => {
                    that.sendCodeText = '重新获取验证码';
                    that.isSending = false;
                }
            });
        },
        error: oResult => {
            that.isSending = false;
            that.showToast(oResult.msg);
        }
    });
}

function fShowToast(sContent) {
    let that = this;
    that.$vux.toast.show({
        type: 'text',
        text: sContent,
        width: '10em',
        position: 'middle'
    });
}

function fCheck() {
    let that = this;
    let oData = that.val();
    let bChina = that.countryCode === CODE_CHINA;
    let sAccount = oData.phone;
    let msg = '';
    if (!sAccount) msg = '请输入手机号码';
    else if (bChina ? !Str.isPhone(sAccount) : !/^\d+$/.test(sAccount)) msg = '请输入正确的手机号码';
    else if (!oData.code) msg = '请输入验证码';
    msg && that.showToast(msg);
    return !msg;
}

function fVal() {
    let that = this;
    that.phone = $(that.$refs.phone.$el).find('input').val();
    that.code = $(that.$refs.code.$el).find('input').val();

    return {
        countryCode: $.trim(that.countryCode),
        phone: $.trim(that.phone),
        code: $.trim(that.code)
    };
}

function fEncrypt(sVal) {
    let oCrypt = new window.NCJSEncrypt();
    oCrypt.setPublicKey($('#jsNCPublicKey').html() || '');
    return oCrypt.encrypt($.trim(sVal));
}

function fGetRequestBody() {
    let that = this;
    let oData = that.val();
    oData.phone = that.fullPhone;
    oData.client = '2';
    delete oData.countryCode;
    return oData;
}

function fActionCountryCode() {
    let that = this;
    UI.popupCountryCode({
        call: oItem => {
            that.countryCode = oItem.code;
            that.countryName = oItem.name;
        }
    });
}

function actionClose() {
    this.$emit('close');
}

function fFullPhone() {
    let that = this;
    return `${that.countryCode || ''}${that.phone || ''}`;
}

function fIsResetPwd() {
    let that = this;
    return that.mode === 'resetPwd';
}

function fIsModeSMSLogin() {
    let that = this;
    return that.mode === 'sms';
}
</script>

<style lang="scss" scoped>
.custom-bg {
    background-color: #ffffff !important;
    border-radius: 10px 10px 0px 0px;
}

.content-wrap {
    color: #333333;
    margin-top: 14px;

    .title {
        position: relative;
        font-weight: 500;
    }
    .close {
        position: absolute;
        right: 18px;
        padding: 6px 8px;
    }
    .qr {
        margin: 0 auto;
        background-color: yellow;
        img {
            height: 107px;
            width: 107px;
        }
    }
    .bottom-word {
        color: #999999;
    }
}
.input-wrap {
    .input-custom {
        height: 25px;
        border-bottom: 1px solid #f3f3f6;
    }
    .input-custom::before {
        content: '';
        border: none;
    }

    ::v-deep input::-webkit-input-placeholder {
        color: #c3c3c6;
    }
    ::v-deep input:-moz-placeholder {
        color: #c3c3c6;
    }
    ::v-deep input::-moz-placeholder {
        color: #c3c3c6;
    }
    ::v-deep input:-ms-input-placeholder {
        color: #c3c3c6;
    }
    .send-code {
        color: #32ca99;
        font-weight: 400;
    }
}

.login-btn,
.weixin-btn {
    width: 347px;
    height: 42px;
    line-height: 42px;
    border-radius: 5px;
}
.login-btn {
    border: 1px solid #32ca99;
    margin: 30px auto 0 auto;
    color: #32ca99;
}
.weixin-btn {
    background: linear-gradient(146deg, #46e394 0%, #26bb9c 100%);
    margin: 10px auto 0 auto;
    color: #ffffff;
}
.captcha-wrap {
    z-index: 1001;
}
</style>
