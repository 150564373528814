import CommonAction from '@/js/lib/action/common';
import {Salary, PageSourcePathnameMap, PageSourceOriginMap, PageSourceIdMap, ChannelLevelOneMap} from '@/utils/constants';
import Base from '@ncfe/nc.base';
let MUtil = require('@/js/lib/util/util');

const Uri = require('@ncfe/nc.uri');

export default {
    download,
    getSalaryMoney,
    getCareerJobNameBycareerJobId,
    getPageSourceVar, // 页面来源
    getChannelLevelOneVar, // 获取一级渠道
    getPdfPreviewUrl,
    getMainUrl,
    imgCompress,
    formatTime
};

// 下载资源
function download(sUrl) {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = sUrl;
    // 保存下载的文件名
    let aResult = /.+\/(.+\.(png|jpg|jpeg|pdf|txt|doc|docx|wps|zip|xlsx|xls))$/gi.exec(sUrl);
    let sName = aResult[1];
    a.download = sName;
    a.target = '_self';
    document.body.appendChild(a);
    a.click();
    // 移除元素
    document.body.removeChild(a);
}

async function getCareerJobNameBycareerJobId(sCareerJob) {
    try {
        const response = await CommonAction.allCareerJobs({type: 'get'});
        var aCareerJobs = response.data?.allJobs;
        var aJobs = [0, 0, 0];
        if (aCareerJobs.length === 0) return aJobs;
        Base.forEach(aCareerJobs, level1 => {
            Base.forEach(level1.subJobs, level2 => {
                Base.forEach(level2.subJobs, level3 => {
                    if (level3.jobInfo.id === +sCareerJob) {
                        aJobs = [level1.jobInfo.name, level2.jobInfo.name, level3.jobInfo.name];
                    }
                });
            });
        });
        return aJobs;
    } catch (error) {}
}

function getSalaryMoney(nSalaryMin, nSalaryMax, nSalaryType, nSalaryMonth) {
    if (nSalaryMin === Salary.SalaryMin && nSalaryMax >= Salary.SalaryMax) {
        return '薪资面议';
    }
    var sSalary = nSalaryMin + '-' + nSalaryMax;
    if (nSalaryType === 1) {
        return sSalary + '元/天';
    } else if (nSalaryType === 2) {
        return nSalaryMonth > 0 ? sSalary + `k · ${nSalaryMonth}薪` : sSalary;
    } else if (nSalaryType === 3) {
        return sSalary + 'w/年';
    } else {
        return '未知';
    }
}

/**
 * @description: 获取页面来源数据
 * 优先级 pageSource透传 => document.referrer
 * => 对 pathname 进行匹配
 * => 对 referrer 进行匹配

 * @param {*}
 * @return {*}
 */
function getPageSourceVar() {
    // pageSource传数字(9月30修改)
    const pageSource = Uri.getParam('pageSource');
    if (pageSource) {
        const res = PageSourceIdMap[pageSource];
        if (res) return res;
    }

    const referrer = document.referrer;
    if (!referrer) return '直接访问';

    let aTag = document.createElement('A');
    aTag.href = referrer;
    const pathname = aTag.pathname;

    let pageSourceVar = '未知来源';
    if (pathname && pathname !== '/') {
        for (const [key, value] of Object.entries(PageSourcePathnameMap)) {
            if (pathname.includes(key)) {
                pageSourceVar = value;
            }
        }
    } else {
        for (const [key, value] of Object.entries(PageSourceOriginMap)) {
            if (referrer.includes(key)) {
                pageSourceVar = value;
            }
        }
    }
    return pageSourceVar;
}

// import SrcUtils from '@/utils';

function getChannelLevelOneVar(channel) {
    if (!channel) return '无';

    const levelOne = channel.split('_');
    if (!levelOne.length) return '无';

    return ChannelLevelOneMap[levelOne[0]] || '无';
}

function getPdfPreviewUrl(sUrl) {
    var that = this;
    var sUrl = (sUrl || '').replace(/^https:\/\//, '').replace(/^http:\/\//, '');
    sUrl = `https://${sUrl}`;
    return `https://preview.nowcoder.com/?ssl=1&furl=${window.encodeURIComponent(sUrl)}`;
}

function getMainUrl(sUrl) {
    var that = this;
    var isInDev = MUtil.isInDev();
    var isInPre = MUtil.isInPre();
    return isInDev ? `https://d.nowcoder.com${sUrl}` : isInPre ? `https://pre.nowcoder.com${sUrl}` : `https://www.nowcoder.com${sUrl}`;
}

function imgCompress(url) {
    return Uri.addParam({'x-oss-process': 'image/resize,m_mfit,w_360'}, url);
}

function formatTime(date) {
    if (date == '' || !date) {
        return '';
    }
    var date = new Date(date);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    var d = date.getDate();
    d = d < 10 ? '0' + d : d;
    return y + '/' + m + '/' + d;
}
