<template>
    <transition name="expand" @after-enter="afterEnter" @enter="enter" @leave="leave">
        <slot />
    </transition>
</template>

<script>
// Based on https://github.com/maoberlehner/transition-to-height-auto-with-vue/blob/master/src/components/TransitionExpand.vue

export default {
    methods: {
        afterEnter: fAfterEnter,
        enter: fEnter,
        leave: fLeave
    }
};

function fAfterEnter(oEl) {
    oEl.style.height = `auto`;
}

function fEnter(oEl) {
    var {width} = getComputedStyle(oEl);

    oEl.style.width = width;
    oEl.style.position = `absolute`;
    oEl.style.visibility = `hidden`;
    oEl.style.height = `auto`;

    var {height} = getComputedStyle(oEl);

    oEl.style.width = null;
    oEl.style.position = null;
    oEl.style.visibility = null;
    oEl.style.height = 0;

    // Force repaint to make sure the
    // animation is triggered correctly.
    getComputedStyle(oEl).height;

    setTimeout(() => {
        oEl.style.height = height;
    });
}

function fLeave(oEl) {
    var {height} = getComputedStyle(oEl);

    oEl.style.height = height;

    // Force repaint to make sure the
    // animation is triggered correctly.
    getComputedStyle(oEl).height;

    setTimeout(() => {
        oEl.style.height = 0;
    });
}
</script>

<style scoped lang="scss">
* {
    will-change: height;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.expand-enter-active,
.expand-leave-active {
    transition: height 0.3s ease-in-out;
    overflow: hidden;
}

.expand-enter,
.expand-leave-to {
    height: 0;
}
</style>
