// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 * @export 活动页面浏览
 * @param {*} oData
 * @param activityName_var 活动名称
 * @param UniqueView_var 浏览用户
 * @param pageTime_var 页面时长（秒）
 * @param pageType_var 页面类型
 * @param platform_var 平台
 */
function activityPageView(oData) {
    gio('activityPageView', oData);
}

/**
 *
 * @export 分享按钮点击
 * @param {*} oData
 * @param activityName_var 活动名称
 * @param UniqueView_var 浏览用户
 */
function buttonShareClick(oData) {
    gio('buttonShareClick', oData);
}

/**
 * @export 活动规则按钮点击
 * @param {*} oData
 * @activityName_var 活动名称
 */
function buttonRuleClick(oData) {
    gio('buttonRuleClick', oData);
}
/**
 * @export 验证码按钮点击
 * @param {*} oData
 * @activityName_var 活动名称
 */
function buttonCaptchaClick(oData) {
    gio('buttonCaptchaClick', oData);
}
/**
 * @export 手机号提交成功
 * @param {*} oData
 * @activityName_var 活动名称
 */
function phonenumSubmit(oData) {
    gio('phonenumSubmit', oData);
}
/**
 * @export 回流页面浏览
 * @param {*} oData
 * @activityName_var 活动名称
 * @pageTime_var 页面时长(秒)
 * @UniqueView_var 浏览用户
 */
function backPageView(oData) {
    gio('backPageView', oData);
}
/**
 * @export 领取按钮点击
 * @awardName_var 奖品名称
 * @activityName_var 活动名称
 * @pageType_var 页面类型
 * @platform_var 平台
 */
function buttonGetClick(oData) {
    gio('buttonGetClick', oData);
}
/**
 * @export 下载页面浏览
 * @param {*} oData
 * @activityName_var 活动名称
 * @pageType_var 页面类型
 * @pageTime_var 页面时长（秒）
 * @environmentType_var 环境类型
 */
function downloadPageView(oData) {
    gio('downloadPageView', oData);
}
/**
 * @export 下载按钮点击
 * @param {*} oData
 * @activityName_var 活动名称
 * @environmentType_var 环境类型
 */
function buttonDownloadClick(oData) {
    gio('buttonDownloadClick', oData);
}
/**
 * @export 跳转APP
 * @param {*} oData
 * @activityName_var 活动名称
 */
function jump(oData) {
    gio('jump', oData);
}
/**
 * @export 打开按钮点击
 * @param {*} oData
 * @activityName_var 活动名称
 * @environmentType_var 环境类型
 */
function buttonOpenClick(oData) {
    gio('buttonOpenClick', oData);
}
/**
 * @export 打开按钮点击
 * @param {*} oData
 * @activityName_var 活动名称
 * @UniqueView_var 浏览用户
 */
function longPress(oData) {
    gio('longPress', oData);
}
/**
 * @export 注册登录按钮点击
 * @param {*} oData
 * @activityName_var 活动名称
 */
function buttonRegisterLoginClick(oData) {
    gio('buttonRegisterLoginClick', oData);
}

/**
 * @export 注册登录按钮点击
 * @param {*} oData
 * @activityName_var 活动名称
 */
function clickActivity(oData) {
    gio('clickActivity', oData);
}
/**
 * @export 活动互动
 * @param {*} oData
 * @activityName_var 活动名称
 * @activitySubmit_var 是否输入手机号
 * @isViewActivityRules_var 是否查看活动规则
 * @isClickAwardButton_var 是否点击领取按钮
 * @isClickDownloadButton_var 是否点击下载按钮
 * @isClickOpenButton_var 是否点击申请(申请特权)按钮
 *
 */
function activityInteractive(oData) {
    gio('activityInteractive', oData);
}
/**
 * @export 离开活动页面
 * @param {*} oData
 * @activityName_var 活动名称,
 * @isShareSuccess_var 是否分享成功
 * @pageTime_var 页面时长（秒）
 */
function leaveActivityPage(oData) {
    gio('leaveActivityPage', oData);
}
export default {
    activityPageView,
    buttonShareClick,
    buttonRuleClick,
    buttonCaptchaClick,
    phonenumSubmit,
    backPageView,
    buttonGetClick,
    downloadPageView,
    buttonDownloadClick,
    jump,
    buttonOpenClick,
    longPress,
    buttonRegisterLoginClick,
    clickActivity,
    activityInteractive,
    leaveActivityPage
};
