let Uri = require('@ncfe/nc.uri');
let $ = require('@ncfe/nc.jquery');
let Time = require('@ncfe/nc.time');
let Browser = require('@ncfe/nc.browser');
let Base = require('@ncfe/nc.base');
let oA = document.createElement('A');

module.exports = {
    jumpUrl: fJumpUrl,
    isNcUrl: fIsNcUrl,
    isWhiteUrl: fIsWhiteUrl,
    isInDev: fIsInDev,
    isInPre: fIsInPre,
    isLogin: fIsLogin,
    goLogin: fGoLogin,
    loadEcharts: fLoadEcharts,
    loadPolyv: fLoadPolyv,
    activePhone: fActivePhone,
    logGio: fLogGio,

    fullscreen: fFullScreen,
    closeWin: fCloseWin,
    getCurrentEnv: fGetCurrentEnv,
    addToken: fAddToken,
    getFormatNumber: fGetFormatNumber,

    getNowpickUrl: fGetNowPickUrl,
    creatWxQuickLoginUrl: fCreatWxQuickLoginUrl /* 创建微信一键登录url */,
    pageViews: fPageViews,
    getUUID: fGetUUID,
    setFontSize: fSetFontSize,
    getEnv: fGetEnv,
    convertSeconds: convertSeconds
};

function fJumpUrl(sUrl, sDefault) {
    const that = this;
    const bNc = that.isNcUrl(sUrl);
    const bWhite = that.isWhiteUrl(sUrl);
    return (bNc || bWhite ? sUrl || sDefault : sDefault) || '/';
}

function fIsWhiteUrl(sUrl) {
    const aWhiteList = ['sti.baidu.com', 'sti-pre.baidu.com'];
    if (!Base.isString(sUrl)) return false;
    oA.href = sUrl;
    let sDomain = oA.href.split('#')[0].split('?')[0].split('/')[2];
    return aWhiteList.includes(sDomain);
}

function fIsNcUrl(sUrl) {
    if (!Base.isString(sUrl)) return false;
    oA.href = sUrl;
    let sDomain = oA.href.split('#')[0].split('?')[0].split('/')[2];
    return /nowcoder\.[a-z]+$/gi.test(sDomain);
}

function fIsInDev() {
    const devHosts = ['md.nowcoder.com', 'md1.nowcoder.com'];
    const host = window.location.host;
    return devHosts.indexOf(host) > -1;
}

function fIsInPre() {
    const preHosts = ['prem.nowcoder.com', 'prem2.nowcoder.com'];
    const host = window.location.host;
    return preHosts.indexOf(host) > -1;
}

function fIsLogin() {
    let that = this;
    return !!(window.globalInfo || {}).ownerId;
}

function fGoLogin(oExt) {
    let that = this;
    if (that.isLogin()) return;
    window.location.href = Uri.addParam({...oExt, callBack: window.location.href}, '/login');
    return true;
}

function fLoadEcharts(fCb, sUrl) {
    let that = this;
    $(document.body).append('<script src="//static.nowcoder.com/lib/echarts.3.7.2.min.js"></script>');
    // $(document.body).append('<script src="https://echarts.baidu.com/dist/echarts.min.js"></script>');
    Time.waitUtil({
        times: 250, // 等60s
        condition: () => !!window.echarts,
        call: fCb
    });
}

function fLoadPolyv(fCb, sUrl) {
    const that = this;
    $(document.body).append('<script src="//player.polyv.net/script/player.js"></script>');
    Time.waitUtil({
        times: 250,
        condition: () => !!window.polyvPlayer,
        call: fCb
    });
}

function fActivePhone(oParam) {
    window.ncAuthActivePhone &&
        window.ncAuthActivePhone({
            call: oParam.call,
            error: oParam.error
        });
    return !!window.ncAuthActivePhone;
}

function fFullScreen(oEl) {
    let that = this;
    let sUserAgent = window.navigator.userAgent || '';
    let bMobile = !!Browser.mobile();
    let bChrome = !!Browser.chrome();
    let bSafari = !!Browser.safari();
    let bFirefox = !!Browser.firefox();
    let bQQBrowser = /QQBrowser/gi.test(sUserAgent);
    let bPureChrome = bChrome && !!window.chrome && !/Browser/gi.test(sUserAgent);
    if (!bMobile) return;
    if (!bPureChrome && !bFirefox && !bQQBrowser && !bSafari) return;

    oEl = oEl || document.documentElement;
    let bFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
    if (!bFullscreen) {
        let oItem = (oEl.requestFullscreen && oEl.requestFullscreen()) || (oEl.mozRequestFullScreen && oEl.mozRequestFullScreen()) || (oEl.webkitRequestFullscreen && oEl.webkitRequestFullscreen()) || (oEl.msRequestFullscreen && oEl.msRequestFullscreen());
        oItem && oItem.catch && oItem.catch(() => console.log('全屏失败'));
    }
}

function fCloseWin(sHref) {
    try {
        window.close();
    } catch (e) {}
    window.setTimeout(() => (window.location.href = sHref || '/'), 500);
}

function fGetCurrentEnv() {
    let oHost = window.location.host;
    return /^m\.nowcoder\.com/gi.test(oHost) ? 'm' : /^prem2?\.nowcoder\.com/gi.test(oHost) ? 'prem' : 'dev';
}

function fAddToken(sToken) {
    let that = this;
    window.document.cookie = `t=${sToken}`;
}

function fGetFormatNumber(nNum) {
    let that = this;
    return nNum > 999 ? `${(nNum / 1000).toFixed(1)}k` : nNum;
}

function fLogGio(sGio) {
    const that = this;
    const gio = window.gio;
    if (!gio) return;
    gio('track', sGio);
}

function fGetNowPickUrl(sUrl) {
    const currentEnv = fGetCurrentEnv();
    const isInDev = currentEnv === 'dev';
    const isInPre = currentEnv === 'prem';
    return isInDev ? `https://mdnowpick.nowcoder.com${sUrl}` : isInPre ? `https://premnowpick.nowcoder.com${sUrl}` : `https://mnowpick.nowcoder.com${sUrl}`;
}

/**
 * @description: 创建微信一键登录url
 * @param {*}
 * @return {String} url
 * const testAppid   = 'wx23bd6b7e6a5bf6d7';
 * const onlineAppid = 'wxfee0340998de6ab1';
 */
function fCreatWxQuickLoginUrl() {
    const that = this;
    const isInDev = that.isInDev();
    const nowAppid = isInDev ? 'wx23bd6b7e6a5bf6d7' : 'wxfee0340998de6ab1';
    const nowUrl = encodeURIComponent(`https://${isInDev ? 'd' : 'www'}.nowcoder.com/oauth2/login/weixin?callBack=${window.location.href}`);

    return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${nowAppid}&redirect_uri=${nowUrl}&response_type=code&scope=snsapi_login&state=mobile`;
}
//页面浏览事件
function fPageViews(fCb) {
    let time = new Date().getTime();
    $(document).on('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
            time = new Date().getTime();
        } else if (document.hidden) {
            fCb && fCb((new Date().getTime() - time) / 1000);
        }
    });
}
//生成uuid
function fGetUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

//设置根节点字体大小
function fSetFontSize() {
    $('html').css({
        fontSize: `${Math.floor((window.screen.width / 375) * 100)}px`
    });
}

function fGetEnv() {
    const host = window.location.host;
    return /^m\.nowcoder\.com/gi.test(host) ? 'prod' : /^prem\.nowcoder\.com/gi.test(host) ? 'pre' : /^md\.nowcoder\.com/gi.test(host) ? 'test' : 'local';
}

// 视频时间展示
function convertSeconds(sec) {
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    let seconds = Math.floor(sec - hours * 3600 - minutes * 60);

    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }

    return hours + ':' + minutes + ':' + seconds;
}
