// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 * 41
 *
 * @export 职位详情页浏览
 * @param {*} oData
 * @param company_var 公司
 */
function officialWebsiteClick(oData) {
    gio('officialWebsiteClick', oData);
}

/**
 * 42
 *
 * @export 职位详情页浏览
 * @param {*} oData
 * @param pageEnter_var 页面入口
 * @param industry_var 行业
 * @param company_var 公司
 * @param positionID_var 职位ID
 * @param positionLevel1_var 职位一级分类
 * @param positionLevel2_var 职位二级分类
 * @param positionLevel3_var 职位三级分类
 * @param position_var 职位
 * @param city_var 城市
 * @param academicRequire_var 学历要求
 * @param workYears_var 工作年限
 * @param becomeregular_var 转正机会
 * @param finance_var 融资阶段
 * @param salary_var 职位薪资
 * @param positionType 职位类型
 */
function jobDetailPage(oData) {
    gio('jobDetailPage', oData);
}

/**
 * @description: 职位卡片点击
 * @use: 校招职位广场，社招职位广场，实习职位广场，专场落地页（https://www.nowcoder.com/activity/v2/special-activity/index/selected3）四个来源的职位卡片曝光时触发
 * @param {*} oData
 * @param oData.positionID_var	    职位id
 * @param oData.train_id_var	    实验ID
 * @param oData.rccm_reason_var	    推荐原因
 * @param oData.train_tag_var	    实验标签
 * @param oData.jobName_var	        职位名
 * @param oData.positionType_var	职位类型
 * @param oData.jobTopTag_var	    热门标签
 * @param oData.jobCity_var	        职位城市
 * @param oData.pit_var	            坑位
 * @param oData.pageSource_var	    页面来源
 * @param oData.channel_var	        卡片渠道信息k
 * @param oData.activity_var	    活动id
 */

function jobCardCLick(oData) {
    gio('jobCardCLick', oData);
}

/**
 * 75
 * @export 招聘专场活动页 页面曝光时触发
 * @param {String} activityName_var 活动名称
 * @param {Number} activity_var     活动ID
 * @param {String} ZCLDYchannel_var 专场渠道信息
 */
function recruitmentSessionPage(oParams) {
    gio('recruitmentSessionPage', oParams);
}

export default {
    officialWebsiteClick,
    jobDetailPage,
    jobCardCLick,
    recruitmentSessionPage
};
