<template>
    <Popup v-model="isShow" popupClass="text-input radius-bottom custom-input-popup" position="top">
        <div class="flex-row-start color-gray hide-txt custom-input-title-box">
            <span class="flex-auto hide-txt" style="color: #333; font-weight: 500" v-html="title"></span>
            <i v-show="showClose" class="icon-close fs-s flex-none" @click="isShow = false"></i>
        </div>
        <x-input ref="input" v-model="value" :type="type" :placeholder="placeholder" @on-enter="submit"></x-input>
        <div class="flex-row-start" style="border-top: 1px solid #f3f3f3">
            <div v-if="cancelTxt" class="custom-btn" @click="isShow = false">{{ cancelTxt }}</div>
            <div class="custom-btn color-green" @click="submit">{{ submitTxt || '确定' }}</div>
        </div>
    </Popup>
</template>

<script>
import Popup, {PopupMix, PopupShow} from '@nc/vue.popup';
import {XInput} from '@ncfe/nc.vux';
import Vue from '@ncfe/nc.vue';
export default {
    show: PopupShow(Vue),

    props: ['title', 'submitTxt', 'showClose', 'placeholder', 'rows', 'type', 'cancelTxt'],
    components: {Popup, XInput},
    mixins: [PopupMix],
    data: fData,
    methods: {
        submit: fSubmit
    },
    watch: {
        isShow: fWatchIsShow
    }
};

function fData() {
    var that = this;
    return {
        value: ''
    };
}

function fSubmit() {
    var that = this;
    that.$emit('submit', that.value, that.title);
    that.close();
}

function fWatchIsShow(bShow) {
    var that = this;
    that.isShow = bShow;
    bShow && that.$nextTick(() => that.$refs.input.focus());
    !bShow && (that.value = '');
}
</script>
<style type="scss">
.text-input {
    padding: 0 0 10px 0;
}
.custom-input-title-box {
    padding-top: 25px;
    padding-bottom: 14px;
    text-align: center;
}
</style>

<style lang="scss" scoped>
.weui-cell {
    max-width: 191px;
    border: 1px solid #f5f5f5;
    margin: 0 auto;
    margin-bottom: 50px;
    padding: 4px 10px;
    border-radius: 6px;
    &::before {
        border-top: none;
    }
}
.custom-input-popup {
    height: auto;
    width: 254px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 0;
}
.custom-btn {
    flex: 1;
    text-align: center;
    font-size: 14px;
    padding: 13px 0;
    height: 14px;
    line-height: 14px;
    & + .custom-btn {
        border-left: 1px solid #f3f3f3;
    }
}
</style>
