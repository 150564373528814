// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};
/**
 * @export 完成全部新手任务时触发
 * @param {String} isCompletetask_var 是否完成
 */
function fCompletenewbietask(oData) {
    gio('completenewbietask', oData);
}
/**
 * @export 完成单个任务时触发
 * @param {String} isCompletetask_var 是否完成
 * @param {String} taskname_var	任务名称
 * @param {String} taskID_var 任务ID
 */
function fCompleteonetask(oData) {
    gio('completeonetask', oData);
}
export default {
    completenewbietask: fCompletenewbietask,
    completeonetask: fCompleteonetask
};
