// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 * 36
 *
 * @export 专栏详情页浏览 页面曝光时触发
 * @param {*} oData
 * @param courseID_var 课程ID
 * @param courseName_var 课程名称
 * @param isFree_var 是否免费
 
 * @param courseAmount_var 课程金额
 * @param courseTeacher_var 课程讲师
 * @param courseType_var 课程类型
 * @param activityType_var 活动类型

 * @param courseLevel1_var 课程一分类
 * @param courseLevel2_var 课程二级分类
 */
function columnDetailPage(oData) {
    gio('columnDetailPage', oData);
}
export default {
    columnDetailPage
};
