<template>
    <div>
        <CmtItem :item="item" class="block vux-1px-tb mb-4" @actionClick="actionCmt(item)" @actionLike="actionLike(item)" @actionCmt="actionCmt(item)"></CmtItem>
        <CmtList :id="item.id" ref="cmtList" class="bg-white" :commentDisable="isDisableReply" :type="2" :isSub="true" @actionNone="actionCmt(item)" @actionClick="actionCmt" @items="actionUpdateItems"></CmtList>
        <div v-if="!isDisableReply" class="plr-2 mtb-4">
            <a href="javascript:void(0);" class="post-action flex-row radius tap-active color-green bg-white" @click="actionCmt(item)"> <i class="icon-add mr-2"></i>添加回复 </a>
        </div>
        <div v-if="isDisableReply" class="p-2 mtb-4 bg-white vux-1px-tb txt-center">该帖评论已关闭</div>
    </div>
</template>
<script>
import Vue from '@ncfe/nc.vue';
import CmtItem from '../item/CmtItem';
import CmtList from '../CmtList';
import CmtBll from './bll';
import UIUtil from '@/js/lib/util/ui';
var Conf = require('@/js/lib/conf');
var Action = require('@/js/lib/action/action');

export default {
    props: ['item', 'isDisableReply', 'isAnonymousFlag'],
    components: {CmtItem, CmtList},
    methods: {
        actionCmt: fActionCmt,
        actionLike: fActionLike,
        actionUpdateItems: fActionUpdateItems
    }
};

function fActionCmt(oItem) {
    var that = this;
    var oCmt = that.item;
    if (!that.$ncIsLogin) return that.$ncGoLogin();
    if (that.isDisableReply) return;
    CmtBll.reply({
        title: `回复：${oItem.authorName}`,
        data: {entityId: oCmt.id, entityType: 2, toUserId: oItem.authorId, toCommentId: oItem.id, isAnonymousFlag: that.isAnonymousFlag},
        call() {
            that.item.commentCnt++;
            that.$refs.cmtList && that.$refs.cmtList.refreshLast();
            that.$emit('addCmt');
        }
    });
}

function fActionLike(oItem) {
    var that = this;
    Action[oItem.isLiked ? 'actionUnlike' : 'actionLike']({
        body: {id: oItem.id, type: Conf.type.comment},
        call(oResult) {
            oItem.isLiked = !oItem.isLiked;
            oItem.likes = oResult.count;
        },
        error: oResult => UIUtil.alert(oResult.msg)
    });
}

function fActionUpdateItems(aItem) {
    var that = this;
    aItem = [...aItem];
    aItem.length = Math.min(2, aItem.length);
    Vue.set(that.item, 'replies', aItem);
}
</script>
<style lang="scss" scoped>
.post-action {
    height: 42px;
    border: 1px solid $colorGreen;
}
</style>
