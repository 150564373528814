<!--
 * @Author: your name
 * @Date: 2021-09-22 16:04:37
 * @LastEditTime: 2021-09-23 12:09:32
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /sparta_reborn_front/sparta_mobile/src/main/webapp/mobile/app/src/js/page/resume/components/resumeCpn/cpn/basic.vue
-->
<template>
    <div style="display: flex; justify-content: space-between">
        <div class="color-a5a5a5">
            <div class="basic-line">
                <span class="fs-18 fw-500" style="color: #333">{{ item.name || '基本资料' }}</span>
                <div v-if="!isNotComplete" class="uncomplete-resume">
                    <img :src="$ncimage('/images/mobile/resume/myResumes/uncomplete-tag.png')" alt="" />
                </div>
                <IconMobileEdit v-if="isEdit" class="color-d8d8d8" style="margin-left: 8px" @click.native="$emit('clickPushEdit')"></IconMobileEdit>
            </div>
            <div class="basic-line">
                <span v-if="item.gender" class="fs-14">{{ item.gender }}</span>
                <span v-if="item.age" class="fs-14">{{ item.age }}</span>
            </div>
            <div class="basic-line" style="line-height: 16px">
                <span v-if="item.phone" class="hide-txt fs-14" style="max-width: 100px; display: inline-block; vertical-align: top">{{ item.phone }}</span>
                <span v-if="item.email" class="hide-txt fs-14" style="max-width: 152px; display: inline-block; vertical-align: top">{{ item.email }}</span>
            </div>
        </div>
        <div class="basic-head-portrait">
            <!-- <img :src="$ncimage('/images/mobile/resume/myResumes/attachment-resume.png')" alt="" /> -->
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Prop} from 'vue-property-decorator';
import Vue from '@ncfe/nc.vue';
import {ICpnBasic} from '../../../types/index';
import {IconMobileEdit} from '@ncfe/nc.icon.h5';

@Component({components: {IconMobileEdit}})
export default class BaicItem extends Vue {
    @Prop(Object) item?: ICpnBasic | {};
    @Prop(Boolean) isNotComplete?: boolean;
    @Prop(Boolean) isEdit?: boolean;
    @Prop(Boolean) isPreview?: boolean;
}
</script>

<style lang="scss" scoped>
.basic-line {
    line-height: 14px;
    span + span {
        margin-left: 15px;
    }
    & + .basic-line {
        margin-top: 12px;
    }
}
.basic-head-portrait {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
    }
}
.fw-500 {
    font-weight: 500;
}
.uncomplete-resume {
    width: 38px;
    height: 16px;
    display: inline-block;
    border-radius: 3px;
    vertical-align: bottom;
    img {
        width: 100%;
    }
}
</style>
