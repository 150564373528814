const imgs = {
    addIcon: '/images/mobile/activity/2021personalityTest/add-icon.png',
    alleyCat: '/images/mobile/activity/2021personalityTest/alley-cat.png',
    alpaca: '/images/mobile/activity/2021personalityTest/alpaca.png',
    cheetah: '/images/mobile/activity/2021personalityTest/cheetah.png',
    collie: '/images/mobile/activity/2021personalityTest/collie.png',
    hamster: '/images/mobile/activity/2021personalityTest/hamster.png',
    homeBg: '/images/mobile/activity/2021personalityTest/home-bg.png',
    invitationBg: '/images/mobile/activity/2021personalityTest/invitation-bg.png',
    lop: '/images/mobile/activity/2021personalityTest/lop.png',
    mascot: '/images/mobile/activity/2021personalityTest/mascot.gif',
    monkey: '/images/mobile/activity/2021personalityTest/monkey.png',
    otherBg: '/images/mobile/activity/2021personalityTest/other-bg.png',
    owl: '/images/mobile/activity/2021personalityTest/owl.png',
    praise: '/images/mobile/activity/2021personalityTest/praise.png',
    title: '/images/mobile/activity/2021personalityTest/title.png',
    avatarIcon: '/images/mobile/activity/2021personalityTest/add-icon.png',
    nowcoder: '/images/mobile/activity/2021personalityTest/nowcoder.png',
    msg: '/images/mobile/activity/2021personalityTest/msg.png',
    title2: '/images/mobile/activity/2021personalityTest/title2.png'
};
const questionsAll = [
    {
        title: '上学的时候老师提问，在你会这个问题的情况下，你会选择：',
        questions: ['主动举手回答', '实在没人举手回答时，会举手回答', '举手的人多才愿意一起举手', '一般都不会举手回答']
    },
    {
        title: '与团队一起合作完成一次课题/项目时，你会愿意：',
        questions: ['多多提供自己的思路和想法', '不会主动去说，遇到自己想补充/反驳的部分会发言', '喜欢根据他人的阐述进行思考，再选择性进行发言', '还是听别人说比较好，不太想主动发言']
    },
    {
        title: '你旁边的工位来了位新同事，未来会跟你合作同一个项目，你会：',
        questions: ['主动跟ta交谈，先熟悉起来', '有合适的机会再跟ta认识下，方便后续一起工作', '感觉ta会先主动跟我交谈，等主动ta交谈在认识下', '有点尴尬，还是一起工作的时候互相认识比较好']
    },
    {
        title: '工作中如果一位同事自己做错了事，却情绪激动的来指责你，你准备：',
        questions: ['会直接解释这件事，ta愿意激动就激动吧', '虽然会先解释下这件事，但是也会顾忌下ta的情绪', '会先安抚下ta的情绪，然后慢慢解释这件事', '会先安抚ta的情绪，事情也可以后面慢慢解释的']
    },
    {
        title: '工作中面临困境很难过时，你会：',
        questions: ['觉得这样的情绪没有意义，还是积极主动找寻结局方案有意义', '给自己一点缓冲整理情绪，然后继续投入工作中', '情绪还是比较重要的，不整理好情绪是没办法工作的', '难过就先难过，工作先放一边']
    },
    {
        title: '在进行团队合作的时候，在进行讨论前，你会：',
        questions: ['认为自己的观点比较正确，思考怎么能让别人意识到自己的观点不正确', '认为自己的观点比较正确，会想怎么能说服别人', '觉得别人观点的可能更正确一些，想先听听别人的观点哪里比较正确', '希望有个和谐的讨论氛围，如果跟自己的观点不一样也可以先装作认可']
    }
];

const characterType = {
    alleyCat: {
        name: '流浪猫',
        proportion: '50%',
        avatar: '/images/mobile/activity/2021personalityTest/alley-cat.png',
        description: '有超强的适应能力，尤其体现在与同事、领导相处上。哪怕面对再多同事的消息轰炸，你也可以井然有序的回复每一个人，并且让他们满意。虽然你看起来很好相处，但是一旦有人触碰你的底线，你也还是会毫不犹豫的亮起利爪的！'
    },
    monkey: {
        name: '猴子',
        proportion: '50%',
        avatar: '/images/mobile/activity/2021personalityTest/monkey.png',
        description: '虽然有时看起来话多且密，但却是被人人羡慕的social king！在职场中，基本没有你处理不好的关系，你的同事们都认为你很有魅力。同时由于你的较擅言辞，领导也会格外青睐你，比较容易成长为管理层哦~'
    },
    rabbit: {
        name: '垂耳兔',
        proportion: '50%',
        avatar: '/images/mobile/activity/2021personalityTest/lop.png',
        description: '大多数情况下都是比较安静的，也会让人认为你是一个慎重、稳重的人。虽然在职场中交际的人不多，但是与你相交的人都与你关系比较紧密，在工作上能对你起到很多帮助。会觉得办公桌是你的私人空间，让你比较有安全感。'
    },
    hamster: {
        name: '仓鼠',
        proportion: '50%',
        avatar: '/images/mobile/activity/2021personalityTest/hamster.png',
        description: '你喜欢思考，比起主动向他人提问，你更喜欢不断的输入，不断充实自己。所以在职场中，你有时就像团队中的百科全书。同时也是因为你丰厚的积累，基于你的积累再去实践，你的大多数工作都会是有成绩成功的！'
    },
    cheetah: {
        name: '豹子',
        proportion: '50%',
        avatar: '/images/mobile/activity/2021personalityTest/cheetah.png',
        description: '在职场中，高效是你的标签。你总是可以做到客观分析，逻辑严密，在最短时间内给出令大家最满意的方案。你看起来有些高冷有内涵，感情只会影响拔剑速度是你的“座右铭”。这也让许多人觉得你成熟冷静，非常富有魅力。'
    },
    owl: {
        name: '猫头鹰',
        proportion: '50%',
        avatar: '/images/mobile/activity/2021personalityTest/owl.png',
        description: '敏锐、机灵是你的代名词，你能很快意识到工作中的风吹草动，并采取行动。你处事冷静，富有逻辑，重因果重事实，非常容易得到其他同事的尊重。p.s. 有传闻说猫头鹰不用睡觉，所以要小心被当成“内卷之王”哦~'
    },
    alpaca: {
        name: '羊驼',
        proportion: '50%',
        avatar: '/images/mobile/activity/2021personalityTest/alpaca.png',
        description: '你的性格如羊驼一样温和。在职场中大多数人喜欢与你相处，你比较好说话，会变通，好通融。你情感比较丰富，容易与他人共情，但是当别人对你不客气的时候，你也会有自己独特的反击方式（羊驼吐口水），虽然其实看起来也比较温和。'
    },
    collie: {
        name: '羊驼',
        proportion: '50%',
        avatar: '/images/mobile/activity/2021personalityTest/collie.png',
        description: '温和友好是大多数人对你的评价。你在做事时总会顾忌他人的感受，友好且富有同情心。也正是因为这样，大多数同事喜欢与你相处，你在职场中人缘很好。你很容易发现别人的长处，经常赞赏别人。也希望你也多多发现自己的优点，多一些对自己的赞美哦~'
    }
};
export {imgs, questionsAll, characterType};
