<template>
    <div class="resume-preview">
        <div v-for="item in showModule(items, [1])" :key="item.id" class="pt-5 pb-5 resume-edit-module-item border-1-bottom">
            <ModuleTitle v-if="isEmpty(item.value)" :titleInfo="getTitleInfo(item, false, false)"></ModuleTitle>
            <ModuleContentView v-if="!isEmpty(item.value)" :contentInfo="getContentViewInfo(item)" :isEdit="false"></ModuleContentView>
        </div>
        <div v-for="item in showModule(items, [2])" :key="item.id" class="resume-edit-module-item">
            <div v-if="!isEmpty(item.value)" class="pt-5">
                <ModuleTitle :titleInfo="getTitleInfo(item, false, false)"></ModuleTitle>
                <ModuleContentView :contentInfo="getContentViewInfo(item)" :isEdit="false"></ModuleContentView>
            </div>
        </div>
        <div v-for="item in showModule(items, [3, 4, 6])" :key="item.id" :graduated="graduated" class="resume-edit-module-item">
            <div v-if="!isEmpty(item.value)" class="pt-5 pb-5 border-1-bottom">
                <ModuleTitle :titleInfo="getTitleInfo(item, false, false)" :graduated="graduated"></ModuleTitle>
                <ModuleContentView :contentInfo="getContentViewInfo(item)" :isEdit="false"></ModuleContentView>
            </div>
        </div>
        <div v-for="item in showModule(items, [])" :key="item.id" class="resume-edit-module-item">
            <div v-if="!isEmpty(item.value)" class="pt-5 pb-5 border-1-bottom">
                <ModuleTitle :titleInfo="getTitleInfo(item, false, false)"></ModuleTitle>
                <ModuleContentView v-if="!isEmpty(item.value)" :contentInfo="getContentViewInfo(item)" :isEdit="false"></ModuleContentView>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import Base from '@ncfe/nc.base';
import Handle from '../edit/handle';
import {IInterviewResumeInfo, IInterviewResumeItems} from '../types/index';
import {Component} from 'vue-property-decorator';
import Vue from '@ncfe/nc.vue';
import DataMenu from '../lib/dataMenu';
import ModuleTitle from '../components/resumeCpn/form/title.vue';
import ModuleContentView from '../components/resumeCpn/form/contentView.vue';
import ResumeAction from '@/js/lib/action/resume';
@Component({
    components: {
        ModuleTitle,
        ModuleContentView
    }
})
export default class ResumeView extends Vue {
    items: IInterviewResumeItems[] = [];
    graduated = '0';

    async mounted() {
        const that = this;

        const oResult = await ResumeAction.getResumeWindowPageInfo({type: 'get', params: {uuid: (window as any).pageInfo.id}, query: {jobId: (window as any).pageInfo.jobId}});
        (window as any).resumePageInfo = oResult.data;
        Handle.init();
        that.items = that.getList();
        that.graduated = Handle.graduated;
    }
    getList() {
        const that = this;
        let oData = Handle.data;
        let aResult: IInterviewResumeItems[] = [];
        Base.forEach(oData.items, oItem => {
            !oItem.isHide &&
                oItem.isSelected &&
                aResult.push({
                    id: oItem.id,
                    name: oItem.name,
                    isComplete: oItem.isComplete(),
                    isNeed: that.isNeed(oData.rule, oItem.id),
                    value: oItem.value
                });
        });
        return aResult;
    }
    showModule(oList, aRule) {
        const that = this;
        let aExpItem: IInterviewResumeInfo[] = [];
        if (aRule === -1 && oList.length > 0) {
            return [oList[0]];
        }
        aRule.length > 0 &&
            Base.forEach(oList, oItem => {
                if (aRule.indexOf(+oItem.id) !== -1) {
                    aExpItem.push(oItem);
                }
            });

        aRule.length <= 0 &&
            Base.forEach(oList, oItem => {
                if (['1', '2', '3', '4', '5', '6'].indexOf(oItem.id) === -1) {
                    aExpItem.push(oItem);
                }
            });

        return aExpItem;
    }

    isNeed(oRule, sId) {
        let bNeed = sId === DataMenu.ID_BASE;
        if (!bNeed && oRule && oRule.need && oRule.need.length > 0) {
            bNeed = Base.find(oRule.need, function (sItem) {
                return sItem === sId;
            });
        }
        return !!bNeed;
    }
    // 判断value是否全为空
    isEmpty(oValue) {
        var that = this;
        return _fCheck(oValue);

        function _fCheck(oItem) {
            if (Base.isObject(oItem)) {
                return _fIsEmptyObj(oItem);
            } else if (Base.isArray(oItem)) {
                return Base.every(oItem, function (oItem) {
                    return _fCheck(oItem);
                });
            } else if (Base.isString(oItem)) {
                return !oItem;
            }
            return false;
        }

        function _fIsEmptyObj(oItem) {
            var aVal = Base.values(oItem);
            return (
                aVal.length === 0 ||
                Base.every(aVal, function (sVal) {
                    return !sVal;
                })
            );
        }
    }
    // moduleTitle组件获取props
    getTitleInfo(oItem, bHasAdd, bHasEdit) {
        const that = this;
        return {
            name: oItem.name,
            id: oItem.id,
            isNeed: oItem.isNeed,
            hasEdit: bHasEdit,
            hasAdd: bHasAdd,
            isNotComplete: false
        };
    }

    // contentView组件获取props
    getContentViewInfo(oItem) {
        const that = this;
        return {
            id: oItem.id,
            item: oItem.value,
            isEdit: false,
            isPreview: true,
            isComplete: true
        };
    }
}
</script>

<style lang="scss">
.color-00b88f {
    color: #00b88f;
}
.color-a5a5a5 {
    color: #a5a5a5;
}
.fw-500 {
    font-weight: 500;
}
.border-1-bottom {
    border-bottom: 1px solid #f6f6f6;
}
</style>

<style lang="scss" scoped>
.resume-preview {
    padding: 24px 12px;
    background: #fff;
}
</style>
