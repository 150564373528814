<template>
    <div class="post-content" v-html="html"></div>
</template>

<script>
import Vote from './Vote';
import Vue from '@ncfe/nc.vue';
import UIUtil from '@/js/lib/util/ui';

var $ = require('@ncfe/nc.jquery');
var Html = require('@ncfe/nc.html');
var Str = require('@ncfe/nc.str');
var Base = require('@ncfe/nc.base');
var VoteCpn = Vue.extend(Vote);

export default {
    name: 'PostContent',
    props: ['content', 'clear', 'removeStyle', 'removeTag', 'noReplaceLink'],
    mounted: fMounted,
    updated: fUpdated,
    methods: {
        hydrateVote: fHydrateVote,
        initImage: fInitImage
    },
    computed: {
        html: fHtml
    }
};

function fMounted() {
    var that = this;
    that.hydrateVote();
    $(that.$el)
        .find('a')
        .on('click', oEvent => oEvent.stopPropagation());
    that.initImage();
}

function fUpdated() {
    var that = this;
    that.hydrateVote();
}

function fInitImage() {
    var that = this;
    var oEl = $(that.$el);
    oEl.on('click', 'img', oEvent => {
        var sSrc = _fIsImgMath($(oEvent.currentTarget), true);
        if (!sSrc) return;
        var aSrc = Base.map(oEl.find('img'), oEl => {
            var sSrc = _fIsImgMath($(oEl));
            return sSrc ? {src: sSrc} : null;
        });
        aSrc = Base.filter(aSrc, sSrc => !!sSrc);
        var nIndex = Base.findIndex(aSrc, oItem => oItem.src === sSrc);
        UIUtil.image({items: aSrc, index: nIndex});
    });

    function _fIsImgMath(oEl, bClick) {
        var oA = oEl.closest('a');
        var sHref = $.trim(oA.attr('href'));
        var sSrc = $.trim(oEl.attr('src'));
        if (bClick && oA.length > 0 && sHref && !Str.isJsHref(sHref)) return;
        if (!sSrc || /https?:\/\/(www|m|hr-test|hr-pre|hr)\.nowcoder\.com\/equation\?/gi.test(sSrc)) return;
        if (oEl.attr('data-card-emoji')) return;
        return sSrc;
    }
}

function fHydrateVote() {
    var that = this;
    if (!that.$el || !that.$el.querySelectorAll) return;
    var aVotes = that.$el.querySelectorAll('[data-vote-id]');
    Base.forEach(aVotes, oEl => {
        new VoteCpn({
            el: oEl,
            propsData: {
                voteId: oEl.dataset.voteId
            }
        });
    });
}

function fHtml() {
    var that = this;
    let sContent = Html.removeTag(that.content, 'font');
    var sResult = that.clear || that.noReplaceLink ? sContent : Html.replaceLink(sContent);
    var bRemoveStyle = that.removeStyle;
    var bRemoveTag = that.removeTag;
    // 清空多余的样式
    if (that.clear) {
        bRemoveStyle = true;
        bRemoveTag = true;
    }
    sResult = Html.clear(sResult, {
        removeStyle: bRemoveStyle,
        removeTag: bRemoveTag ? {i: 'span'} : {}
        // removeTag: bRemoveTag ? {strong: 'span', b: 'span', i: 'span', em: 'span'} : {}
    });

    return sResult;
}
</script>

<style lang="scss"></style>
