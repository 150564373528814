// 校招日层
// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 *
 * @export 免费点评卡片曝光 免费点评卡片曝光时触发
 * @param {*} oData
 * @param train_id_var 实验ID
 * @param train_tag_var 实验标签（ab分组）
 * @param campany_var 企业名
 * @param industry_var 行业
 */
function freeCommentCardShow(oData) {
    gio('freeCommentCardShow', oData);
}

/**
 *
 * @export 免费点评卡片曝光 免费点评卡片曝光时触发
 * @param {*} oData
 * @param train_id_var 实验ID
 * @param train_tag_var 实验标签（ab分组）
 * @param campany_var 企业名
 * @param industry_var 行业
 */
function freeCommentCardClick(oData) {
    gio('freeCommentCardClick', oData);
}

/**
 *
 * @export 访问校招日程-公司日程页 访问https://m.nowcoder.com/school/schedule/*    时触发
 */
function xzrcgsrcyH5Visit() {
    gio('xzrcgsrcyH5Visit');
}

export default {
    freeCommentCardShow,
    freeCommentCardClick,
    xzrcgsrcyH5Visit
};
