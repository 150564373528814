<template>
    <div class="ncmain" :class="[$ncHideNav ? 'appmain' : !navNoSplit ? 'appwithsplit' : '', adapterPC ? 'page-adapter-pc' : '']">
        <div class="main-nav">
            <NCNav :fromPut="fromPut" :rmDownload="rmDownload" :loginPage="loginPage" :needBack="navNeedBack" :disableDownload="disableDownload" @actionLogo="actionLogo" @downloadApp="actionDownload('up')">
                <template slot="downloadstyle">
                    <slot name="downloadstyle"></slot>
                </template>
                <div slot="navExt">
                    <slot name="navExt"></slot>
                </div>
                <template slot="search">
                    <slot name="search"></slot>
                </template>
            </NCNav>
        </div>
        <div class="main-container">
            <none v-if="none"></none>
            <None v-else-if="error">{{ error }}</None>
            <template v-else>
                <slot></slot>
                <loading v-show="loading"></loading>
            </template>
            <div v-if="!noBtnDownload" class="txt-center ncmain-app-btn">
                <a class="tap-active color-green bg-light-green" @click="actionDownload('down')">{{ downloadText || '下载牛客APP，把求职神器装进口袋' }}</a>
            </div>
        </div>
        <slot name="bottomExt"></slot>
        <NCPanel v-if="!navNoPanel" ref="panel"></NCPanel>
    </div>
</template>
<script>
import NCNav from './NCNav';
import NCPanel from './NCPanel';
import None from '@nc/vue.none';
import Loading from '@nc/vue.loading';
import {GioDiscuss, GioUtil} from '@/gio';
const {gio, commonPageEnter} = require('@ncfe/gio.lib');

export default {
    components: {NCNav, NCPanel, None, Loading},
    props: ['navNoSplit', 'navNeedBack', 'navNoPanel', 'noBtnDownload', 'loginPage', 'error', 'none', 'loading', 'downloadText', 'adapterPC', 'rmDownload', 'fromPut', 'linkParams', 'disableDownload', 'btnDownNeedGio'],
    methods: {
        actionLogo: fActionLogo,
        actionDownload: fActionDownload,
        actionBtnDownNeedGio: fActionBtnDownNeedGio
    },
    mounted() {
        this.$nextTick(() => {
            this.actionBtnDownNeedGio();
        });
    }
};

function fActionLogo() {
    var that = this;
    if (that.navNoPanel) {
        window.location.href = '/';
        return;
    }
    that.$refs.panel.show = true;
}

function fActionDownload(action = 'up') {
    this.$emit('downloadApp');
    let name = GioUtil.commonPageName(window.location.href);
    GioDiscuss.downloadBtnClick({
        downloadPosition_var: action === 'up' ? `${name}右上角` : action === 'down' ? `${name}底部` : '',
        buttonName_var: this.downloadText || '下载牛客APP，把求职神器装进口袋'
    });
    if (action === 'down') {
        this.$ncAppOpen({
            fromPut: this.fromPut,
            params: this.linkParams
        });
    }
    this.actionBtnDownNeedGio('downloadAppClick');
}

function fActionBtnDownNeedGio(eventName = 'downloadAppView') {
    if (!this.noBtnDownload && this.btnDownNeedGio) {
        let sHref = location.href;
        sHref = sHref ? decodeURIComponent(window.unescape(sHref)) : null;

        gio('track', eventName, {
            platform_var: 'H5',
            pageName_var: commonPageEnter(decodeURIComponent(sHref))
        });
    }
}
</script>
<style lang="scss">
.ncmain {
    padding-top: 48px;

    &.appwithsplit {
        padding-top: 54px;
    }

    .main-nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .main-container {
        margin-top: -1px;
        margin-bottom: 86px;
    }

    .ncmain-app-btn {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 14px;
        font-weight: 500;
        z-index: 9;
        background: #ffffff;
        box-sizing: border-box;
        a {
            display: block;
            margin: 24px 12px;
            color: #ffffff;
            height: 38px;
            line-height: 38px;
            border-radius: 19px;
            background: linear-gradient(90deg, #00dcc2 0%, #00dc93 100%);
            border: none;
            border-image: linear-gradient(90deg, rgba(0, 220, 194, 1), rgba(0, 220, 147, 1)) 1 1;
            overflow: hidden;
            box-sizing: border-box;
        }
    }
}

.ncmain.appmain {
    padding-top: 0;

    .main-nav {
        display: none;
    }

    .ncmain-app-btn {
        display: none;
    }
}
</style>
