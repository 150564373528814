// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 *
 * @export 就业指南页面曝光
 * @param {*} oData
 * @pageType_var 页面状态
 * @school_var 学校
 * @profession_var 专业
 * @platform_var 平台
 * @downloadName_var 下载钩子
 */
function careerGuideView(oData) {
    gio('careerGuideView', oData);
}
/**
 * @export 就业指南页面点击
 * @param {*} oData
 * @viewProfessionGuide_var 查看就业去向按钮点击
 * @changeMassage_var 修改学校专业信息点击
 * @careerCardClick_var 职位卡片点击
 * @pit_var 点击坑位
 * @positionLevel3_var 点击的职位名称
 * @positionType_var 点击的职位类型
 * @totalCareer_var 查看全部职位点击
 * @watchPosition_var 查看实习职位点击
 * @downloadName_var 下载钩子
 * @shareClick_var 分享点击
 * @feedback_var 一键反馈
 */
function careerGuideClick(oData) {
    gio('careerGuideClick', oData);
}
/**
 * @export 职业百科页面曝光
 * @positionLevel3_var 职位名称
 * @platform_var 平台
 */
function careerDetailView(oData) {
    gio('careerDetailView_var', oData);
}
/**
 * @export 职业百科页面点击
 * @moreDiscuss_var 更多讨论
 * @watchPosition_var 查看实习职位
 * @shareClick_var 分享点击
 */
function careerDetailClick(oData) {
    gio('careerDetailClick_var', oData);
}
export default {
    careerGuideView,
    careerGuideClick,
    careerDetailView,
    careerDetailClick
};
