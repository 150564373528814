import Vue from '@ncfe/nc.vue';
import {Actionsheet, Popup, Checklist, DatetimePlugin, Previewer} from '@ncfe/nc.vux';

import PopupCompany from '@/js/cpn/popup/PopupCompany';
import PopupJobType from '@/js/cpn/popup/PopupJobType';

import PopupCountryCode from '@/js/cpn/popup/PopupCountryCode';
import PopupSchool from '@/js/cpn/popup/PopupSchool';
import PopupSchoolNew from '@/js/cpn/popup/PopupSchoolNew';
import PopupMajor from '@/js/cpn/popup/PopupMajor';
import PopupSearchPlace from '@/js/cpn/popup/PopupSearchPlace';
import PopupTextInput from '@/js/cpn/popup/PopupTextInput';
import PopupCustomInput from '@/js/cpn/popup/PopupCustomInput';
import PopupRecharge from '@/js/cpn/popup/PopupRecharge';
import PopupCaptcha from '@/js/cpn/popup/PopupCaptcha';
import PopupTimePicker from '@/js/cpn/popup/PopupTimePicker';
import PopupCareerJob from '@/js/cpn/popup/PopupCareerJob';
import PopupReserve from '@/js/cpn/popup/PopupReserve';
import PopupLogin from '@/js/cpn/popup/PopupLogin';

let Clipboard = require('@/js/lib/kit/clipboard');
var Time = require('@ncfe/nc.time');

Vue.use(DatetimePlugin);

var $ = require('@ncfe/nc.jquery');
var Base = require('@ncfe/nc.base');
var ActionSheetCpn = Vue.extend(Actionsheet);
var PopupCpn = Vue.extend(Popup);
var ChecklistCpn = Vue.extend(Checklist);
var CompanyCpn = Vue.extend(PopupCompany);
var JobTypeCpn = Vue.extend(PopupJobType);
var CountryCodeCpn = Vue.extend(PopupCountryCode);
var SchoolCpn = Vue.extend(PopupSchool);
var SchoolNewCpn = Vue.extend(PopupSchoolNew);
var MajorCpn = Vue.extend(PopupMajor);
var SearchPlaceCpn = Vue.extend(PopupSearchPlace);
var PreviewerCpn = Vue.extend(Previewer);
var PopupCaptchaCpn = Vue.extend(PopupCaptcha);
var CareerJob = Vue.extend(PopupCareerJob);

const PopupTimePickerCpn = Vue.extend(PopupTimePicker);
const PopupReserveCpn = Vue.extend(PopupReserve);
const PopupLoginCpn = Vue.extend(PopupLogin);

var oVueItem = null;

export default {
    alert: fAlert,
    confirm: fConfirm,
    loading: fLoading,
    toast: fToast,
    success: fSuccess,
    error: fError,
    date: fDate,
    jumpAlert: fJumpAlert,

    popupCompany: fPopupCompany,
    popupJobType: fPopupJobType,

    popupCountryCode: fPopupCountryCode,
    school: fSchool,
    schoolNew: fSchoolNew,
    major: fMajor,
    actionsheet: fActionSheet,
    select: fSelect,
    input: fInput,
    customInput: fCustomInput,
    recharge: fRecharge,
    image: fImage,
    selectWorkTime: fSelectWorkTime,
    selectEduLevel: fSelectEduLevel,
    selectCustom: fSelectCustom,
    selectGender: fSelectGender,
    clipboardCopy: fClipboardCopy,

    popupCaptcha: fPopupCaptcha,
    destroy: fDestory,
    mergeOptions: fMergeOptions,
    PopupTimePicker: fPopupTimePicker,
    PopupCareerJob: fPopupCareerJob,
    SearchPlace: fSearchPlace,

    actionSelect: fActionSelect,
    popupReserveSuccess: fPopupReserveSuccess, // 预约成功弹窗
    popupLogin: fPopupLogin // 登录弹窗
};

function fAlert(sContent, fCb) {
    (!oVueItem || !oVueItem.$vux) && (oVueItem = new Vue({}));

    var that = this;
    if (sContent === false) return oVueItem.$vux.alert.hide();
    var oConf = Base.isObject(sContent) ? sContent : {content: sContent, onHide: fCb};
    oVueItem.$vux.alert.show(oConf);
}

function fConfirm(sContent, fOk, fCancel) {
    (!oVueItem || !oVueItem.$vux) && (oVueItem = new Vue({}));

    var that = this;
    if (sContent === false) return oVueItem.$vux.confirm.hide();
    var oConf = Base.isObject(sContent) ? sContent : {content: sContent, onConfirm: fOk, onCancel: fCancel};
    oConf.confirmText = oConf.confirmText || '确定';
    oConf.cancelText = oConf.cancelText || '取消';
    oVueItem.$vux.confirm.show(oConf);
}

function fLoading(sContent) {
    (!oVueItem || !oVueItem.$vux) && (oVueItem = new Vue({}));

    var that = this;
    if (sContent === false) return oVueItem.$vux.loading.hide();
    var oConf = Base.isObject(sContent) ? sContent : {text: sContent};
    oVueItem.$vux.loading.show(oConf);
}

function fToast(sType, sContent, nDuration, fCb) {
    (!oVueItem || !oVueItem.$vux) && (oVueItem = new Vue({}));

    var that = this;
    if (sType === false) return oVueItem.$vux.toast.hide();
    var oConf = Base.isObject(sType) ? sType : {text: sContent, type: sType || 'success', time: nDuration || 1500, isShowMask: true, onHide: fCb};
    oVueItem.$vux.toast.show(oConf);
}

function fSuccess(sContent, nDuration, fCb) {
    var that = this;
    if (Base.isObject(sContent)) return that.toast({...sContent, type: 'success'});
    that.toast('success', sContent, nDuration, fCb);
}

function fError(sContent, nDuration, fCb) {
    var that = this;
    if (Base.isObject(sContent)) return that.toast({...sContent, type: 'cancel'});
    that.toast('cancel', sContent, nDuration, fCb);
}

function fDate(oConf) {
    (!oVueItem || !oVueItem.$vux) && (oVueItem = new Vue({}));
    var that = this;
    if (oConf === false) return oVueItem.$vux.datetime.hide();
    var sFormat = oConf.format || 'YYYY-MM-DD HH:mm';
    var bSecond = /:ss\s*$/.test(sFormat);
    var sVal = oConf.value || Time.form(new Date(), sFormat.replace(/Y/gi, 'y').replace(/D/gi, 'd'));
    var bCalled = false;
    bSecond && (sFormat = sFormat.replace(/:ss\s*$/gi, ''));
    bSecond && oConf.value && (sVal = sVal.replace(/:\d+\s*$/gi, ''));
    oVueItem.$vux.datetime.show({
        title: oConf.title || '',
        format: sFormat,
        value: sVal,
        maxYear: oConf.maxYear,
        minYear: oConf.minYear,
        confirmText: oConf.confirmText || '确定',
        cancelText: oConf.cancelText || '取消',
        onConfirm: sVal => {
            bCalled = true;
            bSecond && (sVal += ':00');
            oConf.call && oConf.call(sVal);
        },
        onHide: () => !bCalled && oConf.cancel && oConf.cancel()
    });
}

function fJumpAlert(fCb) {
    const that = this;
    const bWx = /MicroMessenger/i.test(window.navigator.userAgent || '');
    if (!bWx) return fCb && fCb();
    return that.confirm('可能离开微信，打开第三方应用', () => fCb && fCb());
}

/**
 * @param   {Object} oConf
 *  @param  {Function} oConf.call
 *  @param  {Function} oConf.cancel
 *  @param  {Function} oConf.close
 */
function fPopupCompany(oConf = {}) {
    var that = this;
    var $vm = new CompanyCpn({
        el: document.createElement('div')
    });
    $(document.body).append($vm.$el);
    $vm.$on('select', oItem => oConf.call && oConf.call(oItem));
    $vm.$on('cancel', () => oConf.cancel && oConf.cancel());

    let bClosed = false;
    $vm.$on('close', () => {
        if (bClosed) return;
        bClosed = true;
        $vm.show = false;
        oConf.close && oConf.close();
        window.setTimeout(() => that.destroy($vm), 250);
    });
    $vm.show = true;
}

/**
 * @param   {Object} oConf
 *  @param  {Function} oConf.call
 *  @param  {Function} oConf.cancel
 *  @param  {Function} oConf.close
 */
function fPopupJobType(oConf = {}) {
    var that = this;
    var $vm = new JobTypeCpn({
        el: document.createElement('div')
    });
    $(document.body).append($vm.$el);
    $vm.$on('select', oItem => oConf.call && oConf.call(oItem));
    $vm.$on('cancel', () => oConf.cancel && oConf.cancel());

    let bClosed = false;
    $vm.$on('close', () => {
        if (bClosed) return;
        bClosed = true;
        $vm.show = false;
        oConf.close && oConf.close();
        window.setTimeout(() => that.destroy($vm), 250);
    });
    $vm.show = true;
}

/**
 * @param   {Object} oConf
 *  @param  {Function} oConf.call
 *  @param  {Function} [oConf.cancel]
 *  @param  {Function} [oConf.close]
 */
function fPopupCountryCode(oConf = {}) {
    var that = this;
    var $vm = new CountryCodeCpn({
        el: document.createElement('div')
    });
    $(document.body).append($vm.$el);
    $vm.$on('select', oItem => oConf.call && oConf.call(oItem));
    $vm.$on('cancel', () => oConf.cancel && oConf.cancel());

    let bClosed = false;
    $vm.$on('close', () => {
        if (bClosed) return;
        bClosed = true;
        $vm.show = false;
        oConf.close && oConf.close();
        window.setTimeout(() => that.destroy($vm), 250);
    });
    $vm.show = true;
}

/**
 * @param   {Object} oConf
 *  @param  {String} oConf.name
 *  @param  {isMiddle} oConf.isMiddle true表示初中、高中
 *  @param  {Function} oConf.call
 *  @param  {Function} oConf.cancel
 *  @param  {Function} oConf.close
 */
function fSchool(oConf = {}) {
    var that = this;
    var bClosed = false;
    var $vm = new SchoolCpn({
        el: document.createElement('div')
    });
    $(document.body).append($vm.$el);
    that.mergeOptions($vm, {
        default: oConf.name || '',
        isMiddle: oConf.isMiddle
    });
    $vm.$on('select', oItem => oConf.call && oConf.call(oItem));
    $vm.$on('cancel', () => oConf.cancel && oConf.cancel());
    $vm.$on('close', () => {
        if (bClosed) return;
        bClosed = true;
        $vm.show = false;
        oConf.close && oConf.close();
        window.setTimeout(() => that.destroy($vm), 250);
    });
    $vm.show = true;
}

function fSchoolNew(oConf = {}) {
    var that = this;
    var bClosed = false;
    var $vm = new SchoolNewCpn({
        el: document.createElement('div')
    });
    $(document.body).append($vm.$el);
    that.mergeOptions($vm, {
        default: oConf.name || '',
        isMiddle: oConf.isMiddle
    });
    $vm.$on('select', oItem => oConf.call && oConf.call(oItem));
    $vm.$on('cancel', () => oConf.cancel && oConf.cancel());
    $vm.$on('close', () => {
        if (bClosed) return;
        bClosed = true;
        $vm.show = false;
        oConf.close && oConf.close();
        window.setTimeout(() => that.destroy($vm), 250);
    });
    $vm.show = true;
}

function fMajor(oConf = {}) {
    const that = this;
    const $vm = new MajorCpn({
        el: document.createElement('div')
    });
    $(document.body).append($vm.$el);
    let bClosed = false;
    $vm.$on('close', () => {
        if (bClosed) return;
        bClosed = true;
        $vm.show = false;
        oConf.close && oConf.close($vm.selectedJob, $vm.aJobKey);
        window.setTimeout(() => that.destroy($vm), 250);
    });
    $vm.show = true;
    $vm.selectedJob = oConf.selectedJob;
    $vm.selectedKey = oConf.selectedKey;
}

/**
 * @param   {Object} oConf
 *  @param  {String} oConf.name
 *  @param  {Function} oConf.close
 */
function fSearchPlace(oConf = {}) {
    var that = this;
    var bClosed = false;
    var $vm = new SearchPlaceCpn({
        el: document.createElement('div')
    });
    $(document.body).append($vm.$el);
    that.mergeOptions($vm, {
        default: oConf.name || ''
    });
    $vm.$on('close', selectedCities => {
        if (bClosed) return;
        bClosed = true;
        $vm.show = false;
        oConf.close && oConf.close(selectedCities);
        window.setTimeout(() => that.destroy($vm), 250);
    });
    $vm.show = true;
}

/**
 * @param   {Object} oConf
 *  @param  {String} oConf.title 标题，支持html
 *  @param  {String} oConf.cls 根节点额外的class
 *  @param  {Array} oConf.options {text: String, value: String, checked: Boolean, type: String(disabled|warn|primary)}
 *  @param  {Boolean} oConf.showCancel
 *  @param  {String} oConf.cancelText
 *  @param  {Function} oConf.call
 *  @param  {Function} oConf.cancel
 */
function fActionSheet(oConf) {
    var that = this;
    var bCalled = false;
    var sTitleVal = Base.key();
    var aMenu = Base.map(oConf.options, oItem => ({label: oItem.text, value: oItem.value, type: oItem.checked ? 'primary' : oItem.type || ''}));
    $.trim(oConf.title) && aMenu.unshift({type: 'info', label: `<div class="strong">${oConf.title}</div>`, value: sTitleVal});
    var $vm = new ActionSheetCpn({
        el: document.createElement('div'),
        propsData: {
            showCancel: oConf.showCancel !== false,
            cancelText: oConf.cancelText || '取消',
            menus: aMenu
        }
    });
    oConf.cls && $($vm.$el).addClass(oConf.cls);
    $(document.body).append($vm.$el);
    $vm.$on('on-click-menu', (sVal, oItem) => {
        if (sVal === sTitleVal) return;
        bCalled = true;
        var bCancel = sVal === 'cancel' && !oItem;
        var sMethod = bCancel ? 'cancel' : 'call';
        oItem = Base.find(oConf.options, oItem => oItem.value === sVal);
        oConf[sMethod] && oConf[sMethod](sVal, oItem);
    });
    $($vm.$el)
        .find('.weui-actionsheet__menu')
        .css('max-height', Math.max($(window).height() - 120, 250))
        .css('overflow-y', 'auto');
    var fWatcher = $vm.$watch('show', bVal => {
        if (bVal !== false) return;
        fWatcher();
        !bCalled && oConf.cancel && oConf.cancel();
        window.setTimeout(() => that.destroy($vm), 250);
    });
    window.setTimeout(() => ($vm.show = true), 0);
}

/**
 * @param   {Object} oConf
 *  @param  {Boolean} oConf.multi
 *  @param  {String} oConf.title
 *  @param  {Array} oConf.options {text: String, value: String, checked: Boolean}
 *  @param  {Function} oConf.call
 *  @param  {Function} oConf.cancel
 */
function fSelect(oConf) {
    var that = this;
    var oBody = $(document.body);
    var bFlex = oBody.hasClass('flex-page');
    var aChkOption = [];
    var aVal = [];
    var bMulti = oConf.multi !== false;
    var bSure = false;
    Base.forEach(oConf.options, oItem => {
        aChkOption.push({key: $.trim(oItem.value), value: oItem.text});
        oItem.checked && aVal.push($.trim(oItem.value));
    });
    aVal.sort();

    var $chk = null;
    var $vm = new PopupCpn({
        el: document.createElement('div')
    });
    var oEl = $($vm.$el);
    oEl.addClass('flex-page');
    oEl.html(['<div class="flex-container">', oConf.title ? '<div class="strong fs-l p-2 txt-center">' + oConf.title + '</div>' : '', '<div class="flex-content pb-4"></div>', '<div class="filter-footer flex-row-start txt-center" style="flex:none;height:44px;line-height:44px;padding-bottom:env(safe-area-inset-bottom);">', '<div style="flex:30%" class="vux-1px bg-white js-cancel">取消</div>', '<div style="flex:70%" class="vux-1px bg-green color-white js-submit">确定</div>', '</div>', '</div>'].join(''));
    $(document.body).append($vm.$el);
    that.mergeOptions($vm, {
        value: true,
        position: 'right',
        width: '75%'
    });

    $vm.$on('on-show', () => {
        oBody.addClass('flex-page');
        $chk = new ChecklistCpn({
            el: document.createElement('div'),
            props: {options: [], value: []}
        });
        oEl.find('.flex-content').append($chk.$el);
        that.mergeOptions($chk, {
            options: aChkOption,
            labelPosition: 'left',
            max: Math.max(1, bMulti ? aChkOption.length : 1),
            value: aVal.slice(0)
        });
    });
    $vm.$on('on-hide', () => {
        var aChkVal = $chk.currentValue.slice(0).sort();
        var bSumit = bSure && aChkVal.length > 0 && aVal.join('|||') !== aChkVal.join('|||');
        bSure && oConf.submit && oConf.submit(bMulti ? $chk.currentValue : $chk.currentValue[0]);
        bSumit && oConf.call && oConf.call(bMulti ? $chk.currentValue : $chk.currentValue[0]);
        !bSumit && oConf.cancel && oConf.cancel();
        window.setTimeout(() => {
            that.destroy($vm);
            !bFlex && oBody.removeClass('flex-page');
        }, 250);
    });
    oEl.find('.js-cancel').on('click', () => ($vm.value = false));
    oEl.find('.js-submit').on('click', () => {
        bSure = true;
        $vm.value = false;
    });
}

/**
 * @param   {Object} oConf
 *  @param  {String} oConf.title
 *  @param  {String} oConf.placeholder
 *  @param  {String} oConf.value
 *  @param  {Boolean} oConf.isInput
 *  @param  {Boolean} oConf.showClose
 *  @param  {Function} oConf.call
 *  @param  {Function} oConf.cancel
 */
function fInput(oConf) {
    var that = this;
    var bCalled = false;
    var $vm = PopupTextInput.show({
        propsData: {
            title: oConf.title,
            placeholder: oConf.placeholder,
            isInput: oConf.isInput,
            showClose: !!oConf.showClose,
            type: oConf.type || 'text',
            max: oConf.max,
            min: oConf.min
        }
    });
    $vm.value = oConf.value || '';
    $vm.$on('submit', (sVal, sTitle) => {
        oConf.call && oConf.call(sVal, sTitle);
        bCalled = true;
    });
    $vm.$on('close', () => !bCalled && oConf.cancel && oConf.cancel());
}
/**
 * @param   {Object} oConf
 *  @param  {String} oConf.title
 *  @param  {String} oConf.placeholder
 *  @param  {String} oConf.value
 *  @param  {Boolean} oConf.isInput
 *  @param  {Boolean} oConf.showClose
 *  @param  {Function} oConf.call
 *  @param  {Function} oConf.cancel
 *  @param  {Function} oConf.cancelTxt
 */
function fCustomInput(oConf) {
    var that = this;
    var bCalled = false;
    var $vm = PopupCustomInput.show({
        propsData: {
            title: oConf.title,
            placeholder: oConf.placeholder,
            isInput: oConf.isInput,
            showClose: !!oConf.showClose,
            type: oConf.type || 'text',
            max: oConf.max,
            min: oConf.min,
            cancelTxt: oConf.cancelTxt
        }
    });
    $vm.value = oConf.value || '';
    $vm.$on('submit', (sVal, sTitle) => {
        oConf.call && oConf.call(sVal, sTitle);
        bCalled = true;
    });
    $vm.$on('close', () => !bCalled && oConf.cancel && oConf.cancel());
}

function fRecharge(oConf) {
    var that = this;
    oConf = oConf || {};
    PopupRecharge.show({
        propsData: {
            paymentCallBack: oConf.paymentCallBack || window.location.href
        }
    });
}

/**
 * 预览图片
 * @param   {Object} oConf
 *  @param  {Array} oConf.items
 *  @param  {Number} oConf.index
 */
function fImage(oConf) {
    var that = this;
    if ((oConf.items || []).length === 0) return;
    var oItem = that.imagePreviewer;
    if (!oItem) {
        var oItem = new PreviewerCpn({propsData: {list: []}});
        oItem.$mount();
        $(document.body).append(oItem.$el);
        that.imagePreviewer = oItem;
    }
    var aList = Base.map(oConf.items, oItem => ({src: oItem.src, w: oItem.width, h: oItem.height}));
    oItem.list = aList;
    oItem.show(Math.max(0, +oConf.index || 0));
}

function fSelectWorkTime(oConf) {
    let that = this;
    let aYears = [];
    for (let i = oConf.minYear; i < oConf.maxYear + 1; i++) {
        aYears.push(i);
    }
    that.actionsheet({
        options: Base.map(aYears, sItem => ({text: sItem, value: sItem, checked: +oConf.value === sItem})),
        call: (sVal, oSelect) => oConf.call && oConf.call(sVal, oSelect)
    });
}

function fSelectEduLevel(oConf) {
    let that = this;
    let aEduLevel = oConf.isAll ? ['博士后', '博士', '硕士', '本科', '专科', '高中', '初中', '小学'] : ['博士', '硕士', '本科', '高中'];
    that.actionsheet({
        options: Base.map(aEduLevel, sItem => ({text: sItem, value: sItem, checked: oConf.value === sItem})),
        call: (sVal, oSelect) => oConf.call && oConf.call(sVal, oSelect)
    });
}

function fSelectCustom(oConf) {
    let that = this;
    that.actionsheet({
        options: Base.map(oConf.options, oItem => ({text: oItem.text, value: oItem.value, checked: oConf.value === oItem.value})),
        call: (sVal, oSelect) => oConf.call && oConf.call(sVal, oSelect)
    });
}

function fSelectGender(oConf) {
    let that = this;
    let options = Base.map(['男', '女'], sItem => ({text: sItem, value: sItem, checked: oConf.value === sItem}));
    that.actionsheet({
        options,
        call: (sVal, oSelect) => oConf.call && oConf.call(sVal, oSelect)
    });
}

function fClipboardCopy(oConf) {
    let that = this;
    (!oVueItem || !oVueItem.$vux) && (oVueItem = new Vue({}));

    oVueItem.$vux.confirm.show({
        title: oConf.title,
        content: oConf.content,
        confirmText: oConf.confirmText || '复制链接',
        onShow: () => {
            let oBtn = $('.weui-dialog__btn.weui-dialog__btn_primary');
            if (!oBtn.attr('data-clipboard-text')) {
                oBtn.attr('data-clipboard-text', oConf.link);
                new Clipboard(oBtn.get(0));
            }
        },
        onCancel: () => oConf.onCancel && oConf.onCancel(),
        onConfirm: () => {
            oConf.onConfirm && oConf.onConfirm();
            !oConf.onConfirm && oVueItem.$vux.toast.show({text: '复制成功'});
        }
    });
}

function fPopupCaptcha(oConf) {
    let that = this;
    let $vm = new PopupCaptchaCpn({
        el: document.createElement('div')
    });
    $(document.body).append($vm.$el);
    $vm.$on('verify', oItem => oConf.call && oConf.call(oItem));
    let bClosed = false;
    // close
    $vm.$on('input', bBoolean => {
        if (bClosed || bBoolean) return;
        bClosed = true;
        $vm.show = false;
        oConf.close && oConf.close();
        window.setTimeout(() => that.destroy($vm), 250);
    });
    $vm.value = true;
    $vm.type = oConf.type;
}

function fDestory($vm) {
    var that = this;
    $($vm.$el).remove();
    $vm.$destroy();
}

function fMergeOptions($vm, oOptions) {
    var oDefault = {};
    for (var sKey in $vm.$options.props) {
        if (sKey === 'value') continue;
        oDefault[sKey] = $vm.$options.props[sKey].default;
    }
    oOptions = Base.extend({}, oDefault, oOptions);
    Base.forEach(oOptions, (sVal, sKey) => ($vm[sKey] = sVal));
}

function fPopupTimePicker(oConf) {
    let that = this;
    let $vm = new PopupTimePickerCpn({
        el: document.createElement('div'),
        propsData: oConf.propsData
    });
    $(document.body).append($vm.$el);
    $vm.$on('confirm', oData => oConf.call && oConf.call(oData));
    $vm.$on('cancel', () => oConf.cancel && oConf.cancel());
    $vm.show = true;
}

/**
 * @param   {Object} oConf
 *  @param  {Function} oConf.call
 *  @param  {Function} oConf.cancel
 *  @param  {Function} oConf.close
 */
function fPopupCareerJob(oConf = {}) {
    const that = this;
    const $vm = new CareerJob({
        el: document.createElement('div')
    });
    $(document.body).append($vm.$el);

    let bClosed = false;
    $vm.$on('close', () => {
        if (bClosed) return;
        bClosed = true;
        $vm.show = false;
        oConf.close && oConf.close($vm.selectedJob, $vm.aJobKey);
        window.setTimeout(() => that.destroy($vm), 250);
    });
    $vm.show = true;
    $vm.selectedJob = oConf.selectedJob;
    $vm.selectedKey = oConf.selectedKey;
}

/**
 * @param   {Object} oConf
 *  @param  {String} oConf.title 标题，支持html
 *  @param  {String} oConf.cls 根节点额外的class
 *  @param  {Array} oConf.options {text: String, value: String, checked: Boolean, type: String(disabled|warn|primary)}
 *  @param  {Boolean} oConf.showCancel
 *  @param  {String} oConf.cancelText
 *  @param  {Function} oConf.call
 *  @param  {Function} oConf.cancel
 */
function fActionSelect(oConf) {
    const that = this;
    let bCalled = false;
    const sTitleVal = Base.key();
    let aMenu = Base.map(oConf.options, oItem => ({label: oItem.text, value: oItem.value, type: oItem.checked ? 'primary' : oItem.type || ''}));
    $.trim(oConf.title) && aMenu.unshift({type: 'info', label: `<div class="strong">${oConf.title}</div>`, value: sTitleVal});
    let $vm = new ActionSheetCpn({
        el: document.createElement('div'),
        propsData: {
            showCancel: oConf.showCancel !== false,
            cancelText: oConf.cancelText || '取消',
            menus: aMenu
        }
    });
    oConf.cls && $($vm.$el).addClass(oConf.cls);
    $(document.body).append($vm.$el);
    $vm.$on('on-click-menu', (sVal, oItem) => {
        if (sVal === sTitleVal) return;
        bCalled = true;
        const bCancel = sVal === 'cancel' && !oItem;
        const sMethod = bCancel ? 'cancel' : 'call';
        oItem = Base.find(oConf.options, oItem => oItem.value === sVal);
        oConf[sMethod] && oConf[sMethod](sVal, oItem);
    });

    // 添加自定样式
    $($vm.$el)
        .find('.weui-actionsheet')
        .css('width', '350px')
        .css('left', ($(window).width() - 350) / 2)
        .css('border-radius', '10px')
        .css('background', 'none');

    $($vm.$el) // 按钮列表部分
        .find('.weui-actionsheet__menu')
        .css('margin-bottom', '8px')
        .css('max-height', Math.max($(window).height() - 120, 250))
        .css('overflow-y', 'auto')
        .css('border-radius', '10px');

    $($vm.$el) // 选项
        .find('.weui-actionsheet__cell')
        .css('font-size', '14px');

    $($vm.$el) // 取消按钮
        .find('.weui-actionsheet__action')
        .css('margin-bottom', '26px')
        .css('border-radius', '10px')
        .css('color', '#A5A5A5');

    const fWatcher = $vm.$watch('show', bVal => {
        if (bVal !== false) return;
        fWatcher();
        !bCalled && oConf.cancel && oConf.cancel();
        window.setTimeout(() => that.destroy($vm), 250);
    });
    window.setTimeout(() => ($vm.show = true), 0);
}

/**
 * @description: 预约成功弹窗
 * @use: [专场海报页 page/activity/specialV2/index/Page.vue]
 * @param {Object} oConfig
 * @param {String} oConfig.startTimeText   开始时间文案
 * @param {String} oConfig.qrCode          二维码链接
 */
function fPopupReserveSuccess(oConfig = {}) {
    const that = this;
    let bClosed = false;

    const $vm = new PopupReserveCpn({
        el: document.createElement('div'),
        propsData: oConfig
    });
    $(document.body).append($vm.$el);

    $vm.$on('close', () => {
        if (bClosed) return;
        bClosed = true;
        $vm.show = false;
        oConfig.close && oConfig.close();
        window.setTimeout(() => that.destroy($vm), 250);
    });
    $vm.show = true;
}

/**
 * @description: 登录弹窗 (禁止 滑动穿透)
 * @use: []
 * @param {Object}   oConfig
 * @param {Function} oConfig.success
 */
function fPopupLogin(oConfig = {}) {
    const that = this;
    let bClosed = false;

    let propsData = {};
    oConfig?.success && (propsData.success = oConfig.success);

    const $vm = new PopupLoginCpn({
        el: document.createElement('div'),
        propsData: propsData
    });
    $(document.body).append($vm.$el);
    $(document.body).addClass('overflow-hidden');

    $vm.$on('close', () => {
        if (bClosed) return;
        bClosed = true;
        $vm.show = false;
        oConfig.close && oConfig.close();
        $(document.body).removeClass('overflow-hidden');
        window.setTimeout(() => that.destroy($vm), 250);
    });

    $vm.show = true;
}
