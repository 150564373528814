<template>
    <div>
        <popup id="popup-verify" v-model="show" class="container" position="top">
            <div class="p-2">
                <div class="fs-xl color-green txt-center">验证手机</div>
                <span>应国家法律要求，使用该功能时需完成手机号验证。感谢您的支持和理解 ⁄(⁄ ⁄•⁄ω⁄•⁄ ⁄)⁄. </span>
            </div>
            <group label-align="right" label-width="50px" label-margin-right="10px" gutter="0">
                <x-input v-model="phone" title="手机号" placeholder="手机号"></x-input>
                <x-input v-model="code" title="验证码" placeholder="验证码" :show-clear="false">
                    <div slot="right" class="vux-1px-l pl-2" @click.stop="actionSendCode">{{ sendCodeText }}</div>
                </x-input>
            </group>
            <div class="mt-2 plr-2">
                <x-button type="primary" @click.native="actionSubmit">验证手机</x-button>
            </div>
            <div class="mt-3 color-gray txt-center fs-xs">点击“验证手机”按钮，即表示你同意<a href="https://static.nowcoder.com/protocol/register.html" target="_blank">《注册协议》</a>和<a href="https://static.nowcoder.com/protocol/privacy-policy.html" target="_blank">《隐私政策》</a></div>
        </popup>
        <PopupCaptcha ref="captcha" v-model="captchaShow" :type="captchaType" @verify="actionVerify"></PopupCaptcha>
    </div>
</template>

<script>
import {Popup, Group, XInput, XButton} from '@ncfe/nc.vux';
import PopupCaptcha from '@/js/cpn/popup/PopupCaptcha';
var LoginAction = require('@/js/lib/action/login');
var Conf = require('@/js/lib/conf');
var Str = require('@ncfe/nc.str');
var Time = require('@ncfe/nc.time');
var Base = require('@ncfe/nc.base');
export default {
    components: {Popup, Group, XInput, XButton, PopupCaptcha},
    data() {
        return {
            captchaShow: false,
            captchaType: Conf.captcha.sendVerifyCode,

            isSuccess: false,
            show: true,
            phone: '',
            code: '',
            sendCodeText: '获取验证码'
        };
    },
    methods: {
        check: fCheck,
        val: fVal,

        actionSendCode: fActionSendCode,
        actionVerify: fActionVerify,
        actionSubmit: fActionSubmit,

        checkPhone: fCheckPhone
    },
    watch: {
        show: fWatchShow
    }
};

function fCheck() {
    var that = this;
    var sMsg = !Str.isPhone(Str.trim(that.phone)) ? '请输入正确的手机号码' : !Str.trim(that.code) ? '请输入验证码' : '';
    sMsg && that.$vux.toast.text(sMsg, 'middle');
    return !sMsg;
}

function fVal(oData) {
    var that = this;
    if (arguments.length === 0) {
        return {
            phone: Str.trim(that.phone),
            code: Str.trim(that.code)
        };
    }
    that.phone = oData.phone;
    that.code = oData.code;
}

function fActionSendCode() {
    var that = this;
    var sPhone = Str.trim(that.phone);
    if (!Str.isPhone(sPhone)) {
        that.$vux.toast.text('请输入正确的手机号码', 'center');
        return;
    }
    !that.isSending &&
        that.checkPhone(sPhone, bRight => {
            bRight && (that.captchaShow = true);
        });
}

function fActionVerify(oData) {
    var that = this;
    that.isSending = true;
    LoginAction.sendMobileCodeV2({
        body: Base.extend({phone: Str.trim(that.phone)}, oData),
        call: function () {
            that.$vux.toast.text('验证码发送成功', 'center');
            Time.countDown({
                second: 120,
                call(nSecond) {
                    that.sendCodeText = nSecond + '秒重新获取';
                },
                end() {
                    that.sendCodeText = '重新获取验证码';
                    that.isSending = false;
                }
            });
        },
        error: function (oResult) {
            that.isSending = false;
            that.$vux.toast.text(oResult.msg, 'center');
        }
    });
}

function fActionSubmit() {
    var that = this;
    that.check() &&
        LoginAction.activeMobile({
            body: that.val(),
            call: function () {
                that.$vux.toast.text('手机号码验证成功', 'center');
                that.isSuccess = true;
                that.show = false;
                that.$emit('success');
                (window.globalInfo || {}).bindPhone = true;
            },
            error: function (oResult) {
                that.$vux.toast.text(oResult.msg, 'center');
            }
        });
}

function fCheckPhone(sPhone, fCb) {
    var that = this;
    var oPhoneMap = (that.phoneMap = that.phoneMap || {});
    var oPhoneTipsMap = (that.phoneTipsMap = that.phoneTipsMap || {});
    if (!sPhone) {
        return fCb && fCb(false);
    }
    if (sPhone in oPhoneMap) {
        return _fDeal(oPhoneMap[sPhone], oPhoneTipsMap[sPhone]);
    }
    LoginAction.checkPhone({
        query: {phone: sPhone},
        call: function () {
            _fDeal(true);
        },
        error: function (oResult) {
            _fDeal(false, oResult);
        }
    });
    function _fDeal(bRight, oResult) {
        oPhoneMap[sPhone] = bRight;
        oPhoneTipsMap[sPhone] = oResult;
        if (!bRight) {
            oResult = oResult || {};
            that.$vux.toast.text(oResult.msg, 'center');
        }
        fCb && fCb(bRight);
    }
}

function fWatchShow(bShow) {
    var that = this;
    !bShow && !that.isSuccess && that.$emit('error');
    that.isSuccess = false;
}
</script>

<style type="scss">
.container {
    padding-bottom: 15px;
    height: 200px;
}
</style>
