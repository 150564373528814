<template>
    <a href="javascript:void(0);" class="flex-row color-font" style="align-items: flex-start;" @click="actionClick">
        <Avatar :id="item.authorId" :decorateSrc="item.headDecorateUrl" :src="item.headImg" style="flex: none;"></Avatar>
        <div class="ml-3" style="flex: auto; overflow: hidden;">
            <div class="flex-row mb-1" style="align-items: flex-start;">
                <div style="flex: auto; overflow: hidden;">
                    <NickName :id="item.authorId" :div="true" :level="item.honorLevel" :identity="item.identity" style="max-width: 100%;" :name="item.authorName" :tag="lordId && lordId === item.authorId ? (lordType === 'author' ? '作者' : '楼主') : ''"></NickName>
                    <div v-if="$ncIsLogin" class="fs-s color-gray flex-row-start">
                        <span class="color-gray">{{ createTime }}</span>
                    </div>
                    <div v-else-if="(item.identity || []).length > 0" class="fs-s color-gray flex-row-start">
                        <span v-for="(item, index) in item.identity" :key="index" class="hide-txt" :class="index === 0 ? '' : 'ml-1'">{{ item.name }}</span>
                    </div>
                    <!-- 无认证信息，有学校信息 -->
                    <div v-else-if="item.educationInfo" class="fs-s color-gray flex-row-start">
                        <span class="hide-txt">{{ item.educationInfo }}</span>
                    </div>
                </div>
                <div class="ml-2 fs-s color-gray" style="flex: none;">
                    <slot name="mark"></slot>
                    <!-- <span @click.stop="actionCmt"><i class="icon-comment mr-1"></i>{{item.commentCnt}}</span> -->
                    <span v-if="needFloor" class="bg-light-gray radius plr-1 color-white" style="flex: none;">{{ index + 1 }}#</span>
                    <span class="pl-2 pb-2" @click.stop="actionLike">
                        <i v-if="item.isLiked" class="icon-thumb-full mr-1 color-green"></i>
                        <i v-else class="icon-thumb mr-1"></i>
                        {{ item.likes }}
                    </span>
                </div>
            </div>
            <PostContent :content="item.content" :clear="true"></PostContent>
            <CmtItemSubList v-if="showComments && item.commentCnt > 0" :id="item.id" :replies="item.replies" :lordId="lordId" :lordType="lordType" class="mt-1" :commentCnt="item.commentCnt"></CmtItemSubList>
        </div>
    </a>
</template>

<script>
import NickName from '@nc/vue.nickname';
import Avatar from '@nc/vue.avatar';
import PostContent from '@/js/cpn/PostContent';
import CmtItemSubList from './CmtItemSubList';
var Time = require('@ncfe/nc.time');
export default {
    name: 'CmtItem',
    props: ['item', 'index', 'needFloor', 'needOpenApp', 'showComments', 'lordId', 'lordType'],
    components: {NickName, Avatar, PostContent, CmtItemSubList},
    methods: {
        actionClick: fActionClick,
        actionCmt: fActionCmt,
        actionLike: fActionLike,

        wrap: fWrap
    },
    computed: {
        createTime: fCreateTime
    },
    watch: {
        'item.replies': aItem => {
            // console.log(aItem);
        }
    }
};

function fActionClick() {
    var that = this;
    that.wrap(() => {
        that.$emit('actionClick');
    });
}

function fActionCmt() {
    var that = this;
    that.wrap(() => {
        that.$emit('actionCmt');
    });
}

function fActionLike() {
    var that = this;
    that.wrap(() => {
        that.$emit('actionLike');
    });
}

function fWrap(fCb) {
    var that = this;
    fCb && fCb();
    // that.needOpenApp ? that.$ncAppSwitchOpen(fCb) : fCb && fCb();
}

function fCreateTime() {
    var that = this;
    return Time.relate(new Date(that.item.createTime));
}
</script>
