<template>
    <Popup v-model="isShow" :hide-on-blur="false" class="bg-white" width="100%">
        <div>
            <div class="plr-2 ptb-1 bg-table">重要说明</div>
            <div class="plr-2 ptb-1 vux-1px-tb">
                <div>提交学校信息后，管理员将于3个工作日内审核，审核期间将不影响简历的正常投递。</div>
                <div class="mt-1">如有以下情况，则该申请将会被拒绝，且除简历以外涉及学校的个人资料将需重新填写学校信息：</div>
                <div class="mt-1">1. 重复添加学校信息</div>
                <div>2.新增学校名称不完整或名称错误</div>
                <div>3.若未找到学校所在的地区，请选择【中国-北京】并申请地区+学校名称如“美国哈佛大学”</div>
            </div>
        </div>
        <div>
            <div class="plr-2 ptb-1 bg-table">新增学校信息</div>
            <None v-if="error">{{ error }}</None>
            <Loading v-else-if="!items"></Loading>
            <group v-else gutter="0">
                <popup-picker v-model="country" title="学校所在国家" value-text-align="right" :data="countryList"></popup-picker>
                <popup-picker v-if="provList.length > 0" v-model="prov" title="学校所在省份" value-text-align="right" :data="provList"></popup-picker>
                <x-input v-model="school" title="学校名称" placeholder="请输入学校名称" text-align="right"></x-input>
            </group>
        </div>
        <div class="plr-2 ptb-3">
            <x-button type="primary" @click.native="actionSubmit">提交</x-button>
            <x-button type="default" @click.native="actionCancel">取消</x-button>
        </div>
    </Popup>
</template>

<script>
import {Group, Popup, XButton, PopupPicker, XInput} from '@ncfe/nc.vux';
import None from '@nc/vue.none';
import Loading from '@nc/vue.loading';
import CommonAction from '@/js/lib/action/common';
var Base = require('@ncfe/nc.base');

export default {
    props: ['value', 'isMiddle', 'name'],
    components: {XButton, Popup, Group, PopupPicker, XInput, None, Loading},
    data() {
        var that = this;
        return {
            isShow: !!that.value,
            items: null,
            country: [],
            prov: [],
            school: that.name,
            error: ''
        };
    },
    mounted: fMounted,
    methods: {
        actionSchool: fActionSchool,
        actionSubmit: fActionSubmit,
        actionCancel: fActionCancel
    },
    watch: {
        value: fWathcValue,
        name: fWatchName,
        isShow: fWatchIsShow
        // country: fWathcCountry
    },
    computed: {
        hasProv: fHasProv,
        countryList: fCountryList,
        provList: fPorvList
    }
};

function fMounted() {
    var that = this;
    CommonAction.getSchoolBrief({
        query: {
            isMiddle: !!that.isMiddle
        },
        call(oResult) {
            var aItem = oResult.data;
            that.items = aItem || [];
            // 默认值
            // var oItem = aItem[0];
            // oItem && oItem.name && (that.country = [oItem.name]);
        },
        error(oResult) {
            that.error = oResult.msg;
        }
    });
}

function fActionSchool() {
    var that = this;
    that.$sdk.Alert.input({
        title: '请输入学校名称',
        confirmText: '确定',
        cancelText: '取消',
        inputs: [{text: that.school, placeholder: '请输入学校名称'}],
        call(bConfirm, aItem) {
            if (bConfirm) {
                var sName = (aItem[0] || '').trim();
                sName && (that.school = sName);
            }
        }
    });
}

function fActionSubmit() {
    var that = this;
    var bHasProv = that.hasProv;
    var sCountry = (that.country[0] || '').trim();
    var sProv = bHasProv ? (that.prov[0] || '').trim() : '';
    var sSchool = (that.school || '').trim();
    if (!sCountry) {
        return that.$vux.alert.show({content: '请选择学校所在国家'});
    }
    if (bHasProv && !sProv) {
        return that.$vux.alert.show({content: '请选择学校所在省份'});
    }
    if (!sSchool) {
        return that.$vux.alert.show({content: '学校名称不能为空'});
    }
    CommonAction.addCustomizeSchool({
        body: {
            country: sCountry,
            prov: bHasProv ? sProv : undefined,
            name: sSchool,
            isMiddle: !!that.isMiddle
        },
        call(oResult) {
            that.isShow = false;
            that.$emit('submit');
        },
        error(oResult) {
            that.$vux.alert.show({content: oResult.msg});
        }
    });
}

function fActionCancel() {
    var that = this;
    that.isShow = false;
}

function fWathcValue() {
    var that = this;
    that.isShow = !!that.value;
}

function fWatchName() {
    var that = this;
    that.school = that.name;
}

function fWatchIsShow(bShow) {
    var that = this;
    that.$emit('input', bShow);
}

// function fWathcCountry(sCountry) {
//     var that = this;
//     var aItem = that.provList;
//     that.prov = aItem.length > 0 ? [aItem[0][0]] : [];
// }

function fHasProv() {
    var that = this;
    if (!that.country) {
        return true;
    }
    return that.provList.length > 0;
}

function fCountryList() {
    var that = this;
    var aItem = Base.map(that.items, oItem => oItem.name);
    return [aItem];
}

function fPorvList() {
    var that = this;
    var sCountry = (that.country || [])[0];
    if (!sCountry) {
        return [];
    }
    var oItem = Base.find(that.items, oItem => oItem.name === sCountry) || {};
    var aItem = oItem.provs || [];
    return aItem.length === 0 ? [] : [Base.map(aItem, oItem => oItem.name)];
}
</script>

<style lang="scss" scoped></style>
