var Ajax = require('./ajax');
module.exports = {
    url: {
        getInfo: '/profile/user-info',
        getFollowings: '/sns/following-peoples',
        getFollowers: '/sns/people-followers',
        getPractices: '/profile/get-my-tests-v2',

        getApplyData: '/user/authentication/apply-data', // 申请数据
        submitStep1: '/user/authentication/step1', // POST authType: Number 类型， company： // String 公司 , dep：String 部门 , job:String 职位, beginTime： String ， // endTime: String // email: String  code: String 验证码
        submitStep2: '/user/authentication/step2', // POST fileUrl: String 认证文件url， fileName: String 文件名字
        sendValidation: '/user/authentication/validate' // POST 发送验证码 email: String
    }
};
Ajax.add(module.exports, module.exports.url);
