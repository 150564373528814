/**
 updateResumeBasic:{
    body:{
        id: Number, //简历id
        userName : String //用户名字
        workTime : String //工作日期
        birthTime: String //出生日期
        gender : String //性别
        phone : String //电话
        email : String //
        liveAddress : String //所在城市
        eduLevel : String //最高学历
    }
 }
  updateResumeExt:{
    body:{
        id: Number, //简历id
        ext: String //额外信息
    }
  }
  updateResumeHead:{
    body:{
        id: Number, //简历id
        head: String //头像url
    }
  }
  updateResumeName:{
    body:{
        id: Number, //简历id
        resumeName: String //简历名字
    }
  }
  newResume:{
    body:{
        jobId: Number //职位id，可选
    }
  }
  updateResumeDone:{
    body:{
        id: Number, //简历id
    }
  }
  getResumeList:{
  }
  resumeJson:{
    params:{
        resumeUuid: String //简历id
    }
  }
  checkIdCard:{  // 返回找到的resumeId
    query:{
        idCard: String // 证件号码
        idCardType: Number // 证件类型
        jobId： Number // 职位id
    }
  }
 */
var Ajax = require('./ajax');

module.exports = {
    url: {
        //增加面试反馈, POST, {deliverId: 投递id, stage: 阶段 0初选 2笔试 3 面试 6offer阶段，feedback: 1已经反馈 2没有任何反馈 3已经完成, reason: 实际进度或者反馈原因中的选项内容, comment: 详细信息}
        addFeedback: '/deliver/feedback/add',
        //更新简历基本信息
        updateResumeBasic: '/resume/basic/update',
        //更新简历额外信息
        updateResumeExt: '/resume/ext/update',
        //更新简历头像
        updateResumeHead: '/resume/head/update',
        //更新简历名字
        updateResumeName: '/resume/name/update',
        //更新简历为“完成”
        updateResumeDone: '/resume/done/update',
        //获得某人的简历列表
        getResumeList: '/resume/list',
        //简历 json
        getResumeJson: '/resume/json/#{resumeUuid}',
        newResume: '/resume/new',
        recommandV2Step1: '/recommend/v2/step1',
        checkIdCard: '/resume/check-id-card',
        // 校招推荐
        recommendJobs: '/resume/recommend-jobs',
        // 获取模板简历， /attachmentResume 附件简历 normalResumes 是在线简历 chosenResumeId是默认的简历id
        getResumes: '/resume/resumes',
        // 投递简历信息  deliverId：投递id 不为空只获取这个投递   page：Number
        getDelivers: '/resume/delivers',
        getNewDelivers: '/nccommon/v2/resume/deliver-list',
        // 获取投递目录，GET,
        getDeliverMenu: '/resume/delivers-menu',
        // id 删除简历
        deleteResume: '/resume/del',
        // resumeId  设置默认简历
        setDefaultResume: '/resume/default/deliver',

        // post {fromUuid: 同步来源的简历ID，toUuid：目标简历id}
        syncByComplete: '/resume/sync-by-complete',

        // 相似职位展示接口
        getRecommendJobs: '/nccommon/job/recommend-jobs',
        // 一键投递接口
        deliverAllJobs: '/nccommon/recommand/v2/step1-all',
        // 简历投递之后
        jobPublisher: '/recommand/job/publisher',
        parseResume: '/resume/parse-resume',

        recommendJob: '/resume/recommend-job',

        getResume: '/recommand/deliver/resume',
        //简历nps
        getNpsScoreOptions: '/resume/nps-score-options', // 获取分数列表
        addNpsStatistics: '/resume/add-nps-statistics', // Nps评价
        postResumeCopy: '/nccommon/v2/resume/copy', // 简历同步
        getResumeWindowPageInfo: '/resume/info/#{uuid}' // 获取简历信息
    }
};

Ajax.add(module.exports, module.exports.url);
