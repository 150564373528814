<template>
    <div class="flex-row ncnav bg-white">
        <a href="/" class="logo flex-row" @click="actionClickLogo"><img :src="$ncimage('/images/logo-h5-new.png')" /></a>
        <slot name="search"></slot>
        <div class="ext flex-row fs">
            <slot name="downloadstyle">
                <a v-if="!rmDownload" href="javascript:void(0)" @click="actionDownload">下载APP</a>
                <span v-if="!$ncIsLogin && !loginPage" class="ml-1 pl-1 vux-1px-l color-green" @click="actionLogin">注册登录</span>
            </slot>
            <span class="ml-1 pl-1 vux-1px-l">
                <slot name="navExt"></slot>
            </span>
            <span v-if="needBack" class="ml-1 pl-1 vux-1px-l color-green" @click="actionBack">返回</span>
        </div>
    </div>
</template>
<script>
// 临时给讨论区添加一个登录注册的埋点
import {GioDiscuss} from '@/gio';
export default {
    props: ['items', 'needBack', 'loginPage', 'rmDownload', 'fromPut', 'disableDownload'],
    methods: {
        actionClickLogo: fActionClickLogo,
        actionBack: fActionBack,
        actionLogin: fActionLogin,
        actionDownload: fActionDownload
    },

    computed: {
        ncDownloadUrl() {
            // 暂时全部走下载页面
            // if (this.$ncDownloadUrl) {
            //     return this.$ncDownloadUrl;
            // }
            return '/';
        }
    }
};

// 显示仪表盘
function fActionClickLogo(oEvent) {
    oEvent.preventDefault();
    let that = this;
    that.$emit('actionLogo');
}

function fActionBack() {
    window.history.back();
}
function fActionLogin() {
    const that = this;
    that.$ncGoLogin();
    if (window.location.href.includes('/discuss/')) {
        GioDiscuss.contentItemClickh5({
            clickText_var: '注册/登陆',
            isLogin_var: '是'
        });
    }
}

function fActionDownload(oEvent) {
    // 暂时全部走下载页面
    // if (this.ncDownloadUrl) {
    //     return;
    // }
    oEvent.preventDefault();

    this.$emit('downloadApp');
    if (!this.disableDownload) {
        this.$ncAppOpen({fromPut: this.fromPut});
    }
}
</script>
<style lang="scss" scoped>
.ncnav {
    height: 48px;
    padding-left: 12px;
    padding-right: 12px;
    overflow: hidden;
    justify-content: space-between;

    .logo {
        margin-right: 5px;
        flex-shrink: 0;
        overflow: hidden;
        height: 44px;
        img {
            height: 23px;
        }
    }

    .ext {
        flex: none;
        justify-content: flex-end;
    }
}
</style>
