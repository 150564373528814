var Ajax = require('./ajax');
module.exports = {
    url: {
        getBlackList: '/sns/blacks',
        dropFromBlack: '/sns/unblack',
        checkPhone: '/mobile/checkPhone',
        sendPhone: '/mobile/sendCodeV2',
        changePhone: '/profile/change-phone',
        checkEmail: '/email/check-email',
        sendEmail: '/email/send-code',
        changeEmail: '/profile/change-email',
        changePassword: '/profile/change-pwd',
        getSubscribe: '/subscribe/setting',
        getSubscribeDetail: '/subscribe/setting/detail',
        getBindInfo: '/sns-account/bind-info',
        updateSubscribe: '/subscribe/update',
        updateNickName: '/profile/#{id}/nickname',
        updateSchool: '/profile/#{id}/school',
        updateCompany: '/profile/#{id}/work',
        updateJob: '/profile/#{id}/addition/job',
        updateUserProfile: '/profile/update-user-profile',
        honorDetailData: '/profile-v2/#{id}/honor-detail-data',
        updateHeadImg: '/saveHead-v2',
        completeness: '/completeness/connect',
        getAllJobs: '/profile/allJobs',
        completenessNew: '/nccommon/completeness/complete-profile',
        getAllCareerJob: '/completeness/all-career-jobs',
        getAllSchoolMajor: '/completeness/all-school-majors',
        getAllRecommendJob: '/careerplanning/getAllRecommendJob',
        getJobEncyclopedias: '/careerplanning/getJobEncyclopedias',
        getDiscussList: '/careerplanning/getDiscussList',
        // 发送没有职位的反馈
        sendMissCareer: '/user/userfeedback/save-v2'
    }
};
Ajax.add(module.exports, module.exports.url);
