var Base = require('@ncfe/nc.base');
var Exif = require('./exif');
// 图片大于1M，则开始压缩
var MAX_SIZE = 1024 * 256;
// var MAX_WIDTH = 945; // 2 * 3 * 5 * 7 * 4.5
var MAX_WIDTH = 720;

module.exports = {
    compress: fCompress,
    canCompress: fCanCompress,
    getCompressBlob: fGetCompressBlob,
    getBolbFromDataURL: fGetBolbFromDataURL,
    getCanvas: fGetCanvas,
    drawPoster: fDrawPoster
};

function fCompress(oFile, fCb) {
    var that = this;
    var nDelay = 3000;
    var bCalled = false;
    var oTimer;
    if (!that.canCompress(oFile)) {
        return fCall(oFile);
    }
    try {
        // 设置一个超时时间
        fDelay(nDelay);
        var oFileReader = new window.FileReader();
        oFileReader.onload = function (oEvent) {
            var oImage = document.createElement('IMG');
            oImage.onload = function () {
                // 设置延迟
                fDelay(nDelay, function () {
                    var oBlob = that.getCompressBlob(oImage, MAX_WIDTH, 0);
                    fCall(oBlob || oFile);
                });
                // 调整方向
                Exif.getData(oImage, function () {
                    try {
                        // 获取顺时针的旋转方向
                        var sOrientation = Base.id(Exif.getTag(this, 'Orientation'));
                        var nAngle = !sOrientation ? 0 : {6: 90, 3: 180, 8: 270}[sOrientation] || 0;
                        var nType = Math.round(nAngle / 90);
                        var oBlob = that.getCompressBlob(oImage, MAX_WIDTH, nType);
                        oBlob && oFile && oFile.name && (oBlob = new File([oBlob], oFile.name));
                        fCall(oBlob || oFile);
                    } catch (e) {
                        fCall(oFile);
                    }
                });
            };
            oImage.onerror = function () {
                fCall(oFile);
            };
            oImage.src = this.result;
        };
        oFileReader.readAsDataURL(oFile);
    } catch (e) {
        fCall(oFile);
    }

    // 延迟
    function fDelay(nDelay, fCb) {
        window.clearTimeout(oTimer);
        oTimer = window.setTimeout(function () {
            if (fCb) {
                return fCb(oFile);
            }
            fCall(oFile);
        }, 3000);
    }

    // 回调
    function fCall(oFile) {
        window.clearTimeout(oTimer);
        if (!bCalled) {
            bCalled = true;
            fCb && fCb(oFile);
        }
    }
}

function fGetCompressBlob(oImage, nWidth, nType) {
    var that = this;
    var oCanvas = that.getCanvas(oImage, nWidth, nType);
    var sResult = oCanvas.toDataURL('image/jpeg', 0.6);
    var oBlob = that.getBolbFromDataURL(sResult);
    return oBlob;
}

function fCanCompress(oFile) {
    return oFile && oFile.size && +oFile.size > MAX_SIZE;
    // return oFile && oFile.type && oFile.size && +oFile.size > MAX_SIZE;
}

function fGetBolbFromDataURL(sData) {
    var that = this;
    var sImageFmt = '';
    if (sData && sData.match(/^data\:image\/(\w+)/)) {
        sImageFmt = RegExp.$1;
    }
    if (!sImageFmt) {
        return;
    }

    var sRawImageData = sData.replace(/^data\:image\/\w+\;base64\,/, '');
    var oBlob = new Blob([_fBase64DecToArr(sRawImageData)], {type: 'image/' + sImageFmt});
    return oBlob;

    function _fBase64DecToArr(sBase64, nBlocksSize) {
        var sB64Enc = sBase64.replace(/[^A-Za-z0-9\+\/]/g, ''),
            nInLen = sB64Enc.length,
            nOutLen = nBlocksSize ? Math.ceil(((nInLen * 3 + 1) >> 2) / nBlocksSize) * nBlocksSize : (nInLen * 3 + 1) >> 2,
            taBytes = new Uint8Array(nOutLen);
        for (var nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
            nMod4 = nInIdx & 3;
            nUint24 |= _fB64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (18 - 6 * nMod4);
            if (nMod4 === 3 || nInLen - nInIdx === 1) {
                for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
                    taBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255;
                }
                nUint24 = 0;
            }
        }
        return taBytes;
    }

    function _fB64ToUint6(nChr) {
        return nChr > 64 && nChr < 91 ? nChr - 65 : nChr > 96 && nChr < 123 ? nChr - 71 : nChr > 47 && nChr < 58 ? nChr + 4 : nChr === 43 ? 62 : nChr === 47 ? 63 : 0;
    }
}

// nRotate 0~3分别表示顺时针旋转90 * nRotate度
function fGetCanvas(oImage, nMaxWidth, nRotate) {
    var nWidth = oImage.width;
    var nHeight = oImage.height;
    var oCanvas = document.createElement('canvas');
    var oCtx = oCanvas.getContext('2d');
    oCtx.save();
    oCtx.translate(0, 0);
    if (nRotate === 1) {
        oCanvas.width = Math.min(oImage.height, nMaxWidth);
        oCanvas.height = (oCanvas.width * oImage.width) / oImage.height;
        oCtx.rotate(0.5 * Math.PI);
        oCtx.drawImage(oImage, 0, 0, nWidth, nHeight, 0, -oCanvas.width, oCanvas.height, oCanvas.width);
    } else if (nRotate === 2) {
        oCanvas.width = Math.min(oImage.width, nMaxWidth);
        oCanvas.height = (oCanvas.width * oImage.height) / oImage.width;
        oCtx.rotate(Math.PI);
        oCtx.drawImage(oImage, 0, 0, nWidth, nHeight, -oCanvas.width, -oCanvas.height, oCanvas.width, oCanvas.height);
    } else if (nRotate === 3) {
        oCanvas.width = Math.min(oImage.height, nMaxWidth);
        oCanvas.height = (oCanvas.width * oImage.width) / oImage.height;
        oCtx.rotate(1.5 * Math.PI);
        oCtx.drawImage(oImage, 0, 0, nWidth, nHeight, -oCanvas.height, 0, oCanvas.height, oCanvas.width);
    } else {
        oCanvas.width = Math.min(oImage.width, nMaxWidth);
        oCanvas.height = (oCanvas.width * oImage.height) / oImage.width;
        oCtx.drawImage(oImage, 0, 0, nWidth, nHeight, 0, 0, oCanvas.width, oCanvas.height);
    }
    oCtx.restore();
    // document.body.appendChild(oCanvas);
    return oCanvas;
}

//绘制海报
async function fDrawPoster(oParam) {
    const {posterImg, qrCodeImg, userHeadImg} = oParam;
    const canvas = document.createElement('canvas');
    canvas.width = posterImg.width;
    canvas.height = posterImg.height;
    const context = canvas.getContext('2d');
    context.rect(0, 0, canvas.width, canvas.height);
    context.fillStyle = '#fff';
    context.fill();
    await drawImg(context, posterImg.url, 0, 0, canvas.width, canvas.height);
    const userHeadImgP = drawImg(context, userHeadImg.url, userHeadImg.x, userHeadImg.y, userHeadImg.width, userHeadImg.height, userHeadImg.isCircle);
    const qrCodeImgP = drawImg(context, qrCodeImg.url, qrCodeImg.x, qrCodeImg.y, qrCodeImg.width, qrCodeImg.height, qrCodeImg.isCircle);
    await Promise.all([userHeadImgP, qrCodeImgP]);
    return await new Promise(resolve => {
        canvas.toBlob(blob => {
            const a = new FileReader();
            a.readAsDataURL(blob);
            a.onload = e => {
                resolve(e.target.result);
            };
        }, 'image/png');
    });
}
//绘制图片
function drawImg(context, imgSrc, x, y, width, height, isCircle) {
    return new Promise(resolve => {
        const img = new Image();
        //先设置跨域，再设置src，否则在有些机型如iphone11中会报错"SecurityError: The operation is insecure."
        img.crossOrigin = 'Anonymous';
        img.src = imgSrc;
        img.onload = () => {
            if (isCircle) {
                const r = Math.min(width, height) / 2;
                context.save();
                context.beginPath();
                context.arc(x + r, y + r, r, 0, 2 * Math.PI);
                context.clip();
                context.drawImage(img, x, y, width, height);
                context.restore();
            } else {
                context.drawImage(img, x, y, width, height);
            }
            resolve();
        };
    });
}
