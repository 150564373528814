<template>
    <component :is="getComponent" :item="contentInfo.item" :isNotComplete="contentInfo.isComplete" :isEdit="contentInfo.isEdit" :isPreview="contentInfo.isPreview" v-on="$listeners"></component>
</template>

<script lang="ts">
import {Component, Prop} from 'vue-property-decorator';
import Vue from '@ncfe/nc.vue';
import {IFormContentViewProp} from '../../../types';
import EduItem from '../cpn/edu.vue';
import BasicItem from '../cpn/basic.vue';
import JobIntentionExpItems from '../cpn/jobIntentionExp.vue';
import SchoolExpItems from '../cpn/schoolExp.vue';
import ProjectExpItems from '../cpn/projectExp.vue';
import AwardItems from '../cpn/award.vue';
import AttachmentItems from '../cpn/attachment.vue';
import CommonItems from '../cpn/common.vue';

@Component
export default class ContentView extends Vue {
    @Prop(Object) contentInfo?: IFormContentViewProp;

    get getComponent() {
        const that = this;
        return {'1': BasicItem, '2': EduItem, '3': SchoolExpItems, '4': JobIntentionExpItems, '6': ProjectExpItems, '7': AwardItems, '11': AttachmentItems, '100': CommonItems}[that.contentInfo?.id || '100'] || CommonItems;
    }
}
</script>
<style lang="scss">
.color-d8d8d8 {
    color: #d8d8d8;
}
</style>

<style lang="scss">
.hide-txt-2 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.resume-preview-item {
    padding-left: 20px;
    position: relative;
    &::before {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        left: 0;
        top: 5px;
        border: 2px solid #00b88f;
        border-radius: 50%;
    }
    &::after {
        content: '';
        position: absolute;
        height: calc(100% + 10px);
        left: 5px;
        top: 18px;
        width: 2px;
        background: #f6f6f6;
    }
    &:last-child {
        &::after {
            height: 50%;
        }
    }
}
.pre-line {
    white-space: pre-line;
}
</style>
