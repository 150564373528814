<template>
    <popup ref="popup" :value="show" height="100%" style="height: 100%; width: 100%;" @on-hide="$emit('close')" @on-show="onShow">
        <search v-model="word" :auto-fixed="false" auto-scroll-to-top placeholder="输入公司名" @on-cancel="cancel" @on-change="searchCompany" @on-clear="cleanSearchState" @on-result-click="selectItem"></search>
        <group gutter="0">
            <cell v-for="item in items" :key="item.id" :title="item.name" @click.native="selectItem(item)"></cell>
        </group>
        <Loading v-if="!isComplete()"></Loading>
        <None v-if="items.length === 0 && isComplete()">{{ word ? '没有数据' : '输入公司名搜索公司' }}</None>
    </popup>
</template>

<script>
import {Cell, Group, Popup, Search} from '@ncfe/nc.vux';
import CommonAction from '@/js/lib/action/common';
import Loading from '@nc/vue.loading';
import None from '@nc/vue.none';
import Str from '@ncfe/nc.str';

export default {
    props: ['show', 'default'],
    components: {Cell, Group, Popup, Search, Loading, None},
    data() {
        return {
            cache: {},
            word: '',
            items: [],
            isLoading: false
        };
    },
    methods: {
        cleanSearchState: fCleanSearchState,
        cancel: fCancel,
        onShow: fOnShow,
        searchCompany: fSearchCompany,
        selectItem: fSelectItem,
        isComplete: fIsComplete
    }
};

function fCleanSearchState() {
    var that = this;
    that.isLoading = false;
    that.word = '';
    that.items = [];
}

function fCancel() {
    var that = this;
    that.$emit('cancel');
    that.$emit('close');
}

function fOnShow() {
    var that = this;
    that.word = that.default || '';
    that.searchCompany();
}

function fSearchCompany() {
    var that = this;
    var sKey = Str.trim(that.word);
    var oCache = that.cache;
    that.items = [];
    that.isLoading = true;
    if (!sKey || oCache[sKey]) {
        that.isLoading = false;
        that.items = sKey ? oCache[sKey] : [];
        return;
    }
    CommonAction.searchCompany({
        query: {name: sKey},
        call(oResult) {
            var aItem = oResult.data;
            if (aItem.length === 0) {
                aItem = [{name: sKey, isCustom: true}];
            }
            oCache[sKey] = aItem;
            if (Str.trim(that.word) === sKey) {
                that.isLoading = false;
                that.items = aItem;
            }
        },
        error(oResult) {
            if (Str.trim(that.word) === sKey) {
                that.isLoading = false;
                that.items = [];
            }
        }
    });
}

function fSelectItem(oItem) {
    var that = this;
    that.$emit('select', {id: oItem.id, name: oItem.name});
    that.$emit('close');
    that.cleanSearchState();
}

function fIsComplete() {
    var that = this;
    return !Str.trim(that.word) || !that.isLoading;
}
</script>

<style lang="scss">
.vux-cell-bd.vux-cell-primary {
    overflow: hidden;

    label.vux-label div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.weui-icon-clear,
.weui-icon-clear:before {
    visibility: hidden;
}
</style>
