var $ = require('@ncfe/nc.jquery');
import Vue from '@ncfe/nc.vue';
import UIUtil from '@/js/lib/util/ui';
import CmtAction from '@/js/lib/action/cmt';
import Popup from '@nc/vue.popup';

export default {
    reply: fReply,
    showSub: fShowSub
};

function fReply(oConf) {
    UIUtil.input({
        title: oConf.title || '添加回复',
        placeholder: '请输入内容',
        showClose: true,
        call(sVal) {
            var oData = oConf.data || {};
            var sContent = $.trim(sVal);
            if (!sContent) return;
            CmtAction.createItem({
                body: {
                    entityType: oData.entityType,
                    entityId: oData.entityId,
                    commentContent: sVal,
                    toUserId: oData.toUserId,
                    toCommentId: oData.toCommentId,
                    isAnonymousFlag: oData?.isAnonymousFlag || false
                },
                call() {
                    oConf.call && oConf.call();
                    UIUtil.toast('success', '操作成功');
                },
                error: oResult => UIUtil.alert(oResult.msg)
            });
        }
    });
}

function fShowSub(oConf) {
    var that = this;
    var SubIndex = require('./SubIndex').default;
    var SubIndexCpn = Vue.extend(SubIndex);
    var oItem = new SubIndexCpn({
        propsData: {item: oConf.item, isDisableReply: oConf.isDisableReply, isAnonymousFlag: oConf.isAnonymousFlag}
    });
    Popup.show(Vue, oItem, {
        propsData: {
            height: '75%',
            popupClass: 'radius-top'
        }
    });
}
