import CircleAction from '@/js/lib/action/circle';
import FeedAction from '@/js/lib/action/feed';
import SubjectAction from '@/js/lib/action/subject';
import DiscussAction from '@/js/lib/action/discuss';
import CmtAction from '@/js/lib/action/cmt';
import Action from '@/js/lib/action/action';
import UIUtil from '@/js/lib/util/ui';
import Bll from '@/js/lib/bll/index';
import PopupForward from '@/js/cpn/popup/PopupForward';

let Util = require('@/js/lib/util/util');
let Base = require('@ncfe/nc.base');

export default {
    joinCircle: fJoinCircle,
    like: fLike,
    follow: fFollow,
    blockFeed: fBlockFeed,
    blockCircle: fBlockCircle,
    blockUser: fBlockUser,
    sticky: fSticky,

    subjectSticky: fSubjectStycky,
    subjectUnsticky: fSubjectUnsticky,
    del: fDel,
    report: fReport,
    forward: fForward,

    isFollow: fIsFollow,
    // showMenu: fShowMenu,
    showShareMenu: fShowMenu,
    showImage: fShowImage,
    alert: Base.bind(UIUtil.alert)
};

/**
 * 加入/退出圈子
 * @param   {Object} oConf 圈子信息
 *  @param  {String} oConf.id 圈子id
 *  @param  {Number} oConf.visibility 用于判断是否需要申请
 *  @param  {Boolean} oConf.quit true退出
 *  @param  {Function} oConf.call 成功后的回调函数
 */
function fJoinCircle(oConf) {
    if (Util.goLogin()) return;

    let bQuit = !!oConf.quit;
    let bNoReason = +oConf.visibility === 0;
    if (bQuit) return UIUtil.confirm('确定退出圈子？', () => _fAction(true, undefined, oConf.call));
    if (bNoReason) return _fAction(false, undefined, oConf.call);
    _fApply();

    function _fApply(sContent) {
        UIUtil.input({
            title: '申请入圈',
            placeholder: '在此输入申请说明，最多支持140字，可不填',
            value: sContent || '',
            isInput: false,
            call: sVal => {
                if (sVal.length > 140) return UIUtil.alert('申请说明最多支持140字', () => _fApply(sVal));
                _fAction(false, sVal, oConf.call);
            }
        });
    }

    function _fAction(bQuit, sVal, fCb) {
        CircleAction[bQuit ? 'quitCircle' : 'applyCircle']({
            body: {circleId: oConf.id, content: bQuit ? undefined : sVal},
            call: oResult => {
                // 0，非圈内人员 1，已提交申请 2，圈内人员
                let nType = +(oResult.data || {}).relationType;
                fCb && fCb(nType);
            },
            error: oResult => UIUtil.alert(oResult.msg)
        });
    }
}

function fLike(oConf) {
    if (Util.goLogin()) return;

    let bLike = oConf.like !== false;
    Action[bLike ? 'actionLike' : 'actionUnlike']({
        body: {id: oConf.id, type: oConf.type},
        call: oResult => oConf.call && oConf.call({count: oResult.count}),
        error: oResult => UIUtil.alert(oResult.msg)
    });
}

function fFollow(oConf) {
    if (Util.goLogin()) return;

    let bFollow = oConf.follow !== false;
    let bNeedTag = !!oConf.needTag;
    let bNeedSuccessTips = !!oConf.needSuccessTips;
    Bll.collect({
        id: oConf.id,
        type: oConf.type,
        action: !bFollow ? '0' : !bNeedTag ? '1' : '2',
        call(oResult) {
            oConf.call && oConf.call(oResult.data);
            bNeedSuccessTips && UIUtil.toast('success', '操作成功');
        },
        error: oResult => UIUtil.alert(oResult.msg)
    });
}

function fBlockFeed(oConf) {
    if (Util.goLogin()) return;

    UIUtil.confirm('确定屏蔽本条动态吗？', () => {
        FeedAction.shieldFeed({
            body: {feedId: oConf.id},
            call: oConf.call,
            error: oResult => UIUtil.alert(oResult.msg)
        });
    });
}

function fBlockCircle(oConf) {
    if (Util.goLogin()) return;

    let bBlock = oConf.block !== false;
    UIUtil.confirm(`确定${bBlock ? '屏蔽该圈子未关注作者的动态' : '取消屏蔽该圈子动态'}吗？`, () => {
        CircleAction.syncFeed({
            body: {circleId: oConf.id, toFeed: bBlock ? 1 : 0},
            call: oConf.call,
            error: oResult => UIUtil.alert(oResult.msg)
        });
    });
}

function fBlockUser(oConf) {
    if (Util.goLogin()) return;

    UIUtil.confirm('确定屏蔽该作者的全部动态吗？', () => {
        Action.actionBlockUser({
            body: {targetId: oConf.id},
            call: oConf.call,
            error: oResult => UIUtil.alert(oResult.msg)
        });
    });
}

function fSticky(oConf) {
    if (Util.goLogin()) return;
    if (oConf.existTopFeed) return UIUtil.confirm('本圈子已经设置过置顶，是否要替换该动态？', _fAction);
    _fAction();

    function _fAction() {
        CircleAction.stickyFeed({
            body: {
                circleId: oConf.circleId,
                topFeed: JSON.stringify(oConf.feed)
            },
            call: oConf.call,
            error: oResult => UIUtil.alert(oResult.msg)
        });
    }
}

function fSubjectStycky(oConf) {
    if (Util.goLogin()) return;
    if (oConf.existTopFeed) return UIUtil.confirm('本话题已经设置过置顶，是否要替换该动态？', _fAction);
    _fAction();
    function _fAction() {
        SubjectAction.setTopFeed({
            type: 'POST',
            body: {
                subjectId: oConf.subjectId,
                topFeedUuid: oConf.topFeedUuid
            },
            call: oConf.call,
            error: oError => UIUtil.alert(oError.msg)
        });
    }
}

function fSubjectUnsticky(oConf) {
    if (Util.goLogin()) return;
    SubjectAction.unsetTopFeed({
        type: 'POST',
        body: {
            subjectId: oConf.subjectId
        },
        call: oConf.call,
        error: oError => UIUtil.alert(oError.msg)
    });
}

function fDel(oConf) {
    if (Util.goLogin()) return;

    let bGlobal = !!oConf.global;
    let nType = +oConf.type;
    UIUtil.confirm('真的确定要删掉吗？<br />/(ㄒ o ㄒ)/~~', () => {
        if (bGlobal) {
            return FeedAction.deleteFeed({
                body: {feedId: oConf.id},
                call: oConf.call,
                error: oResult => UIUtil.alert(oResult.msg)
            });
        }
        let bDiscuss = nType === 1;
        let bCmt = nType === 3;
        let bShare = nType === 4;
        (bDiscuss ? DiscussAction.deletePost : bCmt ? CmtAction.del : bShare ? CircleAction.deleteShare : CircleAction.deleteMoment)({
            body: {[bDiscuss || bCmt ? 'id' : bShare ? 'shareId' : 'momentId']: oConf.id},
            call: oConf.call,
            error: oResult => UIUtil.alert(oResult.msg)
        });
    });
}

function fReport(oConf) {
    let that = this;
    Bll.report(oConf);
}

function fForward(oConf) {
    if (Util.goLogin()) return;
    let oItem = PopupForward.show({
        propsData: {data: oConf.data}
    });
    oItem.$on('success', oConf.call);
}

function fIsFollow(oConf) {
    if (!Util.isLogin()) {
        oConf.call && oConf.call(false);
        oConf.always && oConf.always();
        return;
    }
    Action.actionIsFollow({
        query: {entityId: oConf.id, entityType: oConf.type},
        call: ({data: {followType}}) => oConf.call && oConf.call(followType === 1 || followType === 3),
        error: oResult => oConf.call && oConf.call(false),
        always: oConf.always
    });
}

function fShowMenu(oConf) {
    UIUtil.actionsheet({
        options: oConf.items,
        call: oConf.call
    });
}

function fShowImage(oConf) {
    let that = this;
    UIUtil.image(oConf);
}
