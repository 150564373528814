import '@ncfe/nc.vux/dist/styles/1px.less';

import Vue from '@ncfe/nc.vue';
import VueRouter from '@ncfe/nc.vue-router';
import FastClick from '@nc/plugin.fastclick';
import {ConfirmPlugin, AlertPlugin, ToastPlugin, LoadingPlugin} from '@ncfe/nc.vux';
import Common from '@/js/lib/plugin/common';
import Directives from '@/js/lib/directives/index';
import '@/js/lib/util/arms';
import {Lazyload} from 'vant';

var PopupVerify = Vue.extend(require('@/js/cpn/popup/PopupVerify').default);

var WeiXin = require('@/js/lib/util/weixin');
var $ = require('@ncfe/nc.jquery');
var Base = require('@ncfe/nc.base');

export default {
    load: fLoad,
    ready: fReady,
    initPlugin: fInitPlugin,
    initWeixin: fInitWeixin,
    initPage: fInitPage,
    initLaunchApp: fInitLaunchApp
};

window.ncAuthActivePhone = function (oParam = {}) {
    $('#popup-verify').get(0) && $('#popup-verify').remove();
    var oPopupVerify = new PopupVerify();
    var oVm = oPopupVerify.$mount();
    document.body.appendChild(oVm.$el);
    oVm.$on('success', () => oParam.call && oParam.call());
    oVm.$on('error', () => oParam.error && oParam.error());
};

function fLoad(oConf) {
    var that = this;
    that.initPlugin(oConf);
    that.initWeixin(oConf);
    that.initLaunchApp();
    that.initPage(oConf);
}

function fReady(oConf) {
    var that = this;
    var oParam = oConf.param || {};
    var oModule = {...oConf};
    that.initPlugin(oParam);
    that.initWeixin(oParam);

    delete oModule.param;
    oModule.initialize && oModule.initialize.call(oModule);
    return oModule;
}

function fInitPlugin(oConf) {
    var that = this;
    var bRouter = Base.isFunction(oConf.router);
    !oConf.noFastClick && FastClick.attach(document.body);

    Vue.use(Common);
    Vue.use(ConfirmPlugin);
    Vue.use(AlertPlugin);
    Vue.use(ToastPlugin);
    Vue.use(LoadingPlugin);
    Vue.use(Directives);
    Vue.use(Lazyload, {
        lazyComponent: true
    });
    bRouter && Vue.use(VueRouter);
}

function fInitWeixin(oConf) {
    var that = this;
    var oWx = oConf.wx || {};
    WeiXin.init({
        title: oWx.title || $('title').text(),
        imgUrl: oWx.imgUrl,
        link: oWx.link,
        desc: oWx.desc || $('meta[name=description]').attr('content'),
        cancelShare: oWx.cancelShare,
        success:
            oWx.success ||
            (() => {
                that.app.$vux.alert.show({
                    content: '感谢您的分享'
                });
            }),
        cancel: oWx.cancel
    });
}

function fInitPage(oConf) {
    var that = this;
    var bRouter = Base.isFunction(oConf.router);
    oConf.beforeInit ? oConf.beforeInit(_fInit) : _fInit();

    function _fInit() {
        that.app = new Vue({
            el: oConf.el || '#app',
            store: oConf.store,
            router: bRouter ? oConf.router(VueRouter) : oConf.router,
            render: h => h(oConf.module)
        });
    }
}

function fInitLaunchApp() {
    Vue.config.ignoredElements = ['wx-open-launch-app'];
}
