<template>
    <popup v-model="show" height="100%">
        <div class="txt-right p-2 color-green">
            <span @click="show = false">取消</span>
        </div>
        <div class="txt-center ptb-5">
            <div class="mb-2 color-green fs-l">请完成验证</div>
            <div v-show="!error" :id="custom_id || 'jsNeteaseCaptcha'">验证码加载中...</div>
            <div v-show="error">{{ error }}</div>
        </div>
    </popup>
</template>

<script>
import {Popup} from '@ncfe/nc.vux';
var $ = require('@ncfe/nc.jquery');
var Time = require('@ncfe/nc.time');
var CommonAction = require('@/js/lib/action/common');
export default {
    props: ['value', 'type', 'custom_id'],
    components: {Popup},
    data: fData,
    methods: {
        initCaptchaId: fInitCaptchaId,
        initNetease: fInitNetease,
        showCaptcha: fShowCaptcha
    },
    watch: {
        value: fWatchValue,
        show: fWathcShow
    },
    mounted: fMounted
};

function fMounted() {
    const that = this;
    CommonAction.getUserIp({type: 'get'}).then(({gioData}) => {
        that.ip = gioData;
    });
}

function fData() {
    var that = this;
    return {
        index: 0,
        gt: null,
        show: !!that.value,

        captchaId: '',
        error: '',

        ip: ''
    };
}

function fInitCaptchaId(fCb) {
    var that = this;
    var nIndex = that.index;
    CommonAction.getGtData({
        query: {t: new Date().getTime(), source: 'netease'},
        params: {type: that.type},
        call(oResult) {
            nIndex === that.index && fCb && fCb(oResult.data.captchaId);
        },
        error(oResult) {
            that.error = oResult.msg;
        }
    });
}

function fInitNetease(fCb) {
    var that = this;
    if (window.initNECaptcha) {
        return fCb && fCb();
    }
    var nIndex = that.index;
    var oScript = $(document.createElement('script'));
    oScript.attr('type', 'text/javascript');
    oScript.attr('src', '//cstaticdun.126.net/load.min.js');
    $(document.body).append(oScript);
    Time.waitUtil({
        times: 250, // 等60s
        condition: function () {
            return !!window.initNECaptcha;
        },
        call() {
            nIndex === that.index && fCb && fCb();
        }
    });
}

function fShowCaptcha() {
    var that = this;
    that.initCaptchaId(sCaptchaId => {
        that.initNetease(() => {
            window.initNECaptcha(
                {
                    captchaId: sCaptchaId,
                    element: `#${that.custom_id ? that.custom_id : 'jsNeteaseCaptcha'}`,
                    mode: 'embed',
                    width: 260,
                    onVerify: function (oErr, oData) {
                        window.gio &&
                            window.gio('track', 'checkCodeSlide', {
                                userIp_var: that.ip
                            });
                        if (!oErr && oData) {
                            that.$emit('verify', {netease_validate: oData.validate});
                            that.show = false;
                        }
                    }
                },
                function (oGt) {
                    that.gt = oGt;
                },
                function (oErr) {
                    that.error = '验证码加载失败，请重试';
                }
            );
        });
    });
}

function fWatchValue(bVal) {
    var that = this;

    that.show = bVal;
}

function fWathcShow(bShow) {
    var that = this;
    var oGt = that.gt;
    that.index++;
    bShow && that.showCaptcha();
    if (!bShow) {
        oGt && oGt.destroy();
        oGt = null;
        $('#jsNeteaseCaptcha').html('验证码加载中...');
    }
    that.$emit('input', bShow);
}
</script>
