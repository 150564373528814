<template>
    <div v-if="!error && (replies || []).length > 0" class="bg-light-white p-2 fs-s">
        <div v-for="item in replies" :key="item.id" class="color-green">
            <NickName :id="item.authorId" class="cmt-list-sub-nickname" :level="item.honorLevel" :identity="item.identity" :name="item.authorName" :tag="lordId && lordId === item.authorId ? (lordType === 'author' ? '作者' : '楼主') : ''"></NickName>
            <span class="color-gray">
                <span v-if="item.toUserId && item.toCommentId !== id">回复<NickName :id="item.toUserId" class="cmt-list-sub-nickname" :level="item.toUserHonorLevel" :identity="item.toUserIdentity" :autoSize="true" :name="item.toUserName" :tag="lordId && lordId === item.authorId ? (lordType === 'author' ? '作者' : '楼主') : ''"></NickName></span>
                <span> : </span>
                <PostContent :content="item.content" :isTxt="true" class="inline"></PostContent>
            </span>
        </div>
        <div v-if="commentCnt > 2" class="txt-right mt-1">
            共 <span class="color-green">{{ commentCnt }}</span> 条回复
        </div>
    </div>
</template>

<script>
import NickName from '@nc/vue.nickname';
import PostContent from '@/js/cpn/PostContent';

export default {
    components: {NickName, PostContent},
    props: [
        'replies', // 直接返回数据了
        'id', // id
        'commentCnt', // 评论数量
        'lordId', // 楼主id
        'lordType'
    ],
    data: fData,
    mounted: fMounted
};

function fData() {
    var that = this;
    return {
        totalCnt: that.total || 0,
        error: ''
    };
}

function fMounted() {
    var that = this;
    !that.id && (that.error = '错误的评论参数');
}
</script>
<style scoped lang="scss">
.inline {
    display: inline;
}
.cmt-list-sub-nickname {
    font-size: $fontS !important;
}
</style>
<style lang="scss">
.cmt-list-sub-nickname {
    img {
        vertical-align: sub;
    }
}
</style>
