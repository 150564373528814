// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 * 3
 *
 * @export 认证提交成功 提交成功时触发
 * @param {*} oData
 * @param identityType_var 认证类型
 * @param company_var 公司
 * @param position_var 职位
 */
function legalizeSubmit(oData) {
    gio('legalizeSubmit', oData);
}

/**
 * @description: 访问H5投递记录
 */
function tdjlyH5Visit() {
    gio('tdjlyH5Visit');
}

/**
 * @description: 访问H5专场热招职位
 */
function zcrzzwH5Visit() {
    gio('zcrzzwH5Visit');
}
/**
 * @description: 访问H5专场热招企业
 */
function zcrzqyH5Visit() {
    gio('zcrzqyH5Visit');
}
/**
 * @description: 访问H5校招日程页
 */
function xzrcyH5Visit() {
    gio('xzrcyH5Visit');
}

/**
 *
 * @export 专场活动职位详情页访问   专场活动落地页
 */
function ZCHDZWXQYvisit(oData) {
    gio('ZCHDZWXQYvisit', oData);
}

export default {
    legalizeSubmit,
    tdjlyH5Visit,
    zcrzzwH5Visit,
    zcrzqyH5Visit,
    xzrcyH5Visit,
    ZCHDZWXQYvisit
};
