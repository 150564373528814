var Ajax = require('./ajax');
module.exports = {
    url: {
        // {query: {source: String, page: Number, query: String}}
        didiGetRecommandList: '/recommand/didi2017/list',
        receiveZiliao: '/app/ad/1/receive',

        codemSign: '/activity/2018codem/sign',

        //获取抽奖记录, GET, activityId|page
        getLotteryRecords: '/lottery/records',
        //抽奖, POST, activityId
        drawLottery: '/lottery/draw',

        // 补充支付宝账号
        completeAlipayAccount: '/activity/course/alipay-account-completion',

        lingCreateGroup: '/activity/lingziliao/create',
        lingJoinGroup: '/activity/lingziliao/join-group',
        lingConfirmReceipt: '/activity/lingziliao/confirm-receipt',
        getGroup: '/activity/lingziliao/#{groupId}',
        getBubble: '/activity/lingziliao/rollMessage',

        //2019名企实习
        //获取暑期实习项目, GET, type|page
        recommandjob2019Projects: '/activity/2019recommand/projects',
        //获取我推荐的用户， GET，page
        recommandjob2019Users: '/activity/2019recommand/users',
        //获取排行榜的用户， GET，page
        recommandjob2019Ranks: '/activity/2019recommand/ranks',
        //领资料， Post
        recommandjob2019Ling: '/activity/2019recommand/ling',

        //2019暖春计划
        warmSpring2019Projects: '/activity/2019warmspring/projects',
        warmSpring2019JobList: '/activity/2019warmspring/jobs',

        invite2019Ling: '/activity/invite2019/ling',

        sp2020List: '/activity/2020sp/projects',
        sp2021List: '/activity/2021sp/projects',
        additionalRecruit2020List: '/activity/2020additionalRecruit/projects',
        sequoiaCap2020List: '/activity/2020hongshan/projects',
        clientAndWeb2021List: '/activity/2021clientAndWeb/projects',
        oversea2020List: '/activity/2020oversea/projects',
        bankActivity2021List: '/activity/2021bankActivity/projects',
        oversea2021list: '/activity/2021oversea/projects',

        // offer神器相关
        //offer雷达，选择活动
        offerShowChoose: '/nccommon/show-offer/choose',
        //offer雷达，选择活动结果
        offerShowChooseResult: '/nccommon/show-offer/choose-result',
        //offer雷达，选择活动结果json版本，参数与offerShowChooseResult一致
        offerShowChooseResultJson: '/nccommon/show-offer/choose-result-json',
        //offer雷达，统计结果Json
        offerShowStatistic: '/nccommon/show-offer/statistic',
        //查offer，统计结果,具体到公司职位,GET,education|city|occupation|company
        offerShowCompanyOccupationStatistic: '/nccommon/show-offer/statistic-company-occupation',
        //offer比较，保存结果
        offerShowCompareSave: '/nccommon/show-offer/compare-save',
        //offer比较，输出结果vm
        offerShowCompareResult: '/nccommon/show-offer/compare-result',
        //offer比较，输出结果Json版本，参数与offerShowCompareResult一致
        offerShowCompareResultJson: '/nccommon/show-offer/compare-result-json',
        offerShowCompareResultJsonV2: '/nccommon/offer/show-v2/compare-result-json',
        //offer比较，删除offer
        offerShowDelOffer: '/nccommon/show-offer/del-offer',
        // 用户已填写的offer信息
        userOffers: '/nccommon/show-offer/user-offers',
        cityList: '/nccommon/offer/show-v2/city-list',
        // offer录入相关
        getMyOfferInfoList: '/nccommon/offer/show-v2/get-my-offer-info-list',
        getMyOfferInfo: '/nccommon/offer/show-v2/get-my-offer-info',
        saveOrEditOfferInfo: '/nccommon/offer/show-v2/save-or-edit-offer-info',
        offerEnteringCompareSave: '/nccommon/offer/show-v2/compare-save',
        deleteMyOfferInfo: '/nccommon/offer/show-v2/delete-my-offer-info',
        // 生成匿名贴
        // anonymousCompareSave: '/nccommon/offer/anonymous-compare-save',
        anonymousCompareSave: '/nccommon/offer/anonymous-compare-save',
        // 2020zte
        // zte2020AlgorithmSign: 'action/zte2020AlgorithmSign',
        // zte2020MarketingSign: 'action/zte2020MarketingSign'
        //算法报名,POST,字段:signInfo和partyNo
        zte2020AlgorithmSign: '/activity/zte2020/algorithm/signDo',
        //营销报名,POST,字段:signInfo
        zte2020MarketingSign: '/activity/zte2020/marketing/signDo',
        // 2021zte
        zte2021AlgorithmSign: '/activity/challenge2021/algorithm/signDo',
        zte2021MarketingSign: '/activity/challenge2021/marketing/signDo',
        zte2021UpdateParty: '/activity/challenge2021/updateParty',
        // 刷题闯关-编程训练营活动（面向新人）
        codingCampJoin: '/studypath/codingCamp/join',

        // 2020intern
        intern2020JobList: '/activity/intern2020/jobList',
        intern2020GetPrize: '/activity/intern2020/getPrize',
        intern2020ChangeDecorate: '/activity/intern2020/changeDecorate',

        // 2020mi
        mi2020AllActivityInfo: '/nccommon/activity/mi2020/all-activity-info',
        mi2020SignUp: '/nccommon/activity/mi2020/sign-up',
        //老带新裂变
        getActivityFissionInfo: '/activity/fission/show-data',
        getActivityFissionRule: '/activity/fission/rule',
        //老带新兑换码
        getActivityFissionCode2: '/activity/fission/coupon2',
        getActivityFissionCode3: '/activity/fission/coupon3',
        getPosters: '/activity/fission/posters',
        //发送站内信
        sendMsg: '/activity/fission/send-msg',
        sendMsgV2: '/activity/fission/v2/send-msg',
        sendMsgV3: '/activity/fission/v3/send-msg',
        //动物测一测 问题列表
        getTestQuestionList: '/activity/appraisal/career/list',
        //选择当前答案 post
        questionDo: '/activity/appraisal/career/do',
        //获取下载app所需要的信息
        getDownloadAppInfo: '/app/button',
        // 年终活动
        getAnnualReport: '/activity/annual-report/pages',
        postMoment: '/activity/annual-report/post-moment',
        saveAccessLog: '/activity/annual-report/visit',

        // 上传简历得礼包
        getResumeActivityUserCount: '/nccommon/activity/resume/obtain-cnt',
        getResumeActivityUserInfo: '/nccommon/activity/resume/user-activity-info',
        getResumeActivityInfo: '/nccommon/activity/resume/activity-info',

        // 获取地推二维码
        getDituiQrcode: '/qrcode/ditui',
        // 是否扫过二维码
        isScanQrcode: '/qrcode/is_scanning',

        // 专场活动
        getSpecialActivityInfo: '/nccommon/activity/v2/special-activity/#{suffix}',
        getSpecialActivityCompanies: '/nccommon/activity/v2/special-activity/company/list',
        getSpecialActivityCompanyInfo: '/nccommon/activity/v2/special-activity/company',
        getSpecialActivityCompaniesFilter: '/nccommon/activity/v2/special-activity/filter-support',
        getSpecialActivityCompanyJobs: '/nccommon/activity/v2/special-activity/company/job',
        getSpecialActivityJobInfo: '/nccommon/activity/v2/special-activity/job/detail',
        getSpecialActivityJobs: '/nccommon/activity/v2/special-activity/job/list',
        deliverSpecialActivityJob: '/nccommon/activity/v2/special-activity/deliver',
        cancelDeliverSpecialActivityJob: '/nccommon/activity/v2/special-activity/cancel-deliver',
        getSpecialActivityVolunteerInfo: '/nccommon/activity/v2/special-activity/deliver/plugin-info',
        getSpecialActivityRecommendCompany: '/nccommon/activity/v2/special-activity/recommend/company',
        getSpecialActivityRecommendJob: '/nccommon/activity/v2/special-activity/recommend/job',

        // MBTI 活动
        getMBTIQuestionList: '/activity/appraisal/mbti-list',
        getMBTIQuestionHistory: '/activity/appraisal/mbti-history',
        submitMBTIQuestion: '/activity/appraisal/mbti-do',
        getMBTIQuestionResult: '/activity/appraisal/mbti-result-index',
        deleteMBTIQuestionHistory: '/activity/appraisal/mbti-answer-log',
        sendMsgMbti: '/activity/appraisal/mbti-send',
        // 简历点评活动
        submitInviteCode: '/activity/resume/invite/code',
        getInvitePreSub: '/activity/resume/invite/pre-sub',
        submitInviteCode2: '/activity/resume/invite/code-v2',
        resumeInviteReceive: '/activity/resume/invite/receive',
        // 社群资料包引流工具
        getFMCGResource: '/activity/fmcg/new-industry/check-phone',
        //新手任务信息列表
        taskList: '/activity/user/new-task/task-list',
        //完成任务
        taskPush: '/activity/user/new-task/task-push',
        //获取奖励
        taskGet: '/activity/user/new-task/task-get',

        // 行测活动
        startAAT: '/activity/test/begin',
        // 2021刷题活动 获取活动信息
        getActivityInfo: '/activity/coding-2021/data/get',
        // 2021刷题活动 获取进度
        getProgress: '/activity/coding-2021/user/progress',
        // 2021刷题活动 查询用户排名
        checkRankInfo: '/activity/coding-2021/user/rank',
        // 2021刷题活动 查询排行榜信息
        getRankList: '/activity/coding-2021/rank/get',
        // 2021刷题活动 查询用户状态
        checkUserStatus: '/nccommon/activity/coding-2021/user/status',
        // 2021刷题活动 用户报名
        userSign: '/nccommon/activity/coding-2021/user/sign',
        // 2021刷题活动 订阅每日刷题提醒
        // 2021刷题活动 取消刷题提醒
        subscribeEveryDay: '/nccommon/activity/coding-2021/user/subscribe',
        unsubscribeEveryDay: '/nccommon/activity/coding-2021/user/unsubscribe',
        // 春招活动
        getSpringJobData: '/activity/spring-job-2022/home/get-data',
        // 预约直播
        subscribeLive: '/api/live/subscribe/act',
        // 抽奖
        lottery: '/activity/spring-job-2022/lottery/draw',
        // 用户奖励列表
        getUserGiftsList: '/activity/spring-job-2022/lottery/query-user-data',
        // 看面经
        taskExperience: '/activity/spring-job-2022/task/finish',
        // 校验经纬度
        checkLocation: '/activity/cheating/cheating/location',
        // 参加活动，用户春招活动iOS ip 归因
        receiveJoin: '/activity/template/type/receive/join'
    }
};
Ajax.add(module.exports, module.exports.url);
