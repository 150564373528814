/**
 * allRecommendActivity // 获取所有内推公司
 * {
 *      params{
 *          type: Number    // 0正在进行中 1已结束 2即将开始
 *      }
 * }
 *
 * getCompany // 获取公司信息
 * {
 *      params{
 *          url: String  // url
 *    }
 * }
 *
 * getJob  // 获取职位
 * {
 *      body{
 *          jobId: Number
 *      }
 * }
 * getResumeSetting // 获取用户内推简历设置信息
 * {
 *      query {
 *
 *      }
 * }
 *
 * updateResumeSetting  // 更新用户内推简历设置信息
 * {
 *      body{
 *          job: String   感兴趣的职位
 *          place: String  期望的地点
 *          salary: String  期望公司薪水
 *          companyScale: String  期望公司规模
 *          resumeRight: Number  简历公开权限
 *          resumeId: String   默认加密简历id
 *      }
 * }
 * signJob // 申请职位
 * {
 *      body {
 *          jobId: Number
 *          resumeId: Number
 *      }
 *
 * }
 * unsignJob // 取消申请职位
 * {
 *      body {
 *          jobId: Number
 *      }
 *
 * }

 * getReferralDeliverList // 获取用户内推的投递
 * {
 *      query {
 *         source: String // 内推工号
 *         page: Number
 *      }
 * }
 * checkReferralSource:{ // 输入的工号是否合法
 *   query:{
 *       projectId: Number // 项目id
 *       source : String //  输入的工号或者邮箱
 *   }
 *}

 *
 **/

var Ajax = require('./ajax');

module.exports = {
    url: {
        // 获取兼职实习列表, GET, type(Number)|city(String)|query(String)|order(Number)|latest(Boolean, true最近一周)|page(Number)
        getJobList: '/job/list',
        getJobMenu: '/job/menu',
        allRecommendActivity: '/recommend/activity',
        getCompany: '/recommend/#{url}',
        getJob: '/recommend/job-info',

        getResumeSetting: '/recommend/resume-setting',
        updateResumeSetting: '/recommend/update-setting',
        signJob: '/recommend/sign/job',
        unsignJob: '/recommend/unsign/job',

        getReferralDeliverList: '/careers/referral/#{url}/#{projectId}/detail',
        checkReferralSource: '/careers/referral/check-source',
        login: '/careers/referral/login',
        tipShow: '/recommand/tip/show',
        tipClose: '/recommand/tip/close',

        step1Data: '/recommand/v3/step1-data',
        recommandV3Step1: '/recommand/v3/step1',
        deliverAllJobs: '/recommand/v3/step1-all',
        unsignJobV3: '/recommand/v3/unsign/job',

        // 一键投递接口
        // deliverAllJobs: '/nccommon/recommand/v2/step1-all',

        // 上周热帖接口
        postTop: '/recommend/quality/post-top',
        questionTop: '/recommend/quality/question-top'
    }
};

Ajax.add(module.exports, module.exports.url);
