<template>
    <Popup v-model="isShow" popupClass="bg-white radius-top" width="100%">
        <div class="p-2 flex-row-start vux-1px-b" style="align-items: flex-start;">
            <div style="flex: auto;">
                <div class="strong fs-l mb-1 color-green">收藏成功！</div>
                <div class="fs-s">你还可添加合适标签进行分类</div>
            </div>
            <div class="color-gray" @click.stop="close">取消</div>
        </div>

        <div class="p-2">
            <x-button class="fs-xs mt-2 rounded" mini type="primary" @click.native="actionAdd">＋ 新建标签</x-button>
            <x-button v-for="(tag, index) in tags" :key="index" :class="isSelected(tag) ? 'green' : 'gray'" class="fs-xs mt-2 rounded" mini plain @click.native="actionSelect(tag)"> <span v-if="isSelected(tag)">✓</span>{{ tag }} </x-button>
        </div>
        <div class="plr-2 ptb-4">
            <x-button type="primary" @click.native="actionSubmit">确定添加</x-button>
        </div>
    </Popup>
</template>

<script>
var Base = require('@ncfe/nc.base');
var Str = require('@ncfe/nc.str');
import {XButton} from '@ncfe/nc.vux';
import Popup, {PopupMix, PopupShow} from '@nc/vue.popup';
import Vue from '@ncfe/nc.vue';
import UIUtil from '@/js/lib/util/ui';

export default {
    show: PopupShow(Vue),
    props: ['initialTags', 'initialSelected'],
    components: {Popup, XButton},
    mixins: [PopupMix],
    data: fData,
    methods: {
        isSelected: fIsSelected,
        actionAdd: fActionAdd,
        actionSelect: fActionSelect,
        actionSubmit: fActionSubmit
    }
};

function fData() {
    var that = this;
    return {
        tags: Base.filter(Base.uniq(that.initialTags), oItem => !!oItem),
        selected: Base.filter(Base.uniq(that.initialSelected), oItem => !!oItem)
    };
}

function fIsSelected(sTag) {
    var that = this;
    return Base.contains(that.selected, sTag);
}

function fActionAdd() {
    var that = this;
    UIUtil.input({
        title: '新建收藏标签',
        isInput: true,
        showClose: true,
        call(sVal) {
            var sNewTag = Str.trim(sVal);
            if (!sNewTag) return;
            var bTag = Base.contains(that.tags, sNewTag);
            var bSelected = Base.contains(that.selected, sNewTag);
            !bTag && (that.tags = [...that.tags, sNewTag]);
            (!bTag || !bSelected) && (that.selected = [...that.selected, sNewTag]);
        }
    });
}

function fActionSelect(sTag) {
    var that = this;
    var nIndex = Base.indexOf(that.selected, sTag);
    nIndex === -1 && that.selected.push(sTag);
    nIndex !== -1 && that.selected.splice(nIndex, 1);
}

function fActionSubmit() {
    var that = this;
    that.$emit('submit', that.selected);
    that.close();
}
</script>

<style lang="scss" scoped>
.rounded {
    border-radius: 100px;
    margin-right: 10px;
    padding: 0 10px;

    &:last-of-type {
        margin-right: 0;
    }
}

.gray {
    border-color: $colorLightGray;
    color: $colorGray;
}

.green {
    border-color: $colorGreen;
    color: $colorGreen;
}
</style>
