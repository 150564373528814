let Ajax = require('./ajax');
let sDomain = (window.host || {}).apiDomain || 'api.nowcoder.com';
let oModule = (module.exports = {
    domain: sDomain,
    devDomain: 'dapi.nowcoder.com',
    url: {
        saveEvaluation: '/v1/interviews/#{id}/evaluations',
        pingV2Interview: '/v1/user/interviews-v2/#{id}/ping',
        getTestResult: '/v1/test-pdf/#{testId}/info',

        // 语音转文字
        aiAudio2Text: '/v1/ai/interview/speech-process',
        getTaskProgress: '/v1/job-progress/#{id}'
    }
});
Ajax.add(module.exports, oModule.url, oModule.domain);
