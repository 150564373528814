// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 *
 * @export 进入直播间时触发
 * @param {*} oData
 * @param isStation_var 是否站内进入
 * @param isLogin_var 是否登录
 * @param isreserbation_var 是否预约
 * @param entranceName_var 入口名称
 * @param sectionID_var 课程章节ID
 * @param liveTopic_var 直播主题
 * @param liveAnchor_var 直播名称
 */
function inRoom(oData) {
    gio('inRoom', oData);
}

/**
 *
 * @export 退出直播间时触发
 * @param {*} oData
 * @param sectionID_var 课程章节ID
 * @param liveTopic_var 直播主题
 * @param liveAnchor_var 直播名称
 * @param watchTime_var 观看时长（分钟）
 */
function liveRoom(oData) {
    gio('improveResumesuccess', oData);
}

/**
 *
 * @export 直播互动 发弹幕、点赞、提问、分享时触发
 * @param {*} oData
 * @param sectionID_var 课程章节ID
 * @param liveTopic_var 直播主题
 * @param liveAnchor_var 直播名称
 */
function liveInteractive(oData) {
    gio('liveInteractive', oData);
}

/**
 *
 * @export 直播链接点击 点击时触发
 * @param {*} oData
 * @param linkName_var 链接名称
 * @param sectionID_var 课程章节ID
 * @param liveTopic_var 直播主题
 * @param liveAnchor_var 直播名称
 */
function liveLinkClick(oData) {
    gio('liveLinkClick', oData);
}

export default {
    inRoom,
    liveRoom,
    liveInteractive,
    liveLinkClick
};
