/**
 * getDiscussList // 获取讨论区列表
 * {
 *      query{
 *          type: Number    // 讨论区类型
 *          order: Number   //  排序规则 0按照更新时间 1按照热度 3按照创建时间 4精华, 5收藏的帖子, 6创建的帖子
 *          page: Number // 从 1 开始
 *       
 *      }
 * }
 *
 * getDiscussTypeMenu  // 获取子版块类型信息
 * {
 *      query{
 *      }
 *      
 * }
 *
 * getDiscussInfo // 获取帖子的内容
 * {
 *      query{
 *          type: Number    // 讨论区类型
 *          order: Number   //  排序规则 0按照最后回复时间，1按照热度，4精华, 5收藏的帖子, 6创建时间
 *          pos: Number     //  位置
 *          discussId: Number // 帖子id， 如果这个大于0，忽略其他参数,
 *          uid: NUmber    // 用户id，如果没有指定，则获取当前用户发表的帖子
 *      }
 * }
 * getMyDiscusses // 获取用户发表的帖子
 * {
 *      query{
 *          page: Number,
 *          uid: NUmber    // 用户id，如果没有指定，则获取当前用户发表的帖子
 *      }
 * }
 *
 * getFollowingDiscuss // 获取收藏的帖子
 * {
 *      query{
 *          page: Number
 *      }
 * }
 *
 * createDiscuss // 创建帖子
 * {
 *     body{
 *         type: Number     //  板块
 *         title: String    //  帖子标题  
 *         content: String  //  帖子内容
 *     }
 * }
    ` *
 * getLatestDiscuss // 获取最新发表的一条帖子信息
 * {
 *     query{
 *     }
 *     
 * }
 * 
 */

var Ajax = require('./ajax');

module.exports = {
    url: {
        getDiscussList: '/discuss/get-discusses-v2',
        getHomeRecommends: '/nc-api/h5/home/recommend',
        getDiscussTypeMenu: '/discuss/get-discuss-type-menu',
        getDiscussInfo: '/discuss/get-discuss-info',

        getMyDiscusses: '/profile/get-my-discusses',
        getFollowingDiscuss: '/profile/get-my-following-discusses',
        createDiscuss: '/discuss/create',
        getLatestDiscuss: '/discuss/get-latest-discuss',
        // 增加帖子 body {type: Number 类型, title: String 标题， content: String 内容， tags: String 标签， reward: float 悬赏的金额}
        addPost: '/discuss/create',
        // 编辑帖子 body {id: Number ， 其他参数和创建帖子一样}
        updatePost: '/discuss/edit',
        // 删除帖子  body {id: Number}
        deletePost: '/discuss/delete',
        //  前十retie
        getTopTenDiscuss: '/discuss/top-ten',
        // 帖子热榜
        getHotPost: '/discuss/home/top-posts',
        // 讨论区默认推荐接口
        getIndexList: '/discuss/list-json',
        // 随机最热前5
        getTopRandomDiscuss: '/discuss/top-random',
        // 大家都在刷
        getAttention: '/paper/recommend-company-paper',
        // 未登录，帖子终端页广告
        getNotLoginAds: '/discuss/ads',
        // 相关热帖
        getRealtiveHotTalks: '/discuss/relative-posts'
    }
};

Ajax.add(module.exports, module.exports.url);
