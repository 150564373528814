<template>
    <div v-transfer-dom>
        <x-dialog v-model="showDialog" class="dialog-demo" :hide-on-blur="true" :dialog-style="dialogStyle">
            <div class="dialog-wrapper">
                <div class="ptb-4 plr-2 radius">
                    <div class="flex-column-center">
                        <img class="img-logo" :src="$ncimage('/images/logo2.png')" />
                        <div class="mt-4">
                            <div>上牛客，立即与牛友互动</div>
                            <div class="mt-1">求职内推｜名企真题｜笔经面经</div>
                        </div>
                    </div>
                    <div class="plr-2 mt-4">
                        <x-button type="primary" style="background-color: #00b88f" :link="$ncDownloadUrl" @click.native="actionHideDialog">体验牛客</x-button>
                        <BtnOpenInApp>
                            <div class="mt-2 fs-s color-green" style="text-decoration: underline"><a :href="$ncAppSwitchHref()" @click="actionOpenInApp">我已安装，用APP打开</a></div>
                        </BtnOpenInApp>
                    </div>
                </div>
                <x-icon size="20" class="dialog-close" type="ios-close-outline" @click.native="actionHideDialog"></x-icon>
            </div>
        </x-dialog>
    </div>
</template>

<script>
import {XDialog, XButton, XIcon} from '@ncfe/nc.vux';
import {TransferDomDirective as TransferDom} from '@ncfe/nc.vux';
import BtnOpenInApp from '@/js/cpn/BtnOpenInApp';

let App = require('@/js/lib/util/app');

export default {
    props: ['value', 'isNew', 'cancellcb', 'confirmcb'],
    components: {XDialog, XButton, XIcon, BtnOpenInApp},
    directives: {TransferDom},
    data: fData,
    methods: {
        actionOpenInApp: fActionOpenInApp,
        actionHideDialog: fActionHideDialog
    },
    watch: {
        value: fValue,
        showDialog: fShowDialog
    }
};

function fData() {
    let that = this;
    return {
        dialogStyle: {'background-color': 'transparent'},
        showDialog: that.value,
        isWx: App.isWx
    };
}

function fActionHideDialog() {
    let that = this;
    that.showDialog = false;
}

function fValue(bVal) {
    let that = this;
    that.showDialog = bVal;
}

function fActionOpenInApp() {
    let that = this;
    if (that.isNew) {
        if (that.isWx) {
            that.showDialog = false;
            that.$vux.confirm.show({
                title: '可能离开微信，打开第三方应用',
                confirmText: '继续',
                onConfirm: () => {
                    that.confirmcb ? that.confirmcb() : that.actionHideDialog(), that.$nextTick(() => that.$ncAppOpen());
                },
                onCancel: () => {
                    that.cancellcb ? that.cancellcb() : that.$ncGoLogin();
                }
            });
        } else {
            setTimeout(() => {
                that.$ncGoLogin();
            }, 50);
        }
    } else {
        that.actionHideDialog();
        that.$nextTick(() => that.$ncAppOpen());
    }
}

function fShowDialog(bVal) {
    let that = this;
    that.$emit('input', bVal);
}
</script>

<style scoped lang="scss">
.img-logo {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: block;
}

.dialog-wrapper {
    background: #eff9ff;
    position: relative;

    .dialog-close {
        position: absolute;
        right: 10px;
        top: 10px;
        fill: #000;
    }
}
::v-deep .btn-open-in-app .wx-open-launch-app #launch-app {
    width: auto;
}
</style>
