import {generateId} from '@ncfe/gio.lib';
const Base = require('@ncfe/nc.base');

function commonPageName(sUrl) {
    const oMapName = {
        '/feed/main/detail': '动态终端页',
        '/discuss': '帖子终端页',
        '/paper/menu': '公司真题',
        '/itest/menu': '专项练习',
        '/itest/wrong-set': '错题练习',
        '/profile': '个人页'
    };
    const DEFAULT = '其他';
    sUrl = sUrl || window.location.href;
    let name = _getMapName(sUrl, oMapName) || DEFAULT;
    return name;

    function _getMapName(sUrl, oMapName) {
        if (sUrl === window.location.origin + '/') {
            return '首页';
        }
        let name = '';
        Base.forEach(Base.keys(oMapName), sItem => {
            if (sUrl.includes(sItem)) {
                name = oMapName[sItem];
            }
        });
        return name;
    }
}

export default {
    generateId: generateId,
    commonPageName
};
