<template>
    <div v-show="!(hideWhenNone && (error || total <= 0))">
        <None v-if="error">{{ error }}</None>
        <div v-else>
            <div v-if="needTitle" class="p-2 flex-row-start vux-1px-tb">
                <span style="flex: auto">{{ total }}条回帖</span>
                <span v-if="needOrder" class="ml-2 flex-row-start color-gray" style="flex: none" @click="actionOrder">
                    <img :src="orderIcon" class="order-image" /><span class="ml-1">{{ orderStr }}</span>
                </span>
            </div>
            <div v-for="(item, index) in showCmtList" :key="item.id" class="p-2 vux-1px-b tap-active" :class="!needTitle && index === 0 ? 'vux-1px-t' : ''">
                <CmtItemSub v-if="isSub" :item="item" :lordId="lordId" :lordType="lordType" :replyId="id" @actionClick="$emit('actionClick', item)" @actionLike="actionLike(item)"></CmtItemSub>
                <CmtItem v-else :item="item" :lordId="lordId" :lordType="lordType" :index="getItemIndex(index)" :needFloor="showFloor" :showComments="true" :needOpenApp="needOpenApp" @actionClick="navItem(item)" @actionLike="actionLike(item)" @actionCmt="navItem(item)"></CmtItem>
            </div>
            <div v-if="isTestCmtGoApp" class="ptb-4 vux-1px-b tap-active txt-center">
                <span class="color-green txt-center fs-13" @click="actionCmtGoApp">APP内查看更多讨论<i class="ml-1 icon-arrow-down"></i></span>
            </div>
            <div v-else-if="useHidden && !isShowAllList && items.length > 10" class="ptb-4 vux-1px-b tap-active txt-center">
                <span class="color-green txt-center fs-13" @click="actionShowAllLists">点击展开剩余{{ total - 10 }}条讨论<i class="ml-1 icon-arrow-down"></i></span>
                <!-- <span v-if="!isCourseStudy" @click="actionShowAllLists" class="color-green  txt-center fs-13">点击展开剩余{{total-10}}条讨论<i class="ml-1 icon-arrow-down"></i></span> -->
                <!-- <span v-else @click="actionShowAllLists2" class="color-green  txt-center fs-13">展开剩余{{total-10}}条回复<i class="ml-1 icon-arrow-down"></i></span> -->
            </div>
            <Loading v-show="isFetching"></Loading>
            <div v-show="!isFetching && !isComplete" class="p-2 txt-center color-green bg-white vux-1px-b tap-active" @click="fetch">查看更多回复</div>
            <None v-if="isComplete && items.length === 0 && !commentDisable" class="bg-white" :class="!needTitle ? 'vux-1px-tb' : 'vux-1px-b'" @click.native="actionNone">没有回复</None>
            <div v-if="!commentDisable && !isSub && needReply" class="p-2 vux-1px-b">
                <div class="radius bg-white plr-2 ptb-1 reply-input" @click="actionReply">请输入你的观点</div>
            </div>
        </div>
    </div>
</template>

<script>
//添加 useHidden prop属性 判断是否直接显示10个的样式，默认不影响之前展示
import CmtItem from '@/js/cpn/cmt/item/CmtItem';
import CmtItemSub from '@/js/cpn/cmt/item/CmtItemSub';
import Loading from '@nc/vue.loading';
import None from '@nc/vue.none';
import UIUtil from '@/js/lib/util/ui';
import App from '@/js/lib/util/app';
import Bll from './util/bll';

let Action = require('@/js/lib/action/action');
let CmtAction = require('@/js/lib/action/cmt');
let Conf = require('@/js/lib/conf');
let Base = require('@ncfe/nc.base');
let Db = require('@ncfe/nc.db');

export default {
    components: {CmtItem, CmtItemSub, Loading, None},
    props: [
        'id', // id
        'type', // 类别
        'isSub', // true子评论
        'subType', // list子评论列表
        'order', // 评论排序方式  0较近在前, 1较早在前, 2较赞在前, 如果needOrder为true这个优先级会降低
        'needTitle', // true显示标题
        'needFloor', // 显示楼层
        'lordId', // 楼主id,
        'lordType', // 楼主 作者
        'isTestCmtGoApp', // true: 推广app下载，https://tower.im/teams/527311/todos/124728/
        'needOrder', // 可以改变排序方式
        'needReply',
        'needOpenApp',
        'hideWhenNone', // 没有数据的时候隐藏,
        'commentDisable',
        'useHidden' //是否隐藏多余10条的恢复
        // 'isCourseStudy'
    ],
    data: fData,
    mounted: fMounted,
    methods: {
        refresh: fRefresh,
        refreshLast: fRefreshLast,
        navItem: fNavItem,
        getItemIndex: fGetItemIndex,
        fetch: fFetch,
        valOrder: fValOrder,
        actionLike: fActionLike,
        actionOrder: fActionOrder,
        actionNone: fActionNone,
        actionReply: fActionReply,
        actionCmtGoApp: fActionCmtGoApp,
        actionShowAllLists: fActionShowAllLists
        // actionShowAllLists2:fActionShowAllLists2
    },
    computed: {
        lastItem: fLastItem,
        orderIcon: fOrderIcon,
        orderStr: fOrderStr,
        showFloor: fShowFloor,
        showCmtList: fShowCmyList
    }
};

function fShowCmyList() {
    const that = this;
    const aItem = that.items;
    if (that.isTestCmtGoApp) return aItem.length <= 3 ? aItem : aItem.slice(0, 3);
    return that.useHidden ? (aItem.length <= 10 ? aItem : that.isShowAllList ? aItem : aItem.slice(0, 10)) : aItem;
}

function fActionShowAllLists() {
    const that = this;
    if (that.$ncIsLogin) return (that.isShowAllList = true);
    if (!App.isWx) return that.$ncGoLogin();
    UIUtil.confirm(
        '可能离开微信，打开第三方应用',
        () => that.$ncAppOpen(),
        () => that.$ncGoLogin()
    );
}

function fData() {
    let that = this;
    let sOrder = that.valOrder();
    return {
        error: '',
        isFetching: true,
        isComplete: false,
        items: [],
        total: 0,
        listOrder: sOrder,
        orderMenus: [],
        lefts: [],
        isShowAllList: false
    };
}

function fMounted() {
    let that = this;
    that.fetch();
}

function fRefresh() {
    let that = this;
    that.error = '';
    that.isFetching = false;
    that.isComplete = false;
    that.items = [];
    that.$nextTick(() => that.fetch());
}

function fRefreshLast() {
    let that = this;
    let sOrder = that.listOrder;
    if (that.error || sOrder !== '1') return that.refresh();
    if (that.isComplete) {
        that.isComplete = false;
        that.$nextTick(() => that.fetch());
    }
}

function fNavItem(oItem) {
    let that = this;
    if (that.needOpenApp) return that.$emit('actionOpenInApp');
    Bll.showSub({item: oItem, isDisableReply: that.commentDisable});
}

function fGetItemIndex(nIndex) {
    let that = this;
    let sOrder = that.valOrder();
    return sOrder === '0' ? that.total - nIndex - 1 : sOrder === '1' ? nIndex : 0;
}

function fFetch(fCb) {
    let that = this;
    let nPageCount = 20;

    that.isFetching = true;
    // 缺少类型和id，不做任何处理
    if (!that.id || !that.type) {
        that.error = '错误的参数';
        that.isFetching = false;
        that.isComplete = true;
        return;
    }
    CmtAction.getListV3({
        query: {
            entityId: that.id,
            entityType: that.type,
            preCommentId: (that.lastItem || {}).id,
            order: that.valOrder(),
            pageSize: nPageCount
        },
        call: oResult => {
            let oData = oResult.data;
            let aItem = oData.comments || [];
            // 录播页面需要用到 回帖数
            //添加一个$emit
            that.$emit('addTotal', oData.totalCnt);

            // 这里需要去重
            // 因为添加评论的时候，会强制重新请求最后一页，数据会有重复
            that.items = Base.uniqPush(that.items, aItem, oItem => oItem.id);
            that.total = oData.totalCnt;
            that.isFetching = false;
            that.isComplete = aItem.length < nPageCount;
            that.$emit('items', [...that.items]);
            that.$emit('totalCnt', oData.totalCnt);
        },
        error: oResult => {
            let aItem = that.items;
            that.error = aItem.length > 0 ? '' : oResult.msg;
            that.isFetching = false;
            that.isComplete = that.error;
        }
    });
}

function fValOrder(sVal) {
    let that = this;
    let sCacheKey = `CmtListOrderType_${that.type}`;
    let oMap = {'0': '0', '1': '1', '2': '2'};
    if (arguments.length === 0) {
        if (!that.listOrder) {
            sVal = Base.id(that.order) || '1';
            sVal = that.needOrder ? Base.id(Db.get(sCacheKey)) || sVal : sVal;
            that.isTestCmtGoApp && (sVal = '2');
            that.listOrder = oMap[sVal] || '1';
        }
        return that.listOrder;
    }
    if (that.needOrder) {
        sVal = oMap[sVal] || '1';
        Db.set(sCacheKey, sVal);
        that.listOrder = sVal;
    }
}

function fActionLike(oItem) {
    let that = this;
    if (that.needOpenApp) return that.$emit('actionOpenInApp');
    Action[oItem.isLiked ? 'actionUnlike' : 'actionLike']({
        body: {id: oItem.id, type: Conf.type.comment},
        call: oResult => {
            oItem.isLiked = !oItem.isLiked;
            oItem.likes = oResult.count;
        },
        error: oResult => UIUtil.alert(oResult.msg)
    });
}

function fActionOrder() {
    let that = this;
    let sVal = that.valOrder();
    UIUtil.actionsheet({
        options: [
            {text: '默认排序', value: '1', checked: sVal === '1'},
            {text: '较近在前', value: '0', checked: sVal === '0'},
            {text: '较赞在前', value: '2', checked: sVal === '2'}
        ],
        call: sVal => {
            let sLastVal = that.valOrder();
            if (sVal === sLastVal) return;
            that.valOrder(sVal);
            that.refresh();
        }
    });
}

function fActionNone() {
    let that = this;
    if (that.isSub) return that.$emit('actionNone');
    that.actionReply();
}

function fActionReply() {
    let that = this;
    if (that.needOpenApp) return that.$emit('actionOpenInApp');
    Bll.reply({
        data: {entityId: that.id, entityType: that.type},
        call: () => that.refreshLast()
    });
}

function fActionCmtGoApp() {
    const that = this;
    UIUtil.jumpAlert(() => that.$ncAppOpen({fromPut: 'h5_discuss_cmt'}));
}

function fLastItem() {
    let that = this;
    let aItem = that.items;
    let nCount = aItem.length;

    return nCount === 0 ? null : aItem[nCount - 1];
}

function fOrderIcon() {
    let that = this;
    let sVal = that.listOrder;
    return that.$image(`/images/main/order-${sVal}.png`);
}

function fOrderStr() {
    let that = this;
    let sVal = that.listOrder;
    return {'0': '较近在前', '1': '默认排序', '2': '较赞在前'}[sVal];
}

function fShowFloor() {
    let that = this;
    let sVal = that.listOrder;
    return that.needFloor && (sVal === '0' || sVal === '1');
}
</script>

<style type="scss" scoped>
.order-image {
    width: 16px;
}
.reply-input {
    border: 1px solid #d9d9d9;
}
.container-style {
    background-color: #efeff4;
    margin-top: 5px;
}
</style>
