<template>
    <div class="bg-table">
        <div class="tab-nav flex-row bg-white vux-1px-b fs-s">
            <a href="javascript:void(0);" class="tap-active flex-row" :class="place ? 'color-green' : 'color-font'" @click="actionFilter('place')">
                <span>{{ place || '地点' }}</span>
                <div class="triangle-border ml-1" :class="currentFilter === 'place' ? 'up' : 'down'"></div>
            </a>
            <a href="javascript:void(0);" class="tap-active flex-row vux-1px-l vux-1px-r" :class="categ ? 'color-green' : 'color-font'" @click="actionFilter('categ')">
                <span>{{ categ || '类别' }}</span>
                <div class="triangle-border ml-1" :class="currentFilter === 'categ' ? 'up' : 'down'"></div>
            </a>
            <a href="javascript:void(0);" class="tap-active flex-row vux-1px-l vux-1px-r" :class="graduationYear ? 'color-green' : 'color-font'" @click="actionFilter('graduationYear')">
                <span>{{ graduationYear || '毕业年份' }}</span>
                <div class="triangle-border ml-1" :class="currentFilter === 'graduationYear' ? 'up' : 'down'"></div>
            </a>
            <a href="javascript:void(0);" class="tap-active flex-row" :class="type ? 'color-green' : 'color-font'" @click="actionFilter('type')">
                <span>{{ type || '排序' }}</span>
                <div class="triangle-border ml-1" :class="currentFilter === 'type' ? 'up' : 'down'"></div>
            </a>
        </div>
        <div class="mt-2">
            <None v-if="isComplete && items.length === 0">没有数据</None>
            <div v-else>
                <a v-for="(item, index) in items" :key="index" class="flex-row-start vux-1px-b plr-2 ptb-1 color-font tap-active" target="_blank" :href="getLink(item)">
                    <span class="inc-logo flex-row" style="flex: none;"><img style="flex: none;" :src="item.img" /></span>
                    <div class="flex-column mlr-2" style="flex: auto;">
                        <span class="mb-1 strong">{{ item.name }}</span>
                        <div>
                            <TagMark v-if="item.fast" class="bg-green">反馈快</TagMark>
                            <TagMark v-if="item.hot" class="bg-red">热</TagMark>
                        </div>
                    </div>
                    <div class="flex-column inc-apply fs-s" style="flex: none; align-items: center;">
                        <span
                            ><span class="color-green">{{ item.jobCount }}</span
                            >个在招职位</span
                        >
                        <span
                            >我已投递<span class="color-green">{{ item.userDeliverCount }}</span
                            >个</span
                        >
                        <span class="mt-1 color-green" :href="getLink(item)">查看全部 &gt;</span>
                    </div>
                </a>
            </div>
            <Loading v-if="isFetching"></Loading>
        </div>
    </div>
</template>
<script>
import None from '@nc/vue.none';
import TagMark from '@nc/vue.tagmark';
import Loading from '@nc/vue.loading';
import UIUtil from '@/js/lib/util/ui';
import waterfall from '@/js/lib/mixin/waterfall';
var Ajax = require('@/js/lib/action/ajax');
var Base = require('@ncfe/nc.base');
var oInfo = Base.clone(window.pageInfo);

export default {
    components: {None, TagMark, Loading},
    mixins: [waterfall],
    data: fData,
    created: fCreated,
    methods: {
        fetch: fFetch,
        getLink: fGetLink,
        getOptionList: fGetOptionList,
        actionFilter: fActionFilter
    }
};

function fData() {
    return {
        api: {
            url: {
                getList: oInfo.projectsUrl
            }
        },

        currentFilter: undefined,

        condition: {
            activityId: oInfo.activityId,
            categ: undefined,
            place: undefined,
            graduationYear: undefined,
            type: undefined,
            pageSize: 10,
            page: 1
        },
        place: '',
        categ: '',
        graduationYear: '',
        type: '',

        items: [],
        error: ''
    };
}

function fCreated() {
    var that = this;
    Ajax.add(that.api, that.api.url);
}

function fFetch(fCb) {
    var that = this;
    var sId = (that.reqId = Base.key());
    var oCond = that.condition;
    that.api.getList({
        query: oCond,
        call(oResult) {
            if (sId === that.reqId) {
                var nPage = oCond.page;
                var oData = oResult.data;
                that.items = Base.uniqPush(oCond.page <= 1 ? [] : that.items, oData.datas, oItem => oItem.id);
                oCond.page++;
                fCb && fCb(oData.totalPage <= nPage);
            }
        },
        error(oResult) {
            that.error = oResult.msg;
            window.setTimeout(() => sId === that.reqId && fCb && fCb(), 1000);
        }
    });
}

function fGetLink(oItem) {
    var that = this;
    return `/careers/${oItem.companyUrl}/${oItem.id}?ncsr=${that.ncsr}&hback=1`;
}

function fActionFilter(sType) {
    var that = this;
    var oCond = that.condition;
    var sVal = oCond[sType];
    var aItem = that.getOptionList(sType, sVal);
    that.currentFilter = sType;
    UIUtil.actionsheet({
        options: aItem,
        call(sVal, oItem) {
            oCond[sType] = sVal || undefined;
            oCond.page = 1;
            that[sType] = sVal ? oItem.text : '';
            that.items = [];
            that.$nextTick(() => {
                that.waterfallRefresh();
            });
            that.currentFilter = undefined;
        },
        cancel() {
            that.currentFilter = undefined;
        }
    });
}

function fGetOptionList(sType, sTypeVal) {
    var that = this;
    const oAll = {text: '不限', value: 0, checked: !sTypeVal};
    switch (sType) {
        case 'place':
            return [oAll, ...Base.map(oInfo.cities, sName => ({text: sName, value: sName, checked: sTypeVal === sName}))];
        case 'categ':
            return [oAll, ...Base.map(oInfo.jobTypes, sName => ({text: sName, value: sName, checked: sTypeVal === sName}))];
        case 'graduationYear':
            return [oAll, ...Base.map(oInfo.allGarduations, sName => ({text: sName, value: sName, checked: sTypeVal === sName}))];
        case 'type':
            return [oAll, {text: '最快处理', value: '0', checked: sTypeVal === '0'}, {text: '最热投递', value: '1', checked: sTypeVal === '1'}];
    }
}
</script>

<style lang="scss">
html,
body {
    background: $colorTable;
}
</style>
<style lang="scss" scoped>
.triangle-border {
    width: 0;
    height: 0;
    border-style: solid;
    &.up {
        border-width: 0 5px 5px;
        border-color: transparent transparent #666;
    }
    &.down {
        border-width: 5px 5px 0;
        border-color: #666 transparent transparent;
    }
}

.tab-nav {
    position: sticky !important;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    height: 40px;
    line-height: 38px;
    a {
        flex: auto;
        text-align: center;
    }
}

.inc-logo {
    width: 80px;
    height: 80px;
    overflow: hidden;
    img {
        width: 100%;
    }
}
</style>
