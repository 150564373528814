var Ajax = require('./ajax');
module.exports = {
    url: {
        createItem: '/comment/create',
        getList: '/comment/list',
        getListV3: '/comment/list-v3',
        // getListV2: '//comment/list-v3',
        // getListV2:'/nccommon/comment/list-by-page-v2',
        getWonderfulList: '/comment/wonderful/list',
        getListAccepted: '/comment/accepted/list',
        getItem: '/comment/get-comment-info',
        getGtData: '/captcha/geetest/#{type}',
        del: '/comment/delete',
        editItem: '/comment/editAnswer',
        getRecommend: '/comment/recommendComment'
    }
};
Ajax.add(module.exports, module.exports.url);
