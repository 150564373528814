var $ = require('@ncfe/nc.jquery');
var Uri = require('@ncfe/nc.uri');
var Base = require('@ncfe/nc.base');
var Str = require('@ncfe/nc.str');
var App = require('@/js/lib/util/app');

const ossPrefix = 'https://static.nowcoder.com/fe/file/images/h5';

export default {
    install(Vue, options) {
        fInitTranslate(Vue, options);
        fInitImage(Vue, options);
        fInitUser(Vue, options);
        fInitData(Vue, options);
        fInitNav(Vue, options);
        fInitApp(Vue, options);
    }
};

function fInitTranslate(Vue, options) {
    Vue.prototype.$ncLang = function (str) {
        if (arguments.length <= 1) return str || '';
        return Str.execTpl.apply(Str, arguments);
    };
}

function fInitImage(Vue, options) {
    Vue.prototype.$image = window.$image =
        window.$image ||
        function (sPath) {
            sPath = sPath || '';
            return window.ncDeployWebpackJSRoot + (sPath.indexOf('/') === 0 ? sPath : `/${sPath}`);
        };
    Vue.prototype.$ncimage = window.$ncimage =
        window.$ncimage ||
        function (sPath) {
            sPath = sPath || '';
            return window.ncDeployImageRoot + (sPath.indexOf('/') === 0 ? sPath : `/${sPath}`);
        };
    Vue.prototype.$npmPath = sPath => {
        return Vue.prototype.$ncimage(sPath);
    };
}

function fInitUser(Vue, options) {
    var oUser = window.globalInfo ? {...window.globalInfo} : {};
    var bLogin = !!oUser.ownerId;
    const query = Uri.getParams();
    Vue.prototype.$query = query;
    Vue.prototype.$ncUser = oUser;
    Vue.prototype.$ncIsLogin = bLogin;
    Vue.prototype.$ncInNowcoderApp = App.isHybridWebview || /nowcoder\/|com\.nowcoder\.app/.test(window.navigator.userAgent || '');
    Vue.prototype.$ncInWxMiniApp = window.__wxjs_environment === 'miniprogram';
    Vue.prototype.$ncHideNav = query.hideNav === '1' || Vue.prototype.$ncInNowcoderApp;
    // h5 oss静态文件前缀
    Vue.prototype.$ossPrefix = ossPrefix;
    Vue.prototype.$ncGoLogin = function (oExt) {
        if (bLogin) return;
        window.location.href = Uri.addParam({...oExt, callBack: window.location.href}, '/login');
        return true;
    };
}

function fInitData(Vue, options) {
    Vue.prototype.$ncDownloadUrl = window.ncAppDownloadUrl || '//m.nowcoder.com/app';
}

function fInitNav(Vue, options) {
    Vue.prototype.$ncNav = sUrl => {
        if (sUrl === window.location.href) {
            return window.location.reload();
        }
        var sHref = Uri.getParam('backUrl', sUrl) ? sUrl : Uri.addParam({backUrl: window.location.href}, sUrl);
        window.location.href = sHref;
    };
    Vue.prototype.$ncNavBack = sUrl => {
        sUrl = Base.isString(sUrl) ? sUrl : '';
        var sHref = sUrl || Uri.getParam('backUrl');
        sHref && (window.location.href = sHref);
        !sHref && window.history.back();
    };
}

function fInitApp(Vue, options) {
    Vue.prototype.$ncAppNeedOpen = App.needOpenApp();
    Vue.prototype.$ncAppHref = sHref => App.getHref(sHref);
    Vue.prototype.$ncAppOpen = oParam => App.openInApp(oParam);
    // 根据产品需求，未登录用户才需要进行打开app操作
    Vue.prototype.$ncAppSwitchHref = sHref => (App.needOpenApp() ? App.getHref(sHref) : !sHref || !$.trim(sHref) ? 'javascript:void(0);' : sHref);
    Vue.prototype.$ncAppSwitchOpen = fCb => {
        App.needOpenApp() ? App.openInApp() : fCb && fCb();
    };
}
