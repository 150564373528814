<template>
    <popup ref="popup" :value="show" height="95%" style="height: 95%; width: 100%;" @on-hide="$emit('close')" @on-show="onShow">
        <search ref="country" v-model="query" :auto-fixed="false" auto-scroll-to-top placeholder="搜索国家/地区" @on-result-click="selectItem" @on-change="onQueryChange" @on-cancel="cancel" @on-clear="cleanSearchState"></search>
        <group gutter="0">
            <cell v-for="item in items" :key="item.id" :value="item.code" @click.native="selectItem(item)">
                <div slot="title">{{ item.name }}</div>
            </cell>
        </group>
        <Loading v-if="!isComplete()"></Loading>
        <None v-if="items.length === 0 && isComplete()">{{ query ? '没有数据' : '搜索国家/地区' }}</None>
    </popup>
</template>

<script>
import {Cell, Group, Popup, Search} from '@ncfe/nc.vux';
import CommonAction from '@/js/lib/action/common';
import Loading from '@nc/vue.loading';
import None from '@nc/vue.none';
var Base = require('@ncfe/nc.base');
var Str = require('@ncfe/nc.str');

export default {
    props: ['show', 'default'],
    components: {Cell, Group, Popup, Search, Loading, None},
    data: fData,
    mounted: fMounted,
    methods: {
        cleanSearchState: fCleanSearchState,
        cancel: fCancel,
        onQueryChange: fOnQueryChange,
        onShow: fOnShow,
        fetchCountries: fFetchCountries,
        selectItem: fSelectItem,
        isComplete: fIsComplete
    }
};

function fData() {
    return {
        cache: null,
        query: '',
        items: [],
        isLoading: false
    };
}

function fMounted() {
    let that = this;
    that.$refs.country.isFocus = true;
}

function fCleanSearchState() {
    var that = this;
    that.isLoading = false;
    that.query = '';
    that.items = [];
}

function fCancel() {
    var that = this;
    that.$emit('cancel');
    that.$emit('close');
}

function fOnQueryChange() {
    var that = this;
    var aCache = that.cache;
    var sQuery = that.query;

    if (sQuery) {
        that.isLoading = true;
        var rSearch = Str.searchRegExp(sQuery);
        that.items = Base.filter(aCache, item => rSearch.test(item.code) || rSearch.test(item.name));
    } else {
        that.items = aCache;
    }
    that.isLoading = false;
}

function fOnShow() {
    var that = this;
    that.query = that.default || '';
    that.fetchCountries();

    // 不能自动聚焦，不然移动端会出问题
    // that.$nextTick(() => {
    //     that.$refs.country.setFocus();
    // });
}

function fFetchCountries() {
    var that = this;
    var aCache = that.cache;
    that.items = [];
    that.isLoading = true;
    if (aCache) {
        that.isLoading = false;
        that.items = aCache;
        return;
    }
    CommonAction.getCountries({
        type: 'GET',
        call(oResult) {
            var aData = oResult.data;
            that.cache = aData;
            that.onQueryChange();
        },
        error(oResult) {
            that.isLoading = false;
            that.items = [];
        }
    });
}

function fSelectItem(oItem) {
    var that = this;
    that.$emit('select', {code: oItem.code, name: oItem.name});
    that.$emit('close');
    that.cleanSearchState();
}

function fIsComplete() {
    var that = this;
    return !that.isLoading;
}
</script>

<style lang="scss">
.vux-popup-dialog {
    z-index: 1000;
}
.vux-cell-bd.vux-cell-primary {
    overflow: hidden;
    label.vux-label div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
.weui-icon-clear,
.weui-icon-clear:before {
    visibility: hidden;
}
</style>
