// 薪资 最大/最小 薪资月数 (薪资不限时设置此值)
export const Salary = {
    SalaryMin: 0,
    SalaryMax: 9999999,
    SalaryMonth: 12
};

// gio 工作年限
export const WorkYears = {
    999: '不限',
    0: '在校生',
    1: '1年以内',
    2: '1-3年',
    3: '3-5年',
    4: '5-10年',
    5: '10年以上'
};

// gio 学历要求
export const AcademicRequire = {
    0: '不限',
    4000: '大专及以上',
    5000: '本科及以上',
    6000: '硕士及以上',
    7000: '博士及以上'
};

//招聘类型
export const RecruitTypeName = {
    1: '校招',
    2: '实习',
    3: '社招'
};

// 反馈时长
export const Feedback = {
    1: '1个工作日内',
    3: '3个工作日内',
    7: '7个工作日内',
    10: '10个工作日内'
};

export const Attachment = {
    0: '在线简历',
    1: '附件简历'
};

// 课程类型
export const CourseType = {
    vod: '录播课程',
    live: '直播课程'
};

export const AuthTypes = {
    0: '正式员工',
    1: '准入职员工',
    2: '实习员工'
};

export const DetailPageChannel = {
    0: '牛聘',
    1: 'moka',
    2: '北森',
    3: 'hr',
    4: '第三方对接'
};

export const PageSourcePathnameMap = {
    '/recommend/campus': '校招求职页',
    '/recommend/fulltime': '社招求职页',
    '/school/jobs': '校招广场页',
    '/m/intern/center': '实习广场页',
    '/m/fulltime/center': '社招广场页',
    '/m/company/home/': '公司职位聚合页', // /w/company/home/:id
    '/school/schedule/': '公司校招日程页', // /school/schedule/:id
    '/m/company-project': '公司专场职位聚合页', // Done
    '/activity/v2/special-activity/company/list/': '专场落地页', // Done // /activity/v2/special-activity/company/list/7
    '/activity/v2/special-activity/company/index/': '专场海报页',
    '/m/special-activity/entrance': '专场聚合页',
    '/m/fulltime/detail': '职位详情页', // 社招
    '/m/intern/detail': '职位详情页', // 实习
    '/m/school/detail': '职位详情页', // 校招
    '/m/msg': '机会沟通页',
    '/community/': '公司主页', // /community/:id
    '/m/deft-job-set/': '小专场落地页', // /w/deft-job-set/:url
    '/school/schedule': '校招日程页', //
    '/discuss': '讨论区帖子' // /w/deft-job-set/:url
};

// pageSource 字典搜索 PageSourceIdMap
export const PageSourceIdMap = {
    5001: '校招求职页',
    5002: '社招求职页',
    5003: '校招广场页',
    5004: '实习广场页',
    5005: '社招广场页',
    5006: '公司职位聚合页',
    5007: '公司校招日程页',
    5008: '公司专场职位聚合页',
    5009: '专场落地页',
    5010: '专场聚合页',
    5011: '职位详情页',
    5012: '公司主页',
    5013: '小专场落地页',
    5014: '校招日程页',
    7001: '机会沟通页'
};

export const PageSourceOriginMap = {
    'd.nowcoder.com': '主站',
    'md.nowcoder.com': '主站',

    'pre.nowcoder.com': '主站',
    'prem.nowcoder.com': '主站',

    'www.nowcoder.com': '主站',
    'm.nowcoder.com': '主站',

    'dnowpick.nowcoder.com': '牛聘',
    'mdnowpick.nowcoder.com': '牛聘',

    'prenowpick.nowcoder.com': '牛聘',
    'premnowpick.nowcoder.com': '牛聘',

    'nowpick.nowcoder.com': '牛聘',
    'mnowpick.nowcoder.com': '牛聘'
};

/* 
    取channel中包含某个一级长链
    比如channel = tz_****的都属于贴子的渠道，其中tz就是一级渠道
*/
export const ChannelLevelOneMap = {
    xzccs: '小专场渠道',
    tz: '帖子',
    gzh: '牛客公众号',
    gg: '站内广告位',
    sq: '社群',
    xttz: '系统通知',
    lb: '类别',
    wb: '微博',
    zh: '知乎',
    mrht: '每日话题',
    zsdt: '助手动态',
    qt: '其他',
    wbqd: '外部渠道'
};
