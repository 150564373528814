<template>
    <div>
        <popup v-show="!isShowAddSchool" ref="popup" :value="show" height="95%" class="popup-box" :hide-on-blur="false" @on-hide="$emit('close')" @on-show="onShow">
            <div class="school-title">
                搜索学校
                <img :src="$ncimage('/images/mobile/activity/2022career/cancelSelect.png')" alt="" @click="cancel" />
            </div>
            <div style="position: relative">
                <input ref="school" v-model="word" class="major-search" placeholder="搜索学校" @input="searchSchool" />
                <img :src="$ncimage('/images/mobile/activity/2022career/majorSearch.png')" class="search-img" alt="" />
            </div>
            <div v-if="items.length > 0" class="job-search">
                <div v-for="item of items" class="job-search-item" @click="actionSelectItem(item)" v-html="changeColor(item)"></div>
            </div>
            <Loading v-if="!isComplete()"></Loading>
            <div v-if="items.length === 0 && isComplete()" class="none-data ptb-5 plr-2 select-result">
                <div class="flex-column color-gray" style="align-items: center">
                    <img :src="$ncimage('/images/mobile/activity/2022career/searchSchool.png')" class="no-search-img" alt="" />
                    <div v-if="word">
                        请输入学校全名或者关键词，如仍未查找到你的学校
                        <a href="javascript:void(0);" class="link-green" @click="actionCreateOne">点此帮助牛客完善学校信息</a>
                    </div>
                    <div v-else>输入学校名搜索学校</div>
                </div>
            </div>
        </popup>
        <PopupAddSchool v-model="isShowAddSchool" :isMiddle="isMiddle" :name="word" @submit="actionRefresh"></PopupAddSchool>
    </div>
</template>

<script>
import {Popup} from '@ncfe/nc.vux';
import CommonAction from '@/js/lib/action/common';
import Loading from '@nc/vue.loading';
import PopupAddSchool from './PopupAddSchool';

var $ = require('@ncfe/nc.jquery');
var oCache = {};

export default {
    props: ['show', 'default', 'isMiddle'],
    components: {PopupAddSchool, Popup, Loading},
    data: fData,
    mounted: fMounted,
    methods: {
        cleanSearchState: fCleanSearchState,
        cancel: fCancel,
        onShow: fOnShow,
        searchSchool: fSearchSchool,
        actionSelectItem: fActionSelectItem,
        actionCreateOne: fActionCreateOne,
        actionRefresh: fActionRefresh,
        isComplete: fIsComplete,
        actionClear: fActionClear,
        changeColor: fChangeColor
    },
    watch: {
        show: {
            handler: fShow,
            immediate: true
        }
    }
};

function fData() {
    var that = this;
    return {
        isShowAddSchool: false,

        word: '',
        items: [],
        isLoading: false
    };
}

function fMounted() {
    let that = this;
}

function fCleanSearchState() {
    var that = this;
    that.isLoading = false;
    that.word = '';
    that.items = [];
}

function fCancel() {
    var that = this;
    that.$emit('cancel');
    that.$emit('close');
}

function fOnShow() {
    var that = this;
    that.word = that.default || '';
    that.searchSchool();
    // 不能自动聚焦，不然移动端会出问题
    // that.$nextTick(() => {
    //     that.$refs.school.setFocus();
    // });
}

function fSearchSchool() {
    var that = this;
    var sKey = $.trim(that.word);
    that.items = [];
    that.isLoading = true;
    if (!sKey || oCache[sKey]) {
        that.isLoading = false;
        that.items = sKey ? oCache[sKey] : [];
        return;
    }
    CommonAction.searchSchool({
        query: {query: sKey, isMiddle: !!that.isMiddle, limit: 50},
        call(oResult) {
            var aItem = oResult.data;
            oCache[sKey] = aItem;
            if ($.trim(that.word) === sKey) {
                that.isLoading = false;
                that.items = aItem;
            }
        },
        error(oResult) {
            if ($.trim(that.word) === sKey) {
                that.isLoading = false;
                that.items = [];
            }
        }
    });
}

function fChangeColor(oItem) {
    const that = this;
    return oItem.name.replace(new RegExp(`${that.word}`), `<span style="color:#00b88f">${that.word}</span>`);
}

function fActionSelectItem(oItem) {
    var that = this;
    that.$emit('select', {id: oItem.id, title: oItem.name});
    that.$emit('close');
    that.cleanSearchState();
}

function fActionCreateOne() {
    var that = this;
    that.isShowAddSchool = true;
}

function fActionRefresh() {
    var that = this;
    oCache = {};
    that.searchSchool();
}

function fIsComplete() {
    var that = this;
    return !$.trim(that.word) || !that.isLoading;
}

function fActionClear() {
    let that = this;
    that.word = '';
    that.searchSchool();
}

function fShow() {
    let that = this;
    if (!that.show) return;
    that.$refs.school.isFocus = true;
}
</script>

<style lang="scss">
.search {
    position: relative;
    .close {
        position: absolute;
        right: 50px;
        top: 10px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        /* background: green; */
        padding: 6px 8px;
        z-index: 2;
    }
}

.vux-cell-bd.vux-cell-primary {
    overflow: hidden;

    label.vux-label div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.weui-icon-clear,
.weui-icon-clear:before {
    visibility: hidden;
}

.weui-search-bar__label {
    pointer-events: none !important;
}
.weui-search-bar__cancel-btn {
    display: block;
}
</style>
<style lang="scss" scoped>
.popup-box {
    height: 95%;
    width: 100%;
    padding: 13px 12px;
    box-sizing: border-box;
    border-radius: 15px 15px 0 0;
}
.school-title {
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 50px;
    text-align: center;
    img {
        width: 24px;
        height: 24px;
        float: right;
        margin-top: 13px;
    }
}
.major-search {
    border: 0px;
    margin: 8px 0px;
    width: calc(100vw - 60px);
    height: 38px;
    background: #f9f9f9;
    border-radius: 19px;
    padding-left: 36px;
}
.search-img {
    position: absolute;
    width: 16px;
    top: calc(50% - 8px);
    left: 16px;
}
.no-search-img {
    width: 200px;
    height: 200px;
}
.job-search {
    margin-top: 14px;
    height: calc(100% - 132px);
    overflow: scroll;
    .job-search-item {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        padding-bottom: 14px;
        margin-bottom: 14px;
        border-bottom: 1px solid #fafafa;
    }
}
.select-result {
    font-size: 13px;
    div > a {
        display: block;
        text-align: center;
    }
}
</style>
