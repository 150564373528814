let $ = require('@ncfe/nc.jquery');
let Base = require('@ncfe/nc.base');
let Uri = require('@ncfe/nc.uri');
const GioApp = require('@/gio').GioApp;

let sUserAgent = $.trim(window.navigator.userAgent);
// let sHref = window.location.href;
// let bIOS = /ip(hone|od)|ipad|ios/i.test(sUserAgent);
// 对于iOS13版本之后 iPad userAgent默认和mac一样，这里做下兼容
let bIOS = /ip(hone|od)|ipad|ios/i.test(sUserAgent) || (window.navigator?.platform === 'MacIntel' && window.navigator?.maxTouchPoints > 1);
let bAndroid = /android/i.test(sUserAgent);

let bIOSWebview = /ip(hone|od)|ipad|ios/i.test(sUserAgent) && /nowcoder\//i.test(sUserAgent);
let bAndroidWebview = /android/i.test(sUserAgent) && /nowcoder\//i.test(sUserAgent);
let bIsMobile = !!sUserAgent.match(/AppleWebKit.*Mobile.*/) && !!sUserAgent.match(/AppleWebKit/);
const bIsZhiYe = /zalent/i.test(sUserAgent); //是否在知页app中打开
let bIsInNowcoderApp = /nowcoder\//i.test(sUserAgent);

//区分微信和企业微信
let bWx = /MicroMessenger/i.test(sUserAgent) && !/wxwork/i.test(sUserAgent);
let bWxWork = /MicroMessenger/i.test(sUserAgent) && /wxwork/i.test(sUserAgent);
let bWp = window.__wxjs_environment === 'miniprogram';
const bWeiBo = /WeiBo/i.test(sUserAgent);
// 7.0.5支持 iOS universal link
let bSupportUL = (function () {
    let sVersion = !bWx ? '' : (/MicroMessenger\/([\d\.]+)/i.exec(sUserAgent) || [])[1];
    let aItem = sVersion.split('.');
    let n0 = +aItem[0] || 0;
    let n2 = +aItem[2] || 0;
    return n0 > 7 || (n0 === 7 && n2 >= 5);
})();
const isIOSUniversalLinksSupported = (function () {
    const userAgent = window.navigator.userAgent;
    const match = userAgent.match(/OS (\d+)_/i);

    if (match && match[1]) {
        const iosVersion = parseInt(match[1], 10);
        return iosVersion >= 9;
    }

    return false;
})();

module.exports = {
    needOpenApp: fNeedOpenApp,
    getHref: fGetHref,
    openInApp: fOpenInApp,
    openInZhiYeApp: fOpenInZhiYeApp,

    fixHref: fFixHref,
    isIOS: bIOS,
    isAndroid: bAndroid,

    isIOSWebview: bIOSWebview,
    isAndroidWebview: bAndroidWebview,
    isInNowcoderApp: bIsInNowcoderApp,
    isHybridWebview: bIOSWebview || bAndroidWebview,
    isMobile: bIsMobile,
    isZhiYe: bIsZhiYe,
    isWx: bWx,
    isWxWork: bWxWork,
    isWp: bWp,
    isWeiBo: bWeiBo,
    userAgent: sUserAgent,
    getPcOrigin: fGetPcOrigin
};

function fNeedOpenApp() {
    return !(window.globalInfo || {}).ownerId && window.__wxjs_environment !== 'miniprogram';
}

function fGetHref(sHref) {
    let that = this;
    sHref = $.trim(sHref);
    sHref && /^javascript/.test(sHref) && (sHref = '');
    let sTarget = that.fixHref(sHref || window.location.href);

    // if (!bWx && bIOS) {
    //     return `nowcoder://open?url=${window.encodeURIComponent(sTarget)}`;
    // }
    // return sHref || 'javascript:void(0);';

    return bWx ? 'javascript:void(0);' : bIOS ? `nowcoder://open?url=${window.encodeURIComponent(sTarget)}` : `nk://nowcoder/open?url=${window.encodeURIComponent(sTarget)}`;
}

function fOpenInApp(oParam) {
    oParam = oParam || {};
    const {generateAlink} = require('./link');
    let that = this;
    let bNoMask = !!oParam.noMask;
    let bNoJump = !!oParam.noJump;
    let sHref = generateAlink({
        href: $.trim(oParam.href || window.location.href),
        params: oParam.params || {}
    });
    let sHrefHash = sHref.split('#')[1] || '';
    let isDeleteJumpUrl = oParam.isDeleteJumpUrl;

    _fAlert(1);
    if (/openInNCApp/g.test(sHrefHash)) {
        sHrefHash = sHrefHash.replace(/openInNCApp/g, '');
        sHref = sHref.split('#')[0] + (sHrefHash ? '#' + sHrefHash : '');
    }
    _fAlert(2);
    sHref = oParam.noFixHref ? sHref : that.fixHref(sHref);
    const sFrom = Uri.getParam('from', sHref);
    const sFromPut = oParam.fromPut;
    const sGioValue = oParam.gioValue;
    const sHrefEncode = oParam.noFixHref ? sHref : window.encodeURIComponent(sHref);
    const isUseCurrentEnv = oParam.isUseCurrentEnv;
    const downloadLink = isUseCurrentEnv ? `https://${window.location.host}/app` : 'https://www.nowcoder.com/app';
    const sHrefTarget = `${downloadLink}?${sFrom ? `from=${sFrom}&` : ''}${sFromPut ? `fromPut=${sFromPut}&` : ''}${sGioValue ? `gioValue=${sGioValue}&` : ''}${isDeleteJumpUrl ? '' : `url=${sHrefEncode}&`}${oParam.btnText ? 'btnText=' + oParam.btnText : ''}`;
    if ((bWxWork || bWx || bWeiBo) && !bNoMask) {
        _fAlert(3);
        if (bIOS) {
            let sHash = $.trim(window.location.hash);
            let sInfo = 'openInNCApp';
            sHash = $.trim(sHash.replace(/openInNCApp/g, ''));
            if (bSupportUL) return (window.location.href = sHrefTarget);
            window.setTimeout(() => {
                window.location.hash = sHash && sHash !== '#' ? `${sHash}&${sInfo}` : sInfo;
                _fShowTips(() => (window.location.hash = sHash));
            }, 0);
        } else {
            _fAlert(4);
            let sTempHref = Uri.addParam({openInNCApp: 1}, sHref);
            _fAlert(5);
            _fReplaceState(sTempHref);
            _fAlert(6);
            _fShowTips(() => {
                let sTempHref = Uri.removeParam('openInNCApp', sHref);
                _fReplaceState(sTempHref);
            });
        }
        return;
    }

    // 判断是否是app下载页
    let bSame = /m(d\.)?nowcoder\.com\/app\/?$/.test(window.location.href.split('#')[0].split('?')[0]);
    // 如果当前页面是app下载页，则不跳转
    if (!bSame && !bNoJump) {
        _fAlert(8);
        let oTimer = window.setTimeout(() => (window.location.href = sHrefTarget), bIOS ? 10 : 1500);
        _fPageHide(() => window.clearTimeout(oTimer));
    }
    (sGioValue || sFromPut) && GioApp.jump({jumpEnter_var: sGioValue === 'mobileyh1' ? '移动端讨论区优化迭代1' : sFromPut});
    // 安卓不管怎样都尝试调起一次，因为iOS可以通过“进入下载页”和“nowocder://”两种方式打开APP，所以需要加一层判断，避免跳转两次
    !bIOS && _fOpenInAndroid();
    bIOS && !!bNoJump && _fOpenInIOS();

    function _fAlert(sContent) {
        // window.alert(sContent);
    }

    function _fReplaceState(sHref) {
        try {
            history.replaceState && history.replaceState('', '', sHref);
        } catch (e) {
            console.log(e);
        }
    }

    function _fShowTips(fCb) {
        let that = this;
        let oMaskEl = $(['<div style="display:block;position:fixed;top:0;width:100%;height: 100%;background-color: rgba(0,0,0,0.8);z-index:9999;">', '<div style="width:100%;background-color:rgba(0,0,0,0.0);margin:0 auto;">', '<img src="//uploadfiles.nowcoder.com/images/20190211/56_1549892148348_190DD17BA51C5B29E0816EA014765F81" style="margin-top:0;background-color:rgba(0,0,0,0.0);max-width:100%;" />', '</div>', '</div>'].join(''));
        $(document.body).append(oMaskEl);
        oMaskEl.on('click', () => {
            oMaskEl.remove();
            fCb && fCb();
        });
    }

    function _fOpenInIOS() {
        let appUrl = '';
        let hasNavigated = false;
        const currentUrl = new URL(window.location.href);

        // 监听页面可见性变化
        addVisibilityChangeListener(() => {
            hasNavigated = true;
        });

        // 开始超时检测
        const timeHandler = () => {
            // 如果没有跳转，执行回调
            startTimeoutDetection(500, () => !hasNavigated && oParam.callback && oParam.callback());
        };

        // 检查是否已经尝试过打开应用
        if (currentUrl.searchParams.get('attemptedOpenApp')) {
            console.log('已尝试打开应用，避免循环跳转');
            timeHandler();
            return;
        }

        // 先判断是否支持通链服务
        if (isIOSUniversalLinksSupported) {
            // 添加标记参数以避免循环跳转
            appUrl = `${sHrefTarget}attemptedOpenApp=true`;
        } else {
            // scheme 形式
            appUrl = `nowcoder://open${isDeleteJumpUrl ? '' : `?url=${sHrefEncode}`}`;
        }

        // 尝试打开应用
        _fAlert(appUrl);
        window.location.href = appUrl;
        timeHandler();
    }

    function _fOpenInAndroid() {
        let sAndroidLink = `nk://nowcoder/open${isDeleteJumpUrl ? '' : `?url=${sHrefEncode}`}`;
        let hasNavigated = false;
        // 加500ms延迟，处理firefox浏览器 直接跳转弹出系统弹窗阻塞图片加载
        setTimeout(() => {
            window.location.href = sAndroidLink;
            // 如果没有跳转，执行回调
            startTimeoutDetection(500, () => !hasNavigated && oParam.callback && oParam.callback());
        }, 500);

        // 监听页面可见性变化
        addVisibilityChangeListener(() => {
            hasNavigated = true;
        });
    }

    function _fPageHide(fCb) {
        Base.forEach(['visibilitychange', 'webkitvisibilitychange'], sName => {
            $(document).on(sName, () => {
                let bHidden = document.hidden || document.webkitHidden;
                bHidden && fCb && fCb();
            });
        });
        Base.forEach(['blur', 'pagehide'], sName => {
            $(window).on(sName, () => fCb && fCb());
        });
    }

    function startTimeoutDetection(timeout, callback) {
        let start = null;

        function step(timestamp) {
            if (!start) start = timestamp;
            let progress = timestamp - start;
            if (progress < timeout) {
                window.requestAnimationFrame(step);
            } else {
                callback();
            }
        }

        window.requestAnimationFrame(step);
    }

    function addVisibilityChangeListener(onHiddenCallback) {
        document.addEventListener('visibilitychange', function () {
            if (document.visibilityState === 'hidden') {
                onHiddenCallback();
            }
        });
    }
}

function fOpenInZhiYeApp(oParam) {
    oParam = oParam || {};
    const {generateAlink} = require('./link');
    let that = this;
    let bNoMask = !!oParam.noMask;
    let bNoJump = !!oParam.noJump;
    // let sHref = $.trim(oParam.href || window.location.href);
    let sHref = generateAlink({
        href: $.trim(oParam.href || window.location.href),
        params: oParam.params || {}
    });
    let sHrefHash = sHref.split('#')[1] || '';
    let isDeleteJumpUrl = oParam.isDeleteJumpUrl;

    _fAlert(1);

    // 去掉hash中的openInNCApp，避免自动打开APP
    if (/openInNCApp/g.test(sHrefHash)) {
        sHrefHash = sHrefHash.replace(/openInNCApp/g, '');
        sHref = sHref.split('#')[0] + (sHrefHash ? '#' + sHrefHash : '');
    }
    _fAlert(2);
    sHref = oParam.noFixHref ? sHref : that.fixHref(sHref);
    const sFrom = Uri.getParam('from', sHref);
    const sFromPut = oParam.fromPut;
    const sGioValue = oParam.gioValue;
    const sHrefEncode = oParam.noFixHref ? sHref : window.encodeURIComponent(sHref);
    const isUseCurrentEnv = oParam.isUseCurrentEnv;
    const downloadLink = isUseCurrentEnv ? `https://${window.location.host}/zhiyeapp` : 'https://www.nowcoder.com/zhiyeapp';
    const sHrefTarget = `${downloadLink}?${sFrom ? `from=${sFrom}&` : ''}${sFromPut ? `fromPut=${sFromPut}&` : ''}${sGioValue ? `gioValue=${sGioValue}&` : ''}${isDeleteJumpUrl ? '' : `url=${sHrefEncode}&`}${oParam.btnText ? 'btnText=' + oParam.btnText : ''}`;
    if ((bWxWork || bWx || bWeiBo) && !bNoMask) {
        _fAlert(3);
        if (bIOS) {
            let sHash = $.trim(window.location.hash);
            let sInfo = 'openInNCApp';
            sHash = $.trim(sHash.replace(/openInNCApp/g, ''));
            if (bSupportUL) return (window.location.href = sHrefTarget);
            window.setTimeout(() => {
                window.location.hash = sHash && sHash !== '#' ? `${sHash}&${sInfo}` : sInfo;
                _fShowTips(() => (window.location.hash = sHash));
            }, 0);
        } else {
            _fAlert(4);
            let sTempHref = Uri.addParam({openInNCApp: 1}, sHref);
            _fAlert(5);
            _fReplaceState(sTempHref);
            _fAlert(6);
            _fShowTips(() => {
                let sTempHref = Uri.removeParam('openInNCApp', sHref);
                _fReplaceState(sTempHref);
            });
        }
        return;
    }

    let bSame = /m(d\.)?nowcoder\.com\/zhiyeapp\/?$/.test(window.location.href.split('#')[0].split('?')[0]);
    if (!bSame && !bNoJump) {
        let oTimer = window.setTimeout(() => (window.location.href = sHrefTarget), bIOS ? 10 : 1500);
        _fPageHide(() => window.clearTimeout(oTimer));
    }
    (sGioValue || sFromPut) && GioApp.jump({jumpEnter_var: sGioValue === 'mobileyh1' ? '移动端讨论区优化迭代1' : sFromPut});
    !bIOS && _fOpenInAndroid();
    bIOS && bNoJump && _fOpenInIOS();

    function _fAlert(sContent) {
        // window.alert(sContent);
    }

    function _fReplaceState(sHref) {
        try {
            history.replaceState && history.replaceState('', '', sHref);
        } catch (e) {
            console.log(e);
        }
    }

    function _fShowTips(fCb) {
        let that = this;
        let oMaskEl = $(['<div style="display:block;position:fixed;top:0;width:100%;height: 100%;background-color: rgba(0,0,0,0.8);z-index:9999;">', '<div style="width:100%;background-color:rgba(0,0,0,0.0);margin:0 auto;">', '<img src="//uploadfiles.nowcoder.com/images/20190211/56_1549892148348_190DD17BA51C5B29E0816EA014765F81" style="margin-top:0;background-color:rgba(0,0,0,0.0);max-width:100%;" />', '</div>', '</div>'].join(''));
        $(document.body).append(oMaskEl);
        oMaskEl.on('click', () => {
            oMaskEl.remove();
            fCb && fCb();
        });
    }

    function _fOpenInIOS() {
        window.location.href = `nowcoder://open${isDeleteJumpUrl ? '' : `?url=${sHrefEncode}`}`;
    }

    function _fOpenInAndroid() {
        let sAndroidLink = `nk://nowcoder/open${isDeleteJumpUrl ? '' : `?url=${sHrefEncode}`}`;
        // if (bAndroid) {
        // Android自动调起好像有问题，不知道怎么解决
        window.setTimeout(() => {
            window.location.href = sAndroidLink;
        }, 500);
    }

    function _fPageHide(fCb) {
        Base.forEach(['visibilitychange', 'webkitvisibilitychange'], sName => {
            $(document).on(sName, () => {
                let bHidden = document.hidden || document.webkitHidden;
                bHidden && fCb && fCb();
            });
        });
        Base.forEach(['blur', 'pagehide'], sName => {
            $(window).on(sName, () => fCb && fCb());
        });
    }
}
function fFixHref(sHref) {
    let oA = document.createElement('a');
    sHref = sHref || window.location.href;
    oA.setAttribute('href', sHref);
    return oA.href || sHref;
}

function fGetPcOrigin() {
    let that = this;
    let md = /https?:\/\/md\d?\.nowcoder\.com/gi;
    let prem = /https?:\/\/prem\.nowcoder\.com/gi;
    let m = /https?:\/\/m\.nowcoder\.com/gi;
    let localOrigin = window.location.origin;
    let origin = '';

    if (md.test(localOrigin)) {
        origin = `https://d.nowcoder.com`;
    } else if (prem.test(localOrigin)) {
        origin = `https://pre.nowcoder.com`;
    } else if (m.test(localOrigin)) {
        origin = `https://www.nowcoder.com`;
    }

    return origin || `https://wwww.nowcoder.com`;
}
