var $ = require('@ncfe/nc.jquery');
var Base = require('@ncfe/nc.base');
var Str = require('@ncfe/nc.str');
var DataMenu = require('./dataMenu');
var DataItem = require('./dataItem');

module.exports = {
    getData: fGetData,
    getItems: fGetItems,
    getRule: fGetRule,
    ensureOrder: fEnsureOrder,
    expand: fExpand
};

function fGetData(oPageInfo) {
    var that = this;
    var oMenu = new DataMenu({
        menus: oPageInfo.menus,
        alias: oPageInfo.menuAlias
    });
    // 转义数据
    var oResume = oPageInfo.resumeInfo || {};
    var oRecommand = oPageInfo.recommandInfo || {};
    Base.isString(oResume.ext) && (oResume.ext = Base.json(Str.decodeHTML(oResume.ext), []));
    Base.isString(oResume.deliverInfo) && (oResume.deliverInfo = Base.json(Str.decodeHTML(oResume.deliverInfo), []));
    // 整理简历信息
    var oRule = that.getRule(oMenu, oPageInfo.rule);
    var oItemData = that.getItems(oMenu, oResume, oRule);
    var aBasicResumeCompleteIds = Str.split(oPageInfo.basicResumeCompleteIds, ',');
    // 全部数据
    return {
        id: oPageInfo.id,
        menu: oMenu,
        // 简历名称
        name: oResume.resumeName || '未命名简历',
        // 模板id
        template: oResume.templateId || '0',
        // 投递信息
        deliver: oResume.deliverInfo,
        // 简历创建规则
        rule: oRule,
        // 自增id
        seed: oItemData.seed,
        // 简历信息
        items: oItemData.items,
        // 简历信息索引
        itemMap: oItemData.itemMap,
        // 推荐
        isOpenRecommand: !!oRecommand.isOpenRecommand,
        isDefault: !!oRecommand.isDefaultDelivery,
        completeness: +oRecommand.completeness || 0,
        // 用于导入数据的基础简历id
        basicResumeUuid: $.trim(oPageInfo.basicResumeUuid),
        basicResumeCompleteIds: aBasicResumeCompleteIds,
        basicResumeCompleteNames: Base.map(aBasicResumeCompleteIds, sId => oMenu.getName(sId)),
        // 显示
        hideAction: !!oRecommand.hideAction,
        hideTpl: !!oRecommand.hideTpl,
        hideCreate: !!oRecommand.hideCreate,
        hideResumeList: !!oRecommand.hideResumeList,
        // 已经创建的简历
        resumes: oPageInfo.resumes || [],
        // step1Info的提示信息，一段文案
        step1Info: oPageInfo.step1Info
    };
}

function fGetItems(oMenu, oResume, oRule) {
    var that = this;
    var nSeed = 10000;
    // 系统标签
    var aMenu = oMenu.items;
    var oMenuMap = Base.toMap(aMenu, function (oItem) {
        return {key: Base.id(oItem.id)};
    });
    // 数据
    var aItem = [];
    var oItemMap = {};
    oRule = oRule || {};
    // 用户之前填写的信息
    Base.forEach(oResume.ext, Base.bind(_fDealDataItem, null, true));
    // 补全剩余的系统信息
    Base.forEach(aMenu, Base.bind(_fDealDataItem, null, false));
    // 排序
    Base.forEach(oRule.order, function (sId, nIndex) {
        var oItem = oItemMap[sId];
        if (oItem) {
            that.ensureOrder(aItem, sId, nIndex);
            oItem.canMove = false;
        }
    });
    // 全部数据
    return {
        items: aItem,
        itemMap: oItemMap,
        seed: nSeed
    };

    function _fDealDataItem(bExt, oItem) {
        var sId = Base.id(oItem.id);
        if (+sId === 5) {
            return;
        }
        bExt && (nSeed = Math.max(nSeed, (+sId || 0) + 1));
        if (!oItemMap[sId]) {
            var bSystem = !!oMenuMap[sId];
            var bNeed = Base.contains(oRule.need, sId);
            var bHide = Base.contains(oRule.hide, sId);
            // 非隐藏项 && (非系统标签 || 之前已经选中 || 必填项）
            var bSelected = bExt ? !bHide && (!bSystem || !oItem.noSelected || bNeed) : !bHide;
            var oItem = new DataItem({
                id: sId,
                menu: oMenu,
                name: oItem.name,
                desc: _fGetDesc(aMenu, sId),
                value: _fGetVal(oItem, sId, bExt),
                basic: _fGetBasic(sId, oRule),
                isSystem: bSystem,
                isNeed: bNeed,
                isHide: bHide,
                isSelected: bSelected
            });
            aItem.push(oItem);
            oItemMap[sId] = oItem;
        }
    }

    function _fGetDesc(aMenu, sId) {
        var oTarget = Base.find(aMenu, oItem => {
            return oItem.id === sId;
        });
        return oTarget && oTarget.desc ? oTarget.desc : '';
    }

    function _fGetVal(oItem, sId, bExt) {
        if (sId === DataMenu.ID_BASE) {
            var sIdCardType = $.trim(oResume.idCardType);
            return {
                name: $.trim(oResume.userName),
                gender: $.trim(oResume.gender),
                liveAddress: $.trim(oResume.liveAddress && oResume.liveAddress !== '未填写所在地区' ? oResume.liveAddress : ''),
                eduLevel: $.trim(oResume.eduLevel),
                graduated: $.trim(oResume.graduated) || '0',
                workTime: $.trim(oResume.workTime),
                birthTime: $.trim(oResume.birthTime),
                phone: $.trim(oResume.phone),
                phoneCountryCode: $.trim(oResume.phoneCountryCode),
                email: $.trim(oResume.email),
                idCard: $.trim(oResume.idCard),
                idCardType: sIdCardType,
                idCardTitle: !sIdCardType ? '' : {0: '其他证件', 1: '身份证', 2: '护照', 3: '港澳通行证', 4: '台胞证', 5: '军官证'}[sIdCardType] || '',
                birthPlace: $.trim(oResume.birthPlace),
                party: $.trim(oResume.party)
            };
        }
        if (sId === DataMenu.ID_AVATAR) {
            return oResume.headUrl;
        }
        return bExt ? oItem.value : null;
    }

    function _fGetBasic(sId, oRule) {
        var that = this;
        if (sId !== DataMenu.ID_BASE) {
            return;
        }
        var aItem = oRule.basicInfo || [];
        var aShow = aItem.length > 0 ? aItem : '1,2,3,4,6,7,5,8,9,10,12'.split(',');
        var aNeed = aItem.length > 0 ? aItem : '1,2,3,4,6,7,8,12'.split(',');
        // 默认加上几项必填的
        aShow = aShow.concat('1,2,3,4,6,7,8,12'.split(','));
        aNeed = aNeed.concat('1,2,3,4,6,7,8,12'.split(','));

        var oBasicShow = Base.toMap(aShow, function (sItem) {
            return {key: sItem, value: true};
        });
        var oBasicNeed = Base.toMap(aNeed, function (sItem) {
            return {key: sItem, value: true};
        });
        // 必填的必须显示
        Base.forEach(aNeed, function (sItem) {
            oBasicShow[sItem] = true;
        });
        return {
            show: oBasicShow,
            need: oBasicNeed
        };
    }
}

function fGetRule(oMenu, oRule) {
    var that = this;
    oRule = Base.extend({}, oRule);
    // 拖动
    oRule.sort = oRule.sort !== false;
    _fInitNeed();
    _fInitHide();
    _fInitOrder();
    _fInitTips();

    // 提示
    return oRule;

    function _fInitNeed() {
        var aNeed = Base.isArray(oRule.need)
            ? Base.map(oRule.need, function (sId) {
                  return Str.str(sId);
              })
            : [DataMenu.ID_BASE, DataMenu.ID_EDU, [DataMenu.ID_SCHOOLEXP, DataMenu.ID_WORKEXP, DataMenu.ID_PROJEXP]];
        // 基本信息是必填项
        aNeed.push(DataMenu.ID_BASE);
        aNeed = Base.uniq(aNeed);

        // 去掉不符合规则的必填项
        // 只能使用纯id指定
        // 如果上一层已经指定必填，则这个数组是没必要存在的
        Base.forReverse(aNeed, function (sId, nIndex) {
            if (Base.isArray(sId)) {
                Base.forReverse(sId, function (sTmpId, nTmp) {
                    if (!oMenu.map[sTmpId]) {
                        sId.splice(nTmp, 1);
                    } else if (Base.contains(aNeed, sTmpId)) {
                        aNeed.splice(nIndex, 1);
                        return Base.BREAK;
                    }
                });
                sId.length === 0 && aNeed.splice(nIndex, 1);
                sId.length === 1 && (aNeed[nIndex] = sId[0]);
            } else if (!oMenu.map[sId]) {
                aNeed.splice(nIndex, 1);
            }
        });
        oRule.need = aNeed;
    }

    function _fInitHide() {
        var that = this;
        var aHide = Base.isArray(oRule.hide)
            ? Base.map(oRule.hide, function (sId) {
                  return Str.str(sId);
              })
            : [];
        // 必填项不能隐藏
        if (aHide.length > 0) {
            var oNeedMap = {};
            Base.forEach(oRule.need, function (sId) {
                if (Base.isArray(sId)) {
                    Base.forEach(sId, function (sId) {
                        oNeedMap[sId] = true;
                    });
                } else {
                    oNeedMap[sId] = true;
                }
            });
            aHide = Base.uniq(aHide);
            Base.forReverse(aHide, function (sId, nIndex) {
                (!oMenu.map[sId] || oNeedMap[sId]) && aHide.splice(nIndex, 1);
            });
        }
        oRule.hide = aHide;
    }

    function _fInitOrder() {
        var aOrder =
            Base.isArray(oRule.order) && oRule.order.length > 0
                ? Base.map(oRule.order, function (sId) {
                      return Str.str(sId);
                  })
                : [DataMenu.ID_BASE, DataMenu.ID_AVATAR];
        aOrder = Base.uniq(aOrder);
        aOrder = Base.filter(aOrder, function (sId) {
            return oMenu.map[sId];
        });
        oRule.order = aOrder;
    }

    function _fInitTips() {
        if (!oRule.tips && oRule.need) {
            var sName = Base.map(oRule.need, function (sId) {
                if (Base.isArray(sId)) {
                    return (
                        Base.map(sId, function (sId) {
                            return oMenu.getName(sId);
                        }).join('/') + '至少一项'
                    );
                } else {
                    return oMenu.getName(sId);
                }
            }).join('、');
            oRule.tips = '<span>一份完整的简历需至少包含' + sName + '。</span>';
        }
    }
}

function fEnsureOrder(aItem, sId, nIndex) {
    var that = this;
    var oTemp;
    sId = Base.id(sId);
    Base.forReverse(aItem, function (oItem, nIndex) {
        if (sId === oItem.id) {
            oTemp = aItem[nIndex];
            aItem.splice(nIndex, 1);
        }
    });
    oTemp && aItem.splice(nIndex, 0, oTemp);
}

function fExpand(aItem) {
    var that = this;
    var aResult = [];
    Base.forEach(aItem, function (sItem) {
        if (Base.isArray(sItem)) {
            aResult = aResult.concat(that.expand(sItem));
        } else {
            aResult.push(Base.id(sItem));
        }
    });
    return aResult;
}
