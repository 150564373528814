<template>
    <div style="background-color: white">
        <None v-if="!type || !id">错误的参数</None>
        <Loading v-else-if="isReported === null">加载中...</Loading>
        <div v-else-if="isReported === true" class="flex-column-center p-2">
            <x-icon type="ios-checkmark" class="report-success mb-2"></x-icon>
            <div>举报我们已经收到，根据<a href="/discuss/91947">《牛客讨论区版规》</a>，您的举报将由管理员审核判定后进行处理。</div>
        </div>
        <template v-else>
            <div class="report-box">
                <div class="report-title">举报类型</div>
                <div v-for="item in items" class="report-type" @click="clickItem(item.value)">
                    <div class="report-value">{{ item.value }}</div>
                    <img class="report-image" :style="violationType === item.value ? '' : 'display: none'" src="https://static.nowcoder.com/fe/file/images/wechat/offer/asset/images/select3-icon.png" />
                    <img class="report-image" :style="violationType === item.value ? 'display: none' : ''" src="https://static.nowcoder.com/fe/file/images/wechat/offer/asset/images/select4-icon.png" />
                </div>
                <div class="report-title">举报说明</div>
                <div>
                    <x-textarea v-model="violationInfo" :max="150" placeholder="请输入不超过150字的内容"></x-textarea>
                </div>
                <div>
                    <a class="report-btn" @click="actionSubmit">提交</a>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import None from '@nc/vue.none';
import Loading from '@nc/vue.loading';
import {XTextarea, XIcon} from '@ncfe/nc.vux';
import Action from '@/js/lib/action/action';
import {getReportContentList} from '@/js/lib/action/gateway';
var Config = require('@/js/lib/conf');
var Base = require('@ncfe/nc.base');
let $sdk = require('@ncfe/sdk.hybird');
export default {
    props: ['id', 'type', 'fromId', 'uuid'],
    components: {None, Loading, XTextarea, XIcon},
    data() {
        return {
            isReported: null,
            violationType: '',
            violationInfo: '',
            items: []
        };
    },
    mounted: fMounted,
    methods: {
        actionSubmit: fActionSubmit,
        clickItem: fClickItem
    },
    computed: {
        typeDesc: fTypeDesc
    }
};

function fMounted() {
    var that = this;
    var sType = that.type;
    var sId = that.id;
    sType &&
        sId &&
        Action.isReported({
            body: {entityType: sType, entityId: sId},
            call: oResult => (that.isReported = !!oResult.data),
            error: () => (that.isReported = false)
        });
    let type = 0;
    if ([250, 74, 75, 2, 8].includes(Number(that.type))) {
        // 帖子、动态、评论
        type = 1;
    } else if ([9].includes(Number(that.type))) {
        // 个人主页
        type = 2;
    } else if ([1].includes(Number(that.type))) {
        // 私信
        type = 3;
    } else if ([130].includes(Number(that.type))) {
        // 直播
        type = 4;
    }
    getReportContentList({
        query: {
            type: type
        }
    }).then(({data, code}) => {
        if (!code) {
            this.items = Base.map(data.result, sItem => ({value: sItem, text: sItem}));
        } else {
            var that = this;
            var sType = that.type;
            var bUser = sType === Base.id(Config.type.user);
            var aItem = bUser ? ['垃圾营销', '传播色情/低俗内容', '传播违法信息', '抄袭或涉嫌侵权', '其他'] : ['广告及垃圾信息', '抄袭或涉嫌侵权', '不友善/色情/低俗内容', '政治敏感', '其他'];
            return Base.map(aItem, sItem => ({value: sItem, text: sItem}));
        }
    });
}

function fClickItem(item) {
    this.violationType = item;
}

function fActionSubmit() {
    var that = this;
    var sType = that.violationType;
    var sInfo = that.violationInfo;
    if (!sType) return that.$vux.alert.show({title: '提示', content: '请输入举报类型'});
    if (sType === '其他' && !sInfo) return that.$vux.alert.show({title: '提示', content: '请填写举报说明'});
    if (that.isSubmiting) return;
    that.isSubmiting = true;
    Action.addReport({
        body: {
            entityType: that.type,
            entityId: that.id,
            uuid: that.uuid,
            mainCommentEntryId: that.fromId || '0',
            violationType: sType,
            violationInfo: sInfo
        },
        call: oResult => {
            that.isReported = true;
            if ($sdk) {
                $sdk.Push.push({
                    pushType: 'reply',
                    descript: '打开推送通知，不错过任何一个回复和点赞'
                });
            }
        },
        error(oResult) {
            var sCode = Base.id(oResult.code);
            var bReported = sCode === '2';
            var sContent = bReported ? `您已经举报过该${that.typeDesc}，请耐心等待管理员处理。` : oResult.msg;
            that.isReported = bReported;
            that.$vux.alert.show({title: '提示', content: sContent});
        },
        always: () => (that.isSubmiting = false)
    });
}

function fTypeDesc() {
    var that = this;
    // var sType = that.type;
    return (
        {
            [Config.type.user]: '用户',
            [Config.type.post]: '帖子',
            [Config.type.comment]: '评论'
        }[that.type] || '对象'
    );
}
</script>
<style scoped lang="scss">
.report-success {
    fill: #08b292;
    width: 80px;
    height: 80px;
}
.report-box {
    background-color: rgba(255, 255, 255, 1);
    // min-height: calc(100vh - 140px);
    color: #333333;
    .report-title {
        padding-top: 0.24rem;
        padding-left: 0.16rem;
        font-size: 0.18rem;
        font-weight: 500;
        line-height: 0.22rem;
    }
    .report-type {
        display: flex;
        justify-content: space-between;
        padding-top: 0.14rem;
        padding-bottom: 0.14rem;
        padding-left: 0.16rem;
        padding-right: 0.16rem;
        .report-value {
            font-size: 0.16rem;
            line-height: 0.2rem;
        }
        .report-image {
            width: 0.2rem;
            height: 0.2rem;
        }
    }
    .report-btn {
        background: linear-gradient(90deg, #00dcc2, #00dc93);
        border: none;
        border-image: linear-gradient(90deg, #00dcc2, #00dc93) 1 1;
        border-radius: 19px;
        box-sizing: border-box;
        color: #fff;
        display: block;
        height: 38px;
        line-height: 38px;
        margin: 24px 12px;
        overflow: hidden;
        text-align: center;
    }
}
</style>
