import NcCopy from './directives/NcCopy';

const directives = {
    NcCopy
};

export default {
    install(Vue) {
        Object.keys(directives).forEach(key => {
            Vue.directive(key, directives[key]);
        });
    }
};
