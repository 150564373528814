const type = {
    // 类别值，常用在ajax请求中的type或者entryType
    user: 1, // 用户
    comment: 2, // 评论
    problem: 3, // 问题
    topic: 4, // 讨论
    feed: 5, // 新鲜事
    paper: 6, // paper
    recommendAnswer: 7, // 推荐答案
    post: 8, // 论坛帖子
    message: 9, // 私信
    live: 11, // 直播
    note: 14, // 笔记
    clockRecord: 15, // 打卡
    ctsTest: 19, // cts考试
    schoolCalendar: 28, // 校招日历
    stack: 41, // 技术栈
    learningMaterial: 42, // 学习资料
    gitProject: 45, // git项目类型
    stackCompany: 50, // 技术栈公司
    book: 52, // 书籍
    bookOneWord: 55, // 书籍一句话点评
    offerData: 56, //offer数据
    blog: 73, // 博客
    campus: 1201, // 校招
    community: 1301, // 社区的回答

    feedDiscuss: 74,

    // 直播课程
    liveCourse: 1501,
    liveChapter: 1502,
    liveSection: 1503,
    liveLive: 1504,

    // 录播课程
    vodCourse: 1401, // 视频课程
    vodChapter: 1402, // 视频章节
    vodSection: 1403, // 视频播放页面

    // ai面试题目
    aiQuestion: 2301,
    // 关注或取消话题 type
    followSubject: 88
};
module.exports = {
    type: type,
    itemType: {
        vod: 1,
        live: 2,
        zhuankan: 19
    },
    captcha: {
        sendVerifyCode: 'send_verify_code',
        refAnswer: 'ref_answer_query',
        login: 'login',

        offerChoose: 'show_offer_choose',
        offerNormal: 'show_offer_normal'
    },

    aodianyun: {
        account: 'nowcoder_weekly',
        password: 'sub_034da48a83095baef7f0196896b38629',
        topic: 'weekly',
        liveAccount: 'live_course',
        livePassword: 'sub_71b59a68e7a86347ce77eece262de375'
    },
    appid: 'wx8b494535aa1d9df7',

    hybridVcid: {
        [type.post]: 'discussitem',
        [type.feedDiscuss]: 'feeddetail',
        [type.comment]: 'commentitem'
    }
};
