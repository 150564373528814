var Ajax = require('./ajax');
module.exports = {
    url: {
        // type: get query: {voteId}
        voteInfo: '/vote/info',
        // type post voteId: 投票id options Array 选项id
        vote: '/vote/do-vote',

        // type post title: 标题， entityId: 实体id，  entityType: 实体类型， expiredAt: 过期时间类型 1表示1周  2表示1个月 3表示3个月 4表示6个月 , type：类型 1表示单选 2表示多选  options：  String Array 选项
        addVote: '/vote/create',

        //  type post   voteId: 投票id,  title: 标题，expiredAt: 过期时间类型,  type：类型, hasChangeOptions: boolean 是否修改了选项，   options ：String Array 选项
        editVote: '/vote/edit'
    }
};
Ajax.add(module.exports, module.exports.url);
