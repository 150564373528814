<template>
    <div>
        <div v-for="(eduItem, index) in item" :key="index" :class="[isPreview ? '' : 'edu-item', 'pt-4']" @click="$emit('clickPushEdit', index + 1)">
            <template v-if="isPreview">
                <div class="flex-row-start">
                    <span class="flex-auto fw-500 fs-14">{{ eduItem.school }}</span>
                    <span v-if="eduItem.start && eduItem.end" class="color-a5a5a5">{{ eduItem.start }}-{{ eduItem.end }}</span>
                </div>
                <div class="color-a5a5a5 fs-14 mt-2 pb-4 border-1-bottom">
                    <span v-if="eduItem.edu">{{ eduItem.edu }}</span>
                    <span v-if="eduItem.major" class="ml-2">{{ eduItem.major }}</span>
                </div>
            </template>
            <template v-else>
                <div class="flex-row-start">
                    <span class="flex-auto fw-500 fs-14">{{ eduItem.school }}</span>
                    <IconMobileRight v-if="isEdit" class="color-d8d8d8 fs-14"></IconMobileRight>
                </div>
                <div class="color-a5a5a5 fs-14 mt-2">
                    <span v-if="eduItem.start && eduItem.end">{{ eduItem.start }}-{{ eduItem.end }}</span>
                    <span v-if="eduItem.edu" class="ml-2">{{ eduItem.edu }}</span>
                    <span v-if="eduItem.major" class="ml-2">{{ eduItem.major }}</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Prop} from 'vue-property-decorator';
import Vue from '@ncfe/nc.vue';
import {IconMobileRight} from '@ncfe/nc.icon.h5';

@Component({components: {IconMobileRight}})
export default class EduItem extends Vue {
    @Prop(Array) item?: any | [];
    @Prop(Boolean) isEdit?: boolean;
    @Prop(Boolean) isPreview?: boolean;
}
</script>

<style lang="scss" scoped>
.edu-item {
    padding-bottom: 16px;
    & + .edu-item {
        border-top: 1px solid #f6f6f6;
    }
    &:last-child {
        padding-bottom: 0;
    }
}
</style>
