var UI = require('@/js/lib/util/ui').default;
var Base = require('@ncfe/nc.base');
var ResumeAction = require('@/js/lib/action/resume');
var Util = require('../lib/util');
// var DataItem = require('../lib/dataItem');
var DataMenu = require('../lib/dataMenu');
let Uri = require('@ncfe/nc.uri');

module.exports = {
    RESUME_NAME: 'resumeName',
    init: fInit,

    checkIdCard: fCheckIdCard,
    updateTitle: fUpdateTitle,
    updateHead: fUpdateHead,
    updateBase: fUpdateBase,
    updateExt: fUpdateExt
};

// Cpn.init();

function fInit() {
    var that = this;
    var oPageInfo = window.resumePageInfo || window.pageInfo;
    var sJobId = Base.id(oPageInfo.jobId) || '0';
    that.bRcb = Uri.getParam('rcb') ? true : false;
    // 一些拓展
    var oRule = (oPageInfo.rule = oPageInfo.rule || {});
    oRule.need && (oRule.need = Base.without(oRule.need || [], DataMenu.ID_AVATAR));
    oRule.hide = (oRule.hide || []).concat([DataMenu.ID_AVATAR]);
    // 整理数据
    that.completeness = oPageInfo.resumeInfo.completeness;
    that.data = Util.getData(oPageInfo);
    that.jobId = sJobId && sJobId !== '0' ? sJobId : '';
    that.needsNps = false;
    that.graduated = that.data.itemMap[DataMenu.ID_BASE].value.graduated;
}

function fCheckIdCard(oConf) {
    var that = this;
    // // 需要检查身份证就把下面的注释打开
    // oConf.call && oConf.call();
    ResumeAction.checkIdCard({
        query: {
            idCard: oConf.idCard,
            idCardType: oConf.idCardType,
            jobId: that.jobId
        },
        call(oResult) {
            var sId = that.data.id;
            var sResumeId = (oResult.data || {}).resumeId;
            if (sResumeId && sId !== sResumeId) return UI.alert('该证件号码已经被占用');
            oConf.call && oConf.call();
        },
        error(oResult) {
            UI.alert(oResult.msg);
        }
    });
}

function fUpdateTitle(fCb) {
    var that = this;
    var oData = that.data;
    ResumeAction.updateResumeName({
        body: {id: oData.id, resumeName: oData.name},
        call: fCb,
        error: oResult => {
            UI.alert('简历名称保存失败，' + oResult.msg);
        }
    });
}

function fUpdateHead(fCb) {
    var that = this;
    var oData = that.data;
    ResumeAction.updateResumeHead({
        body: {id: oData.id, head: oData.itemMap[DataMenu.ID_AVATAR].value},
        call: fCb,
        error: function (oResult) {
            UI.alert(oResult.msg);
        }
    });
}

function fUpdateBase(oParam = {}) {
    var that = this;
    var oData = that.data;
    var oBaseData = oData.itemMap[DataMenu.ID_BASE].value;
    that.graduated = oBaseData.graduated;
    ResumeAction.updateResumeBasic({
        body: {
            id: oData.id,
            userName: oBaseData.name,
            graduated: oBaseData.graduated,
            workTime: oBaseData.workTime,
            birthTime: oBaseData.birthTime,
            gender: oBaseData.gender,
            phone: oBaseData.phone,
            email: oBaseData.email,
            liveAddress: oBaseData.liveAddress,
            eduLevel: oBaseData.eduLevel,
            idCardType: oBaseData.idCardType,
            idCard: oBaseData.idCard,
            birthPlace: oBaseData.birthPlace,
            party: oBaseData.party,
            confirm: oParam.confirm || '0',
            showNps: !that.bRcb
        },
        call: function (oResult) {
            that.needsNps = oResult.needsNps;
            oParam.call && oParam.call();
        },
        error: function (oResult) {
            // var sMsg = oResult.msg;
            // if (sMsg && oResult.code === 6666) {
            //     UI.confirm({
            //         content: `你填写的最高学历与教育经历中的学历不一致，是否更新为教育经历中的最高学历-${sMsg}？`,
            //         cancelText: '继续保存',
            //         confirmText: '确定',
            //         onConfirm () {
            //             oBaseData.eduLevel = sMsg;
            //             that.updateBase(oParam);
            //         },
            //         onCancel () {
            //             oParam.confirm = '1';
            //             that.updateBase(oParam);
            //         }
            //     });
            // } else {
            //     UI.alert(sMsg);
            // }

            var sMsg = oResult.msg;
            var nCode = +oResult.code;
            if (sMsg && nCode === 6666) {
                UI.confirm({
                    content: `你填写的最高学历与教育经历中的学历不一致，是否更新为教育经历中的最高学历-${sMsg}？`,
                    cancelText: '继续保存',
                    confirmText: '确定',
                    onConfirm() {
                        oBaseData.eduLevel = sMsg;
                        that.updateBase(oParam);
                    },
                    onCancel() {
                        oParam.confirm = '1';
                        that.updateBase(oParam);
                    }
                });
            } else if (sMsg && nCode === 6667) {
                UI.alert({
                    content: `你填写的最高学历与教育经历中的学历不一致，系统自动将你的最高学历更新为-${sMsg}`,
                    confirmText: '我知道了',
                    onHide() {
                        oBaseData.eduLevel = sMsg;
                        that.updateBase(oParam);
                    }
                });
            } else {
                UI.alert(sMsg);
            }
        }
    });
}

function fUpdateExt(oParam = {}) {
    var that = this;
    var oData = that.data;
    var aExt = Base.map(oData.items, function (oItem) {
        const oRes = oItem.getReqVal();
        const value = oRes.value;
        if (value instanceof Array && value.length > 0) {
            if (value[0].canDisabled === 'true') {
                oRes.status = 1;
            }
        }
        return oRes;
    });
    var sExt = JSON.stringify(aExt);
    ResumeAction.updateResumeExt({
        body: {id: oData.id, ext: sExt, confirm: oParam.confirm || '0', showNps: !that.bRcb},
        call: function (oResult) {
            that.needsNps = oResult.needsNps;
            that.completeness = oResult.data.completeness;
            oParam.call && oParam.call();
        },
        error: function (oResult) {
            var sMsg = oResult.msg;
            var nCode = oResult.code;
            if (sMsg && nCode === 6666) {
                UI.confirm({
                    content: `你的基本资料中最高学历为-${sMsg}，你还没有完善${sMsg}教育经历。`,
                    cancelText: '我知道了',
                    confirmText: '继续完善',
                    onConfirm() {
                        that.updateExt({confirm: '1'});
                    },
                    onCancel() {
                        oParam.confirm = '1';
                        that.updateExt(oParam);
                    }
                });
            } else if (sMsg && nCode === 6667) {
                UI.alert({
                    content: `你填写的教育经历中的学历与基本资料中的最高学历不一致，系统自动将你的最高学历更新为-${sMsg}`,
                    confirmText: '我知道了',
                    onHide() {
                        oParam.call();
                    }
                });
            } else {
                UI.alert(sMsg);
            }
        }
    });
}
