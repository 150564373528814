<template>
    <div>
        <popup v-show="!isShowAddSchool" ref="popup" :value="show" height="95%" style="height: 95%; width: 100%;" :hide-on-blur="false" @on-hide="$emit('close')" @on-show="onShow">
            <search ref="school" v-model="word" class="search" :auto-fixed="false" auto-scroll-to-top placeholder="输入学校名" @on-result-click="actionSelectItem" @on-change="searchSchool" @on-cancel="cancel" @on-clear="cleanSearchState">
                <template v-if="word" v-slot:right>
                    <i class="close fs-12 color-gray bg-white icon-close" @click="actionClear"></i>
                </template>
            </search>
            <group gutter="0">
                <cell v-for="item in items" :key="item.id || item.name" @click.native="actionSelectItem(item)">
                    <div slot="title">{{ item.name }}</div>
                    <div class="ml-2">{{ item.type === 3 || item.type === 5 ? '审核中' : '' }}</div>
                </cell>
            </group>
            <Loading v-if="!isComplete()"></Loading>
            <None v-if="items.length === 0 && isComplete()" class="plr-2">
                <div v-if="word">请输入学校全名或者关键词进行搜索，如仍未查找到你的学校，<a href="javascript:void(0);" class="link-green" @click="actionCreateOne">点此帮助牛客完善学校信息</a></div>
                <div v-else>输入学校名搜索学校</div>
            </None>
        </popup>
        <PopupAddSchool v-model="isShowAddSchool" :isMiddle="isMiddle" :name="word" @submit="actionRefresh"></PopupAddSchool>
    </div>
</template>

<script>
import {Cell, Group, Popup, Search} from '@ncfe/nc.vux';
import CommonAction from '@/js/lib/action/common';
import Loading from '@nc/vue.loading';
import None from '@nc/vue.none';
import PopupAddSchool from './PopupAddSchool';

var $ = require('@ncfe/nc.jquery');
var oCache = {};

export default {
    props: ['show', 'default', 'isMiddle'],
    components: {PopupAddSchool, Cell, Group, Popup, Search, Loading, None},
    data: fData,
    mounted: fMounted,
    methods: {
        cleanSearchState: fCleanSearchState,
        cancel: fCancel,
        onShow: fOnShow,
        searchSchool: fSearchSchool,
        actionSelectItem: fActionSelectItem,
        actionCreateOne: fActionCreateOne,
        actionRefresh: fActionRefresh,
        isComplete: fIsComplete,
        actionClear: fActionClear
    },
    watch: {
        show: {
            handler: fShow,
            immediate: true
        }
    }
};

function fData() {
    var that = this;
    return {
        isShowAddSchool: false,

        word: '',
        items: [],
        isLoading: false
    };
}

function fMounted() {
    let that = this;
}

function fCleanSearchState() {
    var that = this;
    that.isLoading = false;
    that.word = '';
    that.items = [];
}

function fCancel() {
    var that = this;
    that.$emit('cancel');
    that.$emit('close');
}

function fOnShow() {
    var that = this;
    that.word = that.default || '';
    that.searchSchool();
    // 不能自动聚焦，不然移动端会出问题
    // that.$nextTick(() => {
    //     that.$refs.school.setFocus();
    // });
}

function fSearchSchool() {
    var that = this;
    var sKey = $.trim(that.word);
    that.items = [];
    that.isLoading = true;
    if (!sKey || oCache[sKey]) {
        that.isLoading = false;
        that.items = sKey ? oCache[sKey] : [];
        return;
    }
    CommonAction.searchSchool({
        query: {query: sKey, isMiddle: !!that.isMiddle, limit: 50},
        call(oResult) {
            var aItem = oResult.data;
            oCache[sKey] = aItem;
            if ($.trim(that.word) === sKey) {
                that.isLoading = false;
                that.items = aItem;
            }
        },
        error(oResult) {
            if ($.trim(that.word) === sKey) {
                that.isLoading = false;
                that.items = [];
            }
        }
    });
}

function fActionSelectItem(oItem) {
    var that = this;
    that.$emit('select', {id: oItem.id, title: oItem.name});
    that.$emit('close');
    that.cleanSearchState();
}

function fActionCreateOne() {
    var that = this;
    that.isShowAddSchool = true;
}

function fActionRefresh() {
    var that = this;
    oCache = {};
    that.searchSchool();
}

function fIsComplete() {
    var that = this;
    return !$.trim(that.word) || !that.isLoading;
}

function fActionClear() {
    let that = this;
    that.word = '';
    that.searchSchool();
}

function fShow() {
    let that = this;
    if (!that.show) return;
    that.$refs.school.isFocus = true;
}
</script>

<style lang="scss">
.search {
    position: relative;
    .close {
        position: absolute;
        right: 50px;
        top: 10px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        /* background: green; */
        padding: 6px 8px;
        z-index: 2;
    }
}

.vux-cell-bd.vux-cell-primary {
    overflow: hidden;

    label.vux-label div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.weui-icon-clear,
.weui-icon-clear:before {
    visibility: hidden;
}

.weui-search-bar__label {
    pointer-events: none !important;
}
.weui-search-bar__cancel-btn {
    display: block;
}
</style>
