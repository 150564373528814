<!--
 * Love and Peace
 * Components: 预约成功弹窗
 * TODO: 
        - 处理时间
        - 处理 二维码图片
 * NOTE:
-->
<template>
    <Popup v-show="!isShow" ref="popup" class="custom-bg" :value="show" height="271px" :hide-on-blur="true" style="width: 100%" @on-hide="$emit('close')">
        <div class="content-wrap txt-center" style="width: 100%">
            <!-- header -->
            <div class="title txt-center">
                <span class="fs-16">预约成功</span>
                <i class="close fs-12 color-gray bg-white icon-close" @click="actionClose"></i>
            </div>
            <!-- 主体部分 -->
            <div v-if="qrCode" style="width: 100%">
                <div class="time mt-4 fs-14">{{ startTimeText }}</div>
                <div class="qr mt-4" style="height: 107px; width: 107px" @click="imagePreview">
                    <img :src="qrCode" />
                </div>
                <div class="bottom-word fs-12 mt-2">
                    <p>长按扫码进入专场沟通群</p>
                    <p>免费获得求职礼包+1v1简历指导</p>
                </div>
            </div>
            <div v-else class="no-qr">
                <div class="time fs-14">{{ startTimeText }}</div>
                <div class="konw-button" @click="actionClose">知道了</div>
            </div>
        </div>
    </Popup>
</template>

<script>
import UIUtil from '@/js/lib/util/ui';
import {Popup} from '@ncfe/nc.vux';

export default {
    components: {Popup},
    props: {
        show: {type: Boolean, default: false},
        qrCode: {type: String, default: ''}, // 二维码链接
        startTimeText: {type: String, default: ''} // 开始时间文案
    },
    data: fData,
    mounted() {
        console.log(this.img);
    },
    methods: {
        actionClose,
        imagePreview
    },
    watch: {
        show: {
            handler: function () {
                let that = this;
                if (!that.show) return;
            },
            immediate: true
        }
    }
};

function fData() {
    return {
        isShow: false
    };
}

function actionClose() {
    this.$emit('close');
}

function imagePreview() {
    const that = this;
    that.qrCode && UIUtil.image({items: [{src: that.qrCode, w: 107, h: 107}], index: 0});
}
</script>

<style lang="scss" scoped>
.custom-bg {
    background-color: #ffffff !important;
    border-radius: 10px 10px 0px 0px;
}

.content-wrap {
    color: #333333;
    margin-top: 14px;

    .title {
        position: relative;
        font-weight: bold;
    }
    .close {
        position: absolute;
        right: 18px;
        padding: 6px 8px;
    }
    .qr {
        margin: 0 auto;
        img {
            height: 107px;
            width: 107px;
        }
    }
    .bottom-word {
        color: #999999;
    }
    .no-qr {
        .time {
            color: #999999;
            margin-top: 76px;
        }
        .konw-button {
            width: 347px;
            height: 44px;
            line-height: 44px;

            background: linear-gradient(146deg, #46e394 0%, #26bb9c 100%);
            border-radius: 5px;
            margin: 78px auto 0 auto;
            color: #ffffff;
        }
    }
}
</style>
