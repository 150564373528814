<template>
    <div>
        <div v-for="(award, index) in item" :key="index" @click="$emit('clickPushEdit', index + 1)">
            <template v-if="isPreview && award.name">
                <div class="flex-row-start mt-4">
                    <span class="flex-auto fw-500 fs-14" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; display: inline-block; flex: 1">{{ award.name }}</span>
                    <span v-if="award.start" class="color-a5a5a5 fs-14" style="margin-left: 10px">{{ award.start }}</span>
                </div>
            </template>
            <template v-else-if="award.name">
                <div class="flex-row-start mt-4">
                    <span class="flex-auto fw-500 fs-14" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{ award.name }}</span>
                    <IconMobileRight v-if="isEdit" class="color-d8d8d8 fs-14"></IconMobileRight>
                </div>
                <div class="color-a5a5a5 fs-14 mt-2">
                    <span v-if="award.start">{{ award.start }}</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Prop} from 'vue-property-decorator';
import Vue from '@ncfe/nc.vue';
import {IconMobileRight} from '@ncfe/nc.icon.h5';

@Component({components: {IconMobileRight}})
export default class AwardItem extends Vue {
    @Prop(Array) item?: any | [];
    @Prop(Boolean) isEdit?: boolean;
    @Prop(Boolean) isPreview?: boolean;
}
</script>
