/**
 * checkInterviewer // 面试官签到
 * {
 *      params: {
 *          sceneId: String 面试场次id
 *      }
 * 		body{
 *          name: String
 *          email: String
 *          tableNum: Number // 桌号
 *          round: Number // 面试轮次
 *          job: String
 *          confirm: Number  0 或者1 是否确认
 * 	  	}
 * }
 * checkOutInterview // 面试官离开
 * {
 *      params: {
 *          sceneId: String 面试场次id
 *      }
 * 		body{
 *          interviewerId: N
 *          email: String
 *          phone: String
 *          job: String
 *          confirm: Number  0 或者1 是否确认
 * 	  	}
 * }
 * next // 下一个面试官
 * {
 *      params: {
 *          sceneId: String 面试场次id
 *      }
 * 		body{
 *          interviewerId: Number
 *          confirm: Number  0 或者1 是否确认
 * 	  	}
 * }
 * updateAlloc // 修改该分配的状态
 * {
 *      params: {
 *          sceneId: String 面试场次id
 *      }
 * 		body{
 *          allocId: Number
 *          status: Number  1删除或者2面试完成
 * 	  	}
 * }
 *
 * unCheck // 候选人取消签到
 * {
 * 		body{
 *          checkId: Number 签到id
 *          interviewId: Number  面试id
 *          code: String 面试密码
 * 	  	}
 * }
 * ping // 面试官页面的ping请求
 * {
 * 		body{
 *          checkId: Number 签到id
 *          interviewId: Number  面试id
 *          code: String 面试密码
 * 	  	}
 * }
 * sendAllocMpMsg { // 到号提醒
 *    type: POST
 *    sceneId: String 面试场次id
 *    body:{
 *           allocId : Number 分配id
 *    }
 * }
 * updateInterviewerTime { // 更新面试官的操作时间
 *    type: POST
 *    sceneId: String 面试场次id
 *    body:{
 *           confirm : Number 1 表示在线，更新面试官的操作时间， 0 表示已经离开，标记面试官已经离开
 *    }
 * }
 * getWaitList { // 获取本场的候选人列表
 *    params: {
 *      interviewerId: Number 面试官id
 *      sceneId: String 面试场次id
 *     }
 *    query:{
 *           query: 搜索关键词，候选人名字，邮箱或者手机
 *           page : Number 1
 *           pageSize
 *    }
 * pingAlloc // 面试分配页面的ping请求
 * {
 *      params: {
 *          sceneId: String 面试场次id
 *      }
 * 	  body{
 *          allocId: Number  分配id
 * 	  	}
 * }
 * addCall // 叫号
 * {
 *      params: {
 *          sceneId: String 面试场次id
 *      }
 * 	  body{
 *          allocId: Number  分配id
 * 	  	}
 * }
 **/

var Ajax = require('./ajax');
module.exports = {
    url: {
        feedbackInterviewee: '/interview/interviewee/feedback',

        checkInterviewer: '/interview/scene/#{sceneId}/check',
        checkOutInterview: '/interview/scene/#{sceneId}/check-out',
        nextInterviewer: '/interview/scene/#{sceneId}/next',

        updateAlloc: '/interview/scene/#{sceneId}/update-alloc',
        unCheck: '/interview/scene/interviewee-uncheck',
        pingInterviewer: '/interview/scene/#{sceneId}/ping-interviewer',

        sendAllocMpMsg: '/interview/scene/#{sceneId}/send-alloc-event',
        updateInterviewerTime: '/interview/scene/#{sceneId}/update-interviewer-time',

        getWaitList: '/interview/scene/#{sceneId}/#{interviewerId}/wait-list',
        pingAlloc: '/interview/scene/#{sceneId}/ping-alloc',
        addCall: '/interview/scene/#{sceneId}/call',

        askForHelp: '/test/video/interview/v3/askForHelp',
        testDataV3: '/test/video/interview/v3/testdata',

        //   模拟面试相关
        // 拒绝邀请
        evaluationReject: '/interview/ai/evaluation/reject/#{requestId}',
        // 接受邀请
        evaluationAccept: '/interview/ai/evaluation/accept/#{requestId}',
        // 提交某道题
        saveEvaluationDonequestion: '/interview/ai/evaluation/save-evaluation/donequestion/#{doneQuestionId}',
        // 提交总评
        saveEvaluationRequest: '/interview/ai/evaluation/save-evaluation/request/#{requestId}',
        // 提交全部
        submitEvaluationRoom: '/interview/ai/evaluation/submit-evaluation/room/#{requestId}',

        // 保存答案语音的文字, POST, body: {doneQuestionId: Number, answerText: String}
        aiSaveAnswerText: '/interview/ai/answer-text'
    }
};
Ajax.add(module.exports, module.exports.url);
