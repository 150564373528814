export default {
    mounted: fAddFlex,
    destroyed: fRemoveFlex,
    beforeRouteUpdate: fAddFlex,
    beforeRouteLeave(to, from, next) {
        fRemoveFlex();
        next();
    }
};

function fAddFlex() {
    document.documentElement.classList.add('flex-page');
    document.body.classList.add('flex-page');
}

function fRemoveFlex() {
    document.documentElement.classList.remove('flex-page');
    document.body.classList.remove('flex-page');
}
