const LINK_MAP: {[key: string]: string} = {
    'discuss/comment/': 'comment/item'
};

const ALINK_START = 'https://www.nowcoder.com/approuternav?commandInfo=';

interface ALink {
    href: string;
    params: any;
}

interface ICommandInfo {
    commandKey: string;
    commandValue: {
        path: string;
        needAuth: string;
        param: any[];
    };
}

export function generateAlink(data: ALink): string {
    if (!data.href) {
        return '';
    }
    let aLinkKey = Object.keys(LINK_MAP).find(key => data.href.includes(key));
    if (!aLinkKey) return data.href;
    let alinkPath = LINK_MAP[aLinkKey];
    if (!alinkPath) return data.href;
    let params: any[] = [];
    Object.keys(data.params || {}).forEach(key => {
        params.push({
            key: key,
            type: 'string',
            value: data.params[key] + ''
        });
    });
    let commandInfoStr = '';
    const commandInfo: ICommandInfo[] = [];
    commandInfo.push({
        commandKey: 'goto',
        commandValue: {
            path: alinkPath,
            needAuth: 'false',
            param: params
        }
    });
    try {
        commandInfoStr = JSON.stringify(commandInfo);
    } catch (error) {
        commandInfoStr = '';
    }
    return commandInfoStr ? `${ALINK_START}${JSON.stringify(commandInfo)}` : data.href;
}
