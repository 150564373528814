// var Uri = require('@ncfe/nc.uri');
module.exports = {
    getWxConf: fGetWxConf
};

function fGetWxConf() {
    var sUid = (window.globalInfo || {}).ownerId;
    var sMine = sUid ? `2021sp-${sUid}` : '';
    return {
        title: '免费改简历|简历点评|简历评估',
        desc: '牛客网狂送10万张1V1简历免费点评券，由大厂HR指导，进行专业的简历质量评估，求职OR跳槽的敲门砖',
        link: `https://m.nowcoder.com/activity/resume/invite/index?ncsr=${sMine}`,
        imgUrl: '//static.nowcoder.com/images/logo_87_87.png'
    };
}
