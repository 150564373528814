<template>
    <Popup v-model="isShow" popupClass="text-input radius-bottom" position="top">
        <div class="flex-row-start plr-2 ptb-1 color-gray hide-txt">
            <span class="flex-auto hide-txt" v-html="title"></span>
            <i v-show="showClose" class="icon-close fs-s flex-none" @click="isShow = false"></i>
        </div>
        <group gutter="0">
            <x-input v-if="isInput" ref="input" v-model="value" :type="type" :placeholder="placeholder" @on-enter="submit"></x-input>
            <x-textarea v-else ref="input" v-model="value" :placeholder="placeholder" :rows="rows || 3"></x-textarea>
        </group>
        <div class="pt-2 plr-2">
            <x-button type="primary" @click.native="submit">{{ submitTxt || '确定' }}</x-button>
        </div>
    </Popup>
</template>

<script>
import Popup, {PopupMix, PopupShow} from '@nc/vue.popup';
import {Group, XTextarea, XInput, XButton} from '@ncfe/nc.vux';
import Vue from '@ncfe/nc.vue';
export default {
    show: PopupShow(Vue),

    props: ['title', 'submitTxt', 'showClose', 'placeholder', 'rows', 'isInput', 'type'],
    components: {Popup, Group, XTextarea, XInput, XButton},
    mixins: [PopupMix],
    data: fData,
    methods: {
        submit: fSubmit
    },
    watch: {
        isShow: fWatchIsShow
    }
};

function fData() {
    var that = this;
    return {
        value: ''
    };
}

function fSubmit() {
    var that = this;
    that.$emit('submit', that.value, that.title);
    that.close();
}

function fWatchIsShow(bShow) {
    var that = this;
    that.isShow = bShow;
    bShow && that.$nextTick(() => that.$refs.input.focus());
    !bShow && (that.value = '');
}
</script>
<style type="scss">
.text-input {
    padding: 0 0 10px 0;
}
</style>
