<template>
    <div>
        <popup :value="show" class="job-pop" position="right" height="100vh" :style="showSubJobPopup ? 'background:#a1a1a1' : ''" @on-hide="close">
            <p class="major-topic">
                <img class="major-return" :src="$ncimage('/images/mobile/activity/2022career/majorReturn.png')" alt="" @click="close" />
                <span>专业列表</span>
            </p>
            <div style="position: relative">
                <input v-model="inputValue" class="major-search" placeholder="搜索专业" :style="showSubJobPopup ? 'background:#9d9d9d' : ''" @input="actionInput" />
                <img :src="$ncimage('/images/mobile/activity/2022career/majorSearch.png')" class="search-img" alt="" />
            </div>
            <div v-if="searchResult.length > 0" class="job-search">
                <div v-for="item of searchResult" class="job-search-item" @click="actionSelect(item)" v-html="changeColor(item)"></div>
            </div>
            <div v-else class="group-box">
                <div class="group-topic">专业列表</div>
                <loading v-show="loading"></loading>
                <div v-for="oItem in allCareerJob" :key="oItem.schoolMajorInfo.id" class="group-cell" @click="actionParent(oItem.subSchoolMajorInfos)">
                    <span>{{ oItem.schoolMajorInfo.name }}</span>
                    <img :src="$ncimage('/images/mobile/activity/2022career/majorRight.png')" alt="" />
                </div>
            </div>
        </popup>
        <popup v-model="showSubJobPopup" class="group-pop" width="90%" height="100vh" position="right" style="position: absolute; box-shadow: rgb(0 0 0 / 7%) 0px 6px 6px 3px; overflow: visible; background: #fff">
            <flexbox :gutter="0" style="align-items: flex-start; height: 100%">
                <flexbox-item class="group-left" :span="5" style="height: 100%; overflow: scroll">
                    <div class="left-topic">职位列表</div>
                    <div v-for="(oSubItem, nIndex) in aSubCareerJob" :key="oSubItem.schoolMajorInfo.id" class="left-cell" @click="actionSub(oSubItem.subSchoolMajorInfos, nIndex)">
                        <span :style="clickNumber === nIndex ? 'color: #00b88f;' : ''">{{ oSubItem.schoolMajorInfo.name }}</span>
                        <img v-show="clickNumber === nIndex" :src="$ncimage('/images/mobile/activity/2022career/selectBackground.png')" alt="" />
                    </div>
                </flexbox-item>
                <flexbox-item class="group-right" :span="7" style="background: #f9f9f9; height: 100%; overflow: scroll">
                    <div>
                        <checklist v-model="selectedJob" class="right-list" label-position="left" required :options="aJobList" :max="1" @on-change="actionChange"></checklist>
                    </div>
                </flexbox-item>
            </flexbox>
        </popup>
    </div>
</template>

<script>
import {Popup, Flexbox, FlexboxItem, Checklist} from '@ncfe/nc.vux';
import SettingAction from '@/js/lib/action/setting';
import Loading from '@nc/vue.loading';
const Base = require('@ncfe/nc.base');

export default {
    props: ['show', 'default'],
    components: {Popup, Flexbox, FlexboxItem, Checklist, Loading},
    data: fData,
    mounted: fMounted,
    methods: {
        close: fClose,
        actionParent: fActionParent,
        actionSub: fActionSub,
        actionChange: fActionChange,
        actionInput: fActionInput,
        changeColor: fChangeColor,
        actionSelect: fActionSelect
    }
};

function fData() {
    const that = this;
    return {
        showSubJobPopup: false,
        selectedJob: [],
        aSubCareerJob: [],
        selectedKey: [],
        aJobList: [],
        aJobKey: [],
        oMapLabel: {},
        allCareerJob: [],
        clickNumber: 0,
        loading: true,
        inputValue: '',
        searchResult: []
    };
}

function fMounted() {
    const that = this;
    SettingAction.getAllSchoolMajor({
        type: 'get',
        call({data}) {
            that.allCareerJob = data.allSchoolMajors;
            that.loading = false;
            Base.forEach(that.selectedKey, (oItem, nIndex) => {
                that.oMapLabel[that.selectedJob[nIndex]] = oItem;
            });
        }
    });
}

function fClose() {
    const that = this;
    that.showSubJobPopup = false;
    Base.forEach(that.selectedJob, sItem => {
        that.aJobKey.push(that.oMapLabel[sItem]);
    });
    that.$emit('close');
}

function fActionParent(aSubJob) {
    const that = this;
    if (!that.showSubJobPopup) {
        that.aSubCareerJob = aSubJob;
        that.aJobList = [];
        Base.forEach(aSubJob[0].subSchoolMajorInfos, oItem => {
            that.aJobList.push(oItem.schoolMajorInfo.name);
            that.oMapLabel[oItem.schoolMajorInfo.name] = oItem.schoolMajorInfo.id;
        });
    }
    that.showSubJobPopup = !that.showSubJobPopup;
}

function fActionSub(aSubJob, nIndex) {
    const that = this;
    that.aJobList = [];
    that.clickNumber = nIndex;
    Base.forEach(aSubJob, oItem => {
        that.aJobList.push(oItem.schoolMajorInfo.name);
        that.oMapLabel[oItem.schoolMajorInfo.name] = oItem.schoolMajorInfo.id;
    });
}

function fActionChange() {
    const that = this;
    if (that.selectedJob.length === 1) {
        that.close();
        // that.$vux.toast.text('只能选择一个专业哦~', 'middle');
    }
}

function fActionInput() {
    const that = this;
    that.searchResult = that.allCareerJob
        .map(item1 =>
            item1.subSchoolMajorInfos.map(item2 =>
                item2.subSchoolMajorInfos.map(item3 => {
                    return {
                        name: item3.schoolMajorInfo.name,
                        id: item3.schoolMajorInfo.id
                    };
                })
            )
        )
        .flat(Infinity)
        .filter(item => {
            if (that.inputValue.trim()) {
                return item.name.includes(that.inputValue.trim());
            } else {
                return false;
            }
        });
}

function fChangeColor(oItem) {
    const that = this;
    return oItem.name.replace(new RegExp(`${that.inputValue}`), `<span style="color:#00b88f">${that.inputValue}</span>`);
}

function fActionSelect(oItem) {
    const that = this;
    if (that.selectedJob.length === 0) {
        Base.forEach(oItem, sItem => {
            that.oMapLabel[oItem.name] = oItem.id;
        });
        that.selectedJob.push(oItem.name);
        that.close();
    }
}
</script>
<style lang="scss">
.group-box,
.group-pop {
    .weui-cells:before {
        border-top: none !important;
    }
    .weui-cells {
        .weui-cell:before {
            border-top: none !important;
        }
    }
    .weui-cells:after {
        border-bottom: none;
    }
}
.group-right {
    .weui-cells_checkbox .weui-cell__hd {
        display: none;
    }
}
</style>

<style lang="scss" scope>
.major-return {
    float: left;
    width: 24px;
}
.job-pop {
    background-color: #fff;
    box-sizing: border-box;
    padding: 0 12px;
    .major-topic {
        font-weight: bolder;
        font-size: 18px;
        line-height: 24px;
        padding: 30px 0px 10px;
        text-align: center;
    }
    .major-search {
        border: 0px;
        margin: 8px 0px;
        width: calc(100vw - 60px);
        height: 38px;
        background: #f9f9f9;
        border-radius: 19px;
        padding-left: 36px;
    }
    .search-img {
        position: absolute;
        width: 16px;
        top: calc(50% - 8px);
        left: 16px;
    }
    .job-search {
        margin-top: 14px;
        height: calc(100% - 132px);
        overflow: scroll;
        .job-search-item {
            font-size: 16px;
            font-weight: 400;
            // color: #00B88F;
            line-height: 20px;
            padding-bottom: 14px;
            margin-bottom: 14px;
            border-bottom: 1px solid #fafafa;
        }
    }
    .group-box {
        height: calc(100% - 120px);
        .group-topic {
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            line-height: 22px;
            margin-bottom: 20px;
            margin-top: 14px;
        }
        .group-cell {
            margin-bottom: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                width: 16px;
                height: 16px;
            }
        }
    }
}
.group-pop {
    .group-right {
        .weui-cells {
            background-color: #f9f9f9;
        }
    }
    .left-topic {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        margin-bottom: 20px;
    }
    .group-left {
        padding: 30px 0px 0px 24px;
        box-sizing: border-box;
    }
    .left-cell {
        margin-bottom: 28px;
        position: relative;
        img {
            position: absolute;
            left: -12px;
            height: 16px;
            top: calc(50% - 8px);
        }
    }
    .right-list {
        .weui-cells_checkbox {
            padding-top: 58px;
        }
        .weui-cell {
            padding: 14px 24px;
        }
        .left-cell span,
        .weui-cell__bd p {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
    }
}
</style>
