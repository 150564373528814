<template>
    <CmtList :id="data.sourceId" :type="data.sourceType" :needReply="true" @totalCnt="actionUpdateCnt"></CmtList>
</template>
<script>
import CmtList from '@/js/cpn/cmt/CmtList';
export default {
    props: ['data', 'config'],
    components: {CmtList},
    methods: {
        actionUpdateCnt: fActionUpdateCnt
    }
};

function fActionUpdateCnt(nCount) {
    var that = this;
    that.data.commentCount = nCount;
}
</script>
