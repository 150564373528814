const Str = require('@ncfe/nc.str');
const Uri = require('@ncfe/nc.uri');
const $ = require('@ncfe/nc.jquery');
const Time = require('@ncfe/nc.time');
import {XInput} from '@ncfe/nc.vux';
import {Vue, Component} from 'vue-property-decorator';
import UI from '@/js/lib/util/ui';
import App from '@/js/lib/util/app';
import ActivityAction from '@/js/lib/action/activity';
import {GioResumeComment} from '@/gio';
import ResumeDialog from '../cpn/resumeDialog.vue';

declare const window: Window & {
    pageInfo: any;
};
@Component({
    components: {XInput, ResumeDialog}
})
export class Action extends Vue {
    countryCode: string = '+86';
    phone: string = '';
    inviteCode: string = '';
    show: boolean = false;
    hasReceiveCount: string = '';
    type: string = '';
    subInviteCode: string = '';
    isVisible: boolean = true;
    config = {
        pageName: '',
        pageType: 1
    };

    public initPageInfo(): void {
        const phone = Uri.getParam('phone');
        this.phone = phone || '';
        ActivityAction.getInvitePreSub({
            query: {
                phone
            }
        })
            .then(({data}) => {
                this.hasReceiveCount = data.hasReceiveCount;
                phone && this.actionReceive();
            })
            .catch(err => {
                this.$vux.toast.text(err.msg || '获取数据失败', 'middle');
            });
    }
    public selectCountryCode(isTikTokActivity: boolean): void {
        if (!isTikTokActivity) return;
        UI.popupCountryCode({
            call: oItem => {
                this.countryCode = oItem.code;
            },
            cancel: () => {},
            close: () => {}
        });
    }
    public actionRule() {
        this.type = 'rule';
        this.show = true;
    }
    public actionReceive(v): void {
        if (v === 2) {
            (window as any).gdt?.('track', 'RESERVATION', {});
        }
        if (!this.checkInfo()) {
            return;
        }
        if (!App.isWx) {
            this.actionSubmit();
            return;
        }
        ActivityAction.getInvitePreSub({
            query: {
                phone: this.phone
            }
        }).then(({data}) => {
            if (data.hasSubscribe) {
                this.actionSubmit();
                return;
            }
            window.location.href = `https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=wxee088dab802752c9&scene=100&template_id=cnEOwfXcThYGw-RwgXNYOAp84R6Zqiv3OYw8By969cg&redirect_url=${data.redirectUrl}?phone=${this.phone}#wechat_redirect`;
        });
    }
    private actionSubmit(): void {
        const {inviteCode} = this;
        ActivityAction.submitInviteCode({
            body: {
                phone: this.countryCode + this.phone,
                from: window.pageInfo.from,
                pageType: this.config.pageType,
                inviteCode
            }
        })
            .then(({data}) => {
                const {hasReceive, inviteCode} = data;
                this.type = hasReceive ? 'new' : 'old';
                this.subInviteCode = inviteCode;
                this.show = true;
                GioResumeComment.phoneSubmit({
                    pageName_var: this.config.pageName,
                    isShare_var: this.inviteCode ? '是' : '否'
                });
            })
            .catch(err => {
                this.$vux.toast.text(err.msg || '领取失败', 'middle');
            });
    }
    public copyResult(bClose): void {
        if (!bClose) return;
        this.type = '';
        this.show = false;
    }
    public checkInfo(): boolean {
        const {phone, countryCode, inviteCode} = this;
        if (!(phone.length >= 7 && phone.length <= 11)) {
            return false;
        }
        if (countryCode === '+86') {
            if (!Str.isPhone(phone)) {
                this.$vux.toast.text('请输入正确的手机号', 'middle');
                return false;
            }
        } else if (!Str.isTel(phone)) {
            this.$vux.toast.text('请输入正确的手机号', 'middle');
            return false;
        }

        if ((inviteCode.length !== 6 || !/^[a-zA-Z0-9]+$/g.test(inviteCode)) && inviteCode.length !== 0) {
            this.$vux.toast.text('请输入正确的邀请码', 'middle');
            return false;
        }

        return true;
    }
    get isPhone() {
        return /^1[3-9][0-9]\d{8}$/.test(this.phone);
    }
    public initSCroll(): void {
        const that = this;
        const oWin = $(window);
        const oDoc = $(document);
        const frequency = Time.frequency(100);
        let scrollHeight = oWin.height() - ($('.receive-msg').offset().top - oDoc.scrollTop());
        oDoc.on('scroll', () => {
            frequency(() => {
                scrollHeight = oWin.height() - ($('.receive-msg').offset().top - oDoc.scrollTop());
                that.isVisible = scrollHeight > 0 ? false : true;
            });
        });
    }
    public actionPart(): void {
        const scrollHeight = $(document).height() - $(window).height();
        $(document).scrollTop(scrollHeight);
    }
}
