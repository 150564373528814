var $ = require('@ncfe/nc.jquery');
var Clazz = require('@ncfe/nc.clazz');
var Base = require('@ncfe/nc.base');
var Str = require('@ncfe/nc.str');
var Module = (module.exports = Clazz.create());

Base.extend(Module, {
    ID_BASE: '1', // 基本资料
    ID_EDU: '2', // 教育经历
    ID_SCHOOLEXP: '3', // 校内经历
    ID_WORKEXP: '4', // 工作经历,
    // ID_WANT: '5', // 求职意向
    ID_PROJEXP: '6', // 项目经历
    ID_AWARD: '7', // 获奖情况
    ID_SKILL: '8', // 个人技能
    ID_ASSESS: '9', // 自我评价,
    ID_PAPER: '10', // 论文发表
    ID_ATTACH: '11', // 附件作品
    ID_AVATAR: '999', // 个人照片
    // 界面相关
    getIconCls: fStaticGetIconCls,
    // 内部方法
    _getSystem: _fStaticGetSystem
});

Base.extend(Module.prototype, {
    initialize: fInitialize,
    load: fLoad,
    loadAlias: fLoadAlias,

    // menu格式相关
    getName: fGetName,
    getKey: fGetKey,
    getDefault: fGetDefault,
    getIsMult: fGetIsMult,
    getInput: fGetInput,
    // 关联数据
    getAlias: fGetAlias,
    getAliasParentBySub: fGetAliasParentBySub,
    getAliasDataBySub: fGetAliasDataBySub
});

function fInitialize(oConf) {
    var that = this;
    that.items = [];
    that.map = {};
    that.alias = {};
    that.load([]);
    oConf.menus && that.load(oConf.menus);
    oConf.alias && that.loadAlias(oConf.alias);
}

function fLoad(aMenu) {
    var that = this;
    aMenu = aMenu || [];
    !Base.isArray(aMenu) && (aMenu = Base.json(Str.decodeHTML(aMenu), []));
    // 避免重复加载
    if ((that._loadedInit && aMenu.length > 0) || (that._loadDefault && aMenu.length === 0)) {
        return;
    }
    // 取得默认值
    var nMenuCount = aMenu.length;
    var oSystemMap = Module._getSystem();
    nMenuCount === 0 && (aMenu = Base.values(oSystemMap));
    // 已经存在的索引
    var oItemMap = that.map;
    // 保存数据
    Base.forEach(aMenu, function (oItem) {
        var sId = Base.id(oItem.id);
        if (+sId === 5) {
            return;
        }
        oItem = oSystemMap[sId] ? oSystemMap[sId] : oItem;
        oItem.id = sId;
        !oItemMap[sId] && that.items.push(oItem);
    });
    that.map = Base.toMap(that.items, function (oItem) {
        return {key: oItem.id, value: oItem};
    });
    // 设置标志位
    that._loadedInit = that._loadedInit || nMenuCount > 0;
    that._loadDefault = that._loadedInit || that._loadDefault || nMenuCount <= 0;
}

function fLoadAlias(oAlias) {
    var that = this;
    oAlias = oAlias || {};
    !Base.isObject(oAlias) && (oAlias = Base.json(Str.decodeHTML(oAlias), {}));
    //处理数据
    that.alias = {};
    Base.forEach(oAlias, function (oData, sKey) {
        var oInfo = _fDealKey(sKey);
        if (oInfo) {
            var sKey = oInfo.key;
            var sId = oInfo.id;
            that.alias[sId] = that.alias[sId] || [];
            Base.forEach(oData, function (oData, sSubKey) {
                var oInfo = _fDealKey(sSubKey);
                oInfo &&
                    that.alias[sId].push({
                        id: oInfo.id,
                        key: oInfo.key,
                        pid: sId,
                        pkey: sKey,
                        data: oData
                    });
            });
        }
    });

    function _fDealKey(sKey) {
        var aItem = (sKey || '').split('|');
        var sId = $.trim(aItem[0]);
        var sKey = $.trim(aItem[1]);
        return sId && sKey ? {id: sId, key: sKey} : null;
    }
}

function fGetName(sId) {
    var that = this;
    var oItem = that.map[sId];
    return oItem.name || '';
}

function fGetKey(sId, bNeed) {
    var that = this;
    var oInput = that.getInput(sId);
    if (!oInput || !oInput.data) {
        return [];
    }
    // bNeed表示只获取必填项
    var aResult = [];
    Base.forEach(oInput.data, function (oItem) {
        (!bNeed || !oItem.empty) && aResult.push(oItem.key);
    });
    return aResult;
}

function fGetDefault(sId) {
    var that = this;
    var oInput = that.getInput(sId);
    if (!oInput || !oInput.data) {
        return '';
    }
    var oResult = {};
    Base.forEach(oInput.data, function (oItem) {
        oResult[oItem.key] = '';
    });
    return sId === Module.ID_ATTACH ? [] : oInput.mult ? [oResult] : oResult;
}

function fGetIsMult(sId) {
    var that = this;
    var oInput = that.getInput(sId);
    return !!(oInput && oInput.mult);
}

function fGetInput(sId) {
    var that = this;
    var oItem = that.map[sId];
    return (oItem || {}).inputType;
}

function fGetAlias(sId) {
    var that = this;
    var aItem = that.alias[sId] || [];
    return aItem.length > 0 ? aItem : null;
}

function fGetAliasParentBySub(sId) {
    var that = this;
    var aResult = [];
    Base.forEach(that.alias, function (aItem, sPid) {
        var oItem = Base.find(aItem, function (oItem) {
            return oItem.id === sId;
        });
        oItem && aResult.push(sPid);
    });
    return aResult.length > 0 ? aResult : null;
}

function fGetAliasDataBySub(sId) {
    var that = this;
    var oAlias = that.alias;
    var aPid = that.getAliasParentBySub(sId);
    var aResult = [];
    Base.forEach(aPid, function (sPid) {
        var aItem = Base.find(
            oAlias[sPid],
            function (oItem) {
                return oItem.id === sId;
            },
            true
        );
        aItem && aItem.length > 0 && (aResult = aResult.concat(aItem));
    });
    return aResult.length > 0 ? aResult : null;
}

function fStaticGetIconCls(sId) {
    var that = this;
    var oMap = {1: 'base', 2: 'edu', 3: 'sch', 4: 'job', 5: 'want', 6: 'file', 7: 'award', 8: 'skill', 9: 'self', 999: 'photo'};
    return oMap[sId] || '';
}

function _fStaticGetSystem() {
    var that = this;
    var aItem = [
        {
            id: that.ID_BASE,
            name: '基本资料',
            inputType: {
                data: [
                    {key: 'name', name: '姓名'},
                    {key: 'gender', name: '性别'},
                    {key: 'liveAddress', name: '出生年月'},
                    {key: 'eduLevel', name: '最高学历'},
                    {key: 'workTime', name: '工作年份'},
                    {key: 'birthTime', name: '出生年份'},
                    {key: 'phone', name: '手机'},
                    {key: 'email', name: '邮箱'}
                ]
            }
        },
        {
            id: that.ID_EDU,
            name: '教育经历',
            inputType: {
                mult: true,
                data: [
                    {key: 'school', name: '学校'},
                    {key: 'major', name: '专业', empty: true},
                    {key: 'edu', name: '学历'},
                    {key: 'rank', name: '专业排名'},
                    {key: 'start', name: '在校时间-结束'},
                    {key: 'end', name: '在校时间-结束'}
                ]
            }
        },
        {
            id: that.ID_SCHOOLEXP,
            name: '校内经历',
            inputType: {
                mult: true,
                data: [
                    {key: 'organization', name: '组织'},
                    {key: 'position', name: '职位'},
                    {key: 'start', name: '经历时间-开始'},
                    {key: 'end', name: '经历时间-结束'},
                    {key: 'description', name: '描述'}
                ]
            }
        },
        {
            id: that.ID_WORKEXP,
            name: '工作经历',
            inputType: {
                mult: true,
                data: [
                    {key: 'company', name: '公司'},
                    {key: 'job', name: '职位'},
                    {key: 'start', name: '工作时间-开始'},
                    {key: 'end', name: '工作时间-结束'},
                    {key: 'description', name: '工作描述'}
                ]
            }
        },
        // {
        //     id: that.ID_WANT,
        //     name: '求职意向',
        //     inputType: {
        //         mult: true,
        //         data: [
        //             {key: 'job', name: '期望职位'},
        //             {key: 'location', name: '期望地点'}
        //         ]
        //     }
        // },
        {
            id: that.ID_PROJEXP,
            name: '项目经历',
            inputType: {
                mult: true,
                data: [
                    {key: 'name', name: '项目'},
                    {key: 'position', name: '职位'},
                    {key: 'start', name: '项目时间-开始'},
                    {key: 'end', name: '项目时间-结束'},
                    {key: 'description', name: '项目描述'}
                ]
            }
        },
        {
            id: that.ID_AWARD,
            name: '获奖情况',
            inputType: {
                mult: true,
                data: [
                    {key: 'name', name: '所获奖项'},
                    {key: 'start', name: '获奖时间'}
                ]
            }
        },
        {
            id: that.ID_SKILL,
            name: '个人技能'
        },
        {
            id: that.ID_ASSESS,
            name: '自我评价'
        },
        {
            id: that.ID_PAPER,
            name: '论文发表'
        },
        {
            id: that.ID_ATTACH,
            name: '附件作品',
            inputType: {
                mult: true,
                data: [
                    {key: 'name', name: '附件名称'},
                    {key: 'url', name: '附件链接', empty: true},
                    {key: 'worksName', name: '名字', empty: true},
                    {key: 'worksLink', name: '链接', empty: true},
                    {key: 'worksDesc', name: '描述', empty: true}
                ]
            }
        },
        {
            id: that.ID_AVATAR,
            name: '个人照片'
        }
    ];

    return Base.toMap(aItem, function (oItem) {
        return {key: oItem.id, value: oItem};
    });
}
