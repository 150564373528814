var Ajax = require('./ajax');
module.exports = {
    url: {
        actionIsFollow: '/nccommon/sns/is-follow',
        actionFollow: '/sns/follow',
        actionUnfollow: '/sns/unfollow',
        actionLike: '/sns/like',
        actionUnlike: '/sns/del_like',
        //屏蔽用户的全部动态
        actionBlockUser: '/nccommon/sns/shield',
        actionCorrect: '/corrections',
        actionShield: '/itest/question/shield',

        scanRecord: '/app/scan-record',

        getTags: '/sns/followdata',
        addTags: '/sns/followtags/create',
        editTags: '/sns/followtags/edit',
        updateTagName: '/sns/followtags/update',
        deleteTag: '/sns/followtags/delete',

        // 增加举报 body: {type: Number, 举报类型 entityId: Number, 举报的实体id mainCommentEntryId: Number, 举报评论的实体id violationType: String, 举报类型 violationInfo: String 举报内容}
        addReport: '/report/add',
        // 是否举报过 body: {type: Number, 举报类型, entityId: Number, 举报的实体id}
        isReported: '/report/isReported'
    }
};
Ajax.add(module.exports, module.exports.url);
