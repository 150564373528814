var Ajax = require('./ajax');
module.exports = {
    url: {
        // POST, {body: {json: String}}
        acmSaveSetting: '/weixin-mp/acm/setting-save',
        bind: '/weixin-mp/bind',
        unBind: '/weixin-mp/un-bind',
        getAppID: '/help/wechat-pay/get',
        getWxQrCode: '/weixin/qr-code'
    }
};
Ajax.add(module.exports, module.exports.url);
