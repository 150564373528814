/**
    微信SDK操作
    var oWx = new Weixin({

    });
 */

var $ = require('@ncfe/nc.jquery');
var Browser = require('@ncfe/nc.browser');
var Action = require('@/js/lib/action/common');
var weixinShare = require('./weixinShare');
var bHttp = window.location.protocol.indexOf('https') >= 0;
var App = require('@/js/lib/util/app');
var Wxmp = require('@/js/lib/action/wxmp');

let bLaunch = false;

module.exports = {
    init: fInit,
    getConf: fGetConf,
    isCanLaunch: fIsCanLaunch,
    isUsable: fIsUsable,
    isUseWxCmp: fIsUseWxCmp
};

function fInit(oConf) {
    var that = this;
    var WeixinJSBridge = window.jWeixin;
    if (WeixinJSBridge && Browser.wechat()) {
        that.getConf(oData => {
            WeixinJSBridge.ready(() => {
                WeixinJSBridge.checkJsApi({
                    jsApiList: ['wx-open-launch-app'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                    success: function (res) {
                        console.log('可用');
                        bLaunch = true;
                    },
                    fail: err => {
                        console.log(err, '不可用');
                    }
                });

                var sImgUrl = oConf.imgUrl || $('#wx_logo img').attr('src') || '//static.nowcoder.com/mobile/images/share/icon_512.png';
                !/^http/.test(sImgUrl) && (sImgUrl = (bHttp ? 'https:' : 'http:') + sImgUrl);
                var sLink = oConf.link || window.location.href.split('#')[0];
                var oWxConf = {
                    title: oConf.title,
                    desc: oConf.desc,
                    link: sLink,
                    imgUrl: sImgUrl,
                    success: oConf.success,
                    cancel: oConf.cancel
                };
                !oConf.cancelShare && weixinShare.weixinShare(oWxConf);
            });
            WeixinJSBridge.config({
                debug: false,
                appId: oData.appId, // 必填，公众号的唯一标识
                timestamp: oData.timestamp, // 必填，生成签名的时间戳
                nonceStr: oData.nonceStr, // 必填，生成签名的随机串
                signature: oData.signature, // 必填，签名，见附录1
                jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo', 'onMenuShareQZone'],
                openTagList: ['wx-open-launch-weapp', 'wx-open-launch-app']
            });
        });
    }
}

function fGetConf(fCb) {
    var that = this;
    Action.jsApiConfig({
        query: {url: window.location.href.split('#')[0]},
        call: function (oResult) {
            var oData = oResult.data;
            fCb && fCb(oData);
        },
        error: function (oResult) {
            console.log('获取微信配置失败，请刷新页面重试', oResult);
        }
    });
}

function fIsCanLaunch() {
    return bLaunch;
}

//检测微信接口是否可用
function fIsUsable() {
    const WeixinJSBridge = window.jWeixin;
    return new Promise(resolve => {
        if (!WeixinJSBridge || !Browser.wechat()) return resolve(false);
        WeixinJSBridge.ready(() => {
            WeixinJSBridge.checkJsApi({
                jsApiList: ['wx-open-launch-app'], // 需要检测的JS接口列表
                success: () => {
                    resolve(true);
                },
                fail: () => {
                    resolve(false);
                }
            });
        });
    });
}

//是否使用微信组件
async function fIsUseWxCmp() {
    const isUsable = await fIsUsable();
    let appid = '';
    if (isUsable) {
        const {data} = await Wxmp.getAppID({type: 'POST'}).catch(() => ({data: 'wx8b494535aa1d9df7'}));
        appid = data;
    }
    return App.isAndroid && App.isWx && appid;
}
