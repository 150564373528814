// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 *
 * @export 跳转App
 * @param {*} oData
 * @param pageNme_var 页面名称
 */
function appDownload(oData) {
    gio('appDownload', oData);
}

/**
 *
 * @export 提交手机号
 * @param {*} oData
 * @param isShare_var 是否填写邀请码
 * @param pageNme_var 页面名称
 */
function phoneSubmit(oData) {
    gio('phoneSubmit', oData);
}

/**
 *
 * @export 浏览页面
 * @param {*} oData
 * @param pageNme_var 页面名称
 */
function resumePage(oData) {
    gio('resumePage', oData);
}
/**
 *
 * @export 抖音小黄车简历点评页面浏览
 * @param {*} oData
 * @param UniqueView_var 浏览用户
 * @param PageEnter_var 访问渠道
 * @param PageName_var 页面名称
 * @param Glide_var 下滑率
 * @param PageTime_var 页面时长（秒）
 */
function douyinResumeCommentView(oData) {
    gio('DouyinResumeCommentView', oData);
}
/**
 *
 * @export 内容条目点击
 * @param {*} oData
 * @param Click_var 点击领取
 */
function douyinResumeCommentClick(oData) {
    gio('DouyinResumeCommentClick', oData);
}
export default {
    appDownload,
    phoneSubmit,
    resumePage,
    douyinResumeCommentView,
    douyinResumeCommentClick
};
