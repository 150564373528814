<template>
    <div v-if="need" v-show="isShow" style="position: fixed; top: 0; min-width: 100vw; min-height: 100vh; background-color: rgba(0, 0, 0, 0.8); z-index: 99999;" @click="click">
        <div style="position: absolute; right: 0; top: 0;">
            <img src="//uploadfiles.nowcoder.com/images/20170705/56_1499241645800_4E6A98F49A00AAFCA570F859C33CBAE7" />
        </div>
        <div class="color-white fs txt-center pt-20" style="width: 75%; margin: 0 auto;">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: ['forceShow', 'clickHide'],
    data() {
        var that = this;
        var sUserAgent = (window.navigator.userAgent || '').toLowerCase();
        return {
            isShow: true,
            need: that.forceShow || sUserAgent.match(/MicroMessenger/i) == 'micromessenger'
        };
    },
    methods: {
        click() {
            var that = this;
            that.clickHide && (that.isShow = false);
        }
    }
};
</script>
