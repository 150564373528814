<template>
    <div>
        <popup v-show="!isShow" ref="popup" :value="show" height="95%" style="height: 95%; width: 100%;" :hide-on-blur="false" @on-hide="cancel" @on-show="onShow">
            <search ref="search" v-model="word" class="search" :auto-fixed="false" auto-scroll-to-top placeholder="输入城市名称" cancel-text="完成" @on-change="actionSearch" @on-cancel="cancel" @on-clear="cleanSearchState">
                <template v-if="word" v-slot:right>
                    <i class="close fs-12 color-gray bg-white icon-close" @click="actionClear"></i>
                </template>
            </search>
            <div v-if="selectedCities.length" class="p-2">
                <span v-for="(sItem, index) in selectedCities" :key="sItem" class="mr-1 mb-1 select-item color-green bg-light-green p-1 pl-2 fs-12">
                    {{ sItem }}
                    <i class="close fs-12 icon-close ml-1 mr-1" @click="actionDeleteCity(index)"></i>
                </span>
            </div>
            <checklist v-model="selectedCities" :disabled="selectedCities.length === 10" :options="items" label-position="left" :max="10" @on-change="actionChange"></checklist>
            <div v-if="items.length === 0">
                <p class="pl-3 ptb-2" style="font-weight: bolder;">热门城市</p>
                <checklist v-model="selectedCities" :disabled="selectedCities.length === 10" :options="hotCities" label-position="left" :max="10" @on-change="actionChange"></checklist>
            </div>
        </popup>
    </div>
</template>

<script>
import {Popup, Search, Checklist} from '@ncfe/nc.vux';
import CommonAction from '@/js/lib/action/common';

const $ = require('@ncfe/nc.jquery');
const Base = require('@ncfe/nc.base');
const oCache = {};

export default {
    props: ['show', 'default'],
    components: {Popup, Search, Checklist},
    data: fData,
    methods: {
        cleanSearchState: fCleanSearchState,
        cancel: fCancel,
        onShow: fOnShow,
        actionSearch: fActionSearch,
        actionRefresh: fActionRefresh,
        actionClear: fActionClear,
        handleCity: fHandleCity,
        actionDeleteCity: fActionDeleteCity,
        actionChange: fActionChange
    },
    watch: {
        show: {
            handler: fShow,
            immediate: true
        }
    }
};

function fData() {
    var that = this;
    return {
        isShow: false,

        word: '',
        items: [],

        hotCities: [],
        allCities: [],
        selectedCities: []
    };
}

function fCleanSearchState() {
    var that = this;
    that.word = '';
    that.items = [];
}

function fCancel() {
    var that = this;
    that.$emit('close', that.selectedCities.join(','));
}

function fOnShow() {
    const that = this;
    if (!!that.default) {
        that.selectedCities = that.default.split(',');
    }
    const sKey = $.trim(that.word);
    that.items = [];
    if (oCache.hotCities && oCache.allCities) {
        that.hotCities = oCache.hotCities;
        that.allCities = oCache.allCities;
        return;
    }
    CommonAction.getAllPalce({
        type: 'get',
        call(oResult) {
            that.handleCity(oResult.data);
        },
        error(oResult) {
            if ($.trim(that.word) === sKey) {
                that.items = [];
            }
        }
    });
}

function fActionSearch() {
    const that = this;
    if (!that.word) {
        that.items = [];
        return;
    }
    that.items = Base.filter(that.allCities, sItem => sItem.includes(that.word));
}

function fActionRefresh() {
    var that = this;
    that.actionSearch();
}

function fActionClear() {
    let that = this;
    that.word = '';
    that.items = [];
}

function fShow() {
    let that = this;
    if (!that.show) return;
    that.$refs.search.isFocus = true;
}

function fHandleCity(oCitys) {
    const that = this;
    that.hotCities = oCitys.hotCities;
    Base.forEach(oCitys.allCities, oItem => {
        if (oItem.levelCount === 3) {
            Base.forEach(oItem.level2, oSubItem => {
                Base.forEach(oSubItem.level3, oGrandItem => {
                    that.allCities.push(oGrandItem.name);
                });
            });
        } else {
            Base.forEach(oItem.level2, oSubItem => {
                that.allCities.push(oSubItem.name);
            });
        }
    });
    oCache.hotCities = that.hotCities;
    oCache.allCities = that.allCities;
}

function fActionDeleteCity(nIndex) {
    const that = this;
    that.selectedCities.splice(nIndex, 1);
}

function fActionChange(val) {
    const that = this;
    if (val.length === 10) {
        that.$vux.toast.text('最多可选10个意向地点哦~', 'middle');
    }
}
</script>

<style lang="scss">
.search {
    position: relative;
    .close {
        position: absolute;
        right: 50px;
        top: 10px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        /* background: green; */
        padding: 6px 8px;
        z-index: 2;
    }
}

.vux-popup-dialog {
    background-color: #fff;
    .select-item {
        display: inline-block;
        border-radius: 2.1rem;
    }
}

.vux-cell-bd.vux-cell-primary {
    overflow: hidden;

    label.vux-label div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.weui-icon-clear,
.weui-icon-clear:before {
    visibility: hidden;
}

.weui-search-bar__label {
    pointer-events: none !important;
}
.weui-search-bar__cancel-btn {
    display: block;
}
</style>
