import Vue from '@ncfe/nc.vue';

var Str = require('@ncfe/nc.str');
var Base = require('@ncfe/nc.base');
var Uri = require('@ncfe/nc.uri');

// 配置信息
var oInfo = window.season;
Base.isString(oInfo) && (oInfo = Base.json(Str.decodeHTML(oInfo)));
oInfo = oInfo || {};
// oInfo = require('./util/season11').default;

export default new Vue({
    data: fData,
    methods: {
        getTypeName: fGetTypeName,
        hex2rgb: fHex2rgb
    },
    computed: {
        isNeedType: fIsNeedType,
        isNeedWorkYear: fIsNeedWorkYear
    }
});

function fData() {
    var oParam = Uri.getParams();
    var sGroupId = oParam.groupId || '';
    var nGroupSize = +oInfo.groupSize || 6;
    var aLimitWorkYear = Base.map(oInfo.limitWorkYear, nYear => Base.id(nYear));
    // 首页和排行榜页面用头一个套图
    oInfo.coverSmall = oInfo.coverSmall || oInfo.coverBig;
    oInfo.coverNum = oInfo.coverNum || oInfo.coverBig;

    return {
        season: oParam.season || '',
        type: oParam.type || '',

        coverBig: {src: oInfo.coverBig || 'https://uploadfiles.nowcoder.com/images/20191123/56_1574494682710_F121D135F39F03E48DA5FE5E8CED5B0A', width: oInfo.coverBigWidth || 750, height: oInfo.coverBigHeight || 766},
        coverSmall: {src: oInfo.coverSmall || 'https://uploadfiles.nowcoder.com/images/20191123/56_1574494682710_F121D135F39F03E48DA5FE5E8CED5B0A', width: oInfo.coverSmallWidth || 750, height: oInfo.coverSmallHeight || 766},
        coverNum: {src: oInfo.coverNum || 'https://uploadfiles.nowcoder.com/images/20191123/56_1574494682710_F121D135F39F03E48DA5FE5E8CED5B0A', width: oInfo.coverNumWidth || 750, height: oInfo.coverNumHeight || 766},
        entryBtnColor: oInfo.entryBtnColor,
        shareBtnColor: oInfo.shareBtnColor,
        // 各种功能配置
        canBuy: !!oInfo.canBuy, // true的话开通购买通道
        groupSize: nGroupSize, // 每个团多少人
        isNeedEdu: !!oInfo.needEdu,
        isOnlyEmail: !!oInfo.onlyEmail,
        isDirectApply: Base.id(oInfo.seasonType) === '1',
        hideSchoolRank: !!oInfo.hideSchoolRank,
        typeList: !oInfo.hasType
            ? null
            : [
                  {text: '技术资料', value: '0'},
                  {text: '非技术资料', value: '1'}
              ], // 区分资料类别
        limitWorkYear: aLimitWorkYear,
        checkWorkYear: aLimitWorkYear.length === 0 ? undefined : sVal => Base.contains(aLimitWorkYear, Base.id(sVal)), // 限制毕业年份

        title: oInfo.title || '牛客带你学编程-C语言入门自学指南',
        objectName: oInfo.objectName || '资料',
        shareTitle: oInfo.shareTitle || '牛客网免费送你C语言入门自学指南，马上组团领取',
        shareWxTitle: Str.execTpl(oInfo[sGroupId ? 'shareWxTitleWithGroup' : 'shareWxTitleNoGroup'] || '邀请你加入我的团', {groupId: sGroupId}),
        shareWxDesc: oInfo.shareWxDesc || '',
        shareImage: oInfo.shareImage || 'https://uploadfiles.nowcoder.com/images/20191123/56_1574495376982_D3F0254E11C09C195C601A5A597B70DF',

        inviteUseQrImage: !!oInfo.inviteUseQrImage,
        inviteTargetLink: oInfo.inviteTargetLink,
        inviteTopTips: oInfo.inviteTopTips,
        inviteBottomTips: oInfo.inviteBottomTips,

        indexListSplitBefore: !!oInfo.indexListSplitBefore || false,
        indexListSplitAfter: !!oInfo.indexListSplitAfter || true,
        indexList: oInfo.indexList || [],

        qqJiShu: oInfo.qqJiShu || '727937511',
        qqExt: oInfo.qqExt || '727937511',
        presidentReward: oInfo.presidentReward || '',

        result1: oInfo.result1 || '电子资料在下周二会发放电子资料到你的牛客站内信，#{threshold}人成团即可收取资料，请注意查收。',
        result2: oInfo.result2 || '恭喜你组团领资料成功，电子资料在下周二会发放电子资料到你的牛客站内信，请注意查收。',
        result3: oInfo.result3 || '恭喜你！资料已经发出，圆通物流单号为#{trackingNumber}，请注意查收，收到资料后请依次发给团员，并提示所有团员收到后点击页面的已收到，全部点击后团长可在页面上点击领取优惠券。',
        result4: oInfo.result4 || `${nGroupSize}人即可成团，成团截止于2020年3月31日，<br>快去邀请小伙伴一起组团领资料！~`,
        result5: oInfo.result5 || '恭喜你组团成功！资料将于每周二安排寄送（节假日顺延），快递单号将会更新在本页面，请注意查收。<br>欢迎加入牛客网2019编程入门学习交流群：#{qqGroup}。', // <br><span class="color-red">注意：如果已经加入其它 牛客网2019秋招备战群，则不用再加入上面的群。</span>

        // 资料没有了，6非技术，7技术
        result6: oInfo.result6 || '恭喜你组团成功！电子版资料将在每周五，通过系统通知处发送给你，请注意查收。<br>欢迎加入牛客网2019编程入门学习交流群：#{qqGroup}',
        result7: oInfo.result7 || '恭喜你组团成功！电子版资料将在每周五，通过系统通知处发送给你，请注意查收。<br>欢迎加入牛客网2019编程入门学习交流群：#{qqGroup}',

        result8: oInfo.result8 || '你的资料已经领取。奖励已发放。',
        result9: oInfo.result9 || '恭喜你！资料已经发出，圆通物流单号为#{trackingNumber}',
        result10: oInfo.result10 || '你的团长手机号码为#{presidentPhone}，',
        result11: oInfo.result11 || '恭喜你！资料已经发出，圆通物流单号为#{trackingNumber}，#{ext}资料将发到团长那里，可通过点击团长头像进行沟通。',
        result12: oInfo.result12 || '你的资料已经领取，圆通物流单号为#{trackingNumber}。'
    };
}

/**
 * @param {String} sType
 * @param {Boolean} bAllowEmpty 是否允许返回空值
 * @returns
 */
function fGetTypeName(sType, bAllowEmpty) {
    var that = this;
    var aItem = that.typeList || [];
    var oItem = Base.find(aItem, oItem => oItem.value === sType);
    return oItem ? (oItem || {}).text || '' : !bAllowEmpty ? (aItem[0] || {}).text || '' : '';
}

function fHex2rgb(sHex, nAlpha) {
    if (!sHex) return sHex;
    if (!Base.isString(sHex)) return sHex;
    var str = sHex.replace(/\s+/gi, '');
    if (!/^#[0-9a-fA-F]{3}$/.test(str) && !/^#[0-9a-fA-F]{6}$/.test(str)) return sHex;
    str = str.replace('#', '');
    var b3 = str.length === 3;
    var aResult = [];
    str.split('').forEach((s, index) => {
        if (!b3 && index % 2 === 0) return;
        var val = (b3 ? s : str[index - 1]) + s;
        aResult.push(parseInt(val, 16));
    });
    var bAlpha = Base.id(nAlpha);
    bAlpha && aResult.push(nAlpha);
    return `rgb${bAlpha ? 'a' : ''}(${aResult.join(', ')})`;
}

function fIsNeedType() {
    var that = this;
    return (that.typeList || []).length > 0;
}

function fIsNeedWorkYear() {
    var that = this;
    return !!that.checkWorkYear;
}
