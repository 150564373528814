<template>
    <popup ref="popup" :value="show" height="100%" style="height: 100%; width: 100%;" @on-hide="$emit('close')" @on-show="actionShow">
        <div v-if="!loading" class="page-wrap bg-eee flex-row-start">
            <div v-if="items.length" class="bg-table fs-15" style="flex: 0 0 40%; min-height: 100vh;">
                <div v-for="(item, index) in items" :key="index" class="ptb-2 txt-center" :class="index === selectedTagIndex ? 'bg-white color-green' : 'color-black'" @click="actionLeftNav(index)">{{ item.shortName }}</div>
            </div>
            <div v-if="items.length && items[selectedTagIndex].jobs.length" class="bg-white hide-txt" style="flex: 0 0 60%; height: 100vh;">
                <div class="p-2 flex-row-start">
                    <span class="flex-auto">共{{ items[selectedTagIndex].jobs.length }}个职位 </span>
                    <span class="flex-none color-green" @click="actionCancel">取消</span>
                </div>
                <div class="right-scroll">
                    <div v-for="(item, index) in items[selectedTagIndex].jobs" :key="index" class="flex-row-start">
                        <div class="flex-row-start p-2 tap-active hide-txt" style="flex: auto;" @click="actionRightItem(item)">
                            <div class="ml-1 hide-txt flex-auto" :class="item.checked ? 'color-green' : ''" style="flex: auto;">{{ item.name }}</div>
                            <i v-if="item.checked" class="mlr-2 color-green flex-none icon-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loading v-else></Loading>
    </popup>
</template>

<script>
import {Popup} from '@ncfe/nc.vux';
import Loading from '@nc/vue.loading';
import Base from '@ncfe/nc.base';

let SettingAction = require('@/js/lib/action/setting');

export default {
    components: {Loading, Popup},
    props: ['show'],
    data: fData,
    methods: {
        actionShow: fActionShow,
        initRefresh: fInitRefresh,
        actionLeftNav: fActionLeftNav,
        actionRightItem: fActionRightItem,
        actionItem: fActionItem,
        actionCancel: fActionCancel
    }
};

function fData() {
    return {
        items: [],
        loading: true,
        error: '',
        selectedTagIndex: 0
    };
}

function fActionShow() {
    let that = this;
    that.initRefresh();
}

function fInitRefresh() {
    let that = this;
    that.loading = true;
    SettingAction.getAllJobs({
        type: 'GET',
        call: oData => {
            that.items = oData.data;
            Base.forEach(that.items, item => {
                Base.forEach(item.jobs, job => (job.checked = false));
            });
        },
        error: oError => (that.error = oError.msg),
        always: () => (that.loading = false)
    });
}

function fActionLeftNav(nIndex) {
    let that = this;
    that.selectedTagIndex = +nIndex;
}

function fActionRightItem(oItem) {
    let that = this;
    let items = JSON.parse(JSON.stringify(that.items));
    Base.forEach(items, item => {
        Base.forEach(item.jobs, job => (job.checked = oItem.id === job.id));
    });
    that.items = items;
    oItem.id && that.actionItem(oItem);
    !oItem.id && that.$sdk.Toast.error({content: '职位不存在'});
}

function fActionItem(oItem) {
    let that = this;
    that.$emit('select', {...oItem});
    that.$emit('close');
}

function fActionCancel() {
    let that = this;
    that.$emit('cancel');
    that.$emit('close');
}
</script>

<style scoped lang="scss">
.page-wrap {
    min-width: 100vw;
    min-height: 100vh;
    align-items: flex-start;
}

.bg-eee {
    background: #eeeeee;
}

.right-scroll {
    max-height: calc(100% - 40px);
    overflow-y: auto;
}

.fs-15 {
    font-size: 15px;
}
</style>
