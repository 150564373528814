var Ajax = require('./ajax');
module.exports = {
    url: {
        getTopicList: '/ta/topic-list',
        getReviewList: '/review/get-final-test',
        getBrowseList: '/question/get-menu-json',
        getPracticeWrongMenu: '/profile/get-practice-wrong-num-menu',
        getTestPaperType: '/itest/paper-menu',
        getTestMenu: '/itest/get-menu-json',
        getRecommendPaper: '/paper/get-recommend-paper',
        getCompanyList: '/paper/get-company-paper-cnt',
        getCompanyPaper: '/paper/get-company-paper-menu',
        getInfo: '/get-question-info-v4',
        getCollect: '/profile/get-my-following-questions',
        getAnswered: '/profile/get-my-answer-questions',
        getShield: '/profile/shield-questions',
        getWrongList: '/profile/get-practice-wrong-num-menu',
        getWrongBrowse: '/profile/get-wrong-num-menu',
        getTestResult: '/test/get-test-result',
        // 获取在线编程题
        getNewQuestion: '/new-question/questions',
        getNewQuestionInfo: '/ta/recommendTaMenu'
    }
};
Ajax.add(module.exports, module.exports.url);
