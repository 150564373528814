var Ajax = require('./ajax');
module.exports = {
    url: {
        getData: '/coin/data',
        getOrderInfo: '/coin/order-info',
        defaultAddress: '/coin/default-address',
        buyVirtual: '/coin/buy-virtual',
        buyReal: '/coin/buy-real',
        products: '/coin/products',
        getCoinData: '/wallet/#{uid}/data',
        getOrdersData: '/coin/orders-data'
    }
};
Ajax.add(module.exports, module.exports.url);
