<template>
    <popup :value="show" class="job-popup" height="80%" @on-hide="close">
        <p class="ptb-3 plr-2" style="text-align: center; font-weight: bolder;">
            意向职位
            <i style="font-size: 12px; float: right;" class="icon-arrow-close" @click="close" />
        </p>
        <!-- <search placeholder="搜索职位" :cancel-text="'d1'" :auto-fixed="false"></search> 暂时去掉 -->
        <group ref="gruopBox" class="group-box">
            <cell v-for="oItem in allCareerJob" :key="oItem.jobInfo.id" class="job-cell" is-link :title="oItem.jobInfo.name" @click.native="actionParent(oItem.subJobs)"></cell>
            <popup v-model="showSubJobPopup" width="85%" position="right" style="position: absolute; box-shadow: rgb(0 0 0 / 7%) 0px 6px 6px 3px; overflow: visible; background: #fff;">
                <flexbox :gutter="0">
                    <flexbox-item class="group-left" :span="5">
                        <group gutter="0">
                            <cell v-for="oSubItem in aSubCareerJob" :key="oSubItem.jobInfo.id" class="job-cell" is-link :title="oSubItem.jobInfo.name" @click.native="actionSub(oSubItem.subJobs)"></cell>
                        </group>
                    </flexbox-item>
                    <flexbox-item class="group-right" :span="7">
                        <group :style="`max-height: ${sRightHeight}`" gutter="0">
                            <checklist v-model="selectedJob" label-position="left" required :options="aJobList" :max="3" @on-change="actionChange"></checklist>
                        </group>
                    </flexbox-item>
                </flexbox>
            </popup>
        </group>
    </popup>
</template>

<script>
import {Cell, Group, Popup, Flexbox, FlexboxItem, Checklist} from '@ncfe/nc.vux';
import SettingAction from '@/js/lib/action/setting';
const Base = require('@ncfe/nc.base');

export default {
    props: ['show', 'default'],
    components: {Cell, Group, Popup, Flexbox, FlexboxItem, Checklist},
    data: fData,
    mounted: fMounted,
    methods: {
        close: fClose,
        actionParent: fActionParent,
        actionSub: fActionSub,
        actionChange: fActionChange
    }
};

function fData() {
    const that = this;
    return {
        showCareerJobPopup: false,
        showSubJobPopup: false,
        allCareerJob: [],
        selectedJob: [],
        aSubCareerJob: [],
        selectedKey: [],
        aJobList: [],
        aJobKey: [],
        sRightHeight: '',
        oMapLabel: {}
    };
}

function fMounted() {
    const that = this;
    SettingAction.getAllCareerJob({
        type: 'get',
        call({data}) {
            that.allCareerJob = data.allJobs;
            Base.forEach(that.selectedKey, (oItem, nIndex) => {
                that.oMapLabel[that.selectedJob[nIndex]] = oItem;
            });
        }
    });
}

function fClose() {
    const that = this;
    that.showSubJobPopup = false;
    Base.forEach(that.selectedJob, sItem => {
        that.aJobKey.push(that.oMapLabel[sItem]);
    });
    that.$emit('close');
}

function fActionParent(aSubJob) {
    const that = this;
    if (!that.showSubJobPopup) {
        that.aSubCareerJob = aSubJob;
        that.aJobList = [];
        Base.forEach(aSubJob[0].subJobs, oItem => {
            that.aJobList.push(oItem.jobInfo.name);
            that.oMapLabel[oItem.jobInfo.name] = oItem.jobInfo.id;
        });
    }
    that.showSubJobPopup = !that.showSubJobPopup;
    that.sRightHeight = that.$refs.gruopBox.$el.scrollHeight + 'px';
}

function fActionSub(aSubJob) {
    const that = this;
    that.aJobList = [];
    Base.forEach(aSubJob, oItem => {
        that.aJobList.push(oItem.jobInfo.name);
        that.oMapLabel[oItem.jobInfo.name] = oItem.jobInfo.id;
    });
}

function fActionChange() {
    const that = this;
    if (that.selectedJob.length === 3) {
        that.$vux.toast.text('最多可选3个职位哦~', 'middle');
    }
}
</script>

<style lang="scss" scope>
.job-popup {
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    .weui-search-bar > a {
        display: none !important;
    }
    .weui-search-bar::before {
        border: none;
    }
    .weui-search-bar::after {
        border: none;
    }
    .group-box {
        height: calc(100% - 120px);
    }
    & > div > .weui-cells {
        min-height: 100%;
    }
    .job-cell > .weui-cell__ft::after {
        border: none;
    }
    .vux-flexbox {
        align-items: baseline;
        background-color: #fff;
    }
    .weui-cells {
        padding-bottom: 0;
    }
    .group-left {
        .job-cell {
            text-align: center;
        }
        .weui-cells {
            background-color: #efeff4;
            .weui-cell::before {
                border: none;
            }
        }
    }
    .group-right {
        overflow-y: auto;
        .weui-cells_checkbox > .weui-cell {
            padding-right: 5px;
            word-break: keep-all;
        }
        .weui-cell::before {
            border: none;
        }
    }
}
</style>
