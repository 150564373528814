// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    setTimeout(() => {
        window.gio('track', eventName, variable);
    }, 2000);
};

/**
 * 29
 *
 * @export 课程主页浏览 页面曝光时触发
 * @param {*} oData
 * @param learnType_var 学习类型
 * @param pageEnter_var 页面入口
 * @param courseID_var  课程ID
 * @param courseName_var 课程名称
 * @param isFree_var 是否免费
 * @param teachType_var 授课方式
 * @param startTime_var 职位三级分类
 * @param suitablePeople_var 适合人群
 * @param courseTime_var 课程时长
 * @param courseAmount_var 课程金额
 * @param courseTeacher_var 课程讲师
 * @param courseType_var 课程类型
 * @param activityType_var 活动类型
 * @param courseMode_var 课程形式
 * @param courseLevel1_var 课程一分类
 * @param courseLevel2_var 课程二级分类
 */
function courseHomepage(oData) {
    gio('courseHomepage', oData);
}

/**
 * 30
 *
 * @export 课程购买按钮点击 点击购买按钮时触发
 * @param {*} oData
 * @param learnType_var 学习类型
 * @param courseID_var  课程ID
 * @param courseName_var 课程名称
 * @param isFree_var 是否免费
 * @param teachType_var 授课方式
 * @param startTime_var 职位三级分类
 * @param suitablePeople_var 适合人群
 * @param courseTime_var 课程时长
 * @param courseAmount_var 课程金额
 * @param courseTeacher_var 课程讲师
 * @param courseType_var 课程类型
 * @param activityType_var 活动类型
 * @param courseMode_var 课程形式
 * @param courseLevel1_var 课程一分类
 * @param courseLevel2_var 课程二级分类
 */
function buyCourseClick(oData) {
    gio('buyCourseClick', oData);
}

export default {
    courseHomepage,
    buyCourseClick
};
