var Base = require('@ncfe/nc.base');
var Str = require('@ncfe/nc.str');
import Vue from '@ncfe/nc.vue';
import Action from '@/js/lib/action/action';
import UIUtil from '@/js/lib/util/ui';
import PopupAddTag from '@/js/cpn/popup/PopupAddTag';
import Report from '@/js/cpn/Report';
import Popup from '@nc/vue.popup';

export default {
    collect: fCollect,
    report: fReport
};

/**
 * 收藏
 * @param   {Object} oParam
 *  @param  {String} oParam.type
 *  @param  {String} oParam.id
 *  @param  {Number} oParam.action 0:取消收藏，1:仅收藏，2:收藏+编辑便签, 3: 编辑标签
 *  @param  {Function} oParam.call
 */
function fCollect(oParam) {
    var that = this;
    var sAction = Base.id(oParam.action) || '0';
    var bFollow = sAction === '1' || sAction === '2';
    if (sAction === '3') return _fTag();
    Action[bFollow ? 'actionFollow' : 'actionUnfollow']({
        body: {id: oParam.id, type: oParam.type},
        call(oResult) {
            oParam.call && oParam.call(oResult);
            sAction === '2' && _fTag();
        },
        error: oResult => UIUtil.alert(oResult.msg)
    });

    function _fTag() {
        var bEdit = sAction === '3';
        Action.getTags({
            query: {
                id: oParam.id,
                type: oParam.type,
                isEdit: bEdit
            },
            call(oResult) {
                var oData = oResult.data;
                var aTag = Str.split(oData.allTags, ',');
                var aSelected = Str.split(oData.draftTags, ',');
                var oItem = PopupAddTag.show({
                    propsData: {initialTags: aTag, initialSelected: aSelected}
                });
                oItem.$on('submit', aItem => {
                    Action[bEdit ? 'editTags' : 'addTags']({
                        body: {id: oParam.id, type: oParam.type, tags: aItem.join(',')},
                        call: () => UIUtil.toast('success', '操作成功')
                    });
                });
            }
        });
    }
}

/**
 * 举报
 * @param   {Object} oParam
 *  @param  {String} oParam.type
 *  @param  {String} oParam.id
 *  @param  {String} oParam.fromId
 *  @param  {String} oParam.uuid feed专用
 */
function fReport(oParam) {
    var that = this;
    var Cpn = Vue.extend(Report);
    var oItem = new Cpn({
        propsData: {
            id: oParam.id,
            type: oParam.type,
            fromId: oParam.fromId,
            uuid: oParam.uuid
        }
    });
    Popup.show(Vue, oItem, {
        propsData: {position: 'top', popupClass: 'pb-4'}
    });
}
