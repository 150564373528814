/**
 * 上传操作
 * var oItem = new Upload({
 *     url: String, 上传路径
 *     name: String, 字段名称，默认为file
 *     extData: Object, 其他需要跟上传一起发送的数据
 *     XMLHttpRequest: Object, 上传对象
 *     beforeSend: Function, 发送之前的回调
 *     noSupport: Function, 不支持的回调
 *     progress: Function, 上传进度的回调
 *     call: Function, 上传完成的回调
 *     error: Function, 上传失败的回调
 * });
 *
 */

var Base = require('@ncfe/nc.base');
var Clazz = require('@ncfe/nc.clazz');

var FormData = window.FormData;
var Upload = (module.exports = Clazz.create());
Base.extend(Upload, {
    support: !!FormData && !!window.XMLHttpRequest && !!window.JSON && !!window.addEventListener
});

Base.extend(Upload.prototype, {
    initialize: fInitialize,
    uploadFile: fUploadFile,
    uploadBlob: fUploadBlob,
    // 上传表单文件
    uploadForm: fUploadForm,
    upload: fUpload
});

function fInitialize(oConf) {
    var that = this;
    that.rawConfig = oConf || {};
}

function fUploadFile(oFile, bNeedSplit) {
    var that = this;
    var oConf = that.rawConfig;
    var nFileSize = oFile.size;
    var sName = oConf.name || 'file';
    var nSend = 0;
    var nErrorCount = 0;
    var oLastResult;
    if (!oFile || nFileSize === 0) return;

    var nPartSize = 250 * 1024;
    if (!bNeedSplit || nFileSize <= nPartSize) {
        var oData = new FormData();
        oData.append(sName, oFile);
        that.uploadForm(oData);
    } else {
        _fUpload();
    }

    function _fUpload() {
        nErrorCount = 0;
        if (nSend < nFileSize) {
            var oPart = oFile.slice(nSend, nPartSize);
            var oData = new FormData(oPart);
            oData.append(sName, oData);
            _fSend(oData);
        } else {
            oConf.call && oConf.call.call(that, oLastResult);
        }
    }

    function _fSend(oData) {
        that.upload({
            data: oData,
            url: oConf.url,
            extData: oConf.extData,
            XMLHttpRequest: oConf.XMLHttpRequest,
            beforeSend: oConf.beforeSend,
            noSupport: oConf.noSupport,
            call: function (oResult) {
                oLastResult = oResult;
                nSend += nPartSize;
                _fUpload();
            },
            error: function (oResult) {
                nErrorCount++;
                var bLimit = nErrorCount < 3;
                bLimit && _fSend(oData);
                !bLimit && oConf.error && oConf.error.call(that, oResult);
            },
            progress: function (nPercent, nLoaded, nTotal) {
                nLoaded = nLoaded + nSend;
                oConf.progress && oConf.progress(+((nLoaded * 100) / nFileSize).toFixed(2), nLoaded, nFileSize);
            }
        });
    }
}

function fUploadBlob(oBlob, sFileName) {
    var that = this;
    var oConf = that.rawConfig;
    var oData = new FormData();
    oData.append(oConf.name || 'file', oBlob, sFileName);
    that.uploadForm(oData, oConf);
}

function fUploadForm(oData) {
    var that = this;
    return that.upload(Base.extend({}, that.rawConfig, {data: oData}));
}

/**
 * 上传
 * @param   {Object} oConf
 *  @param  {Object} oConf.data 数据
 *  @param  {Object} oConf.url 上传接口
 *  @param  {Object} oConf.extData 额外数据
 *  @param  {Object} oConf.XMLHttpRequest 上传对象
 *  @param  {Function} oConf.noSupport 不支持回调
 *  @param  {Function} oConf.beforeSend 发送之前的处理函数
 *  @param  {Function} oConf.progress 上传进度
 *  @param  {Function} oConf.call 上传成功回调
 *  @param  {Function} oConf.error 上传失败回调
 */
function fUpload(oConf) {
    var that = this;
    oConf = oConf || {};
    var oData = oConf.data;
    if (!oData) return;

    // 不支持上传，直接返回回调
    if (!Upload.support) {
        return oConf.noSupport && oConf.noSupport.call(that);
    }
    // 额外的数据
    Base.forEach(oConf.extData, function (sVal, sKey) {
        sKey && sVal && oData.append(sKey, sVal);
    });
    // 发送数据
    var XMLHttpRequest = oConf.XMLHttpRequest || window.XMLHttpRequest;
    var oXhr = new XMLHttpRequest();
    oXhr.upload.addEventListener(
        'progress',
        function (oEvent) {
            var nLoaded = oEvent.loaded;
            var nTotal = oEvent.total || 1;
            var nProgress = +((nLoaded * 100) / nTotal).toFixed(2);
            oConf.progress && oConf.progress.call(that, nProgress, nLoaded, nTotal);
        },
        false
    );
    // 文件上传成功或是失败
    oXhr.onreadystatechange = function (oEvent) {
        if (oXhr.readyState === 4) {
            // var oResult = oXhr.status === 200 ? Base.json(oXhr.responseText, {}) : {msg: '出现错误，请重试'};
            var oResult = Base.json(oXhr.responseText, {msg: '出现错误，请重试'});
            var sCode = Base.id(oResult.code);
            var bSuccess = sCode === '0';
            oResult.msg = sCode === '999' ? '上传失败，请重新登录' : oResult.msg;
            var sMethod = bSuccess ? 'call' : 'error';
            oConf[sMethod] && oConf[sMethod].call(that, oResult);
        }
    };
    oXhr.open('POST', oConf.url, true);
    oConf.beforeSend && oConf.beforeSend(oXhr);
    oXhr.send(oData);
}
