<template>
    <div>
        <div v-for="(projectItem, index) in item" :key="index" :class="[isPreview ? 'resume-preview-item' : 'project-item']" @click="$emit('clickPushEdit', index + 1)">
            <template v-if="isPreview">
                <div class="flex-row-start mt-5">
                    <span class="flex-auto fw-500 fs-14">{{ projectItem.name }}</span>
                    <span v-if="projectItem.start && projectItem.end" class="color-a5a5a5">{{ projectItem.start }}-{{ projectItem.end === '-1' ? '至今' : projectItem.end }}</span>
                </div>
                <div class="color-a5a5a5 fs-14 mt-2">
                    <span v-if="projectItem.position">{{ projectItem.position }}</span>
                </div>
                <div v-if="projectItem.description" class="color-a5a5a5 fs-14 mt-2 mr-4 pre-line" v-html="projectItem.description"></div>
            </template>
            <template v-else>
                <div class="flex-row-start mt-4">
                    <span class="flex-auto fw-500 fs-14">{{ projectItem.name }}</span>
                    <IconMobileRight v-if="isEdit" class="color-d8d8d8 fs-14"></IconMobileRight>
                </div>
                <div class="color-a5a5a5 fs-14 mt-2">
                    <span v-if="projectItem.start && projectItem.end">{{ projectItem.start }}-{{ projectItem.end === '-1' ? '至今' : projectItem.end }}</span>
                    <span v-if="projectItem.position" class="ml-2">{{ projectItem.position }}</span>
                </div>
                <div v-if="projectItem.description" class="color-a5a5a5 fs-14 mt-2 pre-line" :class="isEdit ? 'hide-txt-2' : ''" v-html="projectItem.description"></div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Prop} from 'vue-property-decorator';
import Vue from '@ncfe/nc.vue';
import {IconMobileRight} from '@ncfe/nc.icon.h5';

@Component({components: {IconMobileRight}})
export default class ProjectExpItem extends Vue {
    @Prop(Array) item?: any | [];
    @Prop(Boolean) isEdit?: boolean;
    @Prop(Boolean) isPreview?: boolean;
}
</script>

<style lang="scss" scoped>
.project-item {
    padding-bottom: 16px;
    & + .project-item {
        border-top: 1px solid #f6f6f6;
    }
    &:last-child {
        padding-bottom: 0;
    }
}
</style>
