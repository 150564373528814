// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 *46
 *
 * @export 投递简历
 * @param {*} oData
 * @param industry_var 行业
 * @param company_var 公司
 * @param positionID_var 职位ID
 * @param positionLevel1_var 职位一级分类
 * @param positionLevel2_var 职位二级分类
 * @param positionLevel3_var 职位三级分类
 * @param position_var 职位
 * @param city_var 城市
 * @param academicRequire_var 学历要求
 * @param workYears_var 工作年限
 * @param fasterEnterTime_var 最快入职时间
 * @param currentLocation_var 目前所在地
 * @param volunteerOrder_var 志愿选择
 * @param volunteerCity_var 意向城市
 * @param salary_var 职位薪资
 * @param feedback_var 反馈时长
 * @param positionType_var 职位类型
 * @param resumeType_var 职位发布渠道
 * @param positionChannel_var 简历类型
 * @param candidateid_var 候选人id
 */
function deliveredResume(oData) {
    gio('deliveredResume', oData);
}

/**
 *
 *
 * @export 进入完善简历页 点击完善简历提示触发
 * @param {*} oData
 * @param completeResumeEnter_var 在线简历完善来源
 */
function enterImproveResume(oData) {
    gio('enterImproveResume', oData);
}

/**
 *
 *
 * @export 成功完善简历 通过求职页提示成功完善简历时触发
 * @param {*} oData
 * @param completeResumeEnter_var 在线简历完善来源
 */
function completeResumesuccess(oData) {
    gio('completeResumesuccess', oData);
}
/**
 * @export 简历删除
 */
function deleteResume() {
    return gio('deleteResume');
}
/**
 * @export 简历预览
 */
function previewResume() {
    return gio('previewResume');
}
/**
 * @export 上传在线简历
 * @param {String} userid_var 用户id
 */
function newOnlineResume(oParams) {
    return gio('newOnlineResume', {...oParams});
}
/**
 * @export 上传附件简历
 */
function newPdfResume() {
    return gio('newPdfResume');
}
/**
 * @export 模块未填写报错
 * @param {String} module_var 模块
 */
function completeResumeError(oParams) {
    return gio('completeResumeError', {...oParams});
}
/**
 * @export 进入简历编辑页 页面曝光时触发
 */
function resumeEditPage() {
    return gio('resumeEditPage');
}

/**
 * @export 访问H5投递简历编辑页
 */
function tdjlbjyH5Visit() {
    return gio('tdjlbjyH5Visit');
}

export default {
    deliveredResume,
    enterImproveResume,
    completeResumesuccess,
    deleteResume,
    completeResumeError,
    previewResume,
    resumeEditPage,
    newOnlineResume,
    newPdfResume,
    tdjlbjyH5Visit
};
