// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 * @export 截图示例点击
 * @param {*} oData
 * @param activityName_var 活动名称
 */
function pictureDemoClick(oData) {
    gio('pictureDemoClick', oData);
}
/**
 * @export 上传截图点击
 * @param {*} oData
 * @param activityName_var 活动名称
 * @param UniqueView_var 浏览用户
 */
function pictureUpload(oData) {
    gio('pictureUpload', oData);
}
/**
 * @export 上传截图成功
 * @param {*} oData
 * @param activityName_var 活动名称
 */
function pictureUploadSuccess(oData) {
    gio('pictureUploadSuccess', oData);
}
export default {
    pictureDemoClick,
    pictureUpload,
    pictureUploadSuccess
};
