<template>
    <div class="color-a5a5a5 fs-14 mt-2 flex-row-start" @click="$emit('clickPushEdit', 1)">
        <span class="flex-auto fs-14 pre-line" :class="isEdit ? 'hide-txt-2' : ''" v-html="item"></span>
        <IconMobileRight v-if="isEdit" class="color-d8d8d8 fs-14 ml-4"></IconMobileRight>
    </div>
</template>

<script lang="ts">
import {Component, Prop} from 'vue-property-decorator';
import Vue from '@ncfe/nc.vue';
import {IconMobileRight} from '@ncfe/nc.icon.h5';

@Component({components: {IconMobileRight}})
export default class CommonItem extends Vue {
    @Prop(String) item?: string;
    @Prop(Boolean) isEdit?: boolean;
}
</script>
