// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 *
 * @export 活动页进入完善简历页
 * @param {*} oData
 */
function improveResume() {
    gio('improveResume');
}

/**
 *
 * @export 活动页成功完善简历
 * @param {*} oData
 * @param improveResumesuccess_var 用户UID
 */
function improveResumesuccess(oData) {
    gio('improveResumesuccess', oData);
}

export default {
    improveResume,
    improveResumesuccess
};
