const Ajax = require('./ajax');

module.exports = {
    url: {
        quitCircle: '/nccommon/circle/quit',
        applyCircle: '/nccommon/circle/apply',
        createCircle: '/circle/create',
        exploreCircle: '/nccommon/circle/center/data',
        kickMember: '/circle/kick-out',
        operate: '/circle/operate',
        getCircleInfo: '/nccommon/circle/info',
        getCircleList: '/nccommon/circle/circle-list',
        getCircleMembers: '/nccommon/circle/members',
        getTopFeed: '/nccommon/circle/top-feed',
        stickyFeed: '/nccommon/circle/set/top-feed',
        syncFeed: '/nccommon/circle/set/to-feed',

        createMoment: '/nccommon/moment/create',
        deleteMoment: '/nccommon/moment/delete',
        createShare: '/nccommon/share/create',
        deleteShare: '/nccommon/share/delete'
    }
};

Ajax.add(module.exports, module.exports.url);
