import { render, staticRenderFns } from "./PopupApp.vue?vue&type=template&id=d6164be2&scoped=true&"
import script from "./PopupApp.vue?vue&type=script&lang=js&"
export * from "./PopupApp.vue?vue&type=script&lang=js&"
import style0 from "./PopupApp.vue?vue&type=style&index=0&id=d6164be2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6164be2",
  null
  
)

export default component.exports