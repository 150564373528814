// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 *
 * @export 分享按钮点击
 * @param {*} oData
 * @param activityName_var 活动名称
 * @param UniqueView_var 浏览用户
 */
function buttonShareClick(oData) {
    gio('buttonShareClick', oData);
}
/**
 * @export 查看按钮点击
 * @param {*} oData
 * @awardName_var 奖品名称
 */
function buttonCheckClick(oData) {
    gio('buttonCheckClick', oData);
}
/**
 * @export 弹窗浏览
 * @activityName_var 活动名称
 * @pageTime_var 页面时长（秒）
 */
function popupView(oData) {
    gio('popupView', oData);
}
/**
 * @export 复制按钮点击
 * @param {*} oData
 * @awardName_var 奖品名称
 */
function buttonCopyClick(oData) {
    gio('buttonCopyClick', oData);
}
/**
 * @export 进入课程按钮点击
 * @param {*} oData
 * @awardName_var 奖品名称
 */
function buttonEntercourseClick(oData) {
    gio('buttonEntercourseClick', oData);
}
/**
 * @export 下载按钮点击 活动页面下载按钮点击 如果是正常的业务代码使用ClickDownload
 * @param {*} oData
 * @activityName_var 活动名称
 * @pageType_var 页面类型
 */
function buttonDownloadClick(oData) {
    gio('buttonDownloadClick', oData);
}
/**
 * @export 打开按钮点击
 * @param {*} oData
 * @activityName_var 活动名称
 */
function buttonOpenClick(oData) {
    gio('buttonOpenClick', oData);
}
/**
 * @export 跳转APP
 * @param {*} oData
 * @activityName_var 活动名称
 * @pageType_var 页面类型
 */
function jump(oData) {
    gio('jump', oData);
}
export default {
    buttonShareClick,
    buttonCheckClick,
    popupView,
    buttonCopyClick,
    buttonEntercourseClick,
    buttonDownloadClick,
    buttonOpenClick,
    jump
};
