<template>
    <div>
        <div v-for="(jobItem, index) in item" :key="index" :class="[isPreview ? 'resume-preview-item' : 'work-ite']" @click="$emit('clickPushEdit', index + 1)">
            <template v-if="isPreview">
                <div class="flex-row-start mt-5">
                    <span class="flex-auto fw-500 fs-14">{{ jobItem.company }}</span>
                    <span v-if="jobItem.start && jobItem.end" class="color-a5a5a5">{{ jobItem.start }}-{{ jobItem.end === '-1' ? '至今' : jobItem.end }}</span>
                </div>
                <div class="color-a5a5a5 fs-14 mt-1">
                    <span v-if="jobItem.job">{{ jobItem.job }}</span>
                </div>
                <div v-if="jobItem.description" class="color-a5a5a5 fs-14 mt-1 mr-4 pre-line" v-html="jobItem.description"></div>
            </template>
            <template v-else>
                <div class="flex-row-start pt-4">
                    <span class="flex-auto fw-500 fs-14">{{ jobItem.company }}</span>
                    <IconMobileRight v-if="isEdit" class="color-d8d8d8 fs-14"></IconMobileRight>
                </div>
                <div class="color-a5a5a5 fs-14 mt-2">
                    <span v-if="jobItem.start && jobItem.end">{{ jobItem.start }}-{{ jobItem.end === '-1' ? '至今' : jobItem.end }}</span>
                    <span v-if="jobItem.job" class="ml-2">{{ jobItem.job }}</span>
                </div>
                <div v-if="jobItem.description" class="color-a5a5a5 fs-14 mt-2 pre-line" :class="isEdit ? 'hide-txt-2' : ''" v-html="jobItem.description"></div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Prop} from 'vue-property-decorator';
import Vue from '@ncfe/nc.vue';
import {IconMobileRight} from '@ncfe/nc.icon.h5';

@Component({components: {IconMobileRight}})
export default class JobIntentionExpItem extends Vue {
    @Prop(Array) item?: any | [];
    @Prop(Boolean) isEdit?: boolean;
    @Prop(Boolean) isPreview?: boolean;
}
</script>
<style lang="scss" scoped>
.work-item {
    padding-bottom: 16px;
    & + .work-item {
        border-top: 1px solid #f6f6f6;
    }
    &:last-child {
        padding-bottom: 0;
    }
}
</style>
