<template>
    <popup v-model="show" width="75%" height="100%" position="left">
        <a class="p-2" :href="$ncIsLogin ? profileUrl : '/login'" style="display: block">
            <avatar v-if="$ncUser.ownerTinyHead" size="l" :normal="$ncIsLogin" :decorateSrc="$ncUser.headDecorateUrl" :src="$ncUser.ownerTinyHead"></avatar>
            <div v-else class="nologin"></div>
            <nick-name :id="$ncUser.ownerId" :div="true" :level="+$ncUser.honorLevel" :identity="$ncUser.identity" :name="$ncIsLogin ? $ncUser.ownerName : '请登录后查看'"></nick-name>
        </a>
        <group :title="title" gutter="0">
            <cell title="首页" is-link link="/"><i slot="icon" class="icon-home mr-1"></i></cell>
            <cell title="知识点专项练习" is-link link="/itest/menu"><i slot="icon" class="icon-exam mr-1"></i></cell>
            <cell v-if="false" title="笔试真题查看" is-link link="/question/menu"><i slot="icon" class="icon-look mr-1"></i></cell>
            <cell title="公司真题套题练习" is-link link="/paper/menu"><i slot="icon" class="icon-text mr-1"></i></cell>
            <cell title="计算机期末备考专场" is-link link="/review"><i slot="icon" class="icon-collect mr-1"></i></cell>
            <cell title="讨论区" is-link link="/discuss"><i slot="icon" class="icon-comment mr-1"></i></cell>
            <cell title="我的练习" is-link link="/profile/my-tests"><i slot="icon" class="icon-right mr-1"></i></cell>
        </group>
        <group>
            <cell v-if="$ncIsLogin" title="设置" is-link :link="settingUrl"><i slot="icon" class="icon-setting mr-1"></i></cell>
            <cell v-else title="请登录后设置" is-link link="/login"><i slot="icon" class="icon-setting mr-1"></i></cell>
        </group>
    </popup>
</template>

<script>
import Avatar from '@nc/vue.avatar';
import NickName from '@nc/vue.nickname';
import {Popup, Group, Cell} from '@ncfe/nc.vux';

export default {
    props: ['title', 'data'],
    components: {Popup, Group, Cell, Avatar, NickName},
    data: fData
};

function fData() {
    let that = this;
    let oUser = that.$ncUser;
    return {
        show: false,
        profileUrl: `/profile-v2/${oUser.ownerId}`,
        settingUrl: `/profile/${oUser.ownerId}/setting`
    };
}
</script>

<style scoped>
.nologin {
    width: 48px;
    height: 48px;
}
</style>
