var Ajax = require('./ajax');

var oModule = (module.exports = {
    url: {
        activeMobile: '/mobile/active',
        sendMobileCode: '/mobile/sendCode',
        sendMobileCodeV2: '/mobile/sendCodeV2',
        checkPhone: '/mobile/checkPhone',
        login: '/login/do',
        registerPhone: '/register/do',
        setNewPwd: '/set-new-pwd',
        activePhoneOauth: '/register/active-phone-oauth',
        bridge: '/bridge-to-pc',
        logout: '/logout/do',
        sendEmailCode: '/email/send-code',
        checkEmail: '/email/check-email',
        checkLoginOtherPlace: '/token/login-other-place'
    }
});
Ajax.add(module.exports, oModule.url, oModule.domain);
