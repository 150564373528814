<template>
    <div class="mask-browser" @touchmove.prevent>
        <img src="//uploadfiles.nowcoder.com/images/20190211/56_1549892148348_190DD17BA51C5B29E0816EA014765F81" />
    </div>
</template>
<script>
var $ = require('@ncfe/nc.jquery');
export default {
    mounted: fMounted
};

function fMounted() {
    var that = this;
    $(document.body).append(that.$el);
    $(that.$el).on('click', () => {
        that.$emit('update:isShowMask', false);
    });
}
</script>
<style scoped lang="scss">
.mask-browser {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.75);
    img {
        display: block;
        max-width: 100%;
        position: absolute;
        top: 0;
        right: 0;
    }
}
</style>
