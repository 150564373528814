<template>
    <div class="ncmain" :class="[$ncInNowcoderApp || navHide || $ncInWxMiniApp ? 'appmain' : !navNoSplit ? 'appwithsplit' : '', useSai ? 'sai-pb' : '', adapterPC ? 'page-adapter-pc' : '']">
        <div class="main-nav" :class="theme">
            <div class="ncnav flex-row plr-2 vux-1px-b" :class="theme === 'dark' ? 'bg-nav' : 'bg-white'">
                <span>
                    <slot name="navLeft"></slot>
                </span>
                <span class="hide-txt mlr-1">
                    <slot name="navTitle"></slot>
                </span>
                <span>
                    <slot name="navRight"></slot>
                </span>
            </div>
        </div>
        <div v-if="$ncInNowcoderApp && needNav" :class="theme">
            <div class="ncnav flex-row plr-2 vux-1px-b" :class="theme === 'dark' ? 'bg-nav' : 'bg-white'">
                <span v-if="false">
                    <slot name="navLeft"></slot>
                </span>
                <span class="hide-txt mlr-1">
                    <slot name="navTitle"></slot>
                </span>
                <span>
                    <slot name="navRight"></slot>
                </span>
            </div>
        </div>
        <div class="main-container">
            <None v-if="none"></None>
            <None v-else-if="error">{{ error }}</None>
            <slot v-else name="content"></slot>
        </div>
    </div>
</template>
<script>
import None from '@nc/vue.none';
export default {
    props: ['error', 'navHide', 'needNav', 'navNoSplit', 'none', 'theme', 'useSai', 'adapterPC'],
    components: {None},
    methods: {}
};
</script>
<style lang="scss">
.ncmain {
    padding-top: 44px;

    &.appwithsplit {
        padding-top: 54px;
    }

    .main-nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;

        &.dark {
            color: #ffffff;
        }
    }

    .main-container {
        margin-top: -1px;
    }

    .ncnav {
        height: 44px;
        overflow: hidden;
        justify-content: space-between;
    }
}

.ncmain.appmain {
    padding-top: 0;

    .main-nav {
        display: none;
    }
}
</style>
