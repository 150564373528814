var Ajax = require('./ajax');
module.exports = {
    url: {
        subjectInfo: '/nccommon/subject/subject-info',
        updateHeadImg: '/saveHead-v2',
        setTopFeed: '/nccommon/subject/set-top-feed',
        unsetTopFeed: '/nccommon/subject/unset-top-feed',
        recommendAiCompany: '/nccommon/subject/recommend-ai-company',
        simHotSubjects: '/nccommon/subject/sim-hot-subjects'
    }
};
Ajax.add(module.exports, module.exports.url);
