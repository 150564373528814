import {create, InterceptType, Source, NCAxiosRequestConfig} from '@ncfe/nc.axios';

const instanceGW = create(Source.GATEWAY_C);

const gwDomainMap = {
    dev: 'https://gateway-dev.nowcoder.com',
    pre: 'https://gateway-pre.nowcoder.com',
    prod: 'https://gw-c.nowcoder.com'
};

// 请求前的拦截处理
instanceGW.intercept(InterceptType.REQUEST, async (config: NCAxiosRequestConfig) => {
    let env = 'prod';
    if (window.location.host.includes('md')) {
        env = 'dev';
    } else if (window.location.host.includes('pre')) {
        env = 'pre';
    }
    const gwDomain = gwDomainMap[env];
    config.baseURL = gwDomain;
    return config;
});

export const addOpinionBack = instanceGW.post<any, any>('/api/sparta/opinionBack/addOpinionBack');
export const getOpinionList = instanceGW.get<any, any>('/api/sparta/opinionBack/get-opinion-list');
// 举报相关
export const getReportContentList = instanceGW.get<any, any>('/api/sparta/report/content-list');

export const getTestInfo = instanceGW.get<any, any>('/api/sparta/test/summary');

export const getTagDetailInfoAjax = instanceGW.get<any, any>('/api/sparta/intelligent/queryTagDetailInfo');

// 知识点 获取组卷信息
export const commonPracticeMakePaperAjax = instanceGW.post<any, any>('/api/sparta/common-practice/request-make-paper-pc');
