var Ajax = require('./ajax');
module.exports = {
    url: {
        getCalendarList: '/school/calendar/list-by-year',
        getMonthView: '/school/calendar/list-by-date',
        getDetail: '/school/calendar/#{detailId}',
        attend: '/school/calendar/attend',
        addItem: '/school/calendar/new',
        updateAlarm: '/school/calendar/alarm/update',
        getAlarm: '/school/calendar/alarm',
        deleteEvent: '/school/calendar/del',
        editEvent: '/school/calendar/edit'
    }
};
Ajax.add(module.exports, module.exports.url);
