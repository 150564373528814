<template>
    <div class="btn-open-in-app">
        <div v-if="appid && !diversionWxAB" class="wx-open-launch-app">
            <wx-open-launch-app id="launch-app" :appid="appid" :extinfo="href" @error="launchError" @lanch="launchSuccess" @click="actionClick">
                <script type="text/wxtag-template">
                    <style>
                        .mask{
                            width: {{width}};
                            height: {{height}};
                        }
                    </style>
                    <div class="mask"></div>
                </script>
            </wx-open-launch-app>
            <div id="wrap" ref="wrap">
                <slot></slot>
            </div>
        </div>
        <div v-else id="wrap">
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import $ from '@ncfe/nc.jquery';
import WeiXin from '@/js/lib/util/weixin';
import App from '@/js/lib/util/app';
import {mapState} from '@ncfe/nc.vuex';

const VueExtend = Vue.extend({
    computed: {
        ...mapState({
            diversionWxAB: 'diversionWxAB'
        })
    }
});

@Component({})
export default class BtnOpenInApp extends VueExtend {
    @Prop({type: Function, default: () => {}}) launchSuccess;
    @Prop({type: Function, default: () => {}}) actionClick;
    // href 属性的地址目前只支持完整的地址信息，需要携带 https://m.nowcoder.com
    @Prop({type: String, default: window.location.href}) href;
    @Prop({type: String}) fromPut;
    appid: boolean | string = '';
    width = '';
    height = '';

    async mounted() {
        this.appid = await WeiXin.isUseWxCmp();
        this.$nextTick(() => {
            const wrap: any = this.$refs.wrap;
            this.width = `${$(wrap).width()}px`;
            this.height = `${$(wrap).height()}px`;
        });
    }

    launchError() {
        App.openInApp({href: this.href, formPut: this.fromPut});
    }
}
</script>

<style lang="scss" scoped>
.btn-open-in-app {
    .wx-open-launch-app {
        position: relative;
        width: 100%;
        overflow: hidden;
        #launch-app {
            position: absolute;
            width: 100%;
            z-index: 99;
        }
    }
    #wrap {
        display: inline-block;
    }
}
</style>
