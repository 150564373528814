<template>
    <div class="flex-row" style="align-items: flex-start" @click="$emit('actionClick')">
        <Avatar :id="item.authorId" :decorateSrc="item.headDecorateUrl" :src="item.headImg" style="flex: none"></Avatar>
        <div class="ml-3" style="flex: auto; overflow: hidden">
            <div class="flex-row mb-1" style="align-items: flex-start">
                <div style="flex: auto; overflow: hidden">
                    <NickName :id="item.authorId" :div="true" :level="item.honorLevel" :identity="item.identity" :name="item.authorName" :tag="lordId && lordId === item.authorId ? (lordType === 'author' ? '作者' : '楼主') : ''" style="max-width: 100%"></NickName>
                    <div>
                        <span class="color-gray fs-s">{{ createTime }}</span>
                    </div>
                </div>
                <div class="ml-3 fs-s color-gray" style="flex: none">
                    <span class="ml-2" @click.stop="$emit('actionLike')">
                        <i v-if="item.isLiked" class="icon-thumb-full mr-1 color-green"></i>
                        <i v-else class="icon-thumb mr-1"></i>
                        {{ item.likes }}
                    </span>
                </div>
            </div>
            <div>
                <span v-if="hasReply">
                    回复
                    <NickName :id="item.toUserId" :level="item.toUserHonorLevel" :identity="item.identity" :name="item.toUserName" :tag="lordId && lordId === item.authorId ? (lordType === 'author' ? '作者' : '楼主') : ''"></NickName>：
                </span>
                <span v-html="html"></span>
            </div>
        </div>
    </div>
</template>

<script>
import NickName from '@nc/vue.nickname';
import Avatar from '@nc/vue.avatar';
var Base = require('@ncfe/nc.base');
var Time = require('@ncfe/nc.time');
var Html = require('@ncfe/nc.html');
export default {
    props: ['item', 'replyId', 'lordId', 'lordType'],
    components: {NickName, Avatar},
    computed: {
        hasReply: fHasReply,
        createTime: fCreateTime,
        html: fHtml
    }
};

function fHasReply() {
    var that = this;
    var oItem = that.item;
    return oItem.toUserId && Base.id(oItem.toCommentId) !== Base.id(that.replyId);
}

function fCreateTime() {
    var that = this;
    return Time.relate(new Date(that.item.createTime));
}

function fHtml() {
    var that = this;
    var sContent = Html.replaceLink(that.item.content);
    sContent = Html.txt2html(sContent);
    return sContent;
}
</script>
