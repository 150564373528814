// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 *  大搜页搜索结果用户反馈
 * @param {String} oParams.isSearchResult_var 是否有搜索结果 是/否
 * @param {String} oParams.searchWord_var 用户的搜索词
 * @param {String} oParams.searchType_var 全部、帖子、文章、试题、公司、牛友、职位
 * @param {String} oParams.problemType_var 搜索结果问题类型字符串
 * @param {String} oParams.problemDescription_var 用户填写的反馈内容
 */
function searchFeedback(oParams) {
    return gio('searchFeedback', oParams);
}

export default {
    searchFeedback
};
