var Dom = require('@ncfe/nc.dom');

export default {
    data() {
        return {
            isFetching: false,
            isComplete: false
        };
    },
    methods: {
        waterfall: fWaterfall,
        waterfallRefresh: fWaterfallRefresh,
        waterfallCancel: fWaterfallCancel
    },
    mounted() {
        this.waterfall();
    },
    destroyed() {
        this.waterfallCancel();
    },
    beforeRouteUpdate(to, from, next) {
        this.waterfall();
        next();
    },
    beforeRouteLeave(to, from, next) {
        this.waterfallCancel();
        next();
    }
};

function fWaterfall() {
    var that = this;
    that.waterfallCancel();
    that._cancelWaterfallCb = Dom.scrollBottom({
        left: 150,
        call() {
            if (that.isFetching) {
                return;
            }
            // 完成之后，直接取消监听滚动
            if (that.isComplete) {
                that.waterfallCancel();
                return;
            }
            that.isFetching = true;
            that.fetch &&
                that.fetch(
                    bIsComplete => {
                        that.isFetching = false;
                        that.isComplete = !!bIsComplete;
                    },
                    () => {
                        that.isFetching = false;
                        that.isComplete = false;
                    }
                );
        }
    });
}

function fWaterfallRefresh() {
    var that = this;
    that.isFetching = false;
    that.isComplete = false;
    that.waterfall();
}

function fWaterfallCancel() {
    var that = this;
    that._cancelWaterfallCb && that._cancelWaterfallCb();
}
