/**
 * arms 监控
 * 文档：https://nowcoder.yuque.com/docs/share/1ae66e9d-3b1f-4e33-b4b7-468b5561df3e?#
 * 平台：https://arms.console.aliyun.com/retcode
 */
import arms from '@ncfe/sdk.arms';
import {getEnv} from '@/js/lib/util/util';
import Browser from '@ncfe/nc.browser';

declare const window: Window & {
    globalInfo: any;
};

/**
 * window 环境是在打包后的代码才注入
 * 所以在本地开发中是不存在的。
 */
const environmentMap = {
    test: 'daily',
    pre: 'daily',
    prod: 'prod'
} as const;
const _NC_ENV_ = getEnv();
arms({
    pid: 'j78mgac4op@dec1d900c3ddb2f', // 应用id，需要跟arms平台对应上。
    environment: environmentMap[_NC_ENV_!] || _NC_ENV_ || 'prod', // 对应的环境
    uid: window?.globalInfo?.ownerId, // 如果登录状态，可以传userId
    setUsername: () => {
        return window?.globalInfo?.ownerName; // 用户名
    },
    useWebVitals: true,
    release: Browser.version(), // 版本号，就是每次部署都不一样的hash值或者是版本。如果能拿到就传，不能就不传。
    ignore: {
        ignoreApis: ['^nowcoder.cn-hangzhou', 'api.nowcoder.com/v1/common/system-timestamp', 'api.nowcoder.com/v1/ent/interviews/**/updateInterviewInfo'] // 忽略这些api
    },
    dev: _NC_ENV_ !== 'local' // 本地环境不上报
});
