const Ajax = require('./ajax');

module.exports = {
    domain: 'feed.nowcoder.com',
    devDomain: 'dfeed.nowcoder.com',
    withCredentials: true,
    url: {
        refresh: '/feed/refresh',
        getMore: '/feed/more',
        hasMore: '/feed/has-more',
        getLinkDetail: '/moment/outer-link',
        getFeedDetail: '/feed/detail',
        // getFeedDetail: '/feed/terminal/detail/v2',
        getTimeLine: '/feed/timeline',
        shieldFeed: '/feed/shield',
        deleteFeed: '/feed/delete',
        refreshCircle: '/feed/circle/refresh',
        getMoreCircle: '/feed/circle/more',
        hasMoreCircle: '/feed/circle/has-more',
        getHotMoments: '/feed/hot-moments',

        subjectRefresh: '/feed/subject/refresh',
        subjectMore: '/feed/subject/more'
    }
};

Ajax.add(module.exports, module.exports.url);
