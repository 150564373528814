<template>
    <div>
        <Popup v-model="showPoup" :position="position" transition-appear :safe-area-inset-bottom="true">
            <Picker ref="picker" :columns="columns" show-toolbar :confirm-button-text="confirmTxt" :cancel-button-text="cancelTxt" @cancel="actionCancel" @confirm="actionConfirm"></Picker>
        </Popup>
    </div>
</template>

<script>
import Popup from 'vant/lib/popup';
import Picker from 'vant/lib/picker';
import 'vant/lib/popup/style';
import 'vant/lib/picker/style';

export default {
    name: 'PopupTimePicker',
    components: {Popup, Picker},
    data: fData,
    props: {
        value: {
            type: Array
        },
        show: {
            type: Boolean
        },
        cancelTxt: {
            type: String,
            default: '取消'
        },
        title: {
            type: String,
            default: ''
        },
        confirmTxt: {
            type: String,
            default: '确定'
        },
        position: {
            type: String,
            default: 'bottom'
        },
        minYear: {
            type: Number,
            default: new Date().getFullYear() - 40
        },
        maxYear: {
            type: Number,
            default: new Date().getFullYear() + 10
        },
        custom: {
            type: String,
            default: '至今'
        }
    },
    created: fCreated,
    mounted: fMounted,
    methods: {
        actionCancel: fActionCancel,
        actionConfirm: fActionConfirm
    },
    computed: {
        columns: fColumns,
        columnsIndex: fColumnsIndex
    },
    watch: {
        show: {
            handler: fShow,
            immediate: true
        }
    }
};
function fData() {
    return {
        showPoup: false,
        pickerValue: []
    };
}

function fCreated() {
    if (this.minYear >= this.maxYear) console.error('年份传入错误');
}

function fMounted() {
    setTimeout(() => {
        this.$refs.picker.setIndexes(this.columnsIndex);
    }, 10);
}
function fShow(val) {
    this.showPoup = this.show;
}

function fActionCancel() {
    this.showPoup = false;
    this.$emit('cancel');
}

function fActionConfirm(val) {
    if (val[0] === this.custom) {
        this.$emit('confirm', {value: [this.custom]});
    } else {
        this.$emit('confirm', {value: val});
    }

    this.showPoup = false;
}

function fColumns() {
    let columns = [];
    for (let i = this.minYear; i <= this.maxYear; i++) {
        let options = {
            text: i,
            children: Array.from(Array(12), (v, k) => {
                return {
                    text: k + 1
                };
            })
        };

        columns.push(options);
    }
    this.custom &&
        columns.push({
            text: this.custom,
            children: [{text: ''}]
        });
    return columns;
}

function fColumnsIndex() {
    if (!this.value) {
        let index1 = 0;
        this.columns.forEach((item, i) => {
            if (item.text === new Date().getFullYear()) {
                index1 = i;
            }
        });
        return [index1, 0];
    } else if (this.value.length === 2) {
        let index1 = 0;
        let index2 = 0;
        this.columns.forEach((year, yearIndex) => {
            if (year.text === +this.value[0]) {
                index1 = yearIndex;
                index2 = +this.value[1] - 1;
            }
        });

        return [index1, index2];
    } else if (this.value.length === 1) {
        let index1 = 0;
        this.columns.forEach((year, yearIndex) => {
            if (year.text === this.value[0]) {
                index1 = yearIndex;
            }
        });
        return [index1, 0];
    }
}
</script>

<style></style>
