<template>
    <x-dialog class="resume-dialog" :dialog-style="{'border-radius': '10px'}" :show="show">
        <div v-if="type === 'new'">
            <p class="receive-success ptb-2">恭喜你领取成功</p>
            <p class="default-text plr-3 pb-1">我们已经将免费点评券发放至你的牛客APP内 赶快去下载使用吧</p>
            <p class="valid-date pb-2">*免费点评券有效期 3 天</p>
            <div class="plr-2 pb-4">
                <button class="cancel" @click="$emit('cancel')">取消</button>
                <button class="use" @click="actionUse">使用</button>
            </div>
        </div>
        <div v-if="type === 'old'">
            <p class="default-text plr-6 pt-2 pb-1">
                您并非新用户，无法直接获取免费点评券 邀请
                <span style="color: #32ca99"> 1 位</span>
                好友助力即可获得免费点评券
            </p>
            <p class="receive-success pb-1">邀请码</p>
            <div class="invite-code mb-2" @click="actionCopy(inviteCode, false)">{{ inviteCode }}</div>
            <div class="plr-2 pb-4">
                <button class="use" @click="actionCopy(inviteCode, true)">复制并关闭</button>
            </div>
        </div>
        <div v-if="type === 'rule'">
            <p class="receive-success ptb-2">活动规则</p>
            <ul class="plr-4 pb-2">
                <li><span>每位牛客 APP 新用户仅限领取一张。</span></li>
                <li><span>牛客老用户可通过邀请好友报名的方式，获得免费点评券一张。</span></li>
                <li><span>简历免费点评券仅可本人使用，不得转让。</span></li>
                <li><span>禁止作弊等不良行为，活动最终解释权归牛客所有。</span></li>
            </ul>
            <div class="plr-2 pb-4">
                <button class="use" @click="$emit('cancel')">知道了</button>
            </div>
        </div>
        <div v-if="type === 'hint'">
            <p class="receive-success ptb-2">参与方式</p>
            <ul class="plr-4 pb-2">
                <li><span>1.点击下载牛客网APP</span></li>
                <li><span>2.进入活动详情页面申请简历点评机会。</span></li>
            </ul>
            <div class="plr-2 pb-4">
                <button class="cancel hint" @click="$emit('cancel')">取消</button>
                <button class="use hint" @click="actionDownLoadApp">下载App</button>
            </div>
        </div>
    </x-dialog>
</template>

<script lang="ts">
import {Vue, Component, Prop, Emit} from 'vue-property-decorator';
import {XDialog, XIcon} from '@ncfe/nc.vux';
import {GioResumeComment} from '@/gio';
import App from '@/js/lib/util/app';
import Dom from '@ncfe/nc.dom';

@Component({
    components: {XDialog, XIcon}
})
export default class ResumeDialog extends Vue {
    @Prop(Boolean) show;
    @Prop(String) type;
    @Prop(String) inviteCode;
    @Prop(Object) config;

    @Emit('copy')
    actionCopy(content, close) {
        Dom.copyContent(content, bVal => {
            this.$vux.toast.text(bVal ? '复制成功' : '复制失败', 'middle');
        });
        return close;
    }

    actionUse() {
        if (App.isHybridWebview) {
            window.location.href = `https://mnowpick.nowcoder.com/m/resume/evaluation-resume/index`;
            return;
        }
        GioResumeComment.appDownload({
            pageName_var: this.config.pageName
        });
        window.location.href = 'https://m.nowcoder.com/app?from=resume_invite&url=https://mnowpick.nowcoder.com/m/resume/evaluation-resume/index';
    }

    actionDownLoadApp() {
        this.$emit('actionDownLoadApp');
        this.$emit('cancel');
        window.location.href = 'https://m.nowcoder.com/app?from=resume_invite&url=https://www.nowcoder.com/discuss/798721';
    }
}
</script>

<style lang="scss" scoped>
.resume-dialog {
    .weui-dialog {
        border-radius: 10px;
    }
    .default-text {
        font-size: 14px;
        color: #333333;
    }
    .receive-success {
        font-size: 16px;
        font-weight: bolder;
        color: #333333;
    }
    .valid-date {
        font-size: 12px;
        color: rgba(50, 202, 153, 0.5);
    }
    div {
        .cancel {
            margin-bottom: 5px;
            padding: 5px 43px;
            font-size: 14px;
            color: #32ca99;
            border-radius: 18px;
            border: 1px solid #32ca99;
            background-color: #fff;
        }
        .use {
            padding: 5px 43px;
            font-size: 14px;
            color: #fff;
            background-image: linear-gradient(225deg, #48f1a8 0%, #32ca99 100%);
            border-radius: 18px;
            border: none;
        }
    }
    .invite-code {
        margin: auto 105px;
        background: #f7f8f9;
        border-radius: 2px;
        color: #32ca99;
    }
    ul > li {
        text-align: left;
        display: table;
        span {
            display: table-cell;
        }
    }
    ul > li::before {
        margin-right: 6px;
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 4px;
        background-image: linear-gradient(150deg, #5bb89d 0%, #78e09b 100%);
        border: 1px solid #e0fef7;
    }
    div button.hint {
        width: 116px;
        height: 33px;
        padding: 0;
    }
}
</style>
