let $ = require('@ncfe/nc.jquery');
let Base = require('@ncfe/nc.base');
let Str = require('@ncfe/nc.str');
let Uri = require('@ncfe/nc.uri');
let Util = require('../util/util');
var PromiseUtil = require('@ncfe/nc.promise');

module.exports = {
    request: fRequest,
    requestCb: fRequestCb,
    add: fAdd
};

function fRequest(oParam) {
    var that = this;
    return PromiseUtil.c2p(oParam => that.requestCb(oParam))(oParam);
}

function fRequestCb(oParam = {}) {
    let that = this;
    let sUrl = oParam.url;
    oParam.params && (sUrl = Str.execTpl(sUrl, oParam.params));
    oParam.query && (sUrl = Uri.addParam(oParam.query, sUrl));

    let headers = oParam.headers || {};
    if (oParam.isJson) {
        headers['Content-Type'] = 'application/json';
    }
    // 发送请求
    return $.ajax(sUrl, {
        type: oParam.type || (oParam.query && !oParam.body ? 'GET' : 'POST'),
        cache: !!oParam.cache,
        timeout: oParam.timeout,
        data: oParam.headers ? oParam.body : Uri.getQuery(oParam.body),
        xhrFields: oParam.xhrFields,
        dataType: oParam.dataType || 'text',
        headers: oParam.headers || {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
        error: function (oXhr, sStatus, sErr) {
            console.log('Ajax请求发送失败' + sErr);
        },
        beforeSend: oParam.beforeSend,
        complete: oParam.complete
    }).then(
        function (sResult, sStatus, oXhr) {
            let oResult = {};
            if (oResult && typeof sResult === 'object') {
                oResult = sResult;
            } else {
                sResult = window.decodeURIComponent(window.encodeURIComponent(sResult || '').replace(/(%1A)/g, '')).replace(/\r\n/g, '\n');
                oResult = Base.json(sResult);
            }

            if (!oResult) {
                console.log('Ajax 数据解析失败: ' + sResult);
            }

            oResult = oResult || {};
            let nCode = oResult.code;
            let sMsg = oResult.msg || '错误码: ' + (nCode || '无');
            if (nCode === 0) {
                oParam.call && oParam.call(oResult, oXhr);
                oParam.always && oParam.always();
                return;
            }

            if (nCode === 999) {
                oParam.always && oParam.always();
                window.location.href = '/login?callBack=' + window.encodeURIComponent(window.location.href);
                return;
            }
            oResult.isError = true;
            oResult.msg = oResult.msg || '网络异常，请稍后重试';
            oResult.message = oResult.message || oResult.msg;
            if (nCode === 1012) {
                let bSupport = Util.activePhone({
                    call: () => that.request(oParam),
                    error() {
                        oParam.error && oParam.error(oResult, oXhr);
                        oParam.always && oParam.always();
                    }
                });
                if (bSupport) return;
            }
            Base.out('ajax后端验证错误:' + sMsg);
            oParam.error && oParam.error(oResult, oXhr);
            oParam.always && oParam.always();
        },
        function (oXhr, sStatus, sErr) {
            let oResult = Base.json(oXhr.responseText, {});
            sErr = oResult.msg || sErr || '网络异常，请稍后重试';
            oParam.error &&
                oParam.error({
                    code: oResult.code,
                    msg: sErr,
                    message: sErr,
                    isError: true
                });
            oParam.always && oParam.always();
        },
        function () {}
    );
}

function fAdd(oExports, oUrl) {
    let that = this;
    let sCurrentEnv = Util.getCurrentEnv();
    let sDomain = oExports[sCurrentEnv === 'prem' ? 'preDomain' : sCurrentEnv === 'dev' ? 'devDomain' : 'domain'] || '';
    // 没有配置pre的域名，就默认用线上的
    sCurrentEnv === 'prem' && !sDomain && (sDomain = oExports.domain || '');
    Object.keys(oUrl || {}).forEach(sKey => {
        let sUrl = oUrl[sKey];
        if (!Base.isString(sUrl)) return;
        if (sDomain && sUrl.indexOf('http') !== 0) {
            // sDomain可能已https?开头
            sUrl = /^http?/gi.test(sDomain) ? `${sDomain}${sUrl}` : `//${sDomain}${sUrl}`;
        }
        oExports[sKey] = function (oParam) {
            oParam.url = sUrl;
            oParam.xhrFields = {
                withCredentials: oExports.withCredentials ? true : false
            };
            return that.request(oParam);
        };
        oExports[sKey + 'Url'] = function (oParam) {
            oParam.params && (sUrl = Str.execTpl(sUrl, oParam.params));
            oParam.query && (sUrl = Uri.addParam(oParam.query, sUrl));
            return sUrl;
        };
    });
}
