<template>
    <Popup v-model="isShow" popupClass="radius-top bg-white" position="bottom" height="300px">
        <div class="bg-white" style="height: 100%;">
            <i class="icon-close color-gray close-style p-2" @click="close"></i>
            <div class="txt-center strong fs-l pt-4">
                <span class="mr-1">牛币余额</span
                ><span class="color-green"
                    ><i class="icon-cow-coin"></i> <span v-if="coinData">{{ coinData ? coinData.nowcoderCoin : 0 }}</span></span
                >
            </div>
            <div class="pt-3 plr-1 flex-row-start" style="flex-wrap: wrap; justify-content: space-around;">
                <div v-for="(item, index) in nowcoderCoinGrader" :key="index" class="mt-2 ptb-1 flex-column coin-item-style" :class="{'bg-green': selectedIndex === index}" style="flex: 0 0 30%; align-items: center;" @click="actionCoinItemClick(index)">
                    <div>
                        <i class="icon-cow-coin mr-1" :class="selectedIndex === index ? 'color-white' : 'color-green'"></i><span :class="{'color-white': selectedIndex === index}">{{ item.coin }}</span>
                    </div>
                    <div class="mt-1 fs-s" :class="selectedIndex === index ? 'color-white' : 'color-gray'">{{ item.rmb }}元</div>
                </div>
            </div>
            <div class="flex-row mt-4">
                <div class="bg-green ptb-2 strong txt-center color-white button-style" style="width: 80%;" @click="actionPay">提交订单</div>
            </div>
        </div>
    </Popup>
</template>

<script>
import Popup, {PopupMix, PopupShow} from '@nc/vue.popup';
import Vue from '@ncfe/nc.vue';
const CoinAction = require('@/js/lib/action/coin');
const UserAction = require('@/js/lib/action/user');
const App = require('@/js/lib/util/app');

export default {
    show: PopupShow(Vue),

    props: ['paymentCallBack'],
    components: {Popup},
    mixins: [PopupMix],
    data: fData,
    mounted: fMounted,
    methods: {
        actionCoinItemClick: fActionCoinItemClick,
        actionPay: fActionPay
    }
};

function fData() {
    var that = this;
    return {
        coinData: null,
        selectedIndex: 0,
        nowcoderCoinGrader: [
            {rmb: 1, coin: 10},
            {rmb: 5, coin: 50},
            {rmb: 10, coin: 100},
            {rmb: 30, coin: 300},
            {rmb: 50, coin: 500},
            {rmb: 100, coin: 1000}
        ],
        isAndroid: App.isAndroid
    };
}

function fMounted() {
    let that = this;
    UserAction.getInfo({
        type: 'GET',
        query: {uid: window.globalInfo ? window.globalInfo.ownerId : undefined},
        call(oResult) {
            oResult.data &&
                oResult.data.userId &&
                CoinAction.getCoinData({
                    type: 'get',
                    params: {uid: oResult.data.userId},
                    call: oData => (that.coinData = oData.data),
                    error: oError => (that.error = oError.msg)
                });
        },
        error: oError => (that.error = oError.msg)
    });
}

function fActionCoinItemClick(nIndex) {
    let that = this;
    that.selectedIndex = nIndex;
}

function fActionPay() {
    let that = this;
    let itemId = that.nowcoderCoinGrader[that.selectedIndex].coin;
    let sBackUrl = window.encodeURIComponent(that.paymentCallBack || '');
    if (that.isAndroid) {
        window.open(`/order?itemId=${itemId}&itemType=NOWCODER_COIN&paymentCallBack=${sBackUrl}`);
    } else {
        window.location.href = `/order?itemId=${itemId}&itemType=NOWCODER_COIN&paymentCallBack=${sBackUrl}`;
    }
    // that.$vux.alert.show({ content: '支付成功' });
}
</script>

<style lang="scss" scoped>
.item-style {
    height: 115px;
}

.close-style {
    position: absolute;
    top: 0px;
    right: 0px;
}

.coin-item-style {
    border: 1px solid #dcdcdc;
    border-radius: 4px;
}

.button-style {
    border-radius: 6px;
}
</style>
