import {gio} from '@ncfe/gio.lib';

/**
 *
 * @export 打开App按钮点击  讨论区打开app埋点事件
 * @param {*} oData
 * @param clickText_var 点击位置文案
 */
function contentItemClickh5(oData) {
    return gio('track', 'contentItemClickh5', {
        ...oData,
        isLogin_var: `${typeof oData.isLogin_var === 'string' ? oData.isLogin_var : oData.isLogin_var ? '是' : '否'}`
    });
}

/**
 * 36
 *
 * @export 帖子详情浏览
 * @param {*} oData
 * @param UniqueView_var 浏览用户
 * @param pageEnter_var 入口
 * @param pageName_var 页面名称
 * @param pageTime_var 浏览时长
 */
function view(oData) {
    return gio('track', 'view', oData);
}

/**
 * h5首页资源位
 * @param {*} oData
 */
function h5bitClick(oData) {
    return gio('track', 'h5bitClick', {
        h5bitType_var: '空',
        ...oData
    });
}

function contentItemClick(oData) {
    return gio('track', 'contentItemClick', {
        ...oData
    });
}

function downloadBtnClick(oData) {
    return gio('track', 'downloadBtnClick', {
        ...oData
    });
}

export default {
    contentItemClickh5,
    view,
    h5bitClick,
    contentItemClick,
    downloadBtnClick
};
