import { render, staticRenderFns } from "./PopupTextInput.vue?vue&type=template&id=4ab7d887&"
import script from "./PopupTextInput.vue?vue&type=script&lang=js&"
export * from "./PopupTextInput.vue?vue&type=script&lang=js&"
import style0 from "./PopupTextInput.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports