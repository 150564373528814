// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 * @export 行测活动内容点击条目埋点
 * @param {*} oData
 * @param uniqueView_var 浏览用户
 * @param pageEnter_var 页面来源  fromput
 * @param glide_var 下滑率
 * @param pageName_var 页面名称
 */
function douyinTestXCCommentView(oData) {
    gio('douyinTestXCCommentView', oData);
}

/**
 * @export 行测活动内容点击条目埋点
 * @param {*} oData
 * @click_var
 */
function douyinTestXCCommentClick(oData) {
    gio('douyinTestXCCommentClick', oData);
}

export default {
    douyinTestXCCommentView,
    douyinTestXCCommentClick
};
