<template>
    <div class="resume-title">
        <span style="flex: auto; line-height: 18px">
            <div class="fs-18 fw-500 name">{{ titleInfo.id === '4' ? (graduated === '0' ? '实习经历' : '工作经历') : titleInfo.name }}</div>
            <div v-if="titleInfo.isNotComplete" class="uncomplete-resume">
                <img :src="$ncimage('/images/mobile/resume/myResumes/uncomplete-tag.png')" alt="" />
            </div>
        </span>
        <IconMobileAdd v-if="titleInfo.hasAdd" class="color-d8d8d8" @click.native="$emit('add', titleInfo.id)"></IconMobileAdd>
        <IconMobileEdit v-if="titleInfo.hasEdit" class="color-d8d8d8" @click.native="$emit('edit', titleInfo.id)"></IconMobileEdit>
    </div>
</template>

<script lang="ts">
import {Component, Prop} from 'vue-property-decorator';
import Vue from '@ncfe/nc.vue';
import {IFormTitleProp} from '../../../types';

import {IconMobileEdit, IconMobileAdd} from '@ncfe/nc.icon.h5';

@Component({components: {IconMobileEdit, IconMobileAdd}})
export default class ModuleTitle extends Vue {
    @Prop(Object) titleInfo?: IFormTitleProp | {};
    @Prop(String) graduated?: string | '0';
}
</script>

<style lang="scss" scoped>
.color-d8d8d8 {
    color: #d8d8d8;
}
.resume-title {
    height: 18px;
    display: flex;
    justify-content: space-between;
    .name {
        display: inline-block;
        line-height: 18px;
    }
}
.color-00b88f {
    color: #00b88f;
}
.color-a5a5a5 {
    color: #a5a5a5;
}
.fw-500 {
    font-weight: 500;
}
.uncomplete-resume {
    width: 38px;
    height: 16px;
    display: inline-block;
    border-radius: 3px;
    vertical-align: bottom;
    img {
        width: 100%;
    }
}
</style>
