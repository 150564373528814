<template>
    <div :style="(cssStyle && cssStyle.parent) || {}" :class="noFixed ? '' : 'tab-nav-fixed'" class="tab-nav flex-row bg-table vux-1px-b">
        <div v-for="item in orders" :key="item.value" :style="'flex:' + width + '%'" class="flex-row" @click="select(item)">
            <slot :name="item.value">
                <a :class="item.value === selected ? 'checked color-green' : 'color-font'" class="hide-txt tab fs-13" :style="item.value === selected ? '' : (cssStyle && cssStyle.child) || {}" href="javascript:void(0);">{{ item.name }}</a>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['orders', 'value', 'noFixed', 'cssStyle'],
    data() {
        var that = this;
        return {
            selected: that.value,
            width: 100 / (that.orders.length || 1)
        };
    },
    methods: {
        select: fSelect
    },
    watch: {
        value: fWatchValue
    }
};

function fSelect(oItem) {
    var that = this;
    that.selected = oItem.value;
    that.$emit('input', oItem.value);
}

function fWatchValue(sVal) {
    var that = this;
    that.selected = sVal;
}
</script>

<style lang="scss">
.tab-nav {
    height: 32px;
    line-height: 30px;

    &.tab-nav-fixed {
        position: fixed !important;
        top: 44px;
        width: 100%;
        z-index: 9;
    }
}
</style>
<style scoped lang="scss">
.tab {
    display: block;
    margin: 0 auto;
    text-align: center;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.3s, color 0.3s;
    &.checked {
        border-bottom-color: $colorGreen;
    }
}
</style>
