<template>
    <Popup v-model="isShow" position="top" popupClass="radius-bottom">
        <div class="bg-white">
            <div class="flex-row-start p-2"><textarea ref="input" v-model="comment" placeholder="此刻你的想法和感受"></textarea></div>

            <div v-if="data.type >= 5 || commentLength > commentWarnLength" class="plr-2 flex-row-start mb-4">
                <div class="flex-auto">
                    <div v-if="data.type >= 5" :class="replayRoot ? 'color-green' : 'color-gray'" @click="replayRoot = !replayRoot"><i class="icon-right flex-none mr-2"></i>同时回复给原作者</div>
                </div>
                <div v-if="commentLength > commentWarnLength" class="flex-none fs-s txt-right ml-2">
                    <div v-if="commentLength <= commentMaxLength" class="textarea-txt-tip">
                        还剩<span class="color-green">{{ commentMaxLength - commentLength }}</span
                        >字
                    </div>
                    <div v-else class="textarea-txt-tip">
                        已超出 <span class="color-red">{{ commentLength - commentMaxLength }}</span> 字
                    </div>
                </div>
            </div>

            <div class="plr-2">
                <div class="bg-table p-2 radius">
                    <div v-if="data.type === 4" class="pb-1 mb-1 quote-pre">
                        <span class="color-font strong" v-html="data.ownerView.user.nickname"></span>
                        <span class="color-gray">{{ transmitText }}</span>
                    </div>
                    <div>
                        <div v-if="title" class="strong" v-html="title"></div>
                        <div>
                            <div v-html="content"></div>
                            <div v-if="suffix">{{ suffix }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="plr-2 ptb-4">
                <x-button type="primary" @click.native="actionForward">发布</x-button>
            </div>
        </div>
    </Popup>
</template>
<script>
var Base = require('@ncfe/nc.base');
var $ = require('@ncfe/nc.jquery');
import Vue from '@ncfe/nc.vue';
import {XButton} from '@ncfe/nc.vux';
import Popup, {PopupMix, PopupShow} from '@nc/vue.popup';
import UIUtil from '@/js/lib/util/ui';
import CircleAction from '@/js/lib/action/circle';
import CmtAction from '@/js/lib/action/cmt';
export default {
    show: PopupShow(Vue),
    props: ['data'],
    components: {Popup, XButton},
    mixins: [PopupMix],
    mounted: fMounted,
    data: fData,
    methods: {
        check: fCheck,
        clear: fClear,
        actionForward: fActionForward,
        filterHTML: fFilterHTML,
        getSuffix: fGetSuffix
    },
    computed: {
        root: fRoot,
        commentLength: fCommentLength,
        title: fTitle,
        content: fContent,
        suffix: fSuffix,
        transmitText: fTransmitText
    }
};

function fData() {
    var that = this;
    return {
        comment: '',
        publishing: false,
        replayRoot: false,
        commentWarnLength: 990,
        commentMaxLength: 1000
    };
}

function fMounted() {
    var that = this;
    window.setTimeout(() => $(that.$refs.input).focus(), 250);
}

function fCheck() {
    var that = this;
    var sContent = that.comment.length > that.commentMaxLength ? '字数超出限制啦' : '';
    sContent && UIUtil.alert(sContent);
    return !sContent;
}

function fClear() {
    var that = this;
    that.comment = '';
}

function fActionForward() {
    var that = this;
    if (!that.check()) return;

    var oData = that.data;
    var oExt = that.data.type >= 5 ? JSON.stringify({ext: oData.ext, content: that.data.content}) : oData.ext;
    var nOwnerType = oData.ownerView.ownerType;
    var nOwnerId = nOwnerType === 1 ? Base.json(oData.ext).author : oData.ownerView.user.userId;
    var oForwardData = {
        content: that.comment,
        ext: oExt,
        entityId: oData.sourceId,
        entityType: oData.sourceType,
        entityOwner: nOwnerId,
        uuid: oData.uuid
    };
    var oCmtData = {
        entityType: that.data.sourceType,
        entityOwnerId: nOwnerId || 0,
        entityId: that.data.sourceId,
        commentContent: that.comment
    };

    UIUtil.loading('请稍等...');
    CircleAction.createShare({
        body: oForwardData,
        call: oResult => {
            UIUtil.toast('success', '转发成功');
            that.$emit('success');
            that.replayRoot &&
                CmtAction.createItem({
                    body: oCmtData
                });
            that.close();
        },
        error: oResult => UIUtil.alert(oResult.msg),
        always: () => UIUtil.loading(false)
    });
}

function fGetSuffix(sExt, nType) {
    var that = this;
    return (
        {
            '6': '[打卡]',
            '7': new Array((Base.json(sExt, {}).imgs || []).length + 1).join('[图片]'),
            '8': '[链接]'
        }[nType] || ''
    );
}

function fFilterHTML(sContent) {
    var that = this;
    sContent = sContent.replace(/<img\s+[^>]*?data\-card\-emoji=['"](.*?)['"]\s+[^>]*?src=['"]([^'"]+)[^>]*>/gi, '$1');
    sContent = sContent.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, '[图片]');
    var aPara = sContent.split(/<br[^>]*?>/gi);
    return Base.map(aPara, sPara => $.trim(sPara).replace(/<[^>].*?>/gi, ''));
}

function fRoot() {
    var that = this;
    var oData = that.data;
    var oRoot = oData.root || {};
    var nSourceType = oRoot.sourceType;
    var nRootType = nSourceType === 8 ? 1 : nSourceType === 2 ? 3 : oRoot.momentType + 5;
    var oResult = {...oRoot, type: nRootType};
    if (nRootType >= 5) {
        var oExt = Base.json(oRoot.ext, {});
        oResult = {...oResult, ext: oExt.ext, content: oExt.content};
    }
    return oResult;
}

function fCommentLength() {
    var that = this;
    return $.trim(that.comment).length;
}

function fTitle() {
    var that = this;
    var oData = that.data;
    var nType = oData.type;
    var oExt = Base.json(oData.ext) || {};
    var oRoot = oData.root;
    return nType === 1 || nType === 9 ? oExt.title : nType === 3 ? oData.ownerView.user.nickname + '的回帖：' : nType === 4 ? (oRoot.type === 1 || oRoot.type === 3 || oRoot.type === 9 ? Base.json(oRoot.ext, {}).title : oRoot.ownerView.user.nickname) : oData.ownerView.user.nickname + '说：';
}

function fContent() {
    var that = this;
    var oData = that.data;
    var nType = oData.type;
    var nLength = 0;
    var sContent = '';
    var nMax = 100;
    var oExt = Base.json(oData.ext) || {};
    var oRootExt = Base.json(that.root.ext) || {};
    var aContent = nType === 1 || nType === 9 ? that.filterHTML(oExt.content || '') : nType === 3 ? that.filterHTML(oExt.commentContent || '') : nType === 4 ? that.filterHTML(that.root.type === 1 || that.root.type === 9 ? oRootExt.content || '' : that.root.type === 3 ? oRootExt.commentContent || '' : that.root.content || '') : that.filterHTML(that.data.content);
    Base.forEach(aContent, sPara => {
        if (nLength + sPara.length < nMax) {
            sContent = sContent + '<p>' + sPara + '</p>';
            nLength += sPara.length;
        } else {
            sContent = sContent + sPara.slice(0, nMax - nLength) + '...';
            return Base.BREAK;
        }
    });
    return sContent;
}

function fSuffix() {
    var that = this;
    var nType = that.data.type;
    var oRoot = that.root;
    if (nType === 4) return that.getSuffix(oRoot.ext, oRoot.type);
    return that.getSuffix(that.data.ext, nType);
}

function fTransmitText() {
    var that = this;
    var sText = that.filterHTML(that.data.content).join('');
    return sText.length > 200 ? sText.slice(0, 200) + '…' : sText;
}
</script>

<style lang="scss" scoped>
textarea {
    border: 0;
    outline: 0;
    font-size: $font;
    flex: auto;
    padding: 10px;
    background-color: $colorLightWhite;
    border-radius: 6px;
    height: 60px;
    resize: none;
}

.quote-pre {
    border-bottom: 1px dashed $colorBorder;
    color: $colorGray;
}
</style>
