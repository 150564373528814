let Browser = require('@ncfe/nc.browser');

let WeixinJSBridge = window.jWeixin;

module.exports = {
    weixinShare: fWeixinShare
};

function fWeixinShare(oWxConf) {
    if (!(WeixinJSBridge && Browser.wechat())) return;
    WeixinJSBridge.ready(() => {
        WeixinJSBridge.onMenuShareTimeline(oWxConf);
        WeixinJSBridge.onMenuShareAppMessage(oWxConf);
        WeixinJSBridge.onMenuShareQQ(oWxConf);
        WeixinJSBridge.onMenuShareWeibo(oWxConf);
        WeixinJSBridge.onMenuShareQZone(oWxConf);
    });
}
