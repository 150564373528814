var Ajax = require('./ajax');

module.exports = {
    url: {
        // 搜索公司, get, query: {name: String}
        searchCompany: '/company/search-name',
        // 搜索学校, get, query: {query: String, limit: Number}
        searchSchool: '/school/query-v2',
        // 获取微信jsApi的配置，用于分享等服务, get, query: {url: String, 当前网页的URL，不包含#及其后面部分}
        jsApiConfig: '/weixin-mp/nowcoder/js-api-config',
        // 获取验证码信息
        getGtData: '/captcha/geetest/#{type}',
        // 获取全部国家区号
        getCountries: '/helper/sms/supported_countries',
        getSchoolBrief: '/school/brief',
        addCustomizeSchool: '/school/add/customize',
        // 获取所有求职职位，树形结构
        allCareerJobs: '/completeness/all-career-jobs',
        // 获取所有城市
        getAllPalce: '/city/all',
        // 获取当前用户ip
        getUserIp: '/nccommon/ip/login/get'
    }
};
Ajax.add(module.exports, module.exports.url);
