<template>
    <div>
        <None v-if="error">{{ error }}</None>
        <Loading v-else-if="!data">投票加载中...</Loading>
        <template v-else>
            <NCVote :item="data" :isLoggedIn="!!user" @vote="actionVote" @error="actionError"></NCVote>
        </template>
    </div>
</template>

<script>
import Loading from '@nc/vue.loading';
import None from '@nc/vue.none';
import NCVote from '@nc/vue.vote';
var UserAction = require('@/js/lib/action/user');
var VoteAction = require('@/js/lib/action/vote');

export default {
    props: ['voteId'],
    components: {Loading, NCVote, None},
    data() {
        return {
            data: null,
            user: null,
            error: ''
        };
    },
    mounted: fMounted,
    methods: {
        actionError: fActionError,
        actionVote: fActionVote,
        getUser: fGetUser,
        refresh: fRefresh
    }
};

function fMounted() {
    var that = this;
    that.refresh();
    that.getUser();
}

function fActionError(oData) {
    var that = this;
    if (oData.type === 'login') {
        return that.$vux.alert.show({title: '请登录后投票'});
    }
    if (oData.type === 'msg') {
        return that.$vux.alert.show({title: oData.msg});
    }
}

function fActionVote(oData) {
    var that = this;
    VoteAction.vote({
        body: {
            voteId: oData.voteId,
            options: oData.options
        },
        call: function () {
            that.refresh();
        },
        error: function (oResult) {
            that.$vux.alert.show({title: oResult.msg});
        }
    });
}

function fGetUser() {
    var that = this;
    UserAction.getInfo({
        type: 'GET',
        call(oResult) {
            that.user = oResult.data;
        },
        error() {
            that.user = null;
        }
    });
}

function fRefresh() {
    var that = this;
    that.data = null;
    that.error = '';
    VoteAction.voteInfo({
        query: {voteId: that.voteId},
        call(oResult) {
            that.data = oResult.data;
        },
        error(oResult) {
            that.error = oResult.msg;
        }
    });
}
</script>

<style scoped lang="scss"></style>
