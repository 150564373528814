var Ajax = require('./ajax');
module.exports = {
    url: {
        updateEducationInfo: '/profile/#{uid}/school',
        updateGender: '/profile/change-gender',
        updateEduLevel: '/profile/edu-level',
        getJobs: '/profile/#{uid}/jobs',
        getWrongMenu: '/profile/get-wrong-menu',
        getCareerInfo: '/nccommon/completeness/career-info',
        updateCareerInfo: '/nccommon/completeness/update-career-info'
    }
};
Ajax.add(module.exports, module.exports.url);
