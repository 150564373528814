/**
 * 简历信息的对象
 * var oItem = new Module({
 *     id: String,
 *     menu: Object,
 *     [canMove]: String, true可以移动
 *     [name]: String,
 *     [value]: Object || Array || String, 不同的信息有不同的存储格式
 *     [isSystem]: Boolean, true系统标签
 *     [isSelected]: Boolean, true选中
 *     [isNeed]: Boolean, true必填项, false选填项
 *     [isHide]: Boolean, true隐藏，不可见
 * });
 *
 * 增加1个新项目的流程
 * 1、修改MENU
 * 2、修改initDefault
 * 3、修改getReqVal
 * 4、修改getRenderVal
 */
var Clazz = require('@ncfe/nc.clazz');
var Base = require('@ncfe/nc.base');
var Str = require('@ncfe/nc.str');
var DataMenu = require('./dataMenu');
var Module = (module.exports = Clazz.create());

Base.extend(Module.prototype, {
    initialize: fInitialize,
    initData: fInitData,
    initDefault: fInitDefault,
    updateVal: fUpdateVal,
    // 检查完成度
    isComplete: fIsComplete,
    isEmpty: fIsEmpty,

    getReqVal: fGetReqVal,
    getRenderVal: fGetRenderVal,

    isShowField: fIsShowField,
    isNeedField: fIsNeedField,

    _getYear: _fGetYear,
    _getYearDesc: _fGetYearDesc,
    _getDurationStr: _fGetDurationStr
});

function fInitialize(oConf) {
    var that = this;
    that.rawConfig = oConf;
    that.menu = oConf.menu;
    that.basic = oConf.basic || {};
    // 设置数据
    that.initData();
    // 设置默认值
    that.initDefault();
    // 赋值
    oConf.value && that.updateVal(oConf.value);
}

function fInitData() {
    var that = this;
    var oConf = that.rawConfig;
    that.id = Base.id(oConf.id);
    that.name = oConf.name;
    that.desc = oConf.desc;
    that.canMove = oConf.canMove !== false;
    // 一些判断条件
    that.isSelected = oConf.isSelected !== false;
    that.isSystem = !!(oConf.isSystem || that.menu.map[that.id]);
    that.isNeed = !!oConf.isNeed;
    that.isHide = !!oConf.isHide;
    that.isBase = that.id === DataMenu.ID_BASE;
    that.isAvatar = that.id === DataMenu.ID_AVATAR;
}

function fInitDefault() {
    var that = this;
    var sId = that.id;
    that.name = that.name || that.menu.getName(sId);
    that.value = that.value || that.menu.getDefault(sId);
}

function fUpdateVal(oVal) {
    var that = this;
    var sId = that.id;

    var bIsMult = that.menu.getIsMult(sId);
    var aKey = that.menu.getKey(sId);
    if (bIsMult) {
        that.value = (Base.isArray(oVal) ? oVal : oVal ? [oVal] : []).slice(0);
    } else if (aKey.length > 0) {
        oVal = Base.isArray(oVal) ? oVal[0] : Base.isObject(oVal) ? oVal : null;
        that.value = Base.extend({}, that.value, oVal);
    } else {
        that.value = oVal;
    }

    // 一些调整
    var oVal = that.value;
    if (that.isBase) {
        // 工作时长
        var nYear = that._getYear(oVal.workTime);
        oVal.workYears = Base.id(oVal.graduated) === '0' ? '学生' : (nYear || '0') > 0 ? nYear + '年工作经验' : '1年以内工作经验';
        // 证件信息
        var sIdCardType = oVal.idCardType;
        if (sIdCardType) {
            var sIdCardTitle = !sIdCardType ? '' : {0: '其他证件', 1: '身份证', 2: '护照', 3: '港澳通行证', 4: '台胞证', 5: '军官证'}[sIdCardType] || '';
            oVal.idCardTitle = sIdCardTitle;
        }
        // 年龄
        var nAge = that._getYear(oVal.birthTime);
        oVal.age = nAge === 0 ? '' : nAge + '岁';
    }
}

function fIsComplete(sJobId) {
    var that = this;
    var sId = that.id;
    var oBaseIgnoreMap = {workTime: true, idCardTitle: true, phoneCountryCode: true};
    // 基础信息不需要检查的字段
    // debugger;
    if (that.isBase) {
        oBaseIgnoreMap.idCardType = !that.isNeedField('5');
        oBaseIgnoreMap.idCard = !that.isNeedField('5');
        oBaseIgnoreMap.birthPlace = !that.isNeedField('9');
        oBaseIgnoreMap.party = !that.isNeedField('10');
        oBaseIgnoreMap.eduLevel = +sJobId > 0 ? false : true;
        oBaseIgnoreMap.liveAddress = +sJobId > 0 ? false : true;
    }
    // 个人作品只校验worksName
    if (sId === DataMenu.ID_ATTACH) {
        return Base.every(that.value, oAttach => oAttach.worksName !== '') && that.value.length > 0;
    }

    return that.isSelected && _fCheck(that.value);

    function _fCheck(oItem) {
        if (that.isBase) {
            return _fIsFullObj(oItem, true);
        } else if (that.menu.getIsMult(sId)) {
            var aKey = that.menu.getKey(sId, true);
            return _fIsFullWithKey(oItem, aKey);
        }
        return !that.isEmpty();
    }

    // 基本信息中的工作时间涉及到教育经历的填写，在这里检查会造成误会
    // 投递工作的简历，必须包含身份证信息
    function _fIsFullObj(oItem, bBase) {
        var aKey = Base.keys(oItem);
        return (
            aKey.length > 0 &&
            Base.every(aKey, function (sKey) {
                return !!oItem[sKey] || (bBase && !!oBaseIgnoreMap[sKey]);
            })
        );
    }

    function _fIsFullWithKey(aItem, aKey) {
        var bRight = true;
        Base.forEach(aItem, function (oItem) {
            bRight = Base.every(aKey, function (sKey) {
                return !!oItem[sKey];
            });
            return bRight ? null : Base.BREAK;
        });
        // 处理空数组的情况
        if (Array.isArray(aItem) && aItem.length === 0) {
            return false;
        }
        return bRight;
    }
}

function fIsEmpty() {
    var that = this;
    return that.isSelected && _fCheck(that.value);

    function _fCheck(oItem) {
        if (Base.isObject(oItem)) {
            return _fIsEmptyObj(oItem);
        } else if (Base.isArray(oItem)) {
            return Base.every(oItem, function (oItem) {
                return _fCheck(oItem);
            });
        } else if (Base.isString(oItem)) {
            return !oItem;
        }
        return false;
    }

    function _fIsEmptyObj(oItem) {
        var aVal = Base.values(oItem);
        return (
            aVal.length === 0 ||
            Base.every(aVal, function (sVal) {
                return !sVal;
            })
        );
    }
}

function fGetReqVal() {
    var that = this;
    var oResult = {id: that.id, name: that.name};
    !that.isSelected && (oResult.noSelected = true);
    if (!that.isBase && !that.isAvatar) {
        oResult.value = that.value;
    }
    return oResult;
}

function fGetRenderVal() {
    var that = this;
    if (!that.isSystem && (!that.value || Base.isString(that.value))) {
        return [{desc: that.value}];
    }
    var aResult = [];
    var sId = that.id;
    var aVal = Base.isArray(that.value) ? that.value : [that.value];
    Base.forEach(aVal, function (oVal) {
        var oData = null;
        var aKey = that.menu.getKey(sId);
        // !!!这里兼容一下没有配置dataMenu的情况，直接从值里面获取key
        aKey = aKey.length === 0 && Base.isObject(oVal) ? Object.keys(oVal) : aKey;
        // 没有属性值，也就是只有一个输入框，直接使用当前的值
        // 有属性值，需要做下判断
        if (aKey.length === 0) {
            oData = {desc: oVal};
        } else {
            var oData = sId === DataMenu.ID_ATTACH && oVal && oVal.url && oVal.name ? {html: '<a href="' + window.encodeURI(oVal.url) + '" target="_blank">' + Str.encodeHTML(oVal.name) + '</a>'} : null;
            if (!oData) {
                var bHasDuration = Base.contains(aKey, 'start') && Base.contains(aKey, 'end');
                // var bHasDesc = Base.contains(aKey, 'description');
                var aTitle = [];
                Base.forEach(aKey, function (sKey) {
                    if (!{description: 1}[sKey] && (!bHasDuration || !{start: 1, end: 1}[sKey])) {
                        var bFirst = {start: 1, location: 1, edu: 1}[sKey];
                        var sVal = oVal[sKey];
                        // 一些特殊值
                        sId === DataMenu.ID_EDU && sKey === 'rank' && sVal && (sVal = '专业排名' + sVal);
                        aTitle[bFirst ? 'unshift' : 'push'](sVal);
                    }
                });
                // 加上时间间隔
                bHasDuration && aTitle.unshift(that._getDurationStr(oVal.start, oVal.end));
                oData = {title: aTitle, desc: oVal.description};
            }
        }
        oData && aResult.push(oData);
    });
    return aResult;
}

function fIsShowField(sId) {
    var that = this;
    if (!that.isBase) {
        return true;
    }
    var oMap = (that.basic || {}).show;
    return !!oMap[sId];
}

function fIsNeedField(sId) {
    var that = this;
    if (!that.isBase) {
        return true;
    }
    var oMap = (that.basic || {}).need;
    return !!oMap[sId];
}

function _fGetYear(sStr) {
    sStr = sStr || '';
    var nResult = 0;
    if (/\d{4}\.\d{2}/.test(sStr)) {
        nResult = +/(\d{4}).\d{2}/.exec(sStr)[1] || 0;
    }
    return nResult === 0 ? 0 : new Date().getFullYear() - nResult;
}

function _fGetYearDesc(sStr) {
    return sStr + '' === '-1' ? '至今' : sStr || '';
}

function _fGetDurationStr(sStart, sEnd) {
    var that = this;
    sEnd = that._getYearDesc(sEnd);
    return sStart || sEnd ? (sStart || '未知') + ' - ' + (sEnd || '未知') : '';
}
