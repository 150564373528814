<!--
 * @Author: your name
 * @Date: 2021-09-22 16:04:37
 * @LastEditTime: 2021-09-23 12:15:28
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /sparta_reborn_front/sparta_mobile/src/main/webapp/mobile/app/src/js/page/resume/components/resumeCpn/cpn/attachment.vue
-->
<template>
    <div>
        <div v-for="(attachItem, index) in item" :key="index" @click="$emit('clickPushEdit', index + 1)">
            <div class="flex-row-start mt-4">
                <span class="flex-auto fw-500 fs-14">{{ attachItem.worksName }}</span>
                <IconMobileRight v-if="isEdit" class="color-d8d8d8 fs-14"></IconMobileRight>
            </div>
            <div v-if="attachItem.worksLink" class="color-a5a5a5" style="margin: 12px 0">{{ attachItem.worksLink }}</div>
            <div v-if="attachItem.worksDesc" class="color-a5a5a5">{{ attachItem.worksDesc }}</div>
            <div v-if="attachItem.name" class="flex-row-start mt-4 attachmnet-item">
                <IconResumeAttachment class="color-a5a5a5" size="12"></IconResumeAttachment>
                <span class="flex-auto fw-500 fs-14 color-a5a5a5">{{ attachItem.name }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Prop} from 'vue-property-decorator';
import Vue from '@ncfe/nc.vue';
import {IconResumeAttachment} from '@ncfe/nc.icon';
import {IconMobileRight} from '@ncfe/nc.icon.h5';

@Component({components: {IconResumeAttachment, IconMobileRight}})
export default class AttachmentItem extends Vue {
    @Prop(Array) item?: any | [];
    @Prop(Boolean) isEdit?: boolean;
}
</script>

<style lang="scss" scoped>
.attachmnet-item {
    background: #fafafa;
    border-radius: 10px;
    padding: 17px 16px;
    line-height: 14px;
    margin-top: 16px;
}
</style>
