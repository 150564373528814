// 定义 growing Io 统计事件
const gio = (eventName, variable) => {
    if (!window.gio) return;
    window.gio('track', eventName, variable);
};

/**
 *
 * @export 下载页面浏览
 * @param {*} oData
 * @param UniqueView_var 浏览用户
 * @param PageEnter_var 页面来源  fromput
 * @param TimeView_var 浏览时长
 * @param Glide_var 下滑率
 * @param PageName_var 页面名称
 */
function AppDownload_PageView(oData) {
    gio('AppDownload_PageView', oData);
}

/**
 *
 * @export 点击下载按钮
 * @param {*} oData
 * @param Glide_var 下滑率
 * @param PageName_var 页面名称
 * @param downloadEnter_var 页面来源 fromput
 */
function ClickDownload(oData) {
    gio('ClickDownload', oData);
}

/**
 *
 * @export 下载页面轮播图浏览
 * @param {*} oData
 * @param PageExposure_var 页面名称
 */
function AppDownload_PageExposure(oData) {
    gio('AppDownload_PageExposure', oData);
}

/**
 *
 * @export 跳转App
 * @param {*} oData
 * @param jumpEnter_var 下载渠道
 * @param activityName_var 活动名称
 * @param pageType_var 页面类型
 */
function jump(oData) {
    gio('jump', oData);
}

/**
 *
 * @export 知页h5下载页页面曝光
 * @param {*} oData
 * @param channel_var 渠道信息
 */
function zhiyeDownloadPageView(oData) {
    gio('zhiyeDownloadPageView', oData);
}

/**
 *
 * @export 下载app按钮点击
 * @param {*} oData
 * @param downloadPosition_var 下载位置
 * @param pageName_var 页面名称
 * @param channel_var 渠道信息
 */
function downloadAppClick(oData) {
    gio('downloadAppClick', oData);
}

export default {
    AppDownload_PageView,
    ClickDownload,
    AppDownload_PageExposure,
    jump,
    zhiyeDownloadPageView,
    downloadAppClick
};
