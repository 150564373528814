import Vue from '@ncfe/nc.vue';
import {ToastPlugin} from '@ncfe/nc.vux';
Vue.use(ToastPlugin);

let oVue = new Vue();
const bossCopy = {
    bind(el, {value}, vNode) {
        el.$value = value.value;
        el.$tips = value.tips;
        el.$noShowTips = value.noShowTips;
        el.handler = () => {
            if (!el.$value && !el.$noShowTips) {
                oVue.$vux.toast.text('没有复制内容');
                return;
            }
            const textarea = document.createElement('textarea');
            textarea.readOnly = 'readOnly';
            textarea.style.position = 'absolute';
            textarea.style.left = '-99999px';
            textarea.value = el.$value;
            document.body.append(textarea);
            textarea.select();
            const result = document.execCommand('copy');
            if (result && !el.$noShowTips) {
                oVue.$vux.toast.show({text: el.$tips || '复制成功'});
            }
            document.body.removeChild(textarea);
        };
        el.addEventListener('click', el.handler);
    },
    componentUpdated(el, {value}) {
        el.$value = value.value;
        el.$tips = value.tips;
        el.$noShowTips = value.noShowTips;
    },
    unbind(el) {
        el.removeEventListener('click', el.handler);
    }
};

export default bossCopy;
