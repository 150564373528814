import Bll from './bll';
import Cmt from './cpn/Cmt';

export default {
    getConf: fGetConf
};

function fGetConf(oConf = {}) {
    var oInfo = window.pageInfo || {};
    return {
        bll: Bll,
        action: {
            listFilter: null, // 过滤列表 (fCb) => {}
            feedCheckNew: null, // 检查更新  (fCb) => {}
            feedLastTop: null, // 获取指定动态
            feedRefreshTop: null, // 刷新置顶动态
            feedRefresh: null, // 刷新动态列表  (fCb) => {}
            ...oConf.action
        },
        config: {
            uid: +(window.globalInfo || {}).ownerId || 0,
            isAdmin: oConf.isFeedAdmin || oInfo.isCircleAdmin || oInfo.isFeedAdmin || oInfo.isCircleAdmin,
            menu: ['favorite', 'block', 'followUser', 'followTag', 'blockCircle', 'blockUser', 'report', 'remove', 'delete'],

            lastTimeRead: 0, // 上一次读到的时间戳
            isAutoOpenCmt: false, // true自动打开评论
            CmtCpn: Cmt, // 评论组件
            ...oConf.config
        }
    };
}
